define("cc-frontend/lib/actions/course/date/COURSE_DATE_FORCE_ON", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/utils/filter-dates/course", "lodash-es", "moment", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _patchUpdater, _base, _planbook, _course, _lodashEs, _moment, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = courseDateForceOnAction;

  function courseDateForceOnAction(payload) {
    return {
      name: "COURSE_DATE_FORCE_ON",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    // Add courseDateToAdd if necessary
    let patch;

    if (payload.shouldAddCourseDate) {
      patch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dates", payload.courseDateToAdd);
    } else {
      let courseDate = (0, _course.findCourseDateCustom)(payload.course, payload.dateString);
      patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
        id: courseDate.id
      }).set("embedded", "attributes.isForcedOn", true);
    }

    patch.push("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);
    return [patch.patch];
  }

  function undoPatches(payload) {
    let patch;

    if (payload.shouldAddCourseDate) {
      patch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dates", {
        id: payload.courseDateToAdd.id
      });
    } else {
      let courseDate = (0, _course.findCourseDateCustom)(payload.course, payload.dateString);
      patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
        id: courseDate.id
      }).set("embedded", "attributes.isForcedOn", false);
    }

    patch.pull("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);
    return [patch.patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
    let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
    let courseTitle = payload.course.attributes.title;
    let context = (0, _defaultCourseDateContext.default)(payload, models);
    let newContext = (0, _lodashEs.assign)({}, context, {
      forcedOn: true
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: payload.dateString,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "course",
      primary_object_id: payload.course.id,
      primary_object_specifier: "calendar",
      primary_object_id_and_ancestor_ids: [payload.course.id, models.planbook.id],
      adverbial_verb: "toggling",
      adverbial_object_type: "lesson_slot",
      adverbial_object_id: payload.dateString,
      adverbial_object_specifier: "on",
      adverbial_object_id_and_ancestor_ids: [payload.course.id, models.planbook.id]
    };
    return {
      title: "Turned Class Back On",
      titleForAnalytics: "Lesson - Toggle On",
      description: `Turned your ${courseTitle} class back on for ${formattedDateString}`,
      context: newContext,
      activity: (0, _defaultCourseDateActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});