define("cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_DELETE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash", "moment", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/actions/planbook/date/default-planbook-date-activity", "cc-frontend/lib/actions/planbook/date/default-planbook-date-context", "cc-frontend/lib/actions/planbook/date/default-planbook-date-models"], function (_exports, _patchUpdater, _base, _planbook, _lodash, _moment, _datesOffCalculator, _defaultPlanbookDateActivity, _defaultPlanbookDateContext, _defaultPlanbookDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_DATE_ANNOTATION_DELETE = void 0;
  const PLANBOOK_DATE_ANNOTATION_DELETE = {
    name: "PLANBOOK_DATE_ANNOTATION_DELETE",
    params: {
      annotation: "object",
      planbook: "object",
      calledFrom: "string"
    },

    patches(payload) {
      let newAnnotations = _lodash.default.reject(payload.planbook.attributes.calendar.dateAnnotations, annotation => {
        return annotation.id === payload.annotation.id;
      });

      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id).pull("attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let newAnnotations = JSON.parse(JSON.stringify(payload.planbook.attributes.calendar.dateAnnotations)).concat([JSON.parse(JSON.stringify(payload.annotation))]);
      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id).push("attributes.calendar.dateAnnotations", payload.annotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookDateModels.default)(payload, findFn, userId);
      let context = (0, _defaultPlanbookDateContext.default)(payload, models);
      let formattedDateString = (0, _moment.default)(Ember.get(context, "dateString")).format("ddd, MMM. D, YYYY");
      let formattedStartDate = (0, _moment.default)(Ember.get(context, "startDate")).format("ddd, MMM. D, YYYY");
      let formattedEndDate = (0, _moment.default)(Ember.get(context, "endDate")).format("ddd, MMM. D, YYYY"); // @ts-ignore

      let weekdayTitle = Ember.get(models, "rotation.attributes.title");

      let newContext = _lodash.default.assign({}, context, {
        annotationId: payload.annotation.id
      });

      let titleInnerPhrase = payload.annotation.type === "date-annotation-planbook-custom-single" ? "A Single Day" : payload.annotation.type === "date-annotation-planbook-custom-range" ? "Multiple Days" : `Every ${weekdayTitle}`;
      let title = payload.annotation.attributes.isOff === true ? `Turned ${titleInnerPhrase} Back On` : `Deleted a note on ${titleInnerPhrase}`;
      let descriptionInnerPhrase = payload.annotation.type === "date-annotation-planbook-custom-single" ? `${formattedDateString}` : payload.annotation.type === "date-annotation-planbook-custom-range" ? `every day from ${formattedStartDate} to ${formattedEndDate}` : `every ${weekdayTitle}`;
      let description = payload.annotation.attributes.isOff === true ? `Turned all classes on ${descriptionInnerPhrase} back on` : `Deleted a note on ${descriptionInnerPhrase}`;
      let analyticsModifier = payload.annotation.type === "date-annotation-planbook-custom-single" ? "Single" : payload.annotation.type === "date-annotation-planbook-custom-range" ? "Range" : "Default";
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        primary_object_type: "planbook",
        primary_object_id_and_ancestor_ids: [payload.planbook.id],
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_verb: "edit",
        primary_object_specifier: "calendar",
        adverbial_verb: "removing",
        adverbial_object_type: "annotation"
      };
      return {
        title,
        titleForAnalytics: `Planbook Date - Delete Annotation - ${analyticsModifier}`,
        description,
        context: newContext,
        activity: (0, _defaultPlanbookDateActivity.default)(payload, models, "removed"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.PLANBOOK_DATE_ANNOTATION_DELETE = PLANBOOK_DATE_ANNOTATION_DELETE;
  var _default = PLANBOOK_DATE_ANNOTATION_DELETE;
  _exports.default = _default;
});