define("cc-frontend/components/planner/menus/add-card/primary/body", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-mt-6"></div>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/office-file-text"
    @clickAction={{fn @addCardToBottom "card-title-value-html"}}
    data-test-add-text-card
  >
    Add Text Card
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/search"
    @clickAction={{fn @addCardToBottom "card-standards"}}
    data-test-add-standards-card
  >
    Add Standards Search Card
  </DS::DropdownMenu::SingleButtonRow>
  {{#if (or (eq @usedAs "lesson") (eq @usedAs "lesson-template"))}}
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/google-logo"
      @clickAction={{@goToAddCard}}
      data-test-add-gc-card
    >
      Add Google Classroom Card...
    </DS::DropdownMenu::SingleButtonRow>
  {{/if}}
  */
  {
    "id": "f9JUZCkq",
    "block": "[[[10,0],[14,0,\"tw-mt-6\"],[12],[13],[1,\"\\n\"],[8,[39,0],[[24,\"data-test-add-text-card\",\"\"]],[[\"@iconName\",\"@clickAction\"],[\"icons/office-file-text\",[28,[37,1],[[30,1],\"card-title-value-html\"],null]]],[[\"default\"],[[[[1,\"\\n  Add Text Card\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],[[24,\"data-test-add-standards-card\",\"\"]],[[\"@iconName\",\"@clickAction\"],[\"icons/search\",[28,[37,1],[[30,1],\"card-standards\"],null]]],[[\"default\"],[[[[1,\"\\n  Add Standards Search Card\\n\"]],[]]]]],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,2],\"lesson\"],null],[28,[37,4],[[30,2],\"lesson-template\"],null]],null],[[[1,\"  \"],[8,[39,0],[[24,\"data-test-add-gc-card\",\"\"]],[[\"@iconName\",\"@clickAction\"],[\"icons/google-logo\",[30,3]]],[[\"default\"],[[[[1,\"\\n    Add Google Classroom Card...\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@addCardToBottom\",\"@usedAs\",\"@goToAddCard\"],false,[\"d-s/dropdown-menu/single-button-row\",\"fn\",\"if\",\"or\",\"eq\"]]",
    "moduleName": "cc-frontend/components/planner/menus/add-card/primary/body.hbs",
    "isStrictMode": false
  });

  let AddCardBody = (_dec = Ember.inject.service, (_class = class AddCardBody extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AddCardBody;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddCardBody);
});