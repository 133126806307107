define("cc-frontend/components/icons/cancel-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    ...attributes
  >
    <g clip-path="url(#a)">
      <rect width="18" height="18" fill="#fff" rx="9"></rect>
      <path
        d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm4.117 12.053a.75.75 0 1 1-1.057 1.064l-2.925-2.925a.187.187 0 0 0-.27 0L5.94 13.117a.751.751 0 0 1-1.058-1.065l2.926-2.917a.187.187 0 0 0 0-.27L4.883 5.94A.75.75 0 0 1 5.94 4.882l2.925 2.926a.187.187 0 0 0 .27 0l2.925-2.925a.75.75 0 0 1 1.057 1.057l-2.925 2.925a.187.187 0 0 0 0 .27l2.925 2.918Z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <rect width="18" height="18" fill="#fff" rx="9"></rect>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "mJr12EfE",
    "block": "[[[11,\"svg\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"width\",\"18\"],[24,\"height\",\"18\"],[24,\"fill\",\"none\"],[24,\"viewBox\",\"0 0 18 18\"],[17,1],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"rect\"],[14,\"width\",\"18\"],[14,\"height\",\"18\"],[14,\"fill\",\"#fff\"],[14,\"rx\",\"9\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm4.117 12.053a.75.75 0 1 1-1.057 1.064l-2.925-2.925a.187.187 0 0 0-.27 0L5.94 13.117a.751.751 0 0 1-1.058-1.065l2.926-2.917a.187.187 0 0 0 0-.27L4.883 5.94A.75.75 0 0 1 5.94 4.882l2.925 2.926a.187.187 0 0 0 .27 0l2.925-2.925a.75.75 0 0 1 1.057 1.057l-2.925 2.925a.187.187 0 0 0 0 .27l2.925 2.918Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"rect\"],[14,\"width\",\"18\"],[14,\"height\",\"18\"],[14,\"fill\",\"#fff\"],[14,\"rx\",\"9\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/cancel-x.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});