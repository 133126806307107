define("cc-frontend/components/group/dashboard/dashboard-nav.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "left-dashboard-nav": "_left-dashboard-nav_6557kj",
    "back-button": "_back-button_6557kj",
    "dashboard-header": "_dashboard-header_6557kj",
    "dashboard-title": "_dashboard-title_6557kj",
    "nav-options-group": "_nav-options-group_6557kj",
    "nav-option": "_nav-option_6557kj"
  };
  _exports.default = _default;
});