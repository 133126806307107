define("cc-frontend/lib/actions/lesson/LESSON_CARD_REMOVE_ATTACHMENT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "lodash-es", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _lodashEs, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_REMOVE_ATTACHMENT = void 0;
  const LESSON_CARD_REMOVE_ATTACHMENT = {
    name: "LESSON_CARD_REMOVE_ATTACHMENT",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).pull("embedded", "attributes.attachments", {
        id: payload.attachment.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).addToSet("embedded", "attributes.attachments", payload.attachment).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let card = (0, _lodashEs.find)((0, _lodashEs.get)(models, "cardStack.attributes.cards"), {
        id: payload.sectionId
      });
      let cardTitle = (0, _lodashEs.get)(card, "attributes.title");
      let attachment = payload.attachment;
      let attachmentTitle = (0, _lodashEs.get)(attachment, "title");
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = (0, _lodashEs.assign)({}, context, {
        cardTitle: cardTitle,
        attachmentTitle
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "attachments",
        adverbial_verb: "removing",
        adverbial_object_type: "attachment",
        adverbial_object_specifier: null,
        adverbial_object_id: payload.attachment.id,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Removed a File",
        titleForAnalytics: "Attachment - Remove",
        description: `Removed 1 file from "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "removed"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_REMOVE_ATTACHMENT = LESSON_CARD_REMOVE_ATTACHMENT;
  var _default = LESSON_CARD_REMOVE_ATTACHMENT;
  _exports.default = _default;
});