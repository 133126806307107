define("cc-frontend/models/analytics/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createBlankEvent = createBlankEvent;

  /**
   * The verb list should stay short so we don't have similiar verbs that are
   * essentially the same
   */

  /**
   * This should stay a smaller, more thoughtful list. The more objects, the more
   * things to think about. We want to keep these meaningful.
   */

  /**
   * We should expect this to get quite long as there are quite a few
   * dimensions to objects. It's not a problem if this grows, but we should make
   * sure there isn't duplication.
   */

  /**
   * The verb list should stay short so we don't have similiar verbs that are
   * essentially the same
   */

  /**
   * This will grow unboundedly.
   */
  function createBlankEvent() {
    return {
      date_plan_scheduled_for: null,
      adverbial_verb: null,
      planbook_id: null,
      primary_possessive: null,
      primary_verb: null,
      primary_object_type: null,
      primary_object_id: null,
      primary_object_id_and_ancestor_ids: null,
      primary_object_specifier: null,
      adverbial_object_type: null,
      adverbial_possessive: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_id_and_ancestor_ids: null
    };
  }
});