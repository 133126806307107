define("cc-frontend/components/user/onboarding.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sign-out-button": "_sign-out-button_qmyo7x"
  };
  _exports.default = _default;
});