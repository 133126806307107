define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY", ["exports", "cc-frontend/lib/find-course-date", "date-fns", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context", "cc-frontend/models/analytics/base", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/planbook", "@mobily/ts-belt"], function (_exports, _findCourseDate, _dateFns, _lodashEs, _patchUpdater, _defaultPlanbookLessonsActivity, _defaultPlanbookLessonsContext, _base, _defaultLessonModels, _planbook, _tsBelt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareEraseAllLessonsForDay = prepareEraseAllLessonsForDay;
  _exports.default = _exports.PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY = void 0;

  function prepareEraseAllLessonsForDay(payload) {
    return {
      name: PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.name,
      payload: payload,
      patches: PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.patches(payload),
      undoPatches: PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.narrative, payload)
    };
  }

  const PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY = {
    name: "PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY",

    patches(payload) {
      return (0, _lodashEs.compact)((0, _lodashEs.map)(payload.courses, course => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);

        if (courseDate) {
          return _patchUpdater.default.create("course", course.id, "attributes.calendar.dates", {
            id: courseDate.id
          }).set("embedded", "attributes.cardStackId", null).inc("attributes.calendar.version", 1).patch;
        } else {
          return null;
        }
      }));
    },

    undoPatches(payload) {
      return (0, _lodashEs.compact)((0, _lodashEs.map)(payload.courses, course => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);

        if (courseDate) {
          return _patchUpdater.default.create("course", course.id, "attributes.calendar.dates", {
            id: courseDate.id
          }).set("embedded", "attributes.cardStackId", courseDate.attributes.cardStackId).inc("attributes.calendar.version", 1).patch;
        } else {
          return null;
        }
      }));
    },

    narrative(payload, findFn, userId) {
      let planbook = findFn("planbook", payload.planbookId);
      let formattedDateString = (0, _dateFns.format)((0, _dateFns.parseISO)(payload.dateString), "EEE, LLL. d, yyyy");
      let analyticsEvents = (0, _tsBelt.pipe)(payload.courses, _tsBelt.A.filter(course => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);
        return (courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId) !== undefined && courseDate.attributes.cardStackId !== null;
      }), _tsBelt.A.map(course => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);
        let cardStackId = courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId; // We should never hit this because of the filter above ^

        if (cardStackId === undefined || cardStackId === null) return null;
        let models = (0, _defaultLessonModels.default)({
          cardStackId: cardStackId,
          planbookId: payload.planbookId
        }, findFn, userId);
        return { ...(0, _base.createBlankEvent)(),
          root_id: models.planbook.id,
          root_type: "planbook",
          primary_object_id: cardStackId,
          date_plan_scheduled_for: models.dateStringForCardStack,
          primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
          primary_verb: "delete",
          primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
          primary_object_type: "lesson",
          primary_object_specifier: null,
          adverbial_verb: "removing",
          adverbial_object_type: "lesson",
          adverbial_object_specifier: "entire_day",
          adverbial_object_id: null,
          adverbial_object_id_and_ancestor_ids: null
        };
      }), arr => (0, _lodashEs.compact)(arr));
      return {
        title: "Erased An Entire Day Of Lessons",
        titleForAnalytics: "Lesson - Erase Entire Day",
        description: `Erased all the lessons on ${formattedDateString} and removed their lesson histories`,
        context: (0, _defaultPlanbookLessonsContext.default)(planbook, payload),
        activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbookId, userId),
        newAnalyticsEvents: analyticsEvents
      };
    }

  };
  _exports.PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY = PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY;
  var _default = PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY;
  _exports.default = _default;
});