define("cc-frontend/lib/actions/lesson/default-lesson-models", ["exports", "lodash-es", "cc-frontend/lib/unit-utils"], function (_exports, _lodashEs, _unitUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, findFn, userId) {
    var _course;

    // fetching card stack
    let cardStack = findFn("card-stack", payload.cardStackId) || findFn("card-stack-summary", payload.cardStackId);
    let cardStackType = (0, _lodashEs.get)((0, _lodashEs.get)(cardStack, "attributes"), "usedAs");
    let course = null;
    let courseDate = null;
    let dateStringForCardStack = null;

    if (!(0, _lodashEs.isNil)((0, _lodashEs.get)(cardStack, "relationships.course.data.id"))) {
      course = findFn("course", (0, _lodashEs.get)(cardStack, "relationships.course.data.id"));
    }

    if (course) {
      courseDate = (0, _unitUtils.getCourseDateForCardStackId)(course.attributes.calendar.dates, payload.cardStackId);
    }

    if (courseDate && courseDate.type === "course-date-custom") {
      dateStringForCardStack = courseDate.attributes.dateString;
    } // finding planbook


    let planbookId = payload.planbookId || (0, _lodashEs.get)(payload, "planbook.id") || (0, _lodashEs.get)(cardStack, "relationships.planbook.data.id");
    let planbook = findFn("planbook", planbookId);
    let cardStackIdAndAncestorIds = (0, _lodashEs.compact)([planbookId, (_course = course) === null || _course === void 0 ? void 0 : _course.id, payload.cardStackId]); // finding unit

    let unit = cardStackType === "unit" && course !== null ? (0, _lodashEs.find)(course.attributes.calendar.units, {
      id: payload.cardStackId
    }) : null; // Just ensure unit is not undefined

    if (unit === undefined) unit = null; // finding the user

    let user = findFn("user", userId);
    return {
      cardStack,
      cardStackType,
      course,
      dateStringForCardStack,
      cardStackIdAndAncestorIds,
      courseDate,
      planbook,
      unit,
      user
    };
  }
});