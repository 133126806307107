define("cc-frontend/components/planner/menus/lesson-menu/primary/body-read-only", ["exports", "@ember-decorators/component", "cc-frontend/lib/actions/lesson/LESSON_COPY", "moment"], function (_exports, _component, _LESSON_COPY, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @courseIsNonInstructional}}
    <DS::DropdownMenu::SingleButtonRow
      @isAtTopOfMenu={{true}}
      @iconName="icons/copy-doc"
      @clickAction={{this.copyLesson}}
    >
      Copy Notes
    </DS::DropdownMenu::SingleButtonRow>
  {{else}}
    <DS::DropdownMenu::SingleButtonRow
      @isAtTopOfMenu={{true}}
      @iconName="icons/copy-doc"
      @clickAction={{this.copyLesson}}
    >
      Copy Lesson
    </DS::DropdownMenu::SingleButtonRow>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/print-text"
      @clickAction={{this.printOrDownload}}
    >
      Print or Download
    </DS::DropdownMenu::SingleButtonRow>
  {{/if}}
  */
  {
    "id": "UfmOnPLe",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@isAtTopOfMenu\",\"@iconName\",\"@clickAction\"],[true,\"icons/copy-doc\",[30,0,[\"copyLesson\"]]]],[[\"default\"],[[[[1,\"\\n    Copy Notes\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,[[\"@isAtTopOfMenu\",\"@iconName\",\"@clickAction\"],[true,\"icons/copy-doc\",[30,0,[\"copyLesson\"]]]],[[\"default\"],[[[[1,\"\\n    Copy Lesson\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@iconName\",\"@clickAction\"],[\"icons/print-text\",[30,0,[\"printOrDownload\"]]]],[[\"default\"],[[[[1,\"\\n    Print or Download\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@courseIsNonInstructional\"],false,[\"if\",\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/lesson-menu/primary/body-read-only.hbs",
    "isStrictMode": false
  });

  let LessonMenuPrimaryBodyReadOnly = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class LessonMenuPrimaryBodyReadOnly extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "lesson", void 0);

      _defineProperty(this, "courseId", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "modalManager", _descriptor3, this);
    }

    copyLesson() {
      this.closeMenu();

      if (this.session.isAuthenticated !== true) {
        this.session.mustSignInToProceed("Copy Lesson");
        return;
      }

      if (this.session.userId !== null) {
        this.store.dispatchPreparedAction((0, _LESSON_COPY.prepareLessonCopy)({
          lessonToCopy: this.lesson,
          cardStackId: this.lesson.id,
          currentUserId: this.session.userId,
          ownerIds: [this.planbook.relationships.owner.data.id],
          planbookId: this.planbook.id,
          courseId: this.courseId
        }));
      }
    }

    printOrDownload() {
      //pass in medium as param
      this.modalManager.openModal("planner/modals/print-download", {
        courseId: this.courseId,
        planbookId: this.planbook.id,
        startDate: this.dateString,
        endDate: this.dateString,
        layout: "course-date:single",
        currentUserId: this.session.userId,
        canPrint: true,
        dateRangeString: (0, _moment.default)(this.dateString).format("dddd, MMMM Do YYYY")
      });
      this.closeMenu();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "copyLesson", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "copyLesson"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "printOrDownload", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "printOrDownload"), _class2.prototype)), _class2)) || _class);
  _exports.default = LessonMenuPrimaryBodyReadOnly;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonMenuPrimaryBodyReadOnly);
});