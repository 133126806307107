define("cc-frontend/components/icons/location-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg fill="none" viewBox="0 0 9 9" ...attributes>
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".563"
      d="M3.182 3.182a1.318 1.318 0 1 0 2.636 0 1.318 1.318 0 0 0-2.636 0Z"
    ></path>
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".563"
      d="M4.5.545a2.637 2.637 0 0 1 2.637 2.637c0 1.197-1.784 4.2-2.417 5.155a.264.264 0 0 1-.44 0c-.633-.954-2.417-3.958-2.417-5.155A2.637 2.637 0 0 1 4.5.545Z"
    ></path>
  </svg>
  */
  {
    "id": "M5Xs6qbA",
    "block": "[[[11,\"svg\"],[24,\"fill\",\"none\"],[24,\"viewBox\",\"0 0 9 9\"],[17,1],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"currentColor\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\".563\"],[14,\"d\",\"M3.182 3.182a1.318 1.318 0 1 0 2.636 0 1.318 1.318 0 0 0-2.636 0Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"currentColor\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\".563\"],[14,\"d\",\"M4.5.545a2.637 2.637 0 0 1 2.637 2.637c0 1.197-1.784 4.2-2.417 5.155a.264.264 0 0 1-.44 0c-.633-.954-2.417-3.958-2.417-5.155A2.637 2.637 0 0 1 4.5.545Z\"],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/location-pin.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});