define("cc-frontend/lib/actions/UNDO_ACTION", ["exports", "cc-frontend/models/analytics/base", "lodash-es"], function (_exports, _base, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = undoAction;

  function undoAction(payload) {
    return {
      name: "UNDO_ACTION",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    return payload.patches;
  }

  function narrative(payload, findFn, userId) {
    let context = (0, _lodashEs.assign)({}, payload.originalActionNarrative.context, {
      originalAction: payload.originalActionNarrative.titleForAnalytics
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: null,
      root_type: "app",
      date_plan_scheduled_for: null,
      primary_verb: "use",
      primary_possessive: "owns",
      primary_object_type: "app",
      primary_object_id: "commoncurriculum.com",
      primary_object_specifier: "undo",
      primary_object_id_and_ancestor_ids: ["commoncurriculum.com"],
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: null
    };
    return {
      title: `Undo ${payload.originalActionNarrative.title}`,
      titleForAnalytics: `Undo Action`,
      description: `Undo ${payload.originalActionNarrative.description}`,
      context,
      activity: payload.originalActionNarrative.activity,
      newAnalyticsEvents: [newEvent]
    };
  }
});