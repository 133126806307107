define("cc-frontend/templates/print/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nAx3ooVa",
    "block": "[[[10,\"style\"],[12],[1,\"\\n  @page { size: 8.5in 11in; margin: .5in .5in; }\\n\"],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@model\"],[[30,1]]],null]],[\"@model\"],false,[\"billing/fiscal-group-quote\"]]",
    "moduleName": "cc-frontend/templates/print/quote.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});