define("cc-frontend/components/icons/ios-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    ...attributes
  >
    <path d="M384 265H264v119h-17V265H128v-17h119V128h17v120h120v17z"></path>
  
  </svg>
  */
  {
    "id": "gw15yLAA",
    "block": "[[[11,\"svg\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"width\",\"512\"],[24,\"height\",\"512\"],[24,\"viewBox\",\"0 0 512 512\"],[17,1],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M384 265H264v119h-17V265H128v-17h119V128h17v120h120v17z\"],[12],[13],[1,\"\\n\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/ios-plus.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});