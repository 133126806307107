define("cc-frontend/instance-initializers/filestack", ["exports", "cc-frontend/config/environment", "filestack-js"], function (_exports, _environment, filestackLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    window.filestack = filestackLibrary;
    let filestack = appInstance.lookup("service:filestack"); // We do this to make sure the picker library loads. Otherwise,
    // there's a big delay when we initialize the dropzone.

    if (window && window.document && _environment.default.environment !== "test") {
      filestack.client.picker({
        container: "dummy-dropzone",
        dropPane: {}
      });
    }
  }

  var _default = {
    name: "filestack",
    initialize: initialize
  };
  _exports.default = _default;
});