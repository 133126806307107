define("cc-frontend/lib/actions/lesson/LESSON_CARD_MOVE_TO_NEW_LESSON", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_MOVE_TO_NEW_LESSON = void 0;
  const LESSON_CARD_MOVE_TO_NEW_LESSON = {
    name: "LESSON_CARD_MOVE_TO_NEW_LESSON",

    patches(payload) {
      let oldLessonPatch = _patchUpdater.default.create("card-stack", payload.oldCardStackId).pull("attributes.cards", {
        id: payload.oldCard.id
      }).patch; // should this be .set? If the array is all the sections including the old one then that makes sense, but if not then shouldn't it be addToSet?


      let newLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).push("attributes.cards", payload.card).patch;

      return [oldLessonPatch, newLessonPatch];
    },

    undoPatches(payload) {
      //Is addToSet correct here? I don't think this accounts for position...
      let oldLessonPatch = _patchUpdater.default.create("card-stack", payload.oldCardStackId).addToSet("attributes.cards", JSON.parse(JSON.stringify(payload.oldCard))).patch;

      let newLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
        id: payload.card.id
      }).patch;

      return [oldLessonPatch, newLessonPatch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);
      let cardTitle = (0, _lodashEs.get)(payload.oldCard, "attributes.title");
      let oldLesson = findFn("card-stack", payload.oldCardStackId);
      let oldLessonTitle = (0, _lodashEs.get)(oldLesson, "attributes.title");
      let newLessonTitle = (0, _lodashEs.get)(context, "cardStackTitle");
      let newContext = (0, _lodashEs.assign)({}, context, {
        moveType: "toNewLesson",
        cardTitle: cardTitle,
        oldLessonTitle: oldLessonTitle,
        oldLessonId: oldLesson.id
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "cards",
        adverbial_verb: "dragging_dropping",
        adverbial_object_type: "card",
        adverbial_object_specifier: "within_lesson",
        adverbial_object_id: payload.card.id,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Moved a Lesson Card",
        titleForAnalytics: "Lesson Card - Move",
        verbForStream: "edited",
        description: `Moved "${cardTitle}" card from ${oldLessonTitle} to ${newLessonTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "moved"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_MOVE_TO_NEW_LESSON = LESSON_CARD_MOVE_TO_NEW_LESSON;
  var _default = LESSON_CARD_MOVE_TO_NEW_LESSON;
  _exports.default = _default;
});