define("cc-frontend/lib/actions/lesson/default-lesson-context", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(_payload, models) {
    let cardStackType = Ember.get(models, "cardStackType");
    let unit = Ember.get(models, "unit");
    let courseDate = Ember.get(models, "courseDate");

    let formattedDateString = function (cardStackType) {
      switch (cardStackType) {
        case "lesson":
          return courseDate ? (0, _dateFns.format)((0, _dateFns.parseISO)(courseDate.attributes.dateString), "EEE, LLL. d, yyyy") : "";

        case "unit":
          return unit ? (0, _dateFns.format)((0, _dateFns.parseISO)(unit.startDate), "EEE, LLL. d, yyyy") + " to " + (0, _dateFns.format)((0, _dateFns.parseISO)(unit.endDate), "EEE, LLL. d, yyyy") : "";

        case "lesson-template":
          return "Template";
      }
    }(cardStackType);

    return {
      cardStackId: Ember.get(models, "cardStack.id"),
      cardStackTitle: Ember.get(models, "cardStack.attributes.title"),
      cardStackType: cardStackType,
      cardStackVersionBeingEdited: Ember.get(models, "cardStack.attributes.version"),
      courseId: Ember.get(models, "course.id"),
      courseTitle: Ember.get(models, "course.attributes.title"),
      date: formattedDateString,
      planbookId: Ember.get(models, "planbook.id"),
      planbookTitle: Ember.get(models, "planbook.attributes.title"),
      userOwnsPlanbook: Ember.get(models, "planbook.relationships.owner.data.id") === Ember.get(models, "user.id"),
      userId: Ember.get(models, "user.id")
    };
  }
});