define("cc-frontend/components/planner/planbook/create-edit/templates-by-course/list", ["exports", "@glimmer/component", "cc-frontend/helpers/find-many", "cc-frontend/utils/filter-dates/course", "lodash-es"], function (_exports, _component, _findMany, _course, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="list" data-test-templates-by-course>
    {{#each @courses.content as |course|}}
      {{#unless course.attributes.isNonInstructional}}
        <Planner::Planbook::CreateEdit::TemplatesByCourse::Item
          @course={{course}}
          @assignTemplateToCourse={{fn @assignTemplateToCourse course.id}}
          @createAndAssignTemplate={{fn @createAndAssignTemplate course.id}}
          @goToTemplateGallery={{fn @goToTemplateGallery course.id}}
          @editTemplate={{fn @editTemplate course.id}}
          @usedTemplates={{this.usedTemplates}}
          @noCoursesHaveTemplates={{this.noCoursesHaveTemplates}}
        />
      {{/unless}}
    {{/each}}
  </div>
  */
  {
    "id": "pH2r3MTr",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"list\"],[[\"from\"],[\"cc-frontend/components/planner/planbook/create-edit/templates-by-course/list.css\"]]]]]],[14,\"data-test-templates-by-course\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"content\"]]],null]],null],null,[[[41,[51,[30,2,[\"attributes\",\"isNonInstructional\"]]],[[[1,\"      \"],[8,[39,4],null,[[\"@course\",\"@assignTemplateToCourse\",\"@createAndAssignTemplate\",\"@goToTemplateGallery\",\"@editTemplate\",\"@usedTemplates\",\"@noCoursesHaveTemplates\"],[[30,2],[28,[37,5],[[30,3],[30,2,[\"id\"]]],null],[28,[37,5],[[30,4],[30,2,[\"id\"]]],null],[28,[37,5],[[30,5],[30,2,[\"id\"]]],null],[28,[37,5],[[30,6],[30,2,[\"id\"]]],null],[30,0,[\"usedTemplates\"]],[30,0,[\"noCoursesHaveTemplates\"]]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[13]],[\"@courses\",\"course\",\"@assignTemplateToCourse\",\"@createAndAssignTemplate\",\"@goToTemplateGallery\",\"@editTemplate\"],false,[\"local-class\",\"each\",\"-track-array\",\"unless\",\"planner/planbook/create-edit/templates-by-course/item\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/create-edit/templates-by-course/list.hbs",
    "isStrictMode": false
  });

  let PlanbookTemplatesByCourseListComponent = (_dec = Ember.inject.service, _dec2 = (0, _findMany.default)("templateIds", "card-stack-summary"), _dec3 = Ember.computed("args.courses.content.length"), (_class = class PlanbookTemplatesByCourseListComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "usedTemplates", _descriptor2, this);
    }

    get templateIds() {
      let planbookTemplateIds = (0, _lodashEs.map)(Ember.get(this.args.planbook.relationships.cardStackTemplates, "data"), datum => Ember.get(datum, "id"));
      let courseTemplateIds = (0, _lodashEs.uniq)((0, _lodashEs.compact)((0, _lodashEs.flatMap)(Ember.get(this.args.courses, "content"), course => (0, _course.extractCardStackTemplateIds)(course))));
      return (0, _lodashEs.reject)((0, _lodashEs.uniq)((0, _lodashEs.concat)(planbookTemplateIds, courseTemplateIds)), el => el === "");
    }

    get noCoursesHaveTemplates() {
      let courseTemplateIds = (0, _lodashEs.uniq)((0, _lodashEs.compact)((0, _lodashEs.flatMap)(this.args.courses.content, course => (0, _course.extractCardStackTemplateIds)(course))));
      return courseTemplateIds.length === 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usedTemplates", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateIds", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "templateIds"), _class.prototype)), _class));
  _exports.default = PlanbookTemplatesByCourseListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookTemplatesByCourseListComponent);
});