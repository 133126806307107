define("cc-frontend/components/icons/circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="currentColor"
    viewBox="0 0 12 12"
  >
    <rect width="12" height="12" rx="6"></rect>
  </svg>
  */
  {
    "id": "O2e3te1q",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"12\"],[14,\"height\",\"12\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 12 12\"],[12],[1,\"\\n  \"],[10,\"rect\"],[14,\"width\",\"12\"],[14,\"height\",\"12\"],[14,\"rx\",\"6\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});