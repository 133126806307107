define("cc-frontend/lib/actions/class-website/CREATE_CLASS_WEBSITE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/class-website/default-class-website-activity", "cc-frontend/lib/actions/class-website/default-class-website-context", "cc-frontend/lib/actions/class-website/default-class-website-models"], function (_exports, _patchUpdater, _base, _planbook, _lodashEs, _defaultClassWebsiteActivity, _defaultClassWebsiteContext, _defaultClassWebsiteModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createClassWebsiteAction;

  function createClassWebsiteAction(payload) {
    return {
      name: "CREATE_CLASS_WEBSITE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let createWebsitePatch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.title", "My Fantastic Website").set("attributes.slug", `my-fantastic-website-url-${payload.websiteId}`).set("relationships.planbook.data", {
      id: payload.planbookId,
      type: "planbook"
    }).patch;

    createWebsitePatch.isCreatePatch = true;

    let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).push("relationships.classWebsites.data", {
      id: payload.websiteId,
      type: "class-website"
    }).patch;

    return [createWebsitePatch, planbookPatch];
  }

  function undoPatches(payload) {
    let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).pull("relationships.classWebsites.data", {
      id: payload.websiteId,
      type: "class-website"
    }).patch;

    return [planbookPatch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultClassWebsiteModels.default)(payload, findFn, userId);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "create",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "class_website",
      primary_object_id: payload.websiteId,
      primary_object_specifier: null,
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId],
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId]
    };
    return {
      title: "Created Class Website",
      titleForAnalytics: "Class Website - Created",
      description: "",
      context: (0, _defaultClassWebsiteContext.default)(payload, models),
      activity: (0, _defaultClassWebsiteActivity.default)(payload, models, "created"),
      newAnalyticsEvents: [newEvent]
    };
  }
});