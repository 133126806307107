define("cc-frontend/components/planner/perspectives/timeline.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1gjnr1",
    "header__text": "_header__text_1gjnr1",
    "header-rearrange-wrapper": "_header-rearrange-wrapper_1gjnr1",
    "planbook-header__head-arrow-button": "_planbook-header__head-arrow-button_1gjnr1",
    "svg-icon": "_svg-icon_1gjnr1"
  };
  _exports.default = _default;
});