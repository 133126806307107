define("cc-frontend/components/icons/copy-doc-highlighted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Common-File-Double--Streamline-Ultimate"
  ><desc>Common File Double Streamline Icon: https://streamlinehq.com</desc><defs></defs><path
      d="M18.5 20.5v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1h2"
      fill="#fef9929c"
      stroke-width="1"
    ></path><path
      d="M17.086 0.5a1 1 0 0 1 0.707 0.293l3.414 3.414a1 1 0 0 1 0.293 0.707V19.5a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1Z"
      fill="#ffffff"
      stroke-width="1"
    ></path><path
      d="M18.5 20.5v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1h2"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path><path
      d="M17.086 0.5a1 1 0 0 1 0.707 0.293l3.414 3.414a1 1 0 0 1 0.293 0.707V19.5a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1Z"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path></svg>
  */
  {
    "id": "CxQFlOSL",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[14,1,\"Common-File-Double--Streamline-Ultimate\"],[12],[10,\"desc\"],[12],[1,\"Common File Double Streamline Icon: https://streamlinehq.com\"],[13],[10,\"defs\"],[12],[13],[10,\"path\"],[14,\"d\",\"M18.5 20.5v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1h2\"],[14,\"fill\",\"#fef9929c\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M17.086 0.5a1 1 0 0 1 0.707 0.293l3.414 3.414a1 1 0 0 1 0.293 0.707V19.5a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1Z\"],[14,\"fill\",\"#ffffff\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M18.5 20.5v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1h2\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M17.086 0.5a1 1 0 0 1 0.707 0.293l3.414 3.414a1 1 0 0 1 0.293 0.707V19.5a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1Z\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/copy-doc-highlighted.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});