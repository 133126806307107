define("cc-frontend/components/user/onboarding/next-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" local-class="button" {{on "click" @onClick}}>
    <span>
      {{yield}}
    </span>
    <div class="tw-flex tw-w-5 tw-fill-current">
      <Icons::ArrowRight />
    </div>
  </button>
  */
  {
    "id": "88DTXeIu",
    "block": "[[[11,\"button\"],[16,0,[29,[[28,[37,0],[\"button\"],[[\"from\"],[\"cc-frontend/components/user/onboarding/next-button.css\"]]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tw-flex tw-w-5 tw-fill-current\"],[12],[1,\"\\n    \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@onClick\",\"&default\"],false,[\"local-class\",\"on\",\"yield\",\"icons/arrow-right\"]]",
    "moduleName": "cc-frontend/components/user/onboarding/next-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});