define("cc-frontend/components/group/members-table/cells/actions-approve-request", ["exports", "@glint/environment-ember-loose/glimmer-component", "tracked-built-ins"], function (_exports, _glimmerComponent, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="group-members-table__approve-request-button test-approve-request-button"
    id="role-{{@row.id}}"
    {{on "click" this.approveRequest}}
  >
    {{#if this.isExecutingRpc}}
      <Icons::SpinningCircle />
    {{else}}
      Approve
    {{/if}}
  </div>
  */
  {
    "id": "8EosyZnq",
    "block": "[[[11,0],[24,0,\"group-members-table__approve-request-button test-approve-request-button\"],[16,1,[29,[\"role-\",[30,1,[\"id\"]]]]],[4,[38,0],[\"click\",[30,0,[\"approveRequest\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isExecutingRpc\"]],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    Approve\\n\"]],[]]],[13]],[\"@row\"],false,[\"on\",\"if\",\"icons/spinning-circle\"]]",
    "moduleName": "cc-frontend/components/group/members-table/cells/actions-approve-request.hbs",
    "isStrictMode": false
  });

  let GroupMembersTableActionsApproveRequestCell = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class GroupMembersTableActionsApproveRequestCell extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rpc", _descriptor, this);

      _initializerDefineProperty(this, "isExecutingRpc", _descriptor2, this);
    }

    approveRequest() {
      this.isExecutingRpc = true;
      this.rpc.group_approve_membership_request({
        groupId: this.args.group.id,
        membershipId: this.args.row.id
      }).then(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        } else {
          this.isExecutingRpc = false;
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isExecutingRpc", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "approveRequest", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "approveRequest"), _class.prototype)), _class));
  _exports.default = GroupMembersTableActionsApproveRequestCell;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupMembersTableActionsApproveRequestCell);
});