define("cc-frontend/controllers/planbookv5/search", ["exports", "@ember-decorators/object", "ember-concurrency", "lodash", "cc-frontend/helpers/has-many-collectively"], function (_exports, _object, _emberConcurrency, _lodash, _hasManyCollectively) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _hasManyCollectively.default)("model.planbook", "course", "courses"), _dec4 = Ember.computed("selectedResultId", "results.data.@each.id"), _dec5 = (0, _object.observes)("query"), _dec6 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class SearchController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _defineProperty(this, "queryParams", ["query", "selectedResultId"]);

      _defineProperty(this, "query", null);

      _defineProperty(this, "_query", null);

      _defineProperty(this, "hasQuery", false);

      _defineProperty(this, "isSearching", false);

      _initializerDefineProperty(this, "courses", _descriptor3, this);
    }

    get selectedResult() {
      return _lodash.default.find(Ember.get(this, "results.data"), {
        id: Ember.get(this, "selectedResultId")
      });
    }

    queryObserver() {
      if (Ember.get(this, "_query") === null) Ember.set(this, "_query", Ember.get(this, "query"));
      Ember.set(this, "hasQuery", Ember.get(this, "query") !== "" && Ember.get(this, "query") !== null);
      Ember.get(this, "hitSearchBackend").perform(Ember.get(this, "query"));
    }

    *hitSearchBackend(query) {
      yield (0, _emberConcurrency.timeout)(30);
      Ember.set(this, "query", query);
      Ember.set(this, "isSearching", true);

      if (query === "" || null) {
        Ember.set(this, "results", []);
        Ember.set(this, "selectedResultId", null);
        Ember.set(this, "isSearching", false);
      } else {
        yield Ember.get(this, "rpc").execute("PLANBOOK_SEARCH", query, Ember.get(this, "model.planbook.id")).then(data => {
          Ember.set(this, "results", data);
          Ember.set(this, "isSearching", false);
        }).catch(error => {
          console.log("Error encounted in planbook search");
          console.log(error);
        });
      }
    }

    selectResult(result) {
      Ember.get(this, "store").dispatch("PLANBOOK_SEARCH_SELECT_RESULT", {
        planbookId: Ember.get(this, "model.planbook.id"),
        resultId: result.id
      });
      Ember.set(this, "selectedResultId", result.id);
    }

    search(e) {
      let query = e.target.value;
      Ember.set(this, "query", query);
    }

    leaveSearchBox(e) {
      let query = e.target.value;

      if (query !== "") {
        Ember.get(this, "store").dispatch("PLANBOOK_SEARCH_BOX_UNFOCUS", {
          query: query,
          planbookId: Ember.get(this, "model.planbook.id")
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "courses", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedResult", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectedResult"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryObserver", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "queryObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hitSearchBackend", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "hitSearchBackend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectResult", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectResult"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "leaveSearchBox", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "leaveSearchBox"), _class.prototype)), _class));
  _exports.default = SearchController;
});