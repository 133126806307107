define("cc-frontend/components/icons/noninstructional-course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" fill="none" viewBox="0 0 49 48">
    <path
      fill="#FF808C"
      d="M40.792 15.39V7.71a1.916 1.916 0 0 0-1.916-1.917h-34.5A1.917 1.917 0 0 0 2.459 7.71v7.682h38.333Z"
    ></path>
    <path
      fill="#FFBFC5"
      d="M40.792 9.625V7.71a1.916 1.916 0 0 0-1.916-1.917h-34.5A1.917 1.917 0 0 0 2.459 7.71v1.916h38.333Z"
    ></path>
    <path
      fill="#fff"
      d="M2.459 15.39v19.152a1.917 1.917 0 0 0 1.917 1.916h34.5a1.917 1.917 0 0 0 1.916-1.916V15.375L2.46 15.39Z"
    ></path>
    <path fill="#66E1FF" d="M35.041 46.042a11.5 11.5 0 1 0 0-23 11.5 11.5 0 0 0 0 23Z"></path>
    <path fill="#C2F3FF" d="M43.174 26.41A11.5 11.5 0 1 0 26.91 42.674L43.174 26.41Z"></path>
    <path
      stroke="#191919"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M35.041 46.044a11.5 11.5 0 1 0 0-23.002 11.5 11.5 0 0 0 0 23.002Z"
    ></path>
    <path
      stroke="#191919"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M38.874 34.544h-3.833v-5.75m-15.332 7.665H4.376a1.917 1.917 0 0 1-1.917-1.917V7.709a1.917 1.917 0 0 1 1.917-1.917h34.5a1.916 1.916 0 0 1 1.916 1.917v12.458M12.041 1.96v9.583M31.209 1.96v9.583m-28.75 3.834h38.333"
    ></path>
  </svg>
  */
  {
    "id": "trfeiMSb",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"49\"],[14,\"height\",\"48\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 49 48\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FF808C\"],[14,\"d\",\"M40.792 15.39V7.71a1.916 1.916 0 0 0-1.916-1.917h-34.5A1.917 1.917 0 0 0 2.459 7.71v7.682h38.333Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FFBFC5\"],[14,\"d\",\"M40.792 9.625V7.71a1.916 1.916 0 0 0-1.916-1.917h-34.5A1.917 1.917 0 0 0 2.459 7.71v1.916h38.333Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#fff\"],[14,\"d\",\"M2.459 15.39v19.152a1.917 1.917 0 0 0 1.917 1.916h34.5a1.917 1.917 0 0 0 1.916-1.916V15.375L2.46 15.39Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#66E1FF\"],[14,\"d\",\"M35.041 46.042a11.5 11.5 0 1 0 0-23 11.5 11.5 0 0 0 0 23Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#C2F3FF\"],[14,\"d\",\"M43.174 26.41A11.5 11.5 0 1 0 26.91 42.674L43.174 26.41Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"#191919\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M35.041 46.044a11.5 11.5 0 1 0 0-23.002 11.5 11.5 0 0 0 0 23.002Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"#191919\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M38.874 34.544h-3.833v-5.75m-15.332 7.665H4.376a1.917 1.917 0 0 1-1.917-1.917V7.709a1.917 1.917 0 0 1 1.917-1.917h34.5a1.916 1.916 0 0 1 1.916 1.917v12.458M12.041 1.96v9.583M31.209 1.96v9.583m-28.75 3.834h38.333\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/noninstructional-course.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});