define("cc-frontend/models/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLessonReviewStatus = getLessonReviewStatus;

  /**
   * Lesson review dashboard results for a single team.
   */

  /**
   * Lesson review dashboard results for a single planbook (= table row).
   */
  function getLessonReviewStatus(filledLessons, totalLessons, context) {
    // TODO: Decide what rules to use here.
    if (totalLessons === 0) return "GOOD";
    const ratio = filledLessons / totalLessons;
    if (ratio >= 0.75) return "GOOD";else if (ratio >= 0.25) return "FAIR";else return "POOR";
  }
});