define("cc-frontend/lib/actions/course/default-course-models", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, findFn, userId) {
    // Terrible ternary. if course, use course, otherwise, look it up, otherwise, null
    let course = payload.course ? payload.course : payload.courseId ? findFn("course", payload.courseId) : null;
    let planbook = course ? findFn("planbook", (0, _lodashEs.get)(course, "relationships.planbook.data.id")) : null;
    let user = findFn("user", userId);
    return {
      course,
      planbook,
      user
    };
  }
});