define("cc-frontend/components/planner/card-stacks/unit/color-selector", ["exports", "@ember-decorators/component", "cc-frontend/lib/actions/course/calendar/CHANGE_UNIT_COLOR"], function (_exports, _component, _CHANGE_UNIT_COLOR) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    id="color-picker-{{@unit.id}}"
    {{on "click" this.openMenu}}
    class="unit-popover__color-picker-anchor take-unit-background-color take-unit-border-color
      {{@unit.attributes.color}}"
  >
    {{#if @isEditable}}
      <Icons::ArrowDown />
    {{/if}}
  </div>
  {{#if this.colorMenuIsOpen}}
    <LiquidWormhole @class="dialog-backdrop" @click={{this.closeMenu}} />
    <LiquidTether
      @target={{concat "#color-picker-" @unit.id}}
      @offset="0 0px"
      @targetAttachment="bottom center"
      @attachment="top center"
      @class="undo-menu"
    >
      <div class="course-color-selection-list material-depth-1 bopit-allow-click">
        {{#each this.courseColors as |color|}}
          <div
            class="take-course-background-color course-color-selection-list-item
              {{color}}
              test-change-course-color"
            {{on "click" (fn this.setUnitColor @unit.id color @courseId @unit.attributes.color)}}
          ></div>
        {{/each}}
        <div
          class="course-color-selection-list-item course-color-selection-list-item--full-width"
          {{on "click" (fn this.setUnitColor @unit.id null @courseId @unit.attributes.color)}}
        ></div>
      </div>
    </LiquidTether>
  {{/if}}
  */
  {
    "id": "aAteszF0",
    "block": "[[[11,0],[16,1,[29,[\"color-picker-\",[30,1,[\"id\"]]]]],[16,0,[29,[\"unit-popover__color-picker-anchor take-unit-background-color take-unit-border-color\\n    \",[30,1,[\"attributes\",\"color\"]]]]],[4,[38,0],[\"click\",[30,0,[\"openMenu\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[41,[30,0,[\"colorMenuIsOpen\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@class\",\"@click\"],[\"dialog-backdrop\",[30,0,[\"closeMenu\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@class\"],[[28,[37,5],[\"#color-picker-\",[30,1,[\"id\"]]],null],\"0 0px\",\"bottom center\",\"top center\",\"undo-menu\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"course-color-selection-list material-depth-1 bopit-allow-click\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"courseColors\"]]],null]],null],null,[[[1,\"        \"],[11,0],[16,0,[29,[\"take-course-background-color course-color-selection-list-item\\n            \",[30,3],\"\\n            test-change-course-color\"]]],[4,[38,0],[\"click\",[28,[37,8],[[30,0,[\"setUnitColor\"]],[30,1,[\"id\"]],[30,3],[30,4],[30,1,[\"attributes\",\"color\"]]],null]],null],[12],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[11,0],[24,0,\"course-color-selection-list-item course-color-selection-list-item--full-width\"],[4,[38,0],[\"click\",[28,[37,8],[[30,0,[\"setUnitColor\"]],[30,1,[\"id\"]],null,[30,4],[30,1,[\"attributes\",\"color\"]]],null]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@unit\",\"@isEditable\",\"color\",\"@courseId\"],false,[\"on\",\"if\",\"icons/arrow-down\",\"liquid-wormhole\",\"liquid-tether\",\"concat\",\"each\",\"-track-array\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/unit/color-selector.hbs",
    "isStrictMode": false
  });

  let UnitColorSelector = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec(_class = (_class2 = class UnitColorSelector extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "courseColors", ["yellow", "muted-yellow", "muted-green", "green", "turquoise", "muted-turquoise", "muted-cyan", "cyan", "blue", "muted-blue", "muted-purple", "purple", "pink", "muted-pink", "muted-coral", "coral"]);

      _defineProperty(this, "colorMenuIsOpen", false);
    }

    openMenu(id) {
      if (!this.isEditable) return;
      Ember.set(this, "colorMenuIsOpen", true);
    }

    closeMenu(id) {
      Ember.set(this, "colorMenuIsOpen", false);
    }

    setUnitColor(unitId, color, courseId, originalColor) {
      if (!this.isEditable) return;
      Ember.get(this, "store").dispatchAction(_CHANGE_UNIT_COLOR.CHANGE_UNIT_COLOR, {
        courseId: courseId,
        cardStackId: unitId,
        color: color,
        originalValue: originalColor
      });
      Ember.set(this, "colorMenuIsOpen", false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "openMenu", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "openMenu"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeMenu", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "closeMenu"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setUnitColor", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "setUnitColor"), _class2.prototype)), _class2)) || _class);
  _exports.default = UnitColorSelector;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnitColorSelector);
});