define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_EMPHASIS", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _base, _planbook, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_EMPHASIS = void 0;
  const LESSON_STANDARDS_EMPHASIS = {
    name: "LESSON_STANDARDS_EMPHASIS",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.standards", payload.standards).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.previousStandards))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "standards",
        adverbial_verb: "starring",
        adverbial_object_type: "standard",
        adverbial_object_specifier: "lesson",
        adverbial_object_id: payload.cardId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      let title = payload.emphasisLevel === null ? "Removed emphasis on standard" : payload.emphasisLevel === "STARRED" ? "Starred standard" : "Expanded standard";
      return {
        title: title,
        titleForAnalytics: "Lesson - Toggle Standard Emphasis",
        context: Object.assign((0, _defaultLessonContext.default)(payload, models), {
          newEmphasisLevel: payload.emphasisLevel
        }),
        activity: (0, _defaultLessonActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_STANDARDS_EMPHASIS = LESSON_STANDARDS_EMPHASIS;
  var _default = LESSON_STANDARDS_EMPHASIS;
  _exports.default = _default;
});