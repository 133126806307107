define("cc-frontend/routes/help/article", ["exports", "cc-frontend/lib/actions/app-state/KB_VISIT_ARTICLE", "lodash"], function (_exports, _KB_VISIT_ARTICLE, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ArticleRoute = (_dec = Ember.inject.service, (_class = class ArticleRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    model(params) {
      let id = _lodash.default.last(_lodash.default.split(params.article_id, "-"));

      return Ember.get(this, "store").find("kb-entry", id);
    }

    afterModel(model, transition) {
      if (model) {
        const displayedArticleId = _lodash.default.kebabCase(model.attributes.title) + "-" + model.id;

        if (this.paramsFor(this.routeName).article_id !== displayedArticleId) {
          this.transitionTo("help.article", displayedArticleId);
          return;
        }

        this.store.dispatchPreparedAction((0, _KB_VISIT_ARTICLE.default)({
          articleId: model.id,
          articleTitle: model.attributes.title
        }));
      }

      if (window && window.document) {
        $(window).scrollTop(0);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ArticleRoute;
});