define("cc-frontend/components/user/stripe-upgrade", ["exports", "@ember-decorators/component", "cc-frontend/app"], function (_exports, _component, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeUpgrade = (_dec = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{#if isUpgrading}}
      <DS::ThreeDotSpinner />
    {{else}}
      <div class="stripe-upgrade__title">
        {{buttonTitle}}
      </div>
      <div class="stripe-upgrade__subtitle">
        {{buttonSubtitle}}
      </div>
    {{/if}}
  
  */
  {
    "id": "EetHY6P7",
    "block": "[[[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"stripe-upgrade__title\"],[12],[1,\"\\n      \"],[1,[34,3]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"stripe-upgrade__subtitle\"],[12],[1,\"\\n      \"],[1,[34,4]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"isUpgrading\",\"d-s/three-dot-spinner\",\"buttonTitle\",\"buttonSubtitle\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec(_class = (_class2 = class StripeUpgrade extends Ember.Component.extend() {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stripe", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "socket", _descriptor3, this);

      _initializerDefineProperty(this, "rpc", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);
    }

    click() {
      // analytics.track("Upgrade - Open Modal")
      let email = Ember.get(this, "session.user.attributes.email");
      let userId = Ember.get(this, "session.user.id");
      Ember.get(this, "stripe").handler.open({
        description: "Pro Teacher Account " + Ember.get(this, "price"),
        panelLabel: "Start Free 30 day Trial (" + Ember.get(this, "price") + " afterwards)",
        email: email,
        token: token => {
          Ember.set(this, "isUpgrading", true);
          var data = {
            userId: userId,
            source: token.id,
            plan: Ember.get(this, "plan"),
            coupon: Ember.get(this, "coupon")
          };

          if (Ember.get(this, "coupon") !== "" && !Ember.isNone(Ember.get(this, "coupon"))) {
            data.coupon = Ember.get(this, "coupon");
          } else {
            data.coupon = "";
          }

          Ember.get(this, "rpc").execute("user_upgrade_with_card", data).then(result => {
            // Our poor way of detecting errors leads us to this:
            if (result.userId === userId) {
              this.onSuccess(userId);
            } else {
              this.notifier.warning("", {
                notificationId: userId,
                title: `Something went wrong with the upgrade`,
                message: "Contact us through the message box below",
                duration: 5000,
                type: "is-processing"
              });

              if (window && window.Intercom) {
                window.Intercom("showNewMessage", `I just tried to upgrade and something went wrong.`);
              }
            }
          }).catch(error => {
            if (window && window.Intercom) {
              window.Intercom("showNewMessage", `I just tried to upgrade and something went wrong.`);
            }
          });
        }
      });
    }

    onSuccess(userId) {
      _app.analytics.track("Subscription - Started Trial on Client");

      Ember.get(this, "socket").notifyUpgradeToPro({
        userId: userId
      }).then(() => {
        window.location.reload();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stripe", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "socket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "rpc", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = StripeUpgrade;
});