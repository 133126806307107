define("cc-frontend/components/user/onboarding/school-search-after", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{on "click" @noSchoolListed}} local-class="notice">
    {{@searchType}}
    not listed?
  </div>
  */
  {
    "id": "KDKMo+aE",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[\"notice\"],[[\"from\"],[\"cc-frontend/components/user/onboarding/school-search-after.css\"]]]]]],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n  not listed?\\n\"],[13]],[\"@noSchoolListed\",\"@searchType\"],false,[\"local-class\",\"on\"]]",
    "moduleName": "cc-frontend/components/user/onboarding/school-search-after.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});