define("cc-frontend/lib/actions/class-website/UPDATE_CLASS_WEBSITE_SLUG", ["exports", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/class-website/default-class-website-activity", "cc-frontend/lib/actions/class-website/default-class-website-context", "cc-frontend/lib/actions/class-website/default-class-website-models"], function (_exports, _base, _planbook, _lodashEs, _defaultClassWebsiteActivity, _defaultClassWebsiteContext, _defaultClassWebsiteModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = updateClassWebsiteSlugAction;

  function updateClassWebsiteSlugAction(payload) {
    return {
      name: "UPDATE_CLASS_WEBSITE_SLUG",
      payload: payload,
      patches: null,
      // We don't allow undos because we actually change the slug on the server.
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultClassWebsiteModels.default)(payload, findFn, userId);
    let websiteTitle = models.classWebsite.attributes.title;
    let newSlug = payload.slug;
    let oldSlug = payload.originalSlug;
    let context = (0, _lodashEs.assign)({}, (0, _defaultClassWebsiteContext.default)(payload, models), {
      websiteTitle,
      newSlug,
      oldSlug
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "class_website",
      primary_object_id: payload.websiteId,
      primary_object_specifier: "slug",
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId],
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId]
    };
    return {
      title: "Changed URL for class website",
      titleForAnalytics: "Class Website - Changed Slug",
      activity: (0, _defaultClassWebsiteActivity.default)(payload, models),
      description: `Changed the custom url for your "${websiteTitle}" class website from "${oldSlug}" to "${newSlug}"`,
      context,
      newAnalyticsEvents: [newEvent]
    };
  }
});