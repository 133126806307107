define("cc-frontend/lib/actions/planbook/PLANBOOK_COMPLETE_ONBOARDING", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _patchUpdater, _base, _lodashEs, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = preparePlanbookCompleteOnboarding;

  function preparePlanbookCompleteOnboarding(payload) {
    return {
      name: "PLANBOOK_COMPLETE_ONBOARDING",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const patches = function (payload) {
    let patch = _patchUpdater.default.create("planbook", payload.planbookId).set(`attributes.flags.hasNotFinishedOnboarding`, false).patch;

    return [patch];
  };

  const narrative = function (payload, findFn, userId) {
    let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: null,
      root_type: "app",
      date_plan_scheduled_for: null,
      primary_verb: "use",
      primary_possessive: "owns",
      primary_object_type: "app",
      primary_object_id: "commoncurriculum.com",
      primary_object_specifier: "planbook_onboarding",
      primary_object_id_and_ancestor_ids: ["commoncurriculum.com", payload.planbookId],
      adverbial_verb: "navigating",
      adverbial_object_type: "screen",
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: ["commoncurriculum.com", payload.planbookId]
    };
    return {
      title: `Planbook - Complete Onboarding`,
      titleForAnalytics: "Planbook - Onboarding Complete",
      description: `Completed onboarding`,
      context: (0, _defaultPlanbookContext.default)(payload, models),
      activity: (0, _defaultPlanbookActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  };
});