define("cc-frontend/components/user/onboarding/name-and-school.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-sign__title": "_user-sign__title_7zigsq",
    "user-sign__button-row": "_user-sign__button-row_7zigsq",
    "user-sign__selected-workplace": "_user-sign__selected-workplace_7zigsq",
    "search": "_search_7zigsq",
    "search-district": "_search-district_7zigsq",
    "search-name": "_search-name_7zigsq",
    "search-address": "_search-address_7zigsq",
    "privacy-alert": "_privacy-alert_7zigsq",
    "privacy-alert-icon": "_privacy-alert-icon_7zigsq"
  };
  _exports.default = _default;
});