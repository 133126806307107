define("cc-frontend/components/icons/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14"
    fill="currentColor"
    viewBox="0 0 18 14"
  >
    <path
      fill-rule="evenodd"
      d="M7.123 9.867v-.452h5.766c1.132 0 1.707.147 1.995.34.223.148.336.366.336.846v2.862a.531.531 0 0 0 .948.33A8.538 8.538 0 0 0 18 8.883c0-1.33-.3-2.871-1.086-4.097-.804-1.253-2.11-2.158-4.025-2.158H7.123V1.105a1.104 1.104 0 0 0-1.885-.78L.323 5.238a1.104 1.104 0 0 0 0 1.56l4.915 4.918a1.104 1.104 0 0 0 1.885-.782V9.867ZM6.035 1.065a.041.041 0 0 0-.045.01L1.074 5.99a.042.042 0 0 0 0 .059l4.916 4.917a.04.04 0 0 0 .021.012.041.041 0 0 0 .05-.04V8.883a.53.53 0 0 1 .53-.532h6.298c1.158 0 2.014.139 2.585.519.635.424.809 1.064.809 1.73v.976a7.476 7.476 0 0 0 .655-2.706c-.002-1.195-.276-2.511-.918-3.512-.627-.977-1.611-1.67-3.13-1.67H6.591a.531.531 0 0 1-.531-.53V1.104a.041.041 0 0 0-.026-.039Z"
      clip-rule="evenodd"
    ></path>
  </svg>
  */
  {
    "id": "S7any4h+",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"18\"],[14,\"height\",\"14\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 18 14\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M7.123 9.867v-.452h5.766c1.132 0 1.707.147 1.995.34.223.148.336.366.336.846v2.862a.531.531 0 0 0 .948.33A8.538 8.538 0 0 0 18 8.883c0-1.33-.3-2.871-1.086-4.097-.804-1.253-2.11-2.158-4.025-2.158H7.123V1.105a1.104 1.104 0 0 0-1.885-.78L.323 5.238a1.104 1.104 0 0 0 0 1.56l4.915 4.918a1.104 1.104 0 0 0 1.885-.782V9.867ZM6.035 1.065a.041.041 0 0 0-.045.01L1.074 5.99a.042.042 0 0 0 0 .059l4.916 4.917a.04.04 0 0 0 .021.012.041.041 0 0 0 .05-.04V8.883a.53.53 0 0 1 .53-.532h6.298c1.158 0 2.014.139 2.585.519.635.424.809 1.064.809 1.73v.976a7.476 7.476 0 0 0 .655-2.706c-.002-1.195-.276-2.511-.918-3.512-.627-.977-1.611-1.67-3.13-1.67H6.591a.531.531 0 0 1-.531-.53V1.104a.041.041 0 0 0-.026-.039Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/undo.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});