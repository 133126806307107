define("cc-frontend/components/group/dashboard/lessons/planbook-courses.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "loader": "_loader_1xhe15",
    "planbook-dropdown-wrapper": "_planbook-dropdown-wrapper_1xhe15",
    "course-title-dropdown": "_course-title-dropdown_1xhe15",
    "lesson-title": "_lesson-title_1xhe15",
    "no-lesson": "_no-lesson_1xhe15",
    "lesson-off": "_lesson-off_1xhe15"
  };
  _exports.default = _default;
});