define("cc-frontend/lib/actions/planbook/default-planbook-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(_payload, findFn, userId, planbookId) {
    return {
      planbook: findFn("planbook", planbookId),
      user: findFn("user", userId)
    };
  }
});