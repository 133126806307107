define("cc-frontend/templates/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bvEond2c",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[[30,1,[\"attributes\",\"quoteNumber\"]],\" for \",[30,1,[\"attributes\",\"xeroContactName\"]],\" Common Curriculum\"],null]],null]],[1,\"\\n\"],[10,0],[14,0,\"tw-w-full tw-h-full tw-bg-grey-light tw-align-middle tw-flex tw-flex-col tw-items-center\"],[12],[1,\"\\n  \"],[10,3],[15,6,[29,[\"/api/v4/print/quotes/\",[30,1,[\"id\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"tw-mt-12 tw-rounded-lg tw-bg-stormy-ocean-600 tw-px-6 tw-py-4 tw-text-2xl tw-font-semibold tw-text-white tw-shadow-lg hover:tw-bg-stormy-ocean-900 hover:tw-text-white active:tw-text-white focus:tw-text-white focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:outline-offset-2 focus-visible:tw-outline-indigo-600 print:hidden\"],[12],[1,\"Download as PDF\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tw-bg-white tw-max-w-5xl tw-mt-8 tw-mb-8 tw-shadow-2xl tw-rounded-lg tw-p-12 tw-h-full\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"\"]],[[\"@model\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\"],false,[\"page-title\",\"concat\",\"billing/fiscal-group-quote\"]]",
    "moduleName": "cc-frontend/templates/quote.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});