define("cc-frontend/components/planner/perspectives/shared/customize-layout-nav.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "customize-layout-nav__wrapper": "_customize-layout-nav__wrapper_5ssnjh",
    "customize-layout-nav": "_customize-layout-nav_5ssnjh",
    "filter-class-wrapper": "_filter-class-wrapper_5ssnjh",
    "customize-layout-dropdown": "_customize-layout-dropdown_5ssnjh",
    "customize-layout-nav__option": "_customize-layout-nav__option_5ssnjh",
    "is-active": "_is-active_5ssnjh",
    "customize-layout-nav__option__text": "_customize-layout-nav__option__text_5ssnjh",
    "customize-layout-nav__collapse": "_customize-layout-nav__collapse_5ssnjh"
  };
  _exports.default = _default;
});