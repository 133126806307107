define("cc-frontend/components/planner/card-stacks/cards/parts/standard.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_rdd3ny",
    "item": "_item_rdd3ny",
    "code": "_code_rdd3ny",
    "statement": "_statement_rdd3ny",
    "standard-set-info": "_standard-set-info_rdd3ny",
    "jurisdiction": "_jurisdiction_rdd3ny",
    "grade-levels": "_grade-levels_rdd3ny",
    "subject": "_subject_rdd3ny",
    "star": "_star_rdd3ny",
    "actions": "_actions_rdd3ny",
    "btn-copy-standard": "_btn-copy-standard_rdd3ny",
    "btn-delete-standard": "_btn-delete-standard_rdd3ny"
  };
  _exports.default = _default;
});