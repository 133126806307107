define("cc-frontend/components/planner/card-stacks/lesson/lesson-menu-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-block__menu-trigger": "_lesson-block__menu-trigger_1yxlqf",
    "--has-no-template": "_--has-no-template_1yxlqf"
  };
  _exports.default = _default;
});