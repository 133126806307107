define("cc-frontend/models/transferrable-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTransferrableDate = createTransferrableDate;

  function createTransferrableDate(transferrableDate) {
    const defaults = {
      cardStackId: null,
      unitStart: [],
      unitEnd: []
    };
    return Object.assign({}, defaults, transferrableDate);
  }
});