define("cc-frontend/utils/filter-dates/course", ["exports", "@mobily/ts-belt", "lodash-es"], function (_exports, _tsBelt, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCourseDateCustom = isCourseDateCustom;
  _exports.isCourseDateDefault = isCourseDateDefault;
  _exports.isCourseDateAnnotationSingle = isCourseDateAnnotationSingle;
  _exports.isOnCourseDateDefault = isOnCourseDateDefault;
  _exports.filterCourseDateCustom = filterCourseDateCustom;
  _exports.findCourseDateCustom = findCourseDateCustom;
  _exports.filterCourseDateDefault = filterCourseDateDefault;
  _exports.findCourseDateDefault = findCourseDateDefault;
  _exports.findFirstCourseDateDefault = findFirstCourseDateDefault;
  _exports.extractCardStackTemplateIds = extractCardStackTemplateIds;

  function isCourseDateCustom(cd) {
    return cd.type === "course-date-custom";
  }

  function isCourseDateDefault(cd) {
    return cd.type === "course-date-default";
  }

  function isCourseDateAnnotationSingle(ann) {
    return ann.type === "date-annotation-course-custom-single";
  }

  function isOnCourseDateDefault(course, courseDate) {
    const id = `${courseDate.attributes.semesterId}:${courseDate.attributes.rotationId}`;
    return !course.attributes.calendar.datesOff.includes(id);
  }

  function filterCourseDateCustom(course) {
    return (0, _tsBelt.pipe)(Ember.get(course.attributes.calendar, "dates"), _tsBelt.A.filter(isCourseDateCustom), _tsBelt.A.sortBy(date => date.attributes.dateString));
  }

  function findCourseDateCustom(course, dateString) {
    return (0, _lodashEs.find)(filterCourseDateCustom(course), date => date.attributes.dateString === dateString);
  }

  function filterCourseDateDefault(course) {
    return (0, _lodashEs.filter)(Ember.get(course.attributes.calendar, "dates"), isCourseDateDefault);
  }

  function findCourseDateDefault(course, rotationId, semesterId) {
    return (0, _lodashEs.find)(filterCourseDateDefault(course), date => date.attributes.rotationId === rotationId && date.attributes.semesterId === semesterId);
  }

  function findFirstCourseDateDefault(course, rotationCalendar) {
    const sortedRotations = (0, _tsBelt.pipe)(rotationCalendar.attributes.rotations, _tsBelt.A.sortBy(rotation => rotation.position));
    if (sortedRotations.length === 0) return undefined;
    return (0, _lodashEs.find)(filterCourseDateDefault(course), date => date.attributes.rotationId === sortedRotations[0].id);
  }

  function extractCardStackTemplateIds(course) {
    // Treat non-instructional courses as ones without a template.
    if (course.attributes.isNonInstructional) return [];
    return (0, _lodashEs.compact)((0, _lodashEs.uniq)((0, _lodashEs.map)(filterCourseDateDefault(course), date => Ember.get(date.attributes, "cardStackId"))));
  }
});