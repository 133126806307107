define("cc-frontend/components/icons/expand-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <g clip-path="url(#a)">
      <path
        fill-rule="evenodd"
        d="M11.793.038c-.064.03-.625.577-1.672 1.627C8.689 3.1 8.542 3.255 8.517 3.352a.522.522 0 0 0 .403.637c.044.01.135.004.204-.012.122-.029.152-.056 1.247-1.148l1.122-1.118V10H6.168l-3.827.007-.115.078a.492.492 0 0 0 .041.852l.105.054h19.253l.101-.05a.568.568 0 0 0 .298-.486c0-.066-.007-.114-.015-.106-.008.008-.038-.03-.067-.086a.558.558 0 0 0-.168-.178l-.116-.078H17.83L12.501 10V1.71L13.6 2.805c.604.603 1.13 1.111 1.17 1.131a.502.502 0 0 0 .731-.439c0-.225-.005-.23-1.59-1.82C13.089.856 12.364.144 12.299.096A.38.38 0 0 0 12.035 0a.451.451 0 0 0-.242.04ZM2.007 10.5c0 .079.005.108.01.065a.64.64 0 0 0 0-.144c-.005-.036-.01 0-.01.079Zm.32 3.527a.504.504 0 0 0-.168.833c.16.15-.267.14 3.54.14l5.794-.007v7.283l-1.122-1.119c-1.186-1.183-1.172-1.171-1.388-1.171-.109 0-.325.118-.388.213a.498.498 0 0 0-.078.41l.029.132 1.587 1.59c1.28 1.283 1.608 1.599 1.69 1.63.158.06.338.033.48-.072.063-.047.786-.758 1.607-1.58 1.585-1.588 1.59-1.594 1.59-1.82a.516.516 0 0 0-.515-.503c-.181.001-.255.064-1.386 1.194l-1.098 1.097v-7.284l5.296.007c4.361 0 3.885.01 4.043-.14a.668.668 0 0 0 .184-.406c0-.058-.009-.1-.02-.093-.01.007-.033-.025-.049-.07-.036-.1-.199-.243-.313-.275-.056-.016-2.087-.024-9.654-.023-9.761 0-9.583.006-9.66.034Zm-.32.469c0 .072.005.102.01.066a.53.53 0 0 0 0-.132c-.005-.037-.01-.007-.01.066Z"
        clip-rule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h24v24H0z"></path>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "jUxFx6cf",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M11.793.038c-.064.03-.625.577-1.672 1.627C8.689 3.1 8.542 3.255 8.517 3.352a.522.522 0 0 0 .403.637c.044.01.135.004.204-.012.122-.029.152-.056 1.247-1.148l1.122-1.118V10H6.168l-3.827.007-.115.078a.492.492 0 0 0 .041.852l.105.054h19.253l.101-.05a.568.568 0 0 0 .298-.486c0-.066-.007-.114-.015-.106-.008.008-.038-.03-.067-.086a.558.558 0 0 0-.168-.178l-.116-.078H17.83L12.501 10V1.71L13.6 2.805c.604.603 1.13 1.111 1.17 1.131a.502.502 0 0 0 .731-.439c0-.225-.005-.23-1.59-1.82C13.089.856 12.364.144 12.299.096A.38.38 0 0 0 12.035 0a.451.451 0 0 0-.242.04ZM2.007 10.5c0 .079.005.108.01.065a.64.64 0 0 0 0-.144c-.005-.036-.01 0-.01.079Zm.32 3.527a.504.504 0 0 0-.168.833c.16.15-.267.14 3.54.14l5.794-.007v7.283l-1.122-1.119c-1.186-1.183-1.172-1.171-1.388-1.171-.109 0-.325.118-.388.213a.498.498 0 0 0-.078.41l.029.132 1.587 1.59c1.28 1.283 1.608 1.599 1.69 1.63.158.06.338.033.48-.072.063-.047.786-.758 1.607-1.58 1.585-1.588 1.59-1.594 1.59-1.82a.516.516 0 0 0-.515-.503c-.181.001-.255.064-1.386 1.194l-1.098 1.097v-7.284l5.296.007c4.361 0 3.885.01 4.043-.14a.668.668 0 0 0 .184-.406c0-.058-.009-.1-.02-.093-.01.007-.033-.025-.049-.07-.036-.1-.199-.243-.313-.275-.056-.016-2.087-.024-9.654-.023-9.761 0-9.583.006-9.66.034Zm-.32.469c0 .072.005.102.01.066a.53.53 0 0 0 0-.132c-.005-.037-.01-.007-.01.066Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M0 0h24v24H0z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/expand-vertical.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});