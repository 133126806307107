define("cc-frontend/components/planner/csp/search-modal", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="csp-close-button-wrapper">
    <button type="button" {{on "click" this.onCloseModal}} class="csp-interface__close-button">
      Close and return to lesson
      <Icons::IosCloseEmpty />
    </button>
  </div>
  <div class="modal modal-flexible" style="display: block;">
    <div class="modal-dialog">
      <Planner::Csp::Search
        @jurisdictions={{jurisdictions.content.attributes.list}}
        @jurisdictionsIsFulfilled={{jurisdictions.isFulfilled}}
        @initialJurisdictionId={{@initialJurisdictionId}}
        @standardSetIds={{standardSetIds}}
        @setStandardSetIds={{action "setStandardSetIds"}}
        @toggleStandard={{action "toggleStandard"}}
        @showStandardCoverage={{showStandardCoverage}}
        @selectedStandardIds={{selectedStandardIds}}
        @standardsCoverage={{hierarchicalStandardsReport.content.attributes.standardsCoverage}}
      />
      {{! standardsCoverage=hierarchicalStandardsReport.content.attributes.standardsCoverage }}
  
    </div>
  </div>
  */
  {
    "id": "vaqaMRDY",
    "block": "[[[10,0],[14,0,\"csp-close-button-wrapper\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"csp-interface__close-button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"onCloseModal\"]]],null],[12],[1,\"\\n    Close and return to lesson\\n    \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"modal modal-flexible\"],[14,5,\"display: block;\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-dialog\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@jurisdictions\",\"@jurisdictionsIsFulfilled\",\"@initialJurisdictionId\",\"@standardSetIds\",\"@setStandardSetIds\",\"@toggleStandard\",\"@showStandardCoverage\",\"@selectedStandardIds\",\"@standardsCoverage\"],[[33,3,[\"content\",\"attributes\",\"list\"]],[33,3,[\"isFulfilled\"]],[30,1],[36,4],[28,[37,5],[[30,0],\"setStandardSetIds\"],null],[28,[37,5],[[30,0],\"toggleStandard\"],null],[36,6],[36,7],[33,8,[\"content\",\"attributes\",\"standardsCoverage\"]]]],null],[1,\"\\n\"],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@initialJurisdictionId\"],false,[\"on\",\"icons/ios-close-empty\",\"planner/csp/search\",\"jurisdictions\",\"standardSetIds\",\"action\",\"showStandardCoverage\",\"selectedStandardIds\",\"hierarchicalStandardsReport\"]]",
    "moduleName": "cc-frontend/components/planner/csp/search-modal.hbs",
    "isStrictMode": false
  });

  class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {}

  let SearchModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed, _dec4 = Ember.computed("shadowStandards.@each.id"), _dec5 = Ember.computed("standardSetIds.[]", "courseId"), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class SearchModal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rpc", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    init() {
      super.init(...arguments);
      var ids = [];

      if (!_lodash.default.isEmpty(this.courseStandardSetIds)) {
        ids = _lodash.default.compact(this.courseStandardSetIds);
      }

      if (_lodash.default.isEmpty(ids) || Ember.isNone(ids)) {
        ids = ["blank"];
      } // We clone so we're not manipulating the exact array and thus can compare later if
      // we changed it.


      this.set("standardSetIds", _lodash.default.clone(ids));
      this.set("shadowStandards", _lodash.default.cloneDeep(this.standards || []));
    }

    get jurisdictions() {
      class ObjectPromiseProxy extends Ember.ObjectProxy.extend(Ember.PromiseProxyMixin) {}

      return ObjectPromiseProxy.create({
        promise: Ember.get(this, "store").find("csp-jurisdiction-index", "index")
      });
    }

    get selectedStandardIds() {
      return _lodash.default.map(this.shadowStandards, "id");
    }

    get hierarchicalStandardsReport() {
      let courseId = Ember.get(this, "courseId");

      let standardSetIds = _lodash.default.reject(Ember.get(this, "standardSetIds"), id => _lodash.default.includes(id, "blank"));

      if (courseId && standardSetIds.length > 0) {
        return ObjectPromiseProxy.create({
          promise: Ember.get(this, "rpc").execute("STANDARDS_REPORT", [courseId], standardSetIds).catch(() => {
            Ember.run.later(() => {
              Ember.get(this, "hierarchicalStandardsReport");
            }, 1000);
          })
        });
      } else {
        return null;
      }
    }

    onCloseModal() {
      this.closeModal({
        standards: this.shadowStandards,
        standardSetIds: _lodash.default.slice(this.standardSetIds, 0, 5)
      });
    }

    setStandardSetIds(standardSetIds) {
      this.set("standardSetIds", standardSetIds);
    } // The standard model used in Cc is different than what common standards project uses
    // as csp decided on a new schema after we built the first Cc.
    // This is why we use the ID to remove from the set because we don't have to worry
    // about shape of hash matching exactly or identity of object.


    toggleStandard(standard) {
      if (_lodash.default.includes(this.selectedStandardIds, standard.id)) {
        var index = _lodash.default.findIndex(this.shadowStandards, {
          id: standard.id
        });

        this.shadowStandards.removeAt(index);
      } else {
        this.shadowStandards.pushObject({
          id: standard.id,
          code: standard.altStatementNotation || standard.statementNotation,
          grades: standard.standardSet.title,
          subject: standard.standardSet.subject,
          standardSetId: standard.standardSet.id,
          jurisdiction: standard.jurisdiction.title,
          statement: standard.description,
          clarifications: standard.comments
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "jurisdictions", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "jurisdictions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedStandardIds", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectedStandardIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hierarchicalStandardsReport", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "hierarchicalStandardsReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStandardSetIds", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setStandardSetIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleStandard", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleStandard"), _class.prototype)), _class));
  _exports.default = SearchModal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchModal);
});