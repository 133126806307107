define("cc-frontend/lib/actions/group/GROUP_DATE_ANNOTATION_DELETE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "moment"], function (_exports, _patchUpdater, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareGroupDateAnnotationDelete = prepareGroupDateAnnotationDelete;
  _exports.patches = patches;
  _exports.narrative = narrative;
  _exports.undoPatches = void 0;

  function prepareGroupDateAnnotationDelete(payload) {
    return {
      name: "GROUP_DATE_ANNOTATION_DELETE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches,
      narrative: (0, _lodash.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    const patch = _patchUpdater.default.create("group", payload.group.id).pull("attributes.calendar.dateAnnotations", {
      id: payload.annotation.id
    });

    return [patch.patch];
  }

  const undoPatches = null;
  _exports.undoPatches = undoPatches;

  function narrative(payload, findFn, userId) {
    let formattedStartDate = (0, _moment.default)(payload.annotation.attributes.startDate).format("ddd, MMM. D, YYYY");
    let formattedEndDate = (0, _moment.default)(payload.annotation.attributes.endDate).format("ddd, MMM. D, YYYY");
    return {
      title: "Deleted Group Calendar Event",
      titleForAnalytics: "Group - Calendar - Delete Annotation",
      description: `Deleted Group Calendar Event from ${formattedStartDate} to ${formattedEndDate}`,
      context: {
        calendarGroupId: payload.group.id,
        dateAnnotationId: payload.annotation.id,
        isOff: payload.annotation.attributes.isOff
      },
      activity: null
    };
  }
});