define("cc-frontend/components/planner/card-stacks/cards/parts/move-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless (or @hideMoveButton (not (includes @permissions "can-edit-plan")))}}
    <div
      local-class="move-pad"
      data-is-card-move-pad
      data-is-hovering={{@isHovering}}
      class="{{if
          (includes @permissions "can-edit-plan")
          "js-move-button lesson-section__move-pad--enabled test-card-move-button"
        }}
        test-move-lesson-section"
    >
      <Icons::ArrowsMoveHighlighted />
    </div>
  {{/unless}}
  */
  {
    "id": "bFTrwqUy",
    "block": "[[[41,[51,[28,[37,1],[[30,1],[28,[37,2],[[28,[37,3],[[30,2],\"can-edit-plan\"],null]],null]],null]],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,4],[[52,[28,[37,3],[[30,2],\"can-edit-plan\"],null],\"js-move-button lesson-section__move-pad--enabled test-card-move-button\"],\"\\n      test-move-lesson-section\"],null],\" \",[28,[37,6],[\"move-pad\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/move-button.css\"]]]]]],[14,\"data-is-card-move-pad\",\"\"],[15,\"data-is-hovering\",[30,3]],[12],[1,\"\\n    \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@hideMoveButton\",\"@permissions\",\"@isHovering\"],false,[\"unless\",\"or\",\"not\",\"includes\",\"concat\",\"if\",\"local-class\",\"icons/arrows-move-highlighted\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/move-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});