define("cc-frontend/components/user/onboarding/role-and-title.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-sign__title": "_user-sign__title_1ph842"
  };
  _exports.default = _default;
});