define("cc-frontend/components/planner/course/create-edit/weekly-templates.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "day-column__header": "_day-column__header_ec9o2a",
    "weekly-templates": "_weekly-templates_ec9o2a",
    "day-column": "_day-column_ec9o2a",
    "class-is-off-box": "_class-is-off-box_ec9o2a",
    "lesson-box": "_lesson-box_ec9o2a",
    "lesson-box__lesson": "_lesson-box__lesson_ec9o2a",
    "lesson-box__no-lesson-message": "_lesson-box__no-lesson-message_ec9o2a",
    "lesson-box__button-header": "_lesson-box__button-header_ec9o2a",
    "lesson-box__button-header__button": "_lesson-box__button-header__button_ec9o2a",
    "change-menu__title": "_change-menu__title_ec9o2a",
    "button-row": "_button-row_ec9o2a"
  };
  _exports.default = _default;
});