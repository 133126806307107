define("cc-frontend/components/planner/card-stacks/lesson/dangling-lesson-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="date-is-off-but-lesson">
    <div local-class="date-is-off-but-lesson__title">
      {{#if (eq @danglingLessonType "planbook")}}
        {{#if @isNonInstructional}}
          Today is off, but there are still notes here.
        {{else}}
          Today is off, but there’s still a lesson here.
        {{/if}}
      {{else}}
        {{#if @isNonInstructional}}
          You’ve turned this class off, but there are still notes here.
        {{else}}
          You’ve turned this class off, but there’s still a lesson here.
        {{/if}}
      {{/if}}
    </div>
    <div local-class="date-is-off-but-lesson__description">
      {{#if @isNonInstructional}}
        To hide this class, use the Lesson Menu to erase this lesson.
      {{else}}
        To hide this class, use the Lesson Menu to bump or erase this lesson.
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "/7Cot5ow",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"date-is-off-but-lesson\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/dangling-lesson-notice.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"date-is-off-but-lesson__title\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/dangling-lesson-notice.css\"]]]]]],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,1],\"planbook\"],null],[[[41,[30,2],[[[1,\"        Today is off, but there are still notes here.\\n\"]],[]],[[[1,\"        Today is off, but there’s still a lesson here.\\n\"]],[]]]],[]],[[[41,[30,2],[[[1,\"        You’ve turned this class off, but there are still notes here.\\n\"]],[]],[[[1,\"        You’ve turned this class off, but there’s still a lesson here.\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"date-is-off-but-lesson__description\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/dangling-lesson-notice.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      To hide this class, use the Lesson Menu to erase this lesson.\\n\"]],[]],[[[1,\"      To hide this class, use the Lesson Menu to bump or erase this lesson.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@danglingLessonType\",\"@isNonInstructional\"],false,[\"local-class\",\"if\",\"eq\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson/dangling-lesson-notice.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});