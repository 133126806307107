define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_DELETE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "lodash-es", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _lodashEs, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_DELETE = void 0;
  const LESSON_STANDARDS_DELETE = {
    name: "LESSON_STANDARDS_DELETE",
    params: {
      cardStackId: "string",
      cardId: "string",
      standard: "object"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).pull("embedded", "attributes.standards", {
        id: payload.standard.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).addToSet("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.standard))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);
      let deletedStandard = payload.standard;
      let deletedStandardId = deletedStandard.id;
      let deletedStandardCode = deletedStandard.code;
      let deletedStandardJurisdiction = deletedStandard.jurisdiction;
      let deletedStandardGrades = deletedStandard.grades;
      let deletedStandardSubject = deletedStandard.subject;
      let newContext = (0, _lodashEs.assign)({}, context, {
        deletedStandardId,
        deletedStandardCode,
        deletedStandardJurisdiction,
        deletedStandardGrades,
        deletedStandardSubject
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "standards",
        adverbial_verb: "removing",
        adverbial_object_type: "standard",
        adverbial_object_specifier: "lesson",
        adverbial_object_id: payload.standard.id,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Deleted a Standard in a Lesson",
        titleForAnalytics: "Standards - delete",
        description: `Removed a standard from "${context.cardStackTitle}"`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_STANDARDS_DELETE = LESSON_STANDARDS_DELETE;
  var _default = LESSON_STANDARDS_DELETE;
  _exports.default = _default;
});