define("cc-frontend/components/planner/menus/lesson-menu/change-time-body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "change-time-body": "_change-time-body_9vpcso",
    "course-time-input-start-end-group": "_course-time-input-start-end-group_9vpcso",
    "course-time-label-input-group": "_course-time-label-input-group_9vpcso",
    "course-time-input-label": "_course-time-input-label_9vpcso",
    "course-time-input": "_course-time-input_9vpcso",
    "submit-row": "_submit-row_9vpcso",
    "submit": "_submit_9vpcso"
  };
  _exports.default = _default;
});