define("cc-frontend/templates/new-group/show/dashboard/lesson-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ap4Coj82",
    "block": "[[[8,[39,0],null,[[\"@group\",\"@startDate\",\"@endDate\",\"@setDateRange\",\"@focusedPlanbookId\",\"@setFocusedPlanbookId\",\"@focusedLesson\",\"@setFocusedLesson\"],[[30,1],[30,0,[\"startDate\"]],[30,0,[\"endDate\"]],[30,0,[\"setDateRange\"]],[30,0,[\"planbookId\"]],[30,0,[\"setPlanbookId\"]],[30,0,[\"lessonObj\"]],[30,0,[\"setLessonObj\"]]]],null]],[\"@model\"],false,[\"group/dashboard/lesson-review\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/dashboard/lesson-review.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});