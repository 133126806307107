define("cc-frontend/components/transition/child", ["exports", "ember-headlessui/components/transition/child"], function (_exports, _child) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _child.default;
    }
  });
});