define("cc-frontend/components/icons/rating-star-filled-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Rating-Star-1--Streamline-Ultimate"
  ><desc>Rating Star 1 Streamline Icon: https://streamlinehq.com</desc><path
      d="M23.655 9.1986C23.369 8.4064 22.6717 7.8348 21.8388 7.7099L16.4943 6.8911L14.023 2.023C13.2505 0.4873 11.1053 0.3638 10.1617 1.8007C10.1146 1.8722 10.0718 1.9465 10.0333 2.023L7.5621 6.8911L2.158 7.6652C1.3251 7.7826 0.6289 8.358 0.3567 9.1539C0.0814 9.963 0.3023 10.8583 0.9224 11.4465L4.8824 15.2576L3.9594 20.6021C3.8099 21.4385 4.1581 22.2859 4.8526 22.7756C5.534 23.2755 6.4404 23.3448 7.1899 22.9542L12.0282 20.4234L16.8665 22.9542C18.3913 23.748 20.2035 22.5934 20.1285 20.8761C20.1243 20.7792 20.1138 20.6827 20.097 20.5872L19.174 15.2576L23.0744 11.4763C23.6874 10.8904 23.9127 10.0064 23.655 9.1986Z"
      fill="currentColor"
      stroke-width="1"
    ></path></svg>
  */
  {
    "id": "nSAG5Fge",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[14,1,\"Rating-Star-1--Streamline-Ultimate\"],[12],[10,\"desc\"],[12],[1,\"Rating Star 1 Streamline Icon: https://streamlinehq.com\"],[13],[10,\"path\"],[14,\"d\",\"M23.655 9.1986C23.369 8.4064 22.6717 7.8348 21.8388 7.7099L16.4943 6.8911L14.023 2.023C13.2505 0.4873 11.1053 0.3638 10.1617 1.8007C10.1146 1.8722 10.0718 1.9465 10.0333 2.023L7.5621 6.8911L2.158 7.6652C1.3251 7.7826 0.6289 8.358 0.3567 9.1539C0.0814 9.963 0.3023 10.8583 0.9224 11.4465L4.8824 15.2576L3.9594 20.6021C3.8099 21.4385 4.1581 22.2859 4.8526 22.7756C5.534 23.2755 6.4404 23.3448 7.1899 22.9542L12.0282 20.4234L16.8665 22.9542C18.3913 23.748 20.2035 22.5934 20.1285 20.8761C20.1243 20.7792 20.1138 20.6827 20.097 20.5872L19.174 15.2576L23.0744 11.4763C23.6874 10.8904 23.9127 10.0064 23.655 9.1986Z\"],[14,\"fill\",\"currentColor\"],[14,\"stroke-width\",\"1\"],[12],[13],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/rating-star-filled-in.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});