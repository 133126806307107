define("cc-frontend/components/admin/csp/dropdown-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable style-concatenation }}
  <@items.Item as |item|>
    <item.Element
      style=" {{if item.isActive "background-color: hsla(203, 100%, 96%, 1);"}}"
      class="tw-flex tw-justify-between tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-lg tw-rounded
        {{if item.isDisabled "tw-cursor-not-allowed tw-opacity-50"}}"
      @tagName="button"
      {{on "click" @onClick}}
    >
      <span
        class={{if item.isActive "tw-font-semibold tw-text-link-blue"}}
        style="width: max-content;"
      >
        {{yield}}
      </span>
    </item.Element>
  </@items.Item>
  */
  {
    "id": "EkWK4jah",
    "block": "[[[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Element\"]],[[16,5,[29,[\" \",[52,[30,2,[\"isActive\"]],\"background-color: hsla(203, 100%, 96%, 1);\"]]]],[16,0,[29,[\"tw-flex tw-justify-between tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-lg tw-rounded\\n      \",[52,[30,2,[\"isDisabled\"]],\"tw-cursor-not-allowed tw-opacity-50\"]]]],[4,[38,1],[\"click\",[30,3]],null]],[[\"@tagName\"],[\"button\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[52,[30,2,[\"isActive\"]],\"tw-font-semibold tw-text-link-blue\"]],[14,5,\"width: max-content;\"],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"@items\",\"item\",\"@onClick\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "cc-frontend/components/admin/csp/dropdown-menu-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});