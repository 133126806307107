define("cc-frontend/utils/rotation-calendar/dates", ["exports", "lodash-es", "cc-frontend/utils/rotation-calendar/create-attributes"], function (_exports, _lodashEs, _createAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createRotationCalendarDates = createRotationCalendarDates;
  _exports.generateDates = generateDates;

  function createRotationCalendarDates(rotationCalendar) {
    return generateDates({
      semesters: rotationCalendar.attributes.semesters,
      rotations: rotationCalendar.attributes.rotations,
      schoolDays: rotationCalendar.attributes.schoolDays,
      rotationType: rotationCalendar.attributes.rotationType,
      dateAnnotations: rotationCalendar.attributes.dateAnnotations
    });
  }

  function generateDates({
    semesters,
    rotations,
    dateAnnotations,
    schoolDays,
    rotationType
  }) {
    let semester = semesters[0];
    let startDate = dateFns.parse(semester.startDate);
    let endDate = dateFns.parse(semester.endDate);
    let overrides = calculateRcOverrides(dateAnnotations);
    let indexOfLastReset = 0;
    return (0, _lodashEs.map)((0, _lodashEs.filter)(dateFns.eachDay(startDate, endDate), date => {
      return (0, _lodashEs.includes)(schoolDays, dateFns.getDay(date));
    }), date => {
      let dateString = dateFns.format(date, "YYYY-MM-DD");
      let rotationId;
      let exception = overrides.get(dateString) || null;

      if (rotationType === "weekly") {
        let weekDayInteger = dateFns.getDay(date);
        rotationId = _createAttributes.WEEKDAY_ROTATIONS[weekDayInteger].id;
      } else if (rotationType === "block") {
        /**
         * Handle Rotation Cycle
         * ---------------------
         * We track where we are in the cycle by incrementing an index and
         * resetting the index to 0 if the cycle needs to reset
         */
        if (exception === null || exception.rotationShould === "NOT_CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED") {
          // Increment like normal
          rotationId = rotations[indexOfLastReset % rotations.length].id;
          indexOfLastReset++;
        } else if (exception.rotationShould === "CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED") {
          rotationId = exception.changedRotationId;
          indexOfLastReset++;
        } else if (exception.rotationShould === "CHANGE_AND_CYCLE_SHOULD_RESET") {
          // Set the rotationId to the id of the first rotation
          rotationId = rotations[0].id; // reset the index and add one for the day we just applied

          indexOfLastReset = 1;
        } else if (exception.rotationShould === "CHANGE_AND_CYCLE_SHOULD_BUMP_OVER_DISRUPTION") {
          rotationId = exception.changedRotationId;
        } else {
          throw new Error("Rotation Cycle not set");
        }
      } else {
        throw new Error("Rotation Type not supported");
      }
      /**
       * Return the rotation date
       */


      return {
        id: dateString,
        type: "rotation-date-custom",
        attributes: {
          semesterId: semester.id,
          rotationId: rotationId,
          dateString: dateString,
          isOff: exception && exception.isOff || false
        }
      };
    });
  }

  function calculateRcOverrides(dateAnnotations) {
    let overrides = new Map(); // loop through ranges

    overrides = (0, _lodashEs.reduce)((0, _lodashEs.filter)(dateAnnotations, annotation => annotation.type === "date-annotation-rotation-calendar-range"), (acc, annotation) => {
      let startDate = dateFns.parse(annotation.attributes.startDate);
      let endDate = dateFns.parse(annotation.attributes.endDate);
      (0, _lodashEs.forEach)(dateFns.eachDay(startDate, endDate), date => {
        let dateString = dateFns.format(date, "YYYY-MM-DD");
        acc.set(dateString, {
          rotationShould: annotation.attributes.rotationShould,
          changedRotationId: null,
          isOff: annotation.attributes.isOff
        });
      });
      return acc;
    }, overrides); // loop through singles

    return (0, _lodashEs.reduce)((0, _lodashEs.filter)(dateAnnotations, annotation => annotation.type === "date-annotation-rotation-calendar-single"), (acc, annotation) => {
      return acc.set(annotation.attributes.dateString, {
        isOff: annotation.attributes.isOff,
        rotationShould: annotation.attributes.rotationShould,
        changedRotationId: annotation.attributes.changedRotationId
      });
    }, overrides);
  }
});