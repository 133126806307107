define("cc-frontend/lib/actions/rotation-calendar/CHANGE_CALENDAR", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeCalendar = changeCalendar;

  function changeCalendar(payload) {
    return {
      name: "CHANGE_CALENDAR_WITH_DATE_ANNOTATION",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let rcPatch = _patchUpdater.default.create("rotation-calendar", payload.rotationCalendar.id).set("attributes.dates", payload.rotationCalendar.updatedAttributes.dates).set("attributes.dateAnnotations", payload.rotationCalendar.updatedAttributes.dateAnnotations).set("attributes.schoolDays", payload.rotationCalendar.updatedAttributes.schoolDays).set("attributes.semesters", payload.rotationCalendar.updatedAttributes.semesters).set("attributes.rotations", payload.rotationCalendar.updatedAttributes.rotations).patch;

    let planbookPatch = null;

    if (payload.planbook !== null) {
      planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id).set("attributes.calendar", payload.planbook.updatedCalendar).patch;
    }

    let coursePatches = [];

    if (payload.courses !== null) {
      coursePatches = (0, _lodashEs.map)(payload.courses, ({
        id,
        originalCalendar,
        updatedCalendar
      }) => {
        return _patchUpdater.default.create("course", id).set("attributes.calendar", updatedCalendar).patch;
      });
    } // TODO Add patches for creating card stacks


    return (0, _lodashEs.compact)((0, _lodashEs.concat)([rcPatch, planbookPatch], coursePatches));
  }

  function undoPatches(payload) {
    let rcPatch = _patchUpdater.default.create("rotation-calendar", payload.rotationCalendar.id).set("attributes.dates", payload.rotationCalendar.originalAttributes.dates).set("attributes.dateAnnotations", payload.rotationCalendar.originalAttributes.dateAnnotations).set("attributes.schoolDays", payload.rotationCalendar.originalAttributes.schoolDays).set("attributes.semesters", payload.rotationCalendar.originalAttributes.semesters).set("attributes.rotations", payload.rotationCalendar.originalAttributes.rotations).patch;

    let planbookPatch = null;

    if (payload.planbook !== null) {
      planbookPatch = _patchUpdater.default.create("planbook", payload.planbook.id).set("attributes.calendar", payload.planbook.originalCalendar).patch;
    }

    let coursePatches = [];

    if (payload.courses !== null) {
      coursePatches = (0, _lodashEs.map)(payload.courses, ({
        id,
        originalCalendar,
        updatedCalendar
      }) => {
        return _patchUpdater.default.create("course", id).set("attributes.calendar", originalCalendar).patch;
      });
    }

    return (0, _lodashEs.compact)((0, _lodashEs.concat)([rcPatch, planbookPatch], coursePatches));
  }

  function narrative(payload, findFn, userId) {
    var _payload$planbook6;

    let titleForAnalytics;
    let title;
    let newEvent;

    if (payload.causeOfChange === "ADD_IS_OFF") {
      var _payload$planbook;

      titleForAnalytics = "Rotation Calendar - Add is Off";
      title = "Add Day Off";
      newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id: ((_payload$planbook = payload.planbook) === null || _payload$planbook === void 0 ? void 0 : _payload$planbook.id) || null,
        primary_object_id_and_ancestor_ids: payload.planbook ? [payload.planbook.id] : [],
        primary_verb: "edit",
        primary_possessive: payload.planbook !== null ? (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id) : null,
        primary_object_type: "planbook",
        primary_object_specifier: "calendar",
        adverbial_verb: "adding",
        adverbial_object_type: "rotation_day_off",
        adverbial_object_specifier: null
      };
    } else if (payload.causeOfChange === "ADD_NOTE") {
      titleForAnalytics = "Rotation Calendar - Add Note";
      title = "Add Note";
      newEvent = null;
    } else if (payload.causeOfChange === "CHANGE_ROTATION_ID") {
      var _payload$planbook2;

      titleForAnalytics = "Rotation Calendar - Changed Rotation Id";
      title = "Changed Rotation Day";
      newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id: ((_payload$planbook2 = payload.planbook) === null || _payload$planbook2 === void 0 ? void 0 : _payload$planbook2.id) || null,
        primary_object_id_and_ancestor_ids: payload.planbook ? [payload.planbook.id] : [],
        primary_verb: "edit",
        primary_possessive: payload.planbook !== null ? (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id) : null,
        primary_object_type: "planbook",
        primary_object_specifier: "calendar",
        adverbial_verb: "editing",
        adverbial_object_type: "rotation",
        adverbial_object_specifier: "id"
      };
    } else if (payload.causeOfChange === "RESET_ROTATION") {
      var _payload$planbook3;

      titleForAnalytics = "Rotation Calendar - Reset Rotation";
      title = "Reset Rotation";
      newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id: ((_payload$planbook3 = payload.planbook) === null || _payload$planbook3 === void 0 ? void 0 : _payload$planbook3.id) || null,
        primary_object_id_and_ancestor_ids: payload.planbook ? [payload.planbook.id] : [],
        primary_verb: "edit",
        primary_possessive: payload.planbook !== null ? (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id) : null,
        primary_object_type: "planbook",
        primary_object_specifier: "calendar",
        adverbial_verb: "editing",
        adverbial_object_type: "rotation",
        adverbial_object_specifier: "reset"
      };
    } else if (payload.causeOfChange === "SKIP_ROTATION_DAY") {
      var _payload$planbook4;

      titleForAnalytics = "Rotation Calendar - Skip Rotation";
      title = "Skipped Rotation";
      newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id: ((_payload$planbook4 = payload.planbook) === null || _payload$planbook4 === void 0 ? void 0 : _payload$planbook4.id) || null,
        primary_object_id_and_ancestor_ids: payload.planbook ? [payload.planbook.id] : [],
        primary_verb: "edit",
        primary_possessive: payload.planbook !== null ? (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id) : null,
        primary_object_type: "planbook",
        primary_object_specifier: "calendar",
        adverbial_verb: "editing",
        adverbial_object_type: "rotation",
        adverbial_object_specifier: "skip"
      };
    } else if (payload.causeOfChange === "REMOVE_DATE_ANNOTATION") {
      var _payload$planbook5;

      titleForAnalytics = "Rotation Calendar - Remove Annotation";
      title = "Removed Calendar Event";
      newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id: ((_payload$planbook5 = payload.planbook) === null || _payload$planbook5 === void 0 ? void 0 : _payload$planbook5.id) || null,
        primary_object_id_and_ancestor_ids: payload.planbook ? [payload.planbook.id] : [],
        primary_verb: "edit",
        primary_possessive: payload.planbook !== null ? (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id) : null,
        primary_object_type: "planbook",
        primary_object_specifier: "calendar",
        adverbial_verb: "removing",
        adverbial_object_type: "annotation",
        adverbial_object_specifier: null
      };
    }

    return {
      activity: {},
      context: {
        planbookId: (_payload$planbook6 = payload.planbook) === null || _payload$planbook6 === void 0 ? void 0 : _payload$planbook6.id,
        rotationCalendarId: payload.rotationCalendar.id,
        causeOfChange: payload.causeOfChange
      },
      description: payload.changeDescription,
      // @ts-ignore causeOfChange cases are not exhaustive
      title: title,
      // @ts-ignore causeOfChange cases are not exhaustive
      titleForAnalytics: titleForAnalytics,
      // @ts-ignore causeOfChange cases are not exhaustive
      newAnalyticsEvents: newEvent ? [newEvent] : []
    };
  }
});