define("cc-frontend/components/planner/card-stacks/cards/add-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "add-card-wrapper": "_add-card-wrapper_bukag4",
    "--notes": "_--notes_bukag4",
    "add-card": "_add-card_bukag4",
    "--fullScreen": "_--fullScreen_bukag4"
  };
  _exports.default = _default;
});