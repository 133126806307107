define("cc-frontend/lib/actions/course/calendar/COURSE_ROTATION_SET_TEMPLATE_ID", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "COURSE_ROTATION_SET_TEMPLATE_ID",
    params: {
      courseId: "string",
      rotationId: "string",
      semesterId: "string",
      dateId: "string",
      cardStackId: {
        type: ["string", "null"]
      },
      currentTemplateId: "string"
    },

    patches(payload) {
      return [_patchUpdater.default.create("course", payload.courseId, "attributes.calendar.dates", {
        id: payload.dateId
      }).set("embedded", "attributes.cardStackId", payload.cardStackId).inc("attributes.calendar.version", 1).patch];
    },

    undoPatches(payload) {
      return [_patchUpdater.default.create("course", payload.courseId, "attributes.calendar.dates", {
        id: payload.dateId
      }).set("embedded", "attributes.cardStackId", payload.currentTemplateId).inc("attributes.calendar.version", 1).patch];
    },

    narrative(payload, findFn, userId) {
      var _payload$cardStackId;

      let course = findFn("course", payload.courseId);
      let template = findFn("card-stack", (_payload$cardStackId = payload.cardStackId) !== null && _payload$cardStackId !== void 0 ? _payload$cardStackId : "");
      let planbook = findFn("planbook", payload.planbookId);
      let rotationCalendar = findFn("rotation-calendar", planbook.relationships.rotationCalendar.data.id);
      let models = {
        template: template,
        course: course,
        planbook: planbook,
        rotationCalendar
      };
      let templateTitle = (0, _lodashEs.get)(models, "template.attributes.title");
      let rotation = (0, _lodashEs.find)((0, _lodashEs.get)(models, "rotationCalendar.attributes.rotations"), {
        id: payload.rotationId
      });
      let title = (0, _lodashEs.get)(rotation, "title");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_object_type: "course",
        primary_object_id: payload.courseId,
        primary_object_specifier: "template_assignment",
        primary_object_id_and_ancestor_ids: [payload.planbookId, payload.courseId],
        adverbial_verb: "editing",
        adverbial_object_type: "rotation",
        // Note: This might be a weekday instead of an ObjectId.
        adverbial_object_id: payload.rotationId,
        adverbial_object_specifier: "specific",
        adverbial_object_id_and_ancestor_ids: [payload.courseId, payload.planbookId]
      };
      return {
        title: `Changed the template`,
        titleForAnalytics: "Course - Change Template for Rotation",
        description: `Change the template to "${templateTitle}" for all lessons that happen on ${title}`,
        context: {
          templateTitle: (0, _lodashEs.get)(models, "template.attributes.title"),
          courseTitle: (0, _lodashEs.get)(models, "course.attributes.title"),
          planbookTitle: (0, _lodashEs.get)(models, "planbook.attributes.title"),
          courseId: (0, _lodashEs.get)(models, "course.id"),
          planbookId: (0, _lodashEs.get)(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.course.id}`,
          to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.default = _default;
});