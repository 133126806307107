define("cc-frontend/lib/actions/lesson/LESSON_CARD_REMOVE_COMMENT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_REMOVE_COMMENT = void 0;
  const LESSON_CARD_REMOVE_COMMENT = {
    name: "LESSON_CARD_REMOVE_COMMENT",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).pull("embedded", "attributes.comments", {
        id: payload.comment.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).addToSet("embedded", "attributes.comments", JSON.parse(JSON.stringify(payload.comment))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let card = find((0, _lodashEs.get)(models, "lesson.attributes.cards"), {
        id: payload.cardId
      });
      let cardTitle = (0, _lodashEs.get)(card, "title");
      let context = (0, _defaultLessonContext.default)(payload, models);
      let commenterName = (0, _lodashEs.get)(payload, "comment.commenterName");
      let commenterId = (0, _lodashEs.get)(payload, "comment.commenterId");
      let newContext = (0, _lodashEs.assign)({}, context, {
        cardTitle: cardTitle,
        commenterName,
        commenterId
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        primary_object_id: payload.cardStackId,
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "comment",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "comments",
        adverbial_verb: "removing",
        adverbial_object_type: "comment",
        adverbial_object_specifier: null,
        adverbial_object_id: payload.comment.id,
        adverbial_object_id_and_ancestor_ids: [...models.cardStackIdAndAncestorIds, payload.comment.id]
      };
      return {
        title: "Removed a Comment",
        titleForAnalytics: "Comment - Remove",
        description: `Removed a comment from the "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "removed"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_REMOVE_COMMENT = LESSON_CARD_REMOVE_COMMENT;
  var _default = LESSON_CARD_REMOVE_COMMENT;
  _exports.default = _default;
});