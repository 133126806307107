define("cc-frontend/lib/actions/planbook/PLANBOOK_CREATE_LESSON_TEMPLATE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_SAVE_AS_TEMPLATE", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models", "cc-frontend/models/analytics/planbook", "cc-frontend/models/analytics/base"], function (_exports, _patchUpdater, _lodash, _COURSE_DATE_SAVE_AS_TEMPLATE, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels, _planbook, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_CREATE_LESSON_TEMPLATE = void 0;
  const PLANBOOK_CREATE_LESSON_TEMPLATE = {
    name: "PLANBOOK_CREATE_LESSON_TEMPLATE",
    // Please change lessonAttributes to match payload of COURSE_DATE_EDIT_TEMPLATE
    params: {
      newId: "string",
      planbookId: "string",
      lessonAttributes: "object",
      assignTemplatePatches: "array",
      assignTemplateUndoPatches: "array"
    },

    patches(payload) {
      return _lodash.default.concat(_COURSE_DATE_SAVE_AS_TEMPLATE.COURSE_DATE_SAVE_AS_TEMPLATE.patches(payload), payload.assignTemplatePatches);
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).pull("relationships.cardStackTemplates.data", {
        id: payload.newId,
        type: "card-stack"
      }).patch;

      return _lodash.default.concat([patch], payload.assignTemplateUndoPatches);
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
      let planbook = Ember.get(models, "planbook");
      let planbookTitle = Ember.get(Ember.get(planbook, "attributes"), "title");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id_and_ancestor_ids: [payload.planbookId],
        primary_object_type: "planbook",
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_object_specifier: "templates",
        adverbial_verb: "adding",
        adverbial_object_type: "template"
      };
      return {
        title: "Created New Template",
        titleForAnalytics: "Planbook - Create Template",
        description: `Added a new template to your ${planbookTitle} planbook`,
        context: (0, _defaultPlanbookContext.default)(payload, models),
        activity: (0, _defaultPlanbookActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.PLANBOOK_CREATE_LESSON_TEMPLATE = PLANBOOK_CREATE_LESSON_TEMPLATE;
  var _default = PLANBOOK_CREATE_LESSON_TEMPLATE;
  _exports.default = _default;
});