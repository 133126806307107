define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_REPLACE", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_REPLACE = void 0;
  const LESSON_STANDARDS_REPLACE = {
    name: "LESSON_STANDARDS_REPLACE",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.standards", payload.standards).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardId
      }).set("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.previousStandards))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let diff = (0, _lodashEs.get)(payload, "standards.length") - payload.previousStandards.length;
      let verb = diff > 0 ? "Added" : "Removed";
      let noun = diff === 1 ? "standard" : "standards";
      let absoluteDiff = Math.abs(diff);
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "standards",
        adverbial_verb: "adding",
        adverbial_object_type: "standard",
        adverbial_object_specifier: "lesson",
        adverbial_object_id: payload.cardId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Changed Lesson Standards",
        titleForAnalytics: "Lesson - Update Standards",
        description: `${verb} ${absoluteDiff} ${noun}`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_STANDARDS_REPLACE = LESSON_STANDARDS_REPLACE;
  var _default = LESSON_STANDARDS_REPLACE;
  _exports.default = _default;
});