define("cc-frontend/components/group/team-edit.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "delete": "_delete_ojc6c",
    "remove-member": "_remove-member_ojc6c",
    "user-row": "_user-row_ojc6c"
  };
  _exports.default = _default;
});