define("cc-frontend/components/icons/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg fill="currentColor" viewBox="0 0 18 18">
    <g clip-path="url(#a)">
      <path
        d="M10.313 6.375a1.313 1.313 0 0 0-2.626 0V7.5a.187.187 0 0 0 .188.188h2.25a.188.188 0 0 0 .188-.188V6.375Z"
      ></path>
      <path
        d="M9 0a9 9 0 0 0 0 18h.037A9 9 0 1 0 9 0ZM5.625 12.563V8.438a.75.75 0 0 1 .75-.75h.188V6.375a2.437 2.437 0 0 1 4.875 0v1.313h.187a.75.75 0 0 1 .75.75v4.124a.75.75 0 0 1-.75.75h-5.25a.75.75 0 0 1-.75-.75Z"
      ></path>
      <path d="M8.063 10.875a.937.937 0 1 0 1.874 0 .937.937 0 0 0-1.874 0Z"></path>
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h18v18H0z"></path>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "W5rEeC0Y",
    "block": "[[[10,\"svg\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 18 18\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M10.313 6.375a1.313 1.313 0 0 0-2.626 0V7.5a.187.187 0 0 0 .188.188h2.25a.188.188 0 0 0 .188-.188V6.375Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M9 0a9 9 0 0 0 0 18h.037A9 9 0 1 0 9 0ZM5.625 12.563V8.438a.75.75 0 0 1 .75-.75h.188V6.375a2.437 2.437 0 0 1 4.875 0v1.313h.187a.75.75 0 0 1 .75.75v4.124a.75.75 0 0 1-.75.75h-5.25a.75.75 0 0 1-.75-.75Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M8.063 10.875a.937.937 0 1 0 1.874 0 .937.937 0 0 0-1.874 0Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M0 0h18v18H0z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/lock.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});