define("cc-frontend/components/icons/video-play-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
    <path
      d="M36.2291 11.1976C36.2293 10.5015 36.0923 9.81208 35.8261 9.16884C35.5598 8.52561 35.1694 7.94113 34.6772 7.4488C34.185 6.95646 33.6007 6.56591 32.9575 6.29945C32.3144 6.03299 31.625 5.89584 30.9288 5.89584H6.06946C5.37342 5.89605 4.68424 6.03334 4.04126 6.29989C3.39828 6.56644 2.8141 6.95702 2.32207 7.44934C1.83004 7.94166 1.4398 8.52607 1.17362 9.1692C0.907445 9.81233 0.77055 10.5016 0.770753 11.1976V25.2592C0.770348 25.9553 0.907093 26.6448 1.17318 27.2881C1.43926 27.9314 1.82947 28.516 2.32152 29.0085C2.81358 29.5009 3.39783 29.8917 4.0409 30.1583C4.68398 30.425 5.37329 30.5623 6.06946 30.5625H30.9288C31.6251 30.5625 32.3146 30.4253 32.9579 30.1588C33.6011 29.8922 34.1856 29.5015 34.6778 29.009C35.17 28.5165 35.5603 27.9319 35.8265 27.2884C36.0927 26.645 36.2295 25.9555 36.2291 25.2592V11.1976ZM14.6458 24.3018V10.951L25.0813 17.6279L14.6458 24.3018Z"
      stroke="#757575"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
  */
  {
    "id": "SwTZF8LD",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"37\"],[14,\"height\",\"38\"],[14,\"viewBox\",\"0 0 37 38\"],[14,\"fill\",\"none\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M36.2291 11.1976C36.2293 10.5015 36.0923 9.81208 35.8261 9.16884C35.5598 8.52561 35.1694 7.94113 34.6772 7.4488C34.185 6.95646 33.6007 6.56591 32.9575 6.29945C32.3144 6.03299 31.625 5.89584 30.9288 5.89584H6.06946C5.37342 5.89605 4.68424 6.03334 4.04126 6.29989C3.39828 6.56644 2.8141 6.95702 2.32207 7.44934C1.83004 7.94166 1.4398 8.52607 1.17362 9.1692C0.907445 9.81233 0.77055 10.5016 0.770753 11.1976V25.2592C0.770348 25.9553 0.907093 26.6448 1.17318 27.2881C1.43926 27.9314 1.82947 28.516 2.32152 29.0085C2.81358 29.5009 3.39783 29.8917 4.0409 30.1583C4.68398 30.425 5.37329 30.5623 6.06946 30.5625H30.9288C31.6251 30.5625 32.3146 30.4253 32.9579 30.1588C33.6011 29.8922 34.1856 29.5015 34.6778 29.009C35.17 28.5165 35.5603 27.9319 35.8265 27.2884C36.0927 26.645 36.2295 25.9555 36.2291 25.2592V11.1976ZM14.6458 24.3018V10.951L25.0813 17.6279L14.6458 24.3018Z\"],[14,\"stroke\",\"#757575\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/video-play-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});