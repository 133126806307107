define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_COPY", ["exports", "cc-frontend/lib/patch-updater", "lodash"], function (_exports, _patchUpdater, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_STANDARDS_COPY = void 0;
  const LESSON_STANDARDS_COPY = {
    name: "LESSON_STANDARDS_COPY",
    params: {
      currentUserId: "string",
      standards: {
        type: "array",
        items: {
          type: "object"
        }
      }
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.card", {
        type: "card-standards",
        attributes: {
          standards: payload.standards
        }
      }).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      // let models = defaultLessonModels(payload, findFn, userId)
      // let context = defaultLessonContext(payload, models)
      let standards = _lodash.default.map(payload.standards, standard => {
        return standard.code;
      });

      let standardSets = _lodash.default.map(payload.standards, standard => {
        return `${standard.jurisdiction} - ${standard.grades} - ${standard.subject}`;
      });

      let newContext = _lodash.default.assign({}, {}, {
        standards,
        standardSets
      });

      return {
        title: null,
        titleForAnalytics: "Standards - copy",
        description: null,
        context: newContext,
        activity: null
      };
    }

  };
  _exports.LESSON_STANDARDS_COPY = LESSON_STANDARDS_COPY;
  var _default = LESSON_STANDARDS_COPY;
  _exports.default = _default;
});