define("cc-frontend/lib/actions/course/calendar/COURSE_SET_ALL_TIMES", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/utils/filter-dates/course", "lodash", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models", "cc-frontend/lib/actions/course/calendar/COURSE_SET_ONE_TIME"], function (_exports, _patchUpdater, _base, _planbook, _course, _lodash, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels, _COURSE_SET_ONE_TIME) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCourseSetAllTimesAction = prepareCourseSetAllTimesAction;

  function prepareCourseSetAllTimesAction(payload) {
    return {
      name: "COURSE_SET_ALL_TIMES",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodash.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    // check if there are date defaults
    let defaultDates = (0, _lodash.filter)(payload.course.attributes.calendar.dates, _course.isCourseDateDefault);
    let patches = [];

    if (defaultDates.length === 0) {
      let semester = payload.rotationCalendar.attributes.semesters[0];
      patches = (0, _lodash.map)(payload.rotationCalendar.attributes.rotations, rotation => {
        return _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dates", {
          id: rotation.id,
          type: "course-date-default",
          attributes: {
            rotationId: rotation.id,
            semesterId: semester.id,
            cardStackId: null,
            time: payload.time
          }
        }).inc("attributes.calendar.version", 1).patch;
      });
    } else {
      patches = (0, _lodash.flatten)((0, _lodash.map)((0, _lodash.filter)(payload.course.attributes.calendar.dates, date => date.type === "course-date-default"), courseDate => {
        return _COURSE_SET_ONE_TIME.default.patches({
          course: payload.course,
          planbookId: payload.planbookId,
          courseDate,
          // Not used by patches()
          rotationTitle: "",
          time: payload.time
        });
      }));
    }

    return patches;
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "course",
      primary_object_id: payload.course.id,
      primary_object_specifier: "time",
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.course.id],
      adverbial_verb: "editing",
      adverbial_object_type: "rotation",
      adverbial_object_id: payload.rotationCalendar.id,
      adverbial_object_specifier: "all",
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.course.id]
    };
    return {
      title: "Assigned Time",
      titleForAnalytics: "Course - Assigned Time to All Days",
      description: `Set the time of your ${payload.course.attributes.title} class`,
      context: (0, _defaultCourseContext.default)(payload, models),
      activity: (0, _defaultCourseActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});