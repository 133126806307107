define("cc-frontend/components/planner/menus/help-menu-v2.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "help-menu-button__anchor": "_help-menu-button__anchor_19u3u1",
    "trigger-text": "_trigger-text_19u3u1",
    "dropdown-body": "_dropdown-body_19u3u1",
    "row": "_row_19u3u1",
    "row-icon": "_row-icon_19u3u1",
    "row-text": "_row-text_19u3u1",
    "row-text-headline": "_row-text-headline_19u3u1",
    "row-text-description": "_row-text-description_19u3u1",
    "sync-status-row": "_sync-status-row_19u3u1"
  };
  _exports.default = _default;
});