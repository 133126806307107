define("cc-frontend/components/planner/card-stacks/lesson/expand-collapse-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "expand-collapse-button-wrapper": "_expand-collapse-button-wrapper_sd37oq",
    "expand-collapse-button": "_expand-collapse-button_sd37oq"
  };
  _exports.default = _default;
});