define("cc-frontend/lib/actions/app-state/KB_SELECT_ARTICLE_FROM_SEARCH", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = selectArticleFromSearchAction;

  function selectArticleFromSearchAction(payload) {
    return {
      name: "KB_SELECT_ARTICLE_FROM_SEARCH",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: "help_site",
      root_type: "app",
      primary_verb: "view",
      primary_object_type: "help_article",
      primary_object_id: payload.articleId,
      primary_object_specifier: "search_box",
      primary_object_id_and_ancestor_ids: ["help_site", payload.articleId]
    };
    return (0, _createNarrative.default)({
      title: "KB Article Selected from Search",
      description: "User accessed a help article through the suggested articles in their search of the KB",
      titleForAnalytics: "KB Search - Select Article",
      context: {
        searchQuery: payload.searchQuery,
        articleTitle: payload.articleTitle,
        articleId: payload.articleId,
        parentId: payload.parentId,
        ancestorIds: payload.ancestorIds
      },
      newAnalyticsEvents: [newEvent]
    });
  }
});