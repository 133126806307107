define("cc-frontend/components/planner/card-stacks/lesson/expand-collapse-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="expand-collapse-button-wrapper {{if @showAsNewLesson "--show-as-new-lesson"}}">
    {{#if @lessonIsExpanded}}
      <div local-class="expand-collapse-button" {{on "click" @collapseThisLesson}}>
        <Icons::DoubleArrowUp />
        Collapse
        <Icons::DoubleArrowUp />
      </div>
    {{else}}
      <div local-class="expand-collapse-button" {{on "click" @expandThisLesson}}>
        <Icons::DoubleArrowDown />
        Expand
        <Icons::DoubleArrowDown />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "hZXSCdoP",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[[28,[37,1],[\"expand-collapse-button-wrapper \",[52,[30,1],\"--show-as-new-lesson\"]],null]],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/expand-collapse-button.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[11,0],[16,0,[29,[[28,[37,0],[\"expand-collapse-button\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/expand-collapse-button.css\"]]]]]],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n      Collapse\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[16,0,[29,[[28,[37,0],[\"expand-collapse-button\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/expand-collapse-button.css\"]]]]]],[4,[38,3],[\"click\",[30,4]],null],[12],[1,\"\\n      \"],[8,[39,5],null,null,null],[1,\"\\n      Expand\\n      \"],[8,[39,5],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@showAsNewLesson\",\"@lessonIsExpanded\",\"@collapseThisLesson\",\"@expandThisLesson\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"icons/double-arrow-up\",\"icons/double-arrow-down\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson/expand-collapse-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});