define("cc-frontend/components/group/members-table/cells/avatar", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/helpers/find-doc", "ember-could-get-used-to-this"], function (_exports, _glimmerComponent, _findDoc, _emberCouldGetUsedToThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (includes (array "group-membership-accepted" "group-membership-requested") @row.type)}}
    <div class="group-member-table__avatar">
      {{#if this.userSummary.content}}
        <User::Avatar
          @userSummary={{this.userSummary.content}}
          @avatarSizeClass="user-avatar--md"
          @enableTooltip={{false}}
          class="group-member-table__info__avatar"
        />
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "WB0lkT4t",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[\"group-membership-accepted\",\"group-membership-requested\"],null],[30,1,[\"type\"]]],null],[[[1,\"  \"],[10,0],[14,0,\"group-member-table__avatar\"],[12],[1,\"\\n\"],[41,[30,0,[\"userSummary\",\"content\"]],[[[1,\"      \"],[8,[39,3],[[24,0,\"group-member-table__info__avatar\"]],[[\"@userSummary\",\"@avatarSizeClass\",\"@enableTooltip\"],[[30,0,[\"userSummary\",\"content\"]],\"user-avatar--md\",false]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@row\"],false,[\"if\",\"includes\",\"array\",\"user/avatar\"]]",
    "moduleName": "cc-frontend/components/group/members-table/cells/avatar.hbs",
    "isStrictMode": false
  });

  let GroupMembersTableAvatarCell = (_class = class GroupMembersTableAvatarCell extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "userSummary", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userSummary", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _findDoc.default(() => {
        let userId = this.args.row.type === "group-membership-accepted" || this.args.row.type === "group-membership-requested" ? this.args.row.attributes.userId : null;
        return ["user-summary", userId];
      });
    }
  })), _class);
  _exports.default = GroupMembersTableAvatarCell;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupMembersTableAvatarCell);
});