define("cc-frontend/lib/actions/course/calendar/COURSE_SET_ONE_TIME", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash", "moment", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models"], function (_exports, _patchUpdater, _base, _planbook, _lodash, _moment, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCourseSetOneTimeAction = prepareCourseSetOneTimeAction;
  _exports.default = void 0;

  function prepareCourseSetOneTimeAction(payload) {
    return {
      name: "COURSE_SET_ONE_TIME",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodash.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    return [_patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
      id: payload.courseDate.id
    }).set("embedded", "attributes.time", payload.time).inc("attributes.calendar.version", 1).patch];
  }

  function undoPatches(payload) {
    return [_patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
      id: payload.courseDate.id
    }).set("embedded", "attributes.time", payload.courseDate.attributes.time).inc("attributes.calendar.version", 1).patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "course",
      primary_object_id: payload.course.id,
      primary_object_specifier: "time",
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.course.id],
      adverbial_verb: "editing",
      adverbial_object_type: "day",
      adverbial_object_id: payload.courseDate.id,
      adverbial_object_specifier: "specific",
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.course.id]
    };
    const dateMessage = payload.courseDate.type === "course-date-default" ? `every ${payload.rotationTitle}` : (0, _moment.default)(payload.courseDate.attributes.dateString).format("ddd, MMM. D, YYYY");
    return {
      title: "Assigned Time",
      titleForAnalytics: "Course - Assigned Time to Day",
      description: `Set the time of your ${payload.course.attributes.title} class on ${dateMessage}`,
      context: (0, _defaultCourseContext.default)(payload, models),
      activity: (0, _defaultCourseActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }

  const COURSE_SET_ONE_TIME = {
    patches
  };
  var _default = COURSE_SET_ONE_TIME;
  _exports.default = _default;
});