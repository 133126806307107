define("cc-frontend/components/planner/planbook/date-annotation.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "date-annotation": "_date-annotation_19lwas",
    "on-month": "_on-month_19lwas",
    "is-off": "_is-off_19lwas",
    "is-on": "_is-on_19lwas",
    "date-annotation__info": "_date-annotation__info_19lwas",
    "date-annotation__school-title-wrapper": "_date-annotation__school-title-wrapper_19lwas",
    "date-annotation__school-title": "_date-annotation__school-title_19lwas",
    "date-annotation__title": "_date-annotation__title_19lwas",
    "date-annotation__description": "_date-annotation__description_19lwas",
    "special-event-block__menu-trigger": "_special-event-block__menu-trigger_19lwas",
    "--has-no-template": "_--has-no-template_19lwas",
    "date-annotation__is-off-notice": "_date-annotation__is-off-notice_19lwas",
    "date-annotation__range": "_date-annotation__range_19lwas"
  };
  _exports.default = _default;
});