define("cc-frontend/helpers/permissions-include", ["exports", "@glint/environment-ember-loose/ember-component/helper", "lodash-es"], function (_exports, _helper, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.permissionsInclude = permissionsInclude;
  _exports.default = void 0;

  function permissionsInclude(permissions, permission) {
    return (0, _lodashEs.includes)(permissions, permission);
  }

  const permissionsIncludeHelper = (0, _helper.helper)(function (args) {
    let [permissions, perm] = args;
    return permissionsInclude(permissions, perm);
  });
  var _default = permissionsIncludeHelper;
  _exports.default = _default;
});