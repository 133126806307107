define("cc-frontend/components/planner/navs/undo-button-v2.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "undo-button": "_undo-button_8sena2"
  };
  _exports.default = _default;
});