define("cc-frontend/utils/group", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isGroupMembershipGroup = isGroupMembershipGroup;
  _exports.isGroupMembershipAccepted = isGroupMembershipAccepted;
  _exports.isGroupMembershipInvited = isGroupMembershipInvited;
  _exports.isGroupMembershipRequested = isGroupMembershipRequested;
  _exports.isGroupMembershipIndividual = isGroupMembershipIndividual;
  _exports.isGroupMembershipTeam = isGroupMembershipTeam;
  _exports.isGroupMembershipSchool = isGroupMembershipSchool;
  _exports.membersWithMemberRole = membersWithMemberRole;
  _exports.membersWithAdminRole = membersWithAdminRole;
  _exports.getGroupId = getGroupId;
  _exports.getUserId = getUserId;

  function isGroupMembershipGroup(membership) {
    return membership.type === "group-membership-group";
  }

  function isGroupMembershipAccepted(membership) {
    return membership.type === "group-membership-accepted";
  }

  function isGroupMembershipInvited(membership) {
    return membership.type === "group-membership-invited";
  }

  function isGroupMembershipRequested(membership) {
    return membership.type === "group-membership-requested";
  }

  function isGroupMembershipIndividual(membership) {
    return membership.type === "group-membership-accepted" || membership.type === "group-membership-invited";
  }

  function isGroupMembershipTeam(membership) {
    return Ember.get(membership, "type") === "group-membership-group" && Ember.get(membership.attributes, "usedAs") === "team";
  }

  function isGroupMembershipSchool(membership) {
    return membership.type === "group-membership-group" && membership.attributes.usedAs === "school";
  }

  function membersWithMemberRole(membership) {
    return (0, _lodashEs.includes)(Ember.get(membership.attributes, "roles"), "group:is-member");
  }

  function membersWithAdminRole(membership) {
    return (0, _lodashEs.includes)(Ember.get(membership.attributes, "roles"), "group:is-admin");
  }

  function getGroupId(membership) {
    return Ember.get(membership.attributes, "groupId");
  }

  function getUserId(membership) {
    return Ember.get(membership.attributes, "userId");
  }
});