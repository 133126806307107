define("cc-frontend/models/holiday", ["exports", "cc-frontend/lib/object-id-gen", "lodash-es"], function (_exports, _objectIdGen, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertHolidayToPlanbookDateAnnotation = convertHolidayToPlanbookDateAnnotation;

  function convertHolidayToPlanbookDateAnnotation(holiday) {
    let type = holiday.attributes.startDate === holiday.attributes.endDate ? "date-annotation-planbook-custom-single" : "date-annotation-planbook-custom-range";
    let baseAttributes = {
      title: holiday.attributes.title,
      isOff: holiday.attributes.isOff,
      isPublic: true,
      description: null
    };
    return holiday.attributes.startDate === holiday.attributes.endDate ? {
      id: _objectIdGen.default.create(),
      type: "date-annotation-planbook-custom-single",
      attributes: (0, _lodashEs.merge)(baseAttributes, {
        dateString: holiday.attributes.startDate
      })
    } : {
      id: _objectIdGen.default.create(),
      type: "date-annotation-planbook-custom-range",
      attributes: (0, _lodashEs.merge)(baseAttributes, {
        startDate: holiday.attributes.startDate,
        endDate: holiday.attributes.endDate,
        publishedByGroupId: null
      })
    };
  }
});