define("cc-frontend/lib/actions/class-website/UPDATE_CLASS_WEBSITE_TITLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/class-website/default-class-website-activity", "cc-frontend/lib/actions/class-website/default-class-website-context", "cc-frontend/lib/actions/class-website/default-class-website-models"], function (_exports, _patchUpdater, _base, _planbook, _lodashEs, _defaultClassWebsiteActivity, _defaultClassWebsiteContext, _defaultClassWebsiteModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = updateClassWebsiteTitleAction;

  function updateClassWebsiteTitleAction(payload) {
    return {
      name: "UPDATE_CLASS_WEBSITE_TITLE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.title", payload.title).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("class-website", payload.websiteId).set("attributes.title", payload.originalTitle).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultClassWebsiteModels.default)(payload, findFn, userId);
    let newTitle = payload.title;
    let originalTitle = payload.originalTitle;
    let websiteSlug = (0, _lodashEs.get)(models, "classWebsite.attributes.slug");
    let context = (0, _lodashEs.assign)({}, (0, _defaultClassWebsiteContext.default)(payload, models), {
      newTitle,
      originalTitle,
      websiteSlug
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "class_website",
      primary_object_id: payload.websiteId,
      primary_object_specifier: "title",
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId],
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId]
    };
    return {
      title: "Renamed Class Website",
      titleForAnalytics: "Class Website - Edit Title",
      description: `Change your website's name from "${originalTitle}" to "${originalTitle}"`,
      activity: (0, _defaultClassWebsiteActivity.default)(payload, models),
      context,
      newAnalyticsEvents: [newEvent]
    };
  }
});