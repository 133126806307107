define("cc-frontend/lib/actions/planbook/default-planbook-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultPlanbookContext;

  function defaultPlanbookContext(_payload, models) {
    return {
      planbookId: Ember.get(Ember.get(models, "planbook"), "id"),
      planbookTitle: Ember.get(Ember.get(Ember.get(models, "planbook"), "attributes"), "title"),
      userId: Ember.get(Ember.get(models, "user"), "id")
    };
  }
});