define("cc-frontend/components/planner/card-stacks/cards/card-standards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::CardStacks::Cards::Parts::TopPart>
    <Planner::CardStacks::Cards::Parts::TextBlock>
      <Planner::CardStacks::Cards::Parts::Title
        @modelId={{@card.id}}
        @value={{@card.attributes.title}}
        @permissions={{@permissions}}
        @cardStackId={{@cardStackId}}
        @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
      />
      <Planner::CardStacks::Cards::Parts::Body>
  
        <Planner::CardStacks::Cards::Parts::Standards
          @section={{@card}}
          @cardStackId={{@cardStackId}}
          @permissions={{@permissions}}
          @planbookId={{@planbookId}}
          @courseId={{@courseId}}
          @courseStandardSetIds={{@courseStandardSetIds}}
          @planbookRecentStandardSetIds={{@planbookRecentStandardSetIds}}
          @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
          @cardIndex={{@cardIndex}}
        />
  
        <Planner::CardStacks::Cards::Parts::Attachments
          @card={{@card}}
          @cardStackId={{@cardStackId}}
          @permissions={{@permissions}}
          @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
        />
        <!-- Comments -->
        <Planner::CardStacks::Cards::Parts::Comments
          @comments={{@card.attributes.comments}}
          @cardStackId={{@cardStackId}}
          @cardId={{@card.id}}
          @permissions={{@permissions}}
          @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
          data-test-card-comments={{@cardIndex}}
        />
  
        <Planner::CardStacks::Cards::Parts::IsPublicNotice @card={{@card}} />
      </Planner::CardStacks::Cards::Parts::Body>
  
    </Planner::CardStacks::Cards::Parts::TextBlock>
  </Planner::CardStacks::Cards::Parts::TopPart>
  */
  {
    "id": "q32Qjb4A",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@modelId\",\"@value\",\"@permissions\",\"@cardStackId\",\"@modelsNeededForFirstEdit\"],[[30,1,[\"id\"]],[30,1,[\"attributes\",\"title\"]],[30,2],[30,3],[30,4]]],null],[1,\"\\n    \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,4],null,[[\"@section\",\"@cardStackId\",\"@permissions\",\"@planbookId\",\"@courseId\",\"@courseStandardSetIds\",\"@planbookRecentStandardSetIds\",\"@modelsNeededForFirstEdit\",\"@cardIndex\"],[[30,1],[30,3],[30,2],[30,5],[30,6],[30,7],[30,8],[30,4],[30,9]]],null],[1,\"\\n\\n      \"],[8,[39,5],null,[[\"@card\",\"@cardStackId\",\"@permissions\",\"@modelsNeededForFirstEdit\"],[[30,1],[30,3],[30,2],[30,4]]],null],[1,\"\\n      \"],[3,\" Comments \"],[1,\"\\n      \"],[8,[39,6],[[16,\"data-test-card-comments\",[30,9]]],[[\"@comments\",\"@cardStackId\",\"@cardId\",\"@permissions\",\"@modelsNeededForFirstEdit\"],[[30,1,[\"attributes\",\"comments\"]],[30,3],[30,1,[\"id\"]],[30,2],[30,4]]],null],[1,\"\\n\\n      \"],[8,[39,7],null,[[\"@card\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@card\",\"@permissions\",\"@cardStackId\",\"@modelsNeededForFirstEdit\",\"@planbookId\",\"@courseId\",\"@courseStandardSetIds\",\"@planbookRecentStandardSetIds\",\"@cardIndex\"],false,[\"planner/card-stacks/cards/parts/top-part\",\"planner/card-stacks/cards/parts/text-block\",\"planner/card-stacks/cards/parts/title\",\"planner/card-stacks/cards/parts/body\",\"planner/card-stacks/cards/parts/standards\",\"planner/card-stacks/cards/parts/attachments\",\"planner/card-stacks/cards/parts/comments\",\"planner/card-stacks/cards/parts/is-public-notice\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/card-standards.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});