define("cc-frontend/components/icons/folder-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M3.384 2.534c-.46.099-.911.496-1.075.946-.074.203-.105.48-.072.635.036.169.18.318.352.363a.458.458 0 0 0 .47-.118c.107-.102.127-.153.179-.435a.48.48 0 0 1 .267-.367l.105-.054h8.6c7.72 0 8.609.004 8.689.038a.58.58 0 0 1 .265.238c.036.065.041.244.052 1.728l.012 1.656.066.1a.507.507 0 0 0 .848.004l.07-.104.007-1.648c.007-1.813.005-1.844-.137-2.148a1.517 1.517 0 0 0-1.048-.836c-.244-.051-17.41-.05-17.65.002ZM1.404 5.53a1.506 1.506 0 0 0-1.18 1.55c.017.247 2.607 13.215 2.682 13.427.171.48.601.856 1.1.962.243.051 16.352.05 16.595-.002.48-.101.93-.497 1.092-.96.027-.079.51-2.45 1.073-5.268.988-4.948 1.023-5.13 1.01-5.328-.04-.621-.42-1.118-1.028-1.34l-.176-.064-6.16-.012-6.16-.012-.104-.073c-.17-.12-.193-.187-.366-1.067-.144-.734-.166-.821-.257-1.008a1.582 1.582 0 0 0-.885-.772l-.156-.056-3.468-.004c-2.804-.004-3.496.002-3.613.027Zm.12 1.019a.52.52 0 0 0-.3.44c0 .11 2.604 13.12 2.647 13.22a.53.53 0 0 0 .298.26c.128.036 16.135.036 16.262 0a.516.516 0 0 0 .29-.246c.04-.085 2.055-10.13 2.055-10.245 0-.15-.122-.338-.276-.423-.066-.037-.55-.04-6.228-.052l-6.156-.012-.156-.056a1.583 1.583 0 0 1-.884-.772c-.092-.186-.114-.273-.258-1.008-.173-.88-.196-.947-.366-1.067l-.104-.073-3.364-.005c-3.235-.006-3.368-.004-3.46.039Z"
    ></path>
  </svg>
  */
  {
    "id": "/k9T42Yl",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M3.384 2.534c-.46.099-.911.496-1.075.946-.074.203-.105.48-.072.635.036.169.18.318.352.363a.458.458 0 0 0 .47-.118c.107-.102.127-.153.179-.435a.48.48 0 0 1 .267-.367l.105-.054h8.6c7.72 0 8.609.004 8.689.038a.58.58 0 0 1 .265.238c.036.065.041.244.052 1.728l.012 1.656.066.1a.507.507 0 0 0 .848.004l.07-.104.007-1.648c.007-1.813.005-1.844-.137-2.148a1.517 1.517 0 0 0-1.048-.836c-.244-.051-17.41-.05-17.65.002ZM1.404 5.53a1.506 1.506 0 0 0-1.18 1.55c.017.247 2.607 13.215 2.682 13.427.171.48.601.856 1.1.962.243.051 16.352.05 16.595-.002.48-.101.93-.497 1.092-.96.027-.079.51-2.45 1.073-5.268.988-4.948 1.023-5.13 1.01-5.328-.04-.621-.42-1.118-1.028-1.34l-.176-.064-6.16-.012-6.16-.012-.104-.073c-.17-.12-.193-.187-.366-1.067-.144-.734-.166-.821-.257-1.008a1.582 1.582 0 0 0-.885-.772l-.156-.056-3.468-.004c-2.804-.004-3.496.002-3.613.027Zm.12 1.019a.52.52 0 0 0-.3.44c0 .11 2.604 13.12 2.647 13.22a.53.53 0 0 0 .298.26c.128.036 16.135.036 16.262 0a.516.516 0 0 0 .29-.246c.04-.085 2.055-10.13 2.055-10.245 0-.15-.122-.338-.276-.423-.066-.037-.55-.04-6.228-.052l-6.156-.012-.156-.056a1.583 1.583 0 0 1-.884-.772c-.092-.186-.114-.273-.258-1.008-.173-.88-.196-.947-.366-1.067l-.104-.073-3.364-.005c-3.235-.006-3.368-.004-3.46.039Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/folder-open.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});