define("cc-frontend/components/planner/menus/day-menu/change-class-order/body.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "body": "_body_1oy7w8",
    "card-list": "_card-list_1oy7w8",
    "card-list__item-wrapper": "_card-list__item-wrapper_1oy7w8",
    "card-list__item": "_card-list__item_1oy7w8",
    "card-list__item__move-button": "_card-list__item__move-button_1oy7w8",
    "card-list__item__text": "_card-list__item__text_1oy7w8"
  };
  _exports.default = _default;
});