define("cc-frontend/components/planner/course/timeline/unit.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "unit-title": "_unit-title_iobtwd",
    "unit-date-info": "_unit-date-info_iobtwd",
    "unit-block-title": "_unit-block-title_iobtwd"
  };
  _exports.default = _default;
});