define("cc-frontend/services/rotation-editor", ["exports", "cc-frontend/lib/actions/rotation-calendar/CHANGE_CALENDAR", "cc-frontend/lib/object-id-gen", "cc-frontend/utils/course/update-course-to-rotation-calendar", "cc-frontend/utils/filter-dates/course", "cc-frontend/utils/filter-dates/shared", "cc-frontend/utils/planbook/update-planbook-to-rotation-calendar", "cc-frontend/utils/rotation-calendar/dates", "lodash-es"], function (_exports, _CHANGE_CALENDAR, _objectIdGen, _updateCourseToRotationCalendar, _course, _shared, _updatePlanbookToRotationCalendar, _dates, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /*
  
  Prepared action
  
  - cause
    - Change Id - CHANGE_ID
    - Reset - RESET
    - Add Special Event Is Off  - ADD_IS_OFF
    - Add Special Event Is On - ADD_NOTE
  
  - accepts:
    - new rotation calendar
    - new planbook calendar
    - new course calendar
  
  - returns:
    - patch with changes to the two + n models
  */
  let RotationEditor = (_dec = Ember.inject.service, (_class = class RotationEditor extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    // will also change the dates
    addRotation() {}

    deleteRotation() {}

    changeSchoolYearDates({
      dateString,
      startOrEnd,
      rotationCalendar,
      planbook,
      courses
    }) {
      const updatedRotationCalendar = (0, _lodashEs.cloneDeep)(rotationCalendar);

      if (startOrEnd === "START") {
        updatedRotationCalendar.attributes.semesters[0].startDate = dateString;
        let effectiveDate = (0, _lodashEs.min)((0, _lodashEs.map)((0, _lodashEs.flatMap)(courses, course => (0, _course.filterCourseDateCustom)(course)), date => date.attributes.dateString));
        rotationCalendar.attributes.datesLastChangedOn = effectiveDate || dateString;
      } else if (startOrEnd === "END") {
        updatedRotationCalendar.attributes.semesters[0].endDate = dateString;
        let minimumDateToChangeFrom = (0, _lodashEs.min)([rotationCalendar.attributes.semesters[0].endDate, dateString]);
        if ((0, _lodashEs.isNil)(minimumDateToChangeFrom)) throw Error("No minimum date to change");
        rotationCalendar.attributes.datesLastChangedOn = minimumDateToChangeFrom;
      }

      let updatedDates = (0, _dates.createRotationCalendarDates)(updatedRotationCalendar);
      updatedRotationCalendar.attributes.dates = updatedDates;
      let formattedDate = dateFns.format(dateFns.parse(dateString), "ddd. MMM Do, YYYY");
      this.store.dispatchPreparedAction((0, _CHANGE_CALENDAR.changeCalendar)({
        causeOfChange: "CHANGE_START_END_DATE",
        changeDescription: `Changed ${startOrEnd.toLowerCase()} of school year to ${formattedDate}`,
        rotationCalendar: {
          id: rotationCalendar.id,
          originalAttributes: rotationCalendar.attributes,
          updatedAttributes: updatedRotationCalendar.attributes
        },
        courses: null,
        planbookId: planbook.id,
        planbook: null
      }));
    }

    changeRotationIdOnDay({
      dateString,
      newRotationId,
      rotationCalendar,
      planbook,
      courses
    }) {
      const updatedRotationCalendar = (0, _lodashEs.cloneDeep)(rotationCalendar);
      let newDateAnnotation = {
        id: _objectIdGen.default.create(),
        type: "date-annotation-rotation-calendar-single",
        attributes: {
          changedRotationId: newRotationId,
          dateString: dateString,
          description: null,
          isOff: false,
          isPublic: false,
          rotationShould: "CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED",
          title: null
        }
      };
      (0, _lodashEs.remove)(updatedRotationCalendar.attributes.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-rotation-calendar-single" && annotation.attributes.dateString === dateString && (annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_RESET" || annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED");
      });
      let formattedDate = dateFns.format(dateFns.parse(dateString), "ddd. MMM Do, YYYY");
      rotationCalendar.attributes.datesLastChangedOn = dateString;
      updatedRotationCalendar.attributes.dateAnnotations.push(newDateAnnotation);
      let updatedDates = (0, _dates.createRotationCalendarDates)(updatedRotationCalendar);
      updatedRotationCalendar.attributes.dates = updatedDates;
      return this.generateAction({
        rotationCalendar: rotationCalendar,
        updatedRotationCalendar: updatedRotationCalendar,
        planbook: planbook,
        courses: courses,
        changeDescription: `Changed rotation on ${formattedDate}`,
        causeOfChange: "CHANGE_ROTATION_ID"
      });
    }

    resetRotation({
      dateString,
      rotationCalendar,
      planbook,
      courses
    }) {
      const updatedRotationCalendar = (0, _lodashEs.cloneDeep)(rotationCalendar);
      let newDateAnnotation = {
        id: _objectIdGen.default.create(),
        type: "date-annotation-rotation-calendar-single",
        attributes: {
          changedRotationId: null,
          dateString: dateString,
          description: null,
          isOff: false,
          isPublic: false,
          rotationShould: "CHANGE_AND_CYCLE_SHOULD_RESET",
          title: null
        }
      };
      (0, _lodashEs.remove)(updatedRotationCalendar.attributes.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-rotation-calendar-single" && annotation.attributes.dateString === dateString && (annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_RESET" || annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED");
      });
      rotationCalendar.attributes.datesLastChangedOn = dateString;
      updatedRotationCalendar.attributes.dateAnnotations.push(newDateAnnotation);
      let updatedDates = (0, _dates.createRotationCalendarDates)(updatedRotationCalendar);
      updatedRotationCalendar.attributes.dates = updatedDates;
      let formattedDate = dateFns.format(dateFns.parse(dateString), "ddd. MMM Do, YYYY");
      return this.generateAction({
        rotationCalendar: rotationCalendar,
        updatedRotationCalendar: updatedRotationCalendar,
        planbook: planbook,
        courses: courses,
        changeDescription: `Reset rotation on ${formattedDate}`,
        causeOfChange: "RESET_ROTATION"
      });
    }

    skipRotation({
      dateString,
      rotationCalendar,
      planbook,
      courses
    }) {
      const updatedRotationCalendar = (0, _lodashEs.cloneDeep)(rotationCalendar);
      let rcDate = (0, _shared.findDate)(rotationCalendar.attributes.dates, dateString);
      if (rcDate === undefined) throw Error("Cannot skip a date which doesn't have a rotation date");
      let newDateAnnotation = {
        id: _objectIdGen.default.create(),
        type: "date-annotation-rotation-calendar-single",
        attributes: {
          // keep the existing rotation id
          changedRotationId: rcDate === null || rcDate === void 0 ? void 0 : rcDate.attributes.rotationId,
          dateString: dateString,
          description: null,
          isOff: false,
          isPublic: false,
          rotationShould: "CHANGE_AND_CYCLE_SHOULD_BUMP_OVER_DISRUPTION",
          title: null
        }
      };
      (0, _lodashEs.remove)(updatedRotationCalendar.attributes.dateAnnotations, annotation => {
        return annotation.type === "date-annotation-rotation-calendar-single" && annotation.attributes.dateString === dateString && (annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_RESET" || annotation.attributes.rotationShould === "CHANGE_AND_CYCLE_SHOULD_CONTINUE_UNINTERRUPTED");
      });
      rotationCalendar.attributes.datesLastChangedOn = dateString;
      updatedRotationCalendar.attributes.dateAnnotations.push(newDateAnnotation);
      let updatedDates = (0, _dates.createRotationCalendarDates)(updatedRotationCalendar);
      updatedRotationCalendar.attributes.dates = updatedDates;
      let formattedDate = dateFns.format(dateFns.parse(dateString), "ddd. MMM Do, YYYY");
      return this.generateAction({
        rotationCalendar: rotationCalendar,
        updatedRotationCalendar: updatedRotationCalendar,
        planbook: planbook,
        courses: courses,
        changeDescription: `Skipped Rotation on ${formattedDate}`,
        causeOfChange: "SKIP_ROTATION_DAY"
      });
    }

    addSpecialEventIsOff({
      title,
      description,
      startDate,
      endDate,
      isPublic,
      rotationShould
    }) {}

    addSpecialEventIsOn({
      title,
      description,
      startDate,
      endDate,
      isPublic
    }) {}

    removeDateAnnotation({
      rotationCalendar,
      planbook,
      dateAnnotation,
      courses
    }) {
      const updatedRotationCalendar = (0, _lodashEs.cloneDeep)(rotationCalendar);
      (0, _lodashEs.remove)(updatedRotationCalendar.attributes.dateAnnotations, annotation => annotation.id === dateAnnotation.id);
      rotationCalendar.attributes.datesLastChangedOn = dateFns.format(new Date(), "YYYY-MM-DD");
      let updatedDates = (0, _dates.createRotationCalendarDates)(updatedRotationCalendar);
      updatedRotationCalendar.attributes.dates = updatedDates;
      let dateDesription;

      if (dateAnnotation.type === "date-annotation-rotation-calendar-single") {
        let formattedDate = dateFns.format(dateFns.parse(dateAnnotation.attributes.dateString), "ddd. MMM Do, YYYY");
        dateDesription = `Removed event on ${formattedDate}`;
      }

      if (dateAnnotation.type === "date-annotation-rotation-calendar-range") {
        let startDate = dateFns.format(dateFns.parse(dateAnnotation.attributes.startDate), "ddd. MMM Do, YYYY");
        let endDate = dateFns.format(dateFns.parse(dateAnnotation.attributes.endDate), "ddd. MMM Do, YYYY");
        dateDesription = `Removed event from ${startDate} to ${endDate}`;
      }

      return this.generateAction({
        rotationCalendar: rotationCalendar,
        updatedRotationCalendar: updatedRotationCalendar,
        planbook: planbook,
        courses: courses,
        changeDescription: dateDesription,
        causeOfChange: "REMOVE_DATE_ANNOTATION"
      });
    }

    generateAction({
      rotationCalendar,
      updatedRotationCalendar,
      planbook,
      courses,
      causeOfChange,
      changeDescription
    }) {
      let {
        dates,
        newCardStacks
      } = (0, _updatePlanbookToRotationCalendar.updatePlanbookToRotationCalendar)(rotationCalendar, updatedRotationCalendar, planbook);
      let updatedPlanbookCalendar = (0, _lodashEs.cloneDeep)(planbook.attributes.calendar);
      updatedPlanbookCalendar.dates = dates;
      let updatedCourseCalendars = (0, _lodashEs.map)(courses, course => {
        let {
          dates
        } = (0, _updateCourseToRotationCalendar.updateCourseToRotationCalendar)(rotationCalendar, updatedRotationCalendar, course);
        let newCalendar = (0, _lodashEs.cloneDeep)(course.attributes.calendar);
        newCalendar.dates = dates;
        return {
          id: course.id,
          originalCalendar: course.attributes.calendar,
          updatedCalendar: newCalendar
        };
      }); // course changes

      this.store.dispatchPreparedAction((0, _CHANGE_CALENDAR.changeCalendar)({
        changeDescription: changeDescription,
        causeOfChange: causeOfChange,
        rotationCalendar: {
          id: rotationCalendar.id,
          originalAttributes: rotationCalendar.attributes,
          updatedAttributes: updatedRotationCalendar.attributes
        },
        courses: updatedCourseCalendars,
        planbookId: planbook.id,
        planbook: {
          id: planbook.id,
          originalCalendar: planbook.attributes.calendar,
          updatedCalendar: updatedPlanbookCalendar
        }
      }));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RotationEditor;
});