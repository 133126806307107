define("cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY", ["exports", "lodash-es", "moment", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater"], function (_exports, _lodashEs, _moment, _datesOffCalculator, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePlanbookAddNoSchoolDay = preparePlanbookAddNoSchoolDay;
  _exports.default = _exports.PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY = void 0;

  function preparePlanbookAddNoSchoolDay(payload) {
    return {
      name: PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY.name,
      payload: payload,
      patches: (0, _lodashEs.compact)(PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY.patches(payload).concat(payload.bumpOrDeletePatches)),
      undoPatches: (0, _lodashEs.compact)(PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY.undoPatches(payload).concat(payload.bumpOrDeleteUndoPatches)),
      narrative: (0, _lodashEs.partial)(PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY.narrative, payload)
    };
  }

  const PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY = {
    name: "PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY",
    params: {
      annotation: "object",
      planbook: "object",
      calledFrom: "string"
    },

    patches(payload) {
      let newAnnotations = payload.planbook.attributes.calendar.dateAnnotations.concat([payload.dateAnnotation]);
      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id).push("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatches(payload) {
      let oldAnnotations = JSON.parse(JSON.stringify(payload.planbook.attributes.calendar.dateAnnotations));
      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(oldAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id).pull("attributes.calendar.dateAnnotations", {
        id: payload.dateAnnotation.id
      }).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let titleStarter = "Added ";
      let titlePhrase = payload.dateAnnotation.type === `date-annotation-planbook-custom-single` ? "A No School Day" : "No School Days";
      let descriptionPhrase;

      if (payload.dateAnnotation.type === "date-annotation-planbook-custom-single") {
        descriptionPhrase = "Turned off " + (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      }

      if (payload.dateAnnotation.type === "date-annotation-planbook-custom-range") {
        let formattedStartDate = (0, _moment.default)(payload.dateAnnotation.attributes.startDate).format("ddd, MMM. D, YYYY");
        let formattedEndDate = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
        descriptionPhrase = `Turned off days from ${formattedStartDate} to ${formattedEndDate}`;
      }

      let analyticsModifier = payload.dateAnnotation.type === `date-annotation-planbook-custom-single` ? "Single" : "Range";
      return {
        title: `${titleStarter} ${titlePhrase}`,
        titleForAnalytics: `Planbook Date - Create Annotation - ${analyticsModifier}`,
        description: ` ${descriptionPhrase}`,
        activity: {},
        context: {
          noSchool: true,
          planbookId: payload.planbook.id,
          dateAnnotationId: payload.dateAnnotation.id
        }
      };
    }

  };
  _exports.PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY = PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY;
  var _default = PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY;
  _exports.default = _default;
});