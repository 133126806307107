define("cc-frontend/models/course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createUnit = createUnit;
  _exports.createCourseDateCustomAttributes = createCourseDateCustomAttributes;
  _exports.createCourseDateDefaultAttributes = createCourseDateDefaultAttributes;

  // This is used to create monomorphic objects that have the same key order
  // https://www.builder.io/blog/monomorphic-javascript
  function createUnit(attrs) {
    const defaults = {
      id: "",
      startDate: "",
      startIndex: 0,
      endDate: "",
      endIndex: 0,
      unitLength: 0,
      ancestorIds: [],
      descendantIds: [],
      dateStrings: []
    };
    return Object.assign({}, defaults, attrs);
  }

  function createCourseDateCustomAttributes(attrs) {
    const defaults = {
      dateString: "",
      cardStackId: null,
      time: null,
      isForcedOn: false,
      unitStart: [],
      unitEnd: [],
      unitIds: []
    };
    return Object.assign({}, defaults, attrs);
  }

  function createCourseDateDefaultAttributes(attrs) {
    const defaults = {
      semesterId: "",
      rotationId: "",
      cardStackId: null,
      time: null
    };
    return Object.assign({}, defaults, attrs);
  }
});