define("cc-frontend/components/dialogs/confirm-ignore-membership-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-warning pulse-warning" style="display: block;">
    !
  </div>
  <h1 class="create-annotation__title">
    Would you like to ignore this membership request?
  </h1>
  <h2 class="create-annotation__description">
    If you change your mind, you can invite them manually.
  </h2>
  <div class="create-annotation__submit-buttons">
    <div class="create-annotation__submit-button test-remove-group-member-btn" {{action @accept}}>
      Ignore Request
    </div>
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{action @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "Cu9NVghB",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-warning pulse-warning\"],[14,5,\"display: block;\"],[12],[1,\"\\n  !\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Would you like to ignore this membership request?\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  If you change your mind, you can invite them manually.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"create-annotation__submit-button test-remove-group-member-btn\"],[4,[38,0],[[30,0],[30,1]],null],[12],[1,\"\\n    Ignore Request\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,0],[[30,0],[30,2]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@accept\",\"@decline\"],false,[\"action\"]]",
    "moduleName": "cc-frontend/components/dialogs/confirm-ignore-membership-request.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});