define("cc-frontend/components/planner/buttons/print-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "print-button": "_print-button_1j9qwd",
    "print-button-icon": "_print-button-icon_1j9qwd",
    "print-button-text": "_print-button-text_1j9qwd"
  };
  _exports.default = _default;
});