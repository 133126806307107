define("cc-frontend/components/auth/sign-in-with-google", ["exports", "js-cookie", "@glimmer/component", "lodash"], function (_exports, _jsCookie, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="wrapper" ...attributes>
    <button local-class="sign-in-btn" type="button" {{on "click" this.openSignInWindow}}>
      <div local-class="icon">
        <Icons::GoogleLogoColor />
      </div>
      <div local-class="text">
        {{yield}}
      </div>
    </button>
  </div>
  */
  {
    "id": "lOxs3ul0",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[\"wrapper\"],[[\"from\"],[\"cc-frontend/components/auth/sign-in-with-google.css\"]]]]]],[17,1],[12],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[[28,[37,0],[\"sign-in-btn\"],[[\"from\"],[\"cc-frontend/components/auth/sign-in-with-google.css\"]]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"openSignInWindow\"]]],null],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"icon\"],[[\"from\"],[\"cc-frontend/components/auth/sign-in-with-google.css\"]]]]]],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"text\"],[[\"from\"],[\"cc-frontend/components/auth/sign-in-with-google.css\"]]]]]],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"local-class\",\"on\",\"icons/google-logo-color\",\"yield\"]]",
    "moduleName": "cc-frontend/components/auth/sign-in-with-google.hbs",
    "isStrictMode": false
  });

  let SignInWithGoogleComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class SignInWithGoogleComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "windowObjectReference", null);

      _defineProperty(this, "previousUrl", null);

      _initializerDefineProperty(this, "session", _descriptor, this);

      window.addEventListener("message", this.receiveMessage, false);
    }

    willDestroy() {
      window.removeEventListener("message", this.receiveMessage);
    } // Source:
    // https://dev.to/dinkydani21/how-we-use-a-popup-for-google-and-outlook-oauth-oci


    receiveMessage(event) {
      // Do we trust the sender of this message? (might be
      // different from what we originally opened, for example).
      // if (event.origin !== BASE_URL) {
      //   return
      // }
      const {
        data
      } = event; // if we trust the sender and the source is our popup

      if (data.source === "CC_OAUTH_FLOW") {
        var _this$args$callback, _this$args;

        (_this$args$callback = (_this$args = this.args).callback) === null || _this$args$callback === void 0 ? void 0 : _this$args$callback.call(_this$args, data);
      }
    }

    openSignInWindow() {
      let state = (0, _lodash.merge)({
        userId: this.session.userId,
        sessionId: this.session.id,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }, this.args.stateMap || {});
      let scopes;

      if (this.args.purpose === "GOOGLE_AUTH") {
        state = (0, _lodash.merge)(state, {
          purpose: "SIGN_IN_UP"
        });
        scopes = ["openid", "profile", "email"];
      }

      if (this.args.purpose === "GOOGLE_CLASSROOM") {
        state = (0, _lodash.merge)(state, {
          purpose: "GOOGLE_CLASSROOM_AUTH"
        });
        scopes = ["openid", "profile", "email", "https://www.googleapis.com/auth/drive.file", "https://www.googleapis.com/auth/classroom.announcements", "https://www.googleapis.com/auth/classroom.courses.readonly", "https://www.googleapis.com/auth/classroom.coursework.me", "https://www.googleapis.com/auth/classroom.coursework.students", "https://www.googleapis.com/auth/classroom.courseworkmaterials", "https://www.googleapis.com/auth/classroom.push-notifications", "https://www.googleapis.com/auth/classroom.rosters.readonly", "https://www.googleapis.com/auth/classroom.topics"];
      } // We can't set the state using the state so we have to use a cookie. See
      // https://github.com/achedeuzot/ueberauth_auth0/issues/168#issuecomment-877396335


      _jsCookie.default.set("cc_auth_state", btoa(JSON.stringify(state)));

      let url = `/auth/google?scope=${encodeURI(scopes.join(" "))}`;
      let name = "Sign In With Google"; // window features

      const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

      if (this.windowObjectReference === null || this.windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
        or if such pointer exists but the window was closed */
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (this.previousUrl !== url) {
        var _this$windowObjectRef;

        /* if the resource to load is different,
        then we load it in the already opened secondary window and then
        we bring such window back on top/in front of its parent window. */
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
        (_this$windowObjectRef = this.windowObjectReference) === null || _this$windowObjectRef === void 0 ? void 0 : _this$windowObjectRef.focus();
      } else {
        /* else the window reference must exist and the window
        is not closed; therefore, we can bring it back on top of any other
        window with the focus() method. There would be no need to re-create
        the window or to reload the referenced resource. */
        this.windowObjectReference.focus();
      } // add the listener for receiving a message from the popup
      // assign the previous URL


      this.previousUrl = url;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "receiveMessage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "receiveMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSignInWindow", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openSignInWindow"), _class.prototype)), _class));
  _exports.default = SignInWithGoogleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SignInWithGoogleComponent);
});