define("cc-frontend/lib/actions/course/calendar/MOVE_MULTIPLE_LESSONS", ["exports", "@mobily/ts-belt", "cc-frontend/lib/find-course-date", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "moment", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT"], function (_exports, _tsBelt, _findCourseDate, _base, _planbook, _lodashEs, _moment, _defaultLessonModels, _MOVE_LESSON_BY_AMOUNT) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareMoveMultipleLessons = prepareMoveMultipleLessons;
  _exports.patches = patches;
  _exports.undoPatches = undoPatches;
  _exports.narrative = narrative;
  _exports.name = void 0;

  function prepareMoveMultipleLessons(payload) {
    return {
      name: name,
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  const name = "MOVE_MULTIPLE_LESSONS";
  _exports.name = name;

  function patches(payload) {
    return (0, _lodashEs.flatMap)(payload.courses, course => {
      return _MOVE_LESSON_BY_AMOUNT.MOVE_LESSON_BY_AMOUNT.patches({
        amount: payload.amount,
        dateString: payload.dateString,
        course: course,
        planbook: payload.planbook,
        rotationCalendar: payload.rotationCalendar
      });
    });
  }

  function undoPatches(payload) {
    return (0, _lodashEs.flatMap)(payload.courses, course => {
      return _MOVE_LESSON_BY_AMOUNT.MOVE_LESSON_BY_AMOUNT.undoPatches({
        amount: payload.amount,
        dateString: payload.dateString,
        course: course,
        planbook: payload.planbook,
        rotationCalendar: payload.rotationCalendar
      });
    });
  }

  function narrative(payload, findFn, userId) {
    let planbookRelationship = payload.courses[0].relationships.planbook;
    if (planbookRelationship === undefined) throw Error("The Course's doesn't have a planbook specified. If we start having courses without planbooks, we need to change this.");
    let planbookId = payload.planbook.id;
    let activityToValues = (0, _lodashEs.map)(payload.courses, course => `course-edits:course-${course.id}`);
    activityToValues.push(`planbook-edits:planbook-${planbookId}`);
    let amount = Math.abs(payload.amount);
    let courseCount = payload.courses.length;
    let direction = payload.amount > 0 ? "forward" : "backwards";
    let dayOrDays = payload.amount === 1 ? "day" : "days";
    let formattedDate = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
    let analyticsEvents = (0, _tsBelt.pipe)(payload.courses, _tsBelt.A.filter(course => {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);
      return (courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId) !== undefined && courseDate.attributes.cardStackId !== null;
    }), _tsBelt.A.map(course => {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);
      let cardStackId = courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId;
      if (cardStackId === undefined || cardStackId === null) return null;
      let models = (0, _defaultLessonModels.default)({
        cardStackId: cardStackId,
        planbookId: payload.planbook.id
      }, findFn, userId);
      return { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: null,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "move",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "bumping",
        adverbial_object_type: "day",
        adverbial_object_specifier: "forward",
        adverbial_object_id: payload.dateString,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
    }), arr => (0, _lodashEs.compact)(arr));
    return {
      title: "Bumped Lesson Forward for the day",
      titleForAnalytics: "Day - Bumped",
      description: `Moved ${courseCount} lessons ${direction} on ${formattedDate} ${amount} ${dayOrDays}`,
      context: {
        courseIds: (0, _lodashEs.map)(payload.courses, "id"),
        planbookTitle: payload.planbook.attributes.title,
        planbookId: payload.planbook.id
      },
      activity: {
        actor: `user-${userId}`,
        verb: "edited",
        object: `planbook-${planbookId}`,
        to: activityToValues
      },
      newAnalyticsEvents: analyticsEvents
    };
  }
});