define("cc-frontend/lib/actions/planbook/PLANBOOK_CHANGE_COVER", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models", "cc-frontend/models/analytics/planbook", "cc-frontend/models/analytics/base"], function (_exports, _patchUpdater, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels, _planbook, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_CHANGE_COVER = void 0;
  const PLANBOOK_CHANGE_COVER = {
    name: "PLANBOOK_CHANGE_COVER",
    params: {
      planbookId: "string",
      cover: "string",
      originalCover: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.cover", payload.cover).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.cover", payload.originalCover).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id: payload.planbookId,
        primary_object_id_and_ancestor_ids: [payload.planbookId],
        primary_object_type: "planbook",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_verb: "edit",
        primary_object_specifier: "cover"
      };
      return {
        title: "Changed Planbook Background",
        titleForAnalytics: "Planbook Cover - Change",
        description: `Changed your background for your ${models.planbook.attributes.title} planbook`,
        context: (0, _defaultPlanbookContext.default)(payload, models),
        activity: (0, _defaultPlanbookActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.PLANBOOK_CHANGE_COVER = PLANBOOK_CHANGE_COVER;
  var _default = PLANBOOK_CHANGE_COVER;
  _exports.default = _default;
});