define("cc-frontend/components/planner/menus/lesson-menu/change-time", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToElsewhere
    @named="lesson-menu-title"
    @send={{component
      "planner/menus/titles/with-back-arrow"
      goBack=@goBack
      title=(concat "Change time on " (moment-format @dateString "MMMM D"))
    }}
  />
  
  <ToElsewhere
    @named="lesson-menu-body"
    @send={{component
      "planner/menus/lesson-menu/change-time-body"
      modelsNeededForFirstEdit=@modelsNeededForFirstEdit
      courseDate=@courseDate
      course=@course
      planbook=@planbook
      closeMenu=@closeMenu
    }}
  />
  */
  {
    "id": "8+9zBOiX",
    "block": "[[[8,[39,0],null,[[\"@named\",\"@send\"],[\"lesson-menu-title\",[50,\"planner/menus/titles/with-back-arrow\",0,null,[[\"goBack\",\"title\"],[[30,1],[28,[37,2],[\"Change time on \",[28,[37,3],[[30,2],\"MMMM D\"],null]],null]]]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@named\",\"@send\"],[\"lesson-menu-body\",[50,\"planner/menus/lesson-menu/change-time-body\",0,null,[[\"modelsNeededForFirstEdit\",\"courseDate\",\"course\",\"planbook\",\"closeMenu\"],[[30,3],[30,4],[30,5],[30,6],[30,7]]]]]],null]],[\"@goBack\",\"@dateString\",\"@modelsNeededForFirstEdit\",\"@courseDate\",\"@course\",\"@planbook\",\"@closeMenu\"],false,[\"to-elsewhere\",\"component\",\"concat\",\"moment-format\"]]",
    "moduleName": "cc-frontend/components/planner/menus/lesson-menu/change-time.hbs",
    "isStrictMode": false
  });

  class ChangeTimeComponent extends _glimmerComponent.default {}

  _exports.default = ChangeTimeComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChangeTimeComponent);
});