define("cc-frontend/components/planner/buttons/print-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    {{on "click" @printOrDownload}}
    class="responsive-print-icon"
    type="button"
    local-class="print-button"
  >
    <div local-class="print-button-icon">
      <Icons::Printer />
    </div>
    <div local-class="print-button-text">
      Print
    </div>
  </button>
  */
  {
    "id": "2AKx3ysg",
    "block": "[[[11,\"button\"],[16,0,[29,[\"responsive-print-icon \",[28,[37,0],[\"print-button\"],[[\"from\"],[\"cc-frontend/components/planner/buttons/print-button.css\"]]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"print-button-icon\"],[[\"from\"],[\"cc-frontend/components/planner/buttons/print-button.css\"]]]]]],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"print-button-text\"],[[\"from\"],[\"cc-frontend/components/planner/buttons/print-button.css\"]]]]]],[12],[1,\"\\n    Print\\n  \"],[13],[1,\"\\n\"],[13]],[\"@printOrDownload\"],false,[\"local-class\",\"on\",\"icons/printer\"]]",
    "moduleName": "cc-frontend/components/planner/buttons/print-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});