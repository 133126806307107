define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY", ["exports", "@mobily/ts-belt", "cc-frontend/lib/find-course-date", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/lesson", "cc-frontend/models/analytics/planbook", "lodash-es", "moment", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context"], function (_exports, _tsBelt, _findCourseDate, _patchUpdater, _base, _lesson, _planbook, _lodashEs, _moment, _defaultLessonModels, _defaultPlanbookLessonsActivity, _defaultPlanbookLessonsContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePasteAllLessonsForDay = preparePasteAllLessonsForDay;
  _exports.default = _exports.PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY = void 0;

  function preparePasteAllLessonsForDay(payload) {
    return {
      name: PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.name,
      payload: payload,
      patches: PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.patches(payload),
      undoPatches: PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY.narrative, payload)
    };
  }

  const PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY = {
    name: "PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY",

    patches(payload) {
      let patches = (0, _lodashEs.compact)((0, _lodashEs.map)(payload.cardStacksForCourses, hash => {
        if (hash.oldCardStackId) {
          return _patchUpdater.default.create("card-stack", hash.oldCardStackId).set("attributes.cards", hash.newCardStackAttrs.cards).set("attributes.title", hash.newCardStackAttrs.title).patch;
        }
      }));
      let firstEditPatches = payload.firstEditPatches || [];
      return firstEditPatches.concat(patches);
    },

    undoPatches(payload) {
      let patches = (0, _lodashEs.compact)((0, _lodashEs.map)(payload.cardStacksForCourses, hash => {
        if (hash.oldCardStack) {
          return _patchUpdater.default.create("card-stack", hash.oldCardStack.id).set("attributes.cards", hash.oldCardStack.attributes.cards).set("attributes.title", hash.oldCardStack.attributes.title).patch;
        }
      }));
      let firstEditUndoPatches = payload.firstEditUndoPatches || [];
      return firstEditUndoPatches.concat(patches);
    },

    narrative(payload, findFn, userId) {
      let planbook = findFn("planbook", payload.planbook.id);
      let formattedDate = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      let analyticsEvents = (0, _tsBelt.pipe)(payload.cardStacksForCourses, _tsBelt.A.filter(cardStack => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(findFn("course", cardStack.courseId), payload.dateString);
        return (courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId) !== undefined && courseDate.attributes.cardStackId !== null;
      }), _tsBelt.A.map(cardStack => {
        let courseDate = (0, _findCourseDate.findCourseDateCustom)(findFn("course", cardStack.courseId), payload.dateString);
        let cardStackId = courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId; // We should never hit this because of the filter above ^

        if (cardStackId === undefined || cardStackId === null) return null;
        let models = (0, _defaultLessonModels.default)({
          cardStackId: cardStackId,
          planbookId: payload.planbookId
        }, findFn, userId);
        return { ...(0, _base.createBlankEvent)(),
          root_id: payload.planbookId,
          root_type: "planbook",
          date_plan_scheduled_for: payload.dateString,
          primary_object_id: cardStackId,
          primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
          primary_verb: "edit",
          primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
          primary_object_type: "lesson",
          primary_object_specifier: null,
          adverbial_verb: "pasting",
          adverbial_object_type: "lesson",
          adverbial_object_specifier: "single_lesson",
          adverbial_object_id: cardStackId,
          adverbial_object_possessive: (0, _lesson.getPossessiveForLesson)(findFn, userId, payload.ownerIds || []),
          adverbial_object_id_and_ancestor_ids: [payload.planbookId]
        };
      }), arr => (0, _lodashEs.compact)(arr));
      return {
        title: "Pasted an entire day of lessons",
        titleForAnalytics: "Lesson - Paste Entire Day",
        description: `Pasted a day's worth of lessons on ${formattedDate}`,
        context: (0, _defaultPlanbookLessonsContext.default)(planbook, payload),
        activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbook.id, userId),
        newAnalyticsEvents: analyticsEvents
      };
    }

  };
  _exports.PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY = PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY;
  var _default = PLANBOOK_PASTE_ALL_LESSONS_FOR_DAY;
  _exports.default = _default;
});