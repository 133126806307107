define("cc-frontend/lib/actions/course/date/COURSE_DATE_UNFORCE_ON", ["exports", "cc-frontend/lib/find-course-date", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "moment", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _findCourseDate, _patchUpdater, _base, _planbook, _lodashEs, _moment, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareUnforceOn = prepareUnforceOn;
  _exports.default = _exports.COURSE_DATE_UNFORCE_ON = void 0;

  function prepareUnforceOn(payload) {
    return {
      name: COURSE_DATE_UNFORCE_ON.name,
      payload: payload,
      patches: COURSE_DATE_UNFORCE_ON.patches(payload),
      undoPatches: COURSE_DATE_UNFORCE_ON.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(COURSE_DATE_UNFORCE_ON.narrative, payload)
    };
  }

  const COURSE_DATE_UNFORCE_ON = {
    name: "COURSE_DATE_UNFORCE_ON",

    patches(payload) {
      // I think we need to recalc the datesOff
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(payload.course, payload.dateString);

      if (courseDate) {
        let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: courseDate.id
        }).set("embedded", "attributes.isForcedOn", false).pull("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);

        return [patch.patch];
      } else {
        throw Error("CourseDate is undefined!!");
      }
    },

    undoPatches(payload) {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(payload.course, payload.dateString);

      if (courseDate) {
        let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: courseDate.id
        }).set("embedded", "attributes.isForcedOn", true).push("attributes.calendar.datesForcedOn", payload.dateString).inc("attributes.calendar.version", 1);

        return [patch.patch];
      } else {
        throw Error("CourseDate is undefined!!");
      }
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
      let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      let courseTitle = payload.course.attributes.title;
      let context = (0, _defaultCourseDateContext.default)(payload, models);
      let newContext = (0, _lodashEs.assign)({}, context, {
        unforcedOn: true
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: payload.dateString,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "course",
        primary_object_id: payload.course.id,
        primary_object_specifier: "calendar",
        primary_object_id_and_ancestor_ids: [payload.course.id, models.planbook.id],
        adverbial_verb: "toggling",
        adverbial_object_type: "lesson_slot",
        adverbial_object_id: payload.dateString,
        adverbial_object_specifier: "off",
        adverbial_object_id_and_ancestor_ids: [payload.course.id, models.planbook.id]
      };
      return {
        title: "Turned class back on",
        titleForAnalytics: "Lesson - Toggle Off",
        description: `Turned your ${courseTitle} class back on for ${formattedDateString}`,
        context: newContext,
        activity: (0, _defaultCourseDateActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.COURSE_DATE_UNFORCE_ON = COURSE_DATE_UNFORCE_ON;
  var _default = COURSE_DATE_UNFORCE_ON;
  _exports.default = _default;
});