define("cc-frontend/components/planner/csp/standard", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li
    {{on "click" (fn @toggleStandard @standard)}}
    class="csp-standard csp-standard--depth-{{@standard.depth}}
      {{if this.isSelected "is-selected"}}
      {{if this.standardIsHidden "standard-is-hidden"}}"
  >
  
    <div class="csp-standard__text">
      <div
        class="csp-standard__selected-icon
          {{if this.isSelected "--is-selected" "--is-not-selected"}}
          "
      >
        {{#if this.isSelected}}
          <Icons::IosCheckmark />
        {{else}}
          <Icons::IosPlus />
        {{/if}}
      </div>
  
      <div class="csp-standard__description-and-list-id">
        <div class="csp-standard__description">
          <span class="csp-standard__list-id">{{@standard.listId}}</span>
          {{{@standard.description}}}
          <span class="csp-standard__statement-notation">{{@standard.statementNotation}}</span>
        </div>
      </div>
  
    </div>
    {{#if @showStandardCoverage}}
      <div class="csp-standard__tracking">
        {{#with (get @standardsCoverage @standard.id) as |coverage|}}
          {{#if coverage.uses.length}}
            {{coverage.uses.length}}
          {{else}}
            -
          {{/if}}
        {{/with}}
      </div>
    {{else}}
      <div class="csp-standard__tracking">
        <Icons::QuestionSimple class="tw-w-8 tw-h-8" />
      </div>
  
    {{/if}}
  </li>
  */
  {
    "id": "iNMvov4t",
    "block": "[[[11,\"li\"],[16,0,[29,[\"csp-standard csp-standard--depth-\",[30,1,[\"depth\"]],\"\\n    \",[52,[30,0,[\"isSelected\"]],\"is-selected\"],\"\\n    \",[52,[30,0,[\"standardIsHidden\"]],\"standard-is-hidden\"]]]],[4,[38,1],[\"click\",[28,[37,2],[[30,2],[30,1]],null]],null],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"csp-standard__text\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"csp-standard__selected-icon\\n        \",[52,[30,0,[\"isSelected\"]],\"--is-selected\",\"--is-not-selected\"],\"\\n        \"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isSelected\"]],[[[1,\"        \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"csp-standard__description-and-list-id\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"csp-standard__description\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"csp-standard__list-id\"],[12],[1,[30,1,[\"listId\"]]],[13],[1,\"\\n        \"],[2,[30,1,[\"description\"]]],[1,\"\\n        \"],[10,1],[14,0,\"csp-standard__statement-notation\"],[12],[1,[30,1,[\"statementNotation\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"csp-standard__tracking\"],[12],[1,\"\\n\"],[44,[[28,[37,6],[[30,4],[30,1,[\"id\"]]],null]],[[[41,[30,5],[[[41,[30,5,[\"uses\",\"length\"]],[[[1,\"          \"],[1,[30,5,[\"uses\",\"length\"]]],[1,\"\\n\"]],[]],[[[1,\"          -\\n\"]],[]]]],[]],null]],[5]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"csp-standard__tracking\"],[12],[1,\"\\n      \"],[8,[39,7],[[24,0,\"tw-w-8 tw-h-8\"]],null,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"]],[]]],[13]],[\"@standard\",\"@toggleStandard\",\"@showStandardCoverage\",\"@standardsCoverage\",\"coverage\"],false,[\"if\",\"on\",\"fn\",\"icons/ios-checkmark\",\"icons/ios-plus\",\"let\",\"get\",\"icons/question-simple\"]]",
    "moduleName": "cc-frontend/components/planner/csp/standard.hbs",
    "isStrictMode": false
  });

  let CspStandardComponent = (_dec = Ember.computed("standard.description"), _dec2 = Ember.computed("args.selectedStandardIds.[]", "args.standard.id"), _dec3 = Ember.computed("args.results.[]"), (_class = class CspStandardComponent extends _component.default {
    get textToCopy() {
      var statementNotation = Ember.get(this, "args.standard.statementNotation") || "";
      return [statementNotation, Ember.get(this, "args.standard.description")].join(" ");
    }

    get isSelected() {
      return _lodash.default.includes(this.args.selectedStandardIds, Ember.get(this, "args.standard.id"));
    }

    get standardIsHidden() {
      if (this.args.results == null) return false;
      return !_lodash.default.includes(this.args.results, Ember.get(this, "args.standard.id"));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "textToCopy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "textToCopy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "standardIsHidden", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "standardIsHidden"), _class.prototype)), _class));
  _exports.default = CspStandardComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CspStandardComponent);
});