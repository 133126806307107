define("cc-frontend/components/icons/checkmark-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M11.052.517a11.437 11.437 0 0 0-7.197 3.338A11.435 11.435 0 0 0 .48 12c0 2.82.999 5.473 2.858 7.596A11.474 11.474 0 0 0 12 23.52c1.66 0 3.21-.333 4.747-1.018 2.532-1.13 4.66-3.271 5.785-5.825.66-1.494.987-3.049.987-4.677 0-2.819-.999-5.474-2.857-7.596A11.506 11.506 0 0 0 13.596.587c-.746-.104-1.8-.134-2.544-.07Zm.132.997c-2.107.179-4.05.935-5.664 2.203-2.237 1.759-3.627 4.25-3.977 7.131-.06.494-.06 1.81 0 2.304.06.494.127.865.237 1.32a10.53 10.53 0 0 0 8.756 7.944 11.55 11.55 0 0 0 2.927 0 10.549 10.549 0 0 0 7.646-5.16 10.728 10.728 0 0 0 1.307-3.793 11.55 11.55 0 0 0 0-2.926 10.542 10.542 0 0 0-4.5-7.23 10.619 10.619 0 0 0-4.452-1.723 12.705 12.705 0 0 0-2.28-.07Zm6.432 5.21a.603.603 0 0 0-.16.082c-.043.032-1.451 1.924-3.13 4.204a570.44 570.44 0 0 1-3.127 4.226.668.668 0 0 1-.14.114.663.663 0 0 1-.354.018c-.033-.009-.978-.751-2.1-1.65-1.123-.898-2.09-1.659-2.15-1.69a.484.484 0 0 0-.617.175c-.069.1-.078.134-.078.275 0 .135.01.177.066.26.046.071.67.584 2.082 1.713 1.109.886 2.075 1.651 2.148 1.7.24.163.432.225.734.236.434.016.788-.109 1.062-.374.137-.133 6.261-8.422 6.352-8.597a.598.598 0 0 0 .012-.396.526.526 0 0 0-.6-.297Z"
    ></path>
  </svg>
  */
  {
    "id": "fmUycrEL",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M11.052.517a11.437 11.437 0 0 0-7.197 3.338A11.435 11.435 0 0 0 .48 12c0 2.82.999 5.473 2.858 7.596A11.474 11.474 0 0 0 12 23.52c1.66 0 3.21-.333 4.747-1.018 2.532-1.13 4.66-3.271 5.785-5.825.66-1.494.987-3.049.987-4.677 0-2.819-.999-5.474-2.857-7.596A11.506 11.506 0 0 0 13.596.587c-.746-.104-1.8-.134-2.544-.07Zm.132.997c-2.107.179-4.05.935-5.664 2.203-2.237 1.759-3.627 4.25-3.977 7.131-.06.494-.06 1.81 0 2.304.06.494.127.865.237 1.32a10.53 10.53 0 0 0 8.756 7.944 11.55 11.55 0 0 0 2.927 0 10.549 10.549 0 0 0 7.646-5.16 10.728 10.728 0 0 0 1.307-3.793 11.55 11.55 0 0 0 0-2.926 10.542 10.542 0 0 0-4.5-7.23 10.619 10.619 0 0 0-4.452-1.723 12.705 12.705 0 0 0-2.28-.07Zm6.432 5.21a.603.603 0 0 0-.16.082c-.043.032-1.451 1.924-3.13 4.204a570.44 570.44 0 0 1-3.127 4.226.668.668 0 0 1-.14.114.663.663 0 0 1-.354.018c-.033-.009-.978-.751-2.1-1.65-1.123-.898-2.09-1.659-2.15-1.69a.484.484 0 0 0-.617.175c-.069.1-.078.134-.078.275 0 .135.01.177.066.26.046.071.67.584 2.082 1.713 1.109.886 2.075 1.651 2.148 1.7.24.163.432.225.734.236.434.016.788-.109 1.062-.374.137-.133 6.261-8.422 6.352-8.597a.598.598 0 0 0 .012-.396.526.526 0 0 0-.6-.297Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/checkmark-on.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});