define("cc-frontend/routes/user/show", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShowRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class ShowRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    model(params) {
      if (!(0, _lodash.isNil)(params.redirectToPlanbookId)) {
        Ember.run.scheduleOnce("afterRender", this, () => {
          // We remove this otherwise we keep endlessly redirecting back to the planner.week
          // @ts-ignore
          this.replaceWith({
            queryParams: {
              redirectToPlanbookId: null
            }
          });
          this.transitionTo("planbookv5.planner.week", params.redirectToPlanbookId);
        });
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        return Ember.get(this, "store").find("user", params.user_id).then(user => {
          if (user === undefined) {
            reject({
              status: 404,
              userId: params.user_id
            });
          } else {
            resolve(user);
          }
        });
      });
    }

    afterModel(user, _transition) {
      if (user !== null && user !== void 0 && user.attributes.onboardingInProgress) {
        const step = user === null || user === void 0 ? void 0 : user.attributes.lastOnboardingStep;
        console.log("user.show onboarding redirect", step);
        this.transitionTo("user.onboarding", {
          queryParams: {
            step
          }
        });
      }
    }

    error(error, _transition) {
      if (error.status === 404) {
        console.log(`User was not found with ID "${error.userId}"`); // @ts-ignore

        if (Ember.get(this, "fastboot") && Ember.get(this, "fastboot.isFastBoot")) {
          // @ts-ignore
          this.set("fastboot.response.statusCode", 404);
        }

        this.replaceWith("not-found", "user-not-found");
        return undefined;
      } else {
        // Let the route above this handle the error.
        return true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = ShowRoute;
});