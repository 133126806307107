define("cc-frontend/models/date-annotation-group-attributes", ["exports", "cc-frontend/lib/structor"], function (_exports, _structor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateAnnotationGroupAttributes extends _structor.default {
    defineStruct() {
      this.field("title");
      this.field("description", "<p></p>");
      this.field("startDate");
      this.field("endDate");
      this.field("isOff");
      this.field("schoolYearId");
      this.field("isPublished");
    }

  }

  _exports.default = DateAnnotationGroupAttributes;
});