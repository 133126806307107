define("cc-frontend/components/print/date-annotation-planbook", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DateAnnotationPlanbook = (_dec = (0, _component.classNames)("date-annotation", "date-annotation--planbook-date"), _dec2 = (0, _component.classNameBindings)("annotation.attributes.isOff:is-off:is-on"), _dec3 = (0, _component.layout)(Ember.HTMLBars.template(
  /*
    
    {{#if annotation.attributes.isOff}}
      <div class="date-annotation__is-off-notice">
        Classes are off
      </div>
    {{/if}}
    {{#if
      (and
        (eq @annotation.type "date-annotation-planbook-custom-range")
        (not-eq @annotation.attributes.startDate @annotation.attributes.endDate)
      )
    }}
      <div class="date-annotation__range">
        {{moment-format annotation.attributes.startDate "MMM Do, YYYY"}}
        to
        {{moment-format annotation.attributes.endDate "MMM Do, YYYY"}}
      </div>
    {{/if}}
    <div class="date-annotation__title">
      {{annotation.attributes.title}}
    </div>
    <div class="date-annotation__description">
      {{{annotation.attributes.description}}}
    </div>
  
    {{#if
        (and
          (includes planbook.meta.permissions "can-edit-plan")
          (not annotation.attributes.publishedByGroupId)
        )
      }}
      <DateAnnotationPlanbookMenu @planbook={{planbook}} @annotation={{annotation}} @dateString={{dateString}} />
    {{/if}}
  
  */
  {
    "id": "gXcfB2O+",
    "block": "[[[1,\"\\n\"],[41,[33,1,[\"attributes\",\"isOff\"]],[[[1,\"    \"],[10,0],[14,0,\"date-annotation__is-off-notice\"],[12],[1,\"\\n      Classes are off\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[28,[37,3],[[30,1,[\"type\"]],\"date-annotation-planbook-custom-range\"],null],[28,[37,4],[[30,1,[\"attributes\",\"startDate\"]],[30,1,[\"attributes\",\"endDate\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"date-annotation__range\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[33,1,[\"attributes\",\"startDate\"]],\"MMM Do, YYYY\"],null]],[1,\"\\n      to\\n      \"],[1,[28,[35,5],[[33,1,[\"attributes\",\"endDate\"]],\"MMM Do, YYYY\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"date-annotation__title\"],[12],[1,\"\\n    \"],[1,[33,1,[\"attributes\",\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"date-annotation__description\"],[12],[1,\"\\n    \"],[2,[33,1,[\"attributes\",\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,2],[[28,[37,6],[[33,7,[\"meta\",\"permissions\"]],\"can-edit-plan\"],null],[28,[37,8],[[33,1,[\"attributes\",\"publishedByGroupId\"]]],null]],null],[[[1,\"    \"],[8,[39,9],null,[[\"@planbook\",\"@annotation\",\"@dateString\"],[[36,7],[36,1],[36,10]]],null],[1,\"\\n\"]],[]],null]],[\"@annotation\"],false,[\"if\",\"annotation\",\"and\",\"eq\",\"not-eq\",\"moment-format\",\"includes\",\"planbook\",\"not\",\"date-annotation-planbook-menu\",\"dateString\"]]",
    "moduleName": "(unknown template module)",
    "isStrictMode": false
  })), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class DateAnnotationPlanbook extends Ember.Component.extend() {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appState", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class);
  _exports.default = DateAnnotationPlanbook;
});