define("cc-frontend/components/icons/search-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
    <g clip-path="url(#a)">
      <path
        stroke="#888"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width=".73"
        d="M4.563 11.862h5.474m-5.474-1.825h5.474m-6.691 0H1.52A1.22 1.22 0 0 1 .304 8.821V5.17a1.22 1.22 0 0 1 1.217-1.217h11.558a1.22 1.22 0 0 1 1.217 1.217v3.65a1.22 1.22 0 0 1-1.217 1.216h-1.825M3.346 2.738V.304h5.831c.162 0 .316.064.43.178l1.469 1.469a.608.608 0 0 1 .178.43v.357"
      ></path>
      <path
        stroke="#888"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width=".73"
        d="M11.254 13.687a.609.609 0 0 1-.608.609H3.954a.609.609 0 0 1-.608-.609V8.212h7.908v5.475ZM1.521 5.779a.608.608 0 1 0 1.217 0 .608.608 0 0 0-1.217 0ZM8.82.304v2.433h2.434"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14.6v14.6H0z"></path>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "WjI58kP6",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"15\"],[14,\"height\",\"15\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 15 15\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"stroke\",\"#888\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\".73\"],[14,\"d\",\"M4.563 11.862h5.474m-5.474-1.825h5.474m-6.691 0H1.52A1.22 1.22 0 0 1 .304 8.821V5.17a1.22 1.22 0 0 1 1.217-1.217h11.558a1.22 1.22 0 0 1 1.217 1.217v3.65a1.22 1.22 0 0 1-1.217 1.216h-1.825M3.346 2.738V.304h5.831c.162 0 .316.064.43.178l1.469 1.469a.608.608 0 0 1 .178.43v.357\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"stroke\",\"#888\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\".73\"],[14,\"d\",\"M11.254 13.687a.609.609 0 0 1-.608.609H3.954a.609.609 0 0 1-.608-.609V8.212h7.908v5.475ZM1.521 5.779a.608.608 0 1 0 1.217 0 .608.608 0 0 0-1.217 0ZM8.82.304v2.433h2.434\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"fill\",\"#fff\"],[14,\"d\",\"M0 0h14.6v14.6H0z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/search-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});