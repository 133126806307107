define("cc-frontend/lib/actions/app-state/KB_SEND_US_A_MESSAGE", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sendUsAMessageAction;

  function sendUsAMessageAction(payload) {
    return {
      name: "KB_SEND_US_A_MESSAGE",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: "help_site",
      root_type: "app",
      primary_verb: "use",
      primary_object_type: "app",
      primary_object_id: "help_site",
      primary_object_specifier: payload.locationOfAction,
      primary_object_id_and_ancestor_ids: ["help_site"],
      adverbial_verb: "sending",
      adverbial_object_type: "message",
      adverbial_object_id_and_ancestor_ids: ["help_site"]
    };
    return (0, _createNarrative.default)({
      title: "Sent a Message",
      description: `User contacted us through the ${payload.locationOfAction}`,
      titleForAnalytics: "KB Article - Visit",
      context: {
        searchQuery: payload.searchQuery
      },
      newAnalyticsEvents: [newEvent]
    });
  }
});