define("cc-frontend/components/planner/modals/edit-annotation", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <SlideoutWrapper @isSidebar={{true}} @order={{@order}} @closeModal={{@closeModal}}>
    <div local-class="template-sidebar">
      <div local-class="header">
        <div local-class="header__text">
          {{@model.modalTitle}}
        </div>
        <div local-class="header__close" class="slideout__header__close" {{on "click" @closeModal}}>
          <Icons::IosCloseEmpty />
        </div>
      </div>
      <div local-class="body">
        <Planner::Modals::EditAnnotationBody
          @allowRangeToggle={{@model.allowRangeToggle}}
          @allowNoClassToggle={{@model.allowNoClassToggle}}
          @dateIs={{@model.dateIs}}
          @datesAreEditable={{@model.datesAreEditable}}
          @description={{@model.description}}
          @classIsOff={{@model.classIsOff}}
          @endDate={{@model.endDate}}
          @minimumStartDate={{@model.minimumStartDate}}
          @startDate={{@model.startDate}}
          @submitButtonText={{@model.submitButtonText}}
          @title={{@model.title}}
          @closeModal={{@closeModal}}
          @planbook={{@model.planbook}}
          @oldAnnotation={{@model.oldAnnotation}}
          @submitAction={{@model.submitAction}}
        />
      </div>
    </div>
  </SlideoutWrapper>
  */
  {
    "id": "ffuI26UF",
    "block": "[[[8,[39,0],null,[[\"@isSidebar\",\"@order\",\"@closeModal\"],[true,[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,1],[\"template-sidebar\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-annotation.css\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,1],[\"header\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-annotation.css\"]]]]]],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[[28,[37,1],[\"header__text\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-annotation.css\"]]]]]],[12],[1,\"\\n        \"],[1,[30,3,[\"modalTitle\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[11,0],[16,0,[29,[\"slideout__header__close \",[28,[37,1],[\"header__close\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-annotation.css\"]]]]]],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[8,[39,3],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,1],[\"body\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-annotation.css\"]]]]]],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@allowRangeToggle\",\"@allowNoClassToggle\",\"@dateIs\",\"@datesAreEditable\",\"@description\",\"@classIsOff\",\"@endDate\",\"@minimumStartDate\",\"@startDate\",\"@submitButtonText\",\"@title\",\"@closeModal\",\"@planbook\",\"@oldAnnotation\",\"@submitAction\"],[[30,3,[\"allowRangeToggle\"]],[30,3,[\"allowNoClassToggle\"]],[30,3,[\"dateIs\"]],[30,3,[\"datesAreEditable\"]],[30,3,[\"description\"]],[30,3,[\"classIsOff\"]],[30,3,[\"endDate\"]],[30,3,[\"minimumStartDate\"]],[30,3,[\"startDate\"]],[30,3,[\"submitButtonText\"]],[30,3,[\"title\"]],[30,2],[30,3,[\"planbook\"]],[30,3,[\"oldAnnotation\"]],[30,3,[\"submitAction\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@order\",\"@closeModal\",\"@model\"],false,[\"slideout-wrapper\",\"local-class\",\"on\",\"icons/ios-close-empty\",\"planner/modals/edit-annotation-body\"]]",
    "moduleName": "cc-frontend/components/planner/modals/edit-annotation.hbs",
    "isStrictMode": false
  });

  class EditAnnotationComponent extends _component.default {}

  _exports.default = EditAnnotationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EditAnnotationComponent);
});