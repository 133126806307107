define("cc-frontend/components/user/onboarding/goals.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-sign__title": "_user-sign__title_1cnse6",
    "selected": "_selected_1cnse6"
  };
  _exports.default = _default;
});