define("cc-frontend/utils/parse-emails", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Cleans and parses emails
   * @param  {String} string
   * @return {Array[String]}
   */
  function _default(string) {
    return _lodash.default.chain(string).toLower().replace(/,/g, " ").replace(/;/g, " ").replace(/>/g, " ").replace(/</g, " ").replace(/\s+/g, " ").split(" ").reject(el => el === "").reject(el => el === null).reject(el => el === undefined).filter(el => _lodash.default.includes(el, "@")).value();
  }
});