define("cc-frontend/components/icons/link-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      fill="#C2F3FF"
      d="M19.363 3.325 3.325 19.364a1.617 1.617 0 0 0 0 2.286l2.025 2.025a1.617 1.617 0 0 0 2.287 0L23.675 7.637a1.617 1.617 0 0 0 0-2.286L21.65 3.325a1.617 1.617 0 0 0-2.287 0Z"
    ></path>
    <path
      fill="#191919"
      fill-rule="evenodd"
      d="M11.982 10.275a3.73 3.73 0 0 0-2.639 1.093l-6.582 6.583a3.73 3.73 0 0 0 0 5.276l1.012 1.01a3.733 3.733 0 0 0 5.278 0l4.556-4.555a.565.565 0 1 1 .8.8L9.85 25.036a4.863 4.863 0 0 1-6.876 0l-1.011-1.011a4.861 4.861 0 0 1 0-6.873l6.581-6.583a4.86 4.86 0 0 1 6.875 0l1.013 1.013a.565.565 0 0 1-.8.799l-1.012-1.013a3.732 3.732 0 0 0-2.638-1.094Z"
      clip-rule="evenodd"
    ></path>
    <path
      fill="#191919"
      fill-rule="evenodd"
      d="M20.588 1.67a3.73 3.73 0 0 0-2.639 1.092l-4.556 4.556a.565.565 0 0 1-.8-.8l4.557-4.555a4.86 4.86 0 0 1 6.875 0m0 0 1.012 1.011a4.862 4.862 0 0 1 0 6.874l-6.581 6.582a4.862 4.862 0 0 1-6.875 0l-1.013-1.013a.565.565 0 1 1 .8-.799l1.012 1.014a3.734 3.734 0 0 0 5.277 0l6.582-6.583a3.73 3.73 0 0 0 0-5.275l-1.012-1.011a3.734 3.734 0 0 0-2.64-1.094"
      clip-rule="evenodd"
    ></path>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h27v27H0z"></path>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "iMchrSnv",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#C2F3FF\"],[14,\"d\",\"M19.363 3.325 3.325 19.364a1.617 1.617 0 0 0 0 2.286l2.025 2.025a1.617 1.617 0 0 0 2.287 0L23.675 7.637a1.617 1.617 0 0 0 0-2.286L21.65 3.325a1.617 1.617 0 0 0-2.287 0Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#191919\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M11.982 10.275a3.73 3.73 0 0 0-2.639 1.093l-6.582 6.583a3.73 3.73 0 0 0 0 5.276l1.012 1.01a3.733 3.733 0 0 0 5.278 0l4.556-4.555a.565.565 0 1 1 .8.8L9.85 25.036a4.863 4.863 0 0 1-6.876 0l-1.011-1.011a4.861 4.861 0 0 1 0-6.873l6.581-6.583a4.86 4.86 0 0 1 6.875 0l1.013 1.013a.565.565 0 0 1-.8.799l-1.012-1.013a3.732 3.732 0 0 0-2.638-1.094Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#191919\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M20.588 1.67a3.73 3.73 0 0 0-2.639 1.092l-4.556 4.556a.565.565 0 0 1-.8-.8l4.557-4.555a4.86 4.86 0 0 1 6.875 0m0 0 1.012 1.011a4.862 4.862 0 0 1 0 6.874l-6.581 6.582a4.862 4.862 0 0 1-6.875 0l-1.013-1.013a.565.565 0 1 1 .8-.799l1.012 1.014a3.734 3.734 0 0 0 5.277 0l6.582-6.583a3.73 3.73 0 0 0 0-5.275l-1.012-1.011a3.734 3.734 0 0 0-2.64-1.094\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"fill\",\"#fff\"],[14,\"d\",\"M0 0h27v27H0z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/link-color.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});