define("cc-frontend/lib/actions/lesson/LESSON_PASTE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/lesson", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _base, _lesson, _planbook, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePasteLesson = preparePasteLesson;
  _exports.default = _exports.LESSON_PASTE = void 0;

  function preparePasteLesson(payload) {
    return {
      name: LESSON_PASTE.name,
      payload: payload,
      patches: LESSON_PASTE.patches(payload),
      undoPatches: LESSON_PASTE.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(LESSON_PASTE.narrative, payload)
    };
  }

  const LESSON_PASTE = {
    name: "LESSON_PASTE",
    params: {
      cardStackId: "string",
      newLessonAttributes: "object",
      originalLessonAttributes: "object",
      copiedFrom: "string"
    },

    // This method doesn't apply new card ids because it's called from the  paste all lessons for Day
    // and that got super hard to  do. So, the  card ids need to be changed at the call site...which is a total bummer
    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId);

      patch.set(`attributes.cards`, payload.newLessonAttributes.cards);
      patch.set(`attributes.title`, payload.newLessonAttributes.title);
      patch.set(`attributes.subtitle`, payload.newLessonAttributes.subtitle);
      patch.set("attributes.parentCardStack", payload.parentCardStackInfo);

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId);

      patchForCardStackSummary.set("attributes.title", payload.newLessonAttributes.title);
      return [patch.patch, patchForCardStackSummary.patch];
    },

    undoPatches(payload) {
      let originalLessonAttributes = payload.originalLessonAttributes || {
        cards: null,
        title: "",
        subtitle: ""
      };

      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId);

      patch.set(`attributes.cards`, originalLessonAttributes.cards);
      patch.set(`attributes.title`, originalLessonAttributes.title);
      patch.set(`attributes.subtitle`, originalLessonAttributes.subtitle);
      patch.set(`attributes.parentCardStack`, originalLessonAttributes.parentCardStack);

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId);

      patchForCardStackSummary.set("attributes.title", originalLessonAttributes.title);
      return [patch.patch, patchForCardStackSummary.patch];
    },

    narrative(payload, findFn, userId) {
      var _payload$parentCardSt;

      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let context = (0, _defaultLessonContext.default)(payload, models);
      let donorCardStackTitle = models.cardStack ? models.cardStack.attributes.title : null;
      let recipientLessonTitle = context.cardStackTitle;
      let title = "Pasted a Lesson";
      let titleForAnalytics = "Lesson - Paste";
      let description = `Pasted ${donorCardStackTitle} over ${recipientLessonTitle}`;
      let newContext = (0, _lodashEs.assign)({}, context, {
        donorCardStackTitle,
        donorCardStackId: payload.parentCardStackInfo.id
      });
      let sharedEventProperties = {
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        adverbial_object_possessive: (0, _lesson.getPossessiveForLesson)(findFn, userId, ((_payload$parentCardSt = payload.parentCardStackInfo) === null || _payload$parentCardSt === void 0 ? void 0 : _payload$parentCardSt.ownerIds) || []),
        adverbial_verb: "pasting",
        adverbial_object_id: payload.cardStackId,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      let newEvent = null;

      if (payload.copiedFrom === "template") {
        newEvent = { ...(0, _base.createBlankEvent)(),
          ...sharedEventProperties,
          primary_object_specifier: "cards",
          adverbial_object_type: "template",
          adverbial_object_specifier: null
        };
        title = "Replaced a Lesson with a Template";
        titleForAnalytics = "Lesson - Replace With Template";
        description = `Replaced ${recipientLessonTitle} with your "${donorCardStackTitle}" template`;
      } else {
        newEvent = { ...(0, _base.createBlankEvent)(),
          ...sharedEventProperties,
          primary_object_specifier: null,
          adverbial_object_type: "lesson",
          adverbial_object_specifier: "single_lesson"
        };
      }

      return {
        title: title,
        titleForAnalytics: titleForAnalytics,
        description: description,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_PASTE = LESSON_PASTE;
  var _default = LESSON_PASTE;
  _exports.default = _default;
});