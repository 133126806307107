define("cc-frontend/components/transition", ["exports", "ember-headlessui/components/transition"], function (_exports, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _transition.default;
    }
  });
});