define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY", ["exports", "cc-frontend/lib/patch-updater", "date-fns", "lodash", "lodash-es", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context"], function (_exports, _patchUpdater, _dateFns, _lodash, _lodashEs, _defaultPlanbookLessonsActivity, _defaultPlanbookLessonsContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareClearAllLessonsForDay = prepareClearAllLessonsForDay;
  _exports.default = _exports.PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY = void 0;

  function prepareClearAllLessonsForDay(payload) {
    return {
      name: PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY.name,
      payload: payload,
      patches: PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY.patches(payload),
      undoPatches: PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY.narrative, payload)
    };
  }

  const PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY = {
    name: "PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY",

    patches(payload) {
      return _lodash.default.map(payload.lessons, lesson => {
        return _patchUpdater.default.create("card-stack", lesson.id).set("attributes.title", "").set("attributes.cards", []).patch;
      });
    },

    undoPatches(payload) {
      return _lodash.default.flatMap(payload.lessons, lesson => {
        return _patchUpdater.default.create("card-stack", lesson.id).set("attributes.sections", JSON.parse(JSON.stringify(lesson.attributes.cards))).set("attributes.title", lesson.attributes.title).inc("attributes.version", 1).patch;
      });
    },

    narrative(payload, findFn, userId) {
      let planbook = findFn("planbook", payload.planbookId); // let models = defaultLessonModels(payload, findFn, userId)

      let formattedDate = (0, _dateFns.format)((0, _dateFns.parseISO)(payload.dateString), "EEE, MMM. d, yyyy"); // let newEvent: LessonDelete = {
      //   ...createBlankEvent(),
      //   planbook_id: models.planbook.id,
      //   primary_object_id: payload.cardStackId,
      //   date_plan_scheduled_for: models.dateStringForCardStack,
      //   primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      //   primary_verb: "delete",
      //   primary_possessive: getPrimaryPossiveForPlanbook(findFn, userId, models.planbook.id),
      //   primary_object: "lesson",
      //   primary_object_specifier: null,
      //   adverbial_verb: "removing",
      //   adverbial_object: "lesson",
      //   adverbial_object_specifier: null,
      //   adverbial_object_id: null,
      //   adverbial_object_id_and_ancestor_ids: null,
      // }

      return {
        title: "Cleared An Entire Day of Lessons",
        titleForAnalytics: "Lesson - Clear Entire Day",
        description: `Cleared all the lessons on ${formattedDate}. Did not erase their lesson histories`,
        context: (0, _defaultPlanbookLessonsContext.default)(planbook, payload),
        activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbookId, userId) // newAnalyticsEvents: [newEvent],

      };
    }

  };
  _exports.PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY = PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY;
  var _default = PLANBOOK_CLEAR_ALL_LESSONS_FOR_DAY;
  _exports.default = _default;
});