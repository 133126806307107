define("cc-frontend/components/d-s/dropdown-menu-container", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (match-media "(max-width: 649px)")}}
    <DS::FullScreenMenu @closeMenu={{@closeMenu}}>
      {{yield}}
    </DS::FullScreenMenu>
  {{else}}
    <LiquidWormhole
      @class="dialog-backdrop {{if @noBackdrop "" "--barely-there"}}"
      @click={{@closeMenu}}
    />
    <LiquidTether
      @class={{or @class "animated-dropdown"}}
      @target={{@target}}
      @offset={{@offset}}
      @targetAttachment={{if @targetAttachment @targetAttachment "bottom center"}}
      @attachment={{if @attachment @attachment "top right"}}
      @constraints={{if
        @constraints
        @constraints
        (array (hash to="window" pin=(array "left" "bottom" "right" "top")))
      }}
    >
      {{yield}}
    </LiquidTether>
  {{/if}}
  */
  {
    "id": "cTgCaMMY",
    "block": "[[[41,[28,[37,1],[\"(max-width: 649px)\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@closeMenu\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[18,9,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@class\",\"@click\"],[[29,[\"dialog-backdrop \",[52,[30,2],\"\",\"--barely-there\"]]],[30,1]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@class\",\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@constraints\"],[[28,[37,6],[[30,3],\"animated-dropdown\"],null],[30,4],[30,5],[52,[30,6],[30,6],\"bottom center\"],[52,[30,7],[30,7],\"top right\"],[52,[30,8],[30,8],[28,[37,7],[[28,[37,8],null,[[\"to\",\"pin\"],[\"window\",[28,[37,7],[\"left\",\"bottom\",\"right\",\"top\"],null]]]]],null]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,9,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@closeMenu\",\"@noBackdrop\",\"@class\",\"@target\",\"@offset\",\"@targetAttachment\",\"@attachment\",\"@constraints\",\"&default\"],false,[\"if\",\"match-media\",\"d-s/full-screen-menu\",\"yield\",\"liquid-wormhole\",\"liquid-tether\",\"or\",\"array\",\"hash\"]]",
    "moduleName": "cc-frontend/components/d-s/dropdown-menu-container.hbs",
    "isStrictMode": false
  });

  class DSDropdownContainer extends _glimmerComponent.default {}

  _exports.default = DSDropdownContainer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DSDropdownContainer);
});