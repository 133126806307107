define("cc-frontend/components/dialogs/confirm-delete-course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-warning pulse-warning" style="display: block;">
    !
  </div>
  <h1 class="create-annotation__title">
    {{#if (gt @options.lessonCount 0)}}
      Would you like to delete this and its
      {{@options.lessonCount}}
      {{pluralize @options.lessonCount "lesson" "lessons"}}?
    {{else}}
      Would you like to delete this class?
    {{/if}}
  </h1>
  <h2 class="create-annotation__description">
    Deleting the lesson will remove all the lessons.
  </h2>
  <div class="create-annotation__submit-buttons">
    <button
      class="create-annotation__submit-button"
      {{on "click" @accept}}
      data-test-delete-class-confirm
      type="button"
    >
      Delete it
    </button>
  </div>
  <div class="create-annotation__cancel-button-wrapper">
    <div class="btn btn-link create-annotation__cancel-button" {{on "click" @decline}}>
      Cancel
    </div>
  </div>
  */
  {
    "id": "Sqd1eUZD",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-warning pulse-warning\"],[14,5,\"display: block;\"],[12],[1,\"\\n  !\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"lessonCount\"]],0],null],[[[1,\"    Would you like to delete this and its\\n    \"],[1,[30,1,[\"lessonCount\"]]],[1,\"\\n    \"],[1,[28,[35,2],[[30,1,[\"lessonCount\"]],\"lesson\",\"lessons\"],null]],[1,\"?\\n\"]],[]],[[[1,\"    Would you like to delete this class?\\n\"]],[]]],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description\"],[12],[1,\"\\n  Deleting the lesson will remove all the lessons.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"create-annotation__submit-button\"],[24,\"data-test-delete-class-confirm\",\"\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n    Delete it\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"create-annotation__cancel-button-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"btn btn-link create-annotation__cancel-button\"],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@options\",\"@accept\",\"@decline\"],false,[\"if\",\"gt\",\"pluralize\",\"on\"]]",
    "moduleName": "cc-frontend/components/dialogs/confirm-delete-course.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});