define("cc-frontend/lib/actions/lesson/LESSON_CARD_MOVE_WITHIN_LESSON", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_MOVE_WITHIN_LESSON = void 0;
  const LESSON_CARD_MOVE_WITHIN_LESSON = {
    name: "LESSON_CARD_MOVE_WITHIN_LESSON",

    patches(payload) {
      return (0, _lodashEs.map)(payload.positionDiffs, tuple => {
        return _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: tuple[0]
        }).set("embedded", "attributes.position", tuple[1]).patch;
      });
    },

    undoPatches(payload) {
      return (0, _lodashEs.map)(payload.positionDiffs, tuple => {
        return _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
          id: tuple[0]
        }).set("embedded", "attributes.position", tuple[2]).patch;
      });
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let cardTitle = (0, _lodashEs.get)(payload, "sectionBeingMoved.attributes.title") || "Untitled";
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = (0, _lodashEs.assign)({}, context, {
        moveType: "withinLesson",
        cardTitle: cardTitle
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "cards",
        adverbial_verb: "dragging_dropping",
        adverbial_object_type: "card",
        adverbial_object_specifier: "within_lesson",
        adverbial_object_id: payload.sectionBeingMoved.id,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Moved a Lesson Card",
        titleForAnalytics: "Lesson Card - Move",
        description: `Moved "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "moved"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_MOVE_WITHIN_LESSON = LESSON_CARD_MOVE_WITHIN_LESSON;
  var _default = LESSON_CARD_MOVE_WITHIN_LESSON;
  _exports.default = _default;
});