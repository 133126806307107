define("cc-frontend/models/date-annotation", ["exports", "cc-frontend/lib/structor", "cc-frontend/models/date-annotation-course-custom-single", "cc-frontend/models/date-annotation-course-default-attributes", "cc-frontend/models/date-annotation-group-attributes", "cc-frontend/models/date-annotation-planbook-custom-range-attributes", "cc-frontend/models/date-annotation-planbook-custom-single"], function (_exports, _structor, _dateAnnotationCourseCustomSingle, _dateAnnotationCourseDefaultAttributes, _dateAnnotationGroupAttributes, _dateAnnotationPlanbookCustomRangeAttributes, _dateAnnotationPlanbookCustomSingle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateAnnotation extends _structor.default {
    defineStruct() {
      this.field("id");
      this.field("type");
      this.field("attributes", model => {
        if (model.type === "date-annotation-planbook-custom-single") return new _dateAnnotationPlanbookCustomSingle.default(model.attributes);
        if (model.type === "date-annotation-planbook-custom-range") return new _dateAnnotationPlanbookCustomRangeAttributes.default(model.attributes);
        if (model.type === "date-annotation-course-custom-single") return new _dateAnnotationCourseCustomSingle.default(model.attributes);
        if (model.type === "date-annotation-course-default") return new _dateAnnotationCourseDefaultAttributes.default(model.attributes);
        if (model.type === "date-annotation-group") return new _dateAnnotationGroupAttributes.default(model.attributes);
      });
    }

  }

  _exports.default = DateAnnotation;
});