define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_UPDATE", ["exports", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "moment", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _datesOffCalculator, _patchUpdater, _base, _planbook, _lodashEs, _moment, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = courseDateAnnotationUpdate;

  function courseDateAnnotationUpdate(payload) {
    return {
      name: "COURSE_DATE_ANNOTATION_UPDATE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let newAnnotations = (0, _lodashEs.map)(payload.course.attributes.calendar.dateAnnotations, annotation => {
      return annotation.id === payload.annotation.id ? payload.annotation : annotation;
    });
    let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

    let patch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dateAnnotations", {
      id: payload.annotation.id
    }).set("embedded", `attributes`, payload.annotation.attributes).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  }

  function undoPatches(payload) {
    let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(payload.course.attributes.calendar.dateAnnotations); // this feels heavy handed. Would be better if we just find that single annotation.

    let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dateAnnotations", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dateAnnotations))).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
    let context = (0, _defaultCourseDateContext.default)(payload, models);
    let formattedDateString = (0, _moment.default)(payload.annotation.attributes.dateString).format("ddd, MMM. D, YYYY");
    let originalAnnotation = (0, _lodashEs.find)(payload.course.attributes.calendar.dateAnnotations, annotation => {
      return annotation.id === payload.annotation.id;
    });
    let fieldsEdited = [];
    let description;

    if (originalAnnotation) {
      fieldsEdited = (0, _lodashEs.filter)(["title", "description"], key => {
        var _originalAnnotation$a;

        return payload.annotation.attributes[key] !== (originalAnnotation === null || originalAnnotation === void 0 ? void 0 : (_originalAnnotation$a = originalAnnotation.attributes) === null || _originalAnnotation$a === void 0 ? void 0 : _originalAnnotation$a[key]);
      });
      description = `Changed the ${fieldsEdited.join(" and ")} for the note on your "${context.courseTitle}" class on ${formattedDateString}`;
    } else {
      description = `Updated the note on your "${context.courseTitle}" class on ${formattedDateString}`;
    }

    let newContext = (0, _lodashEs.assign)({}, context, {
      fieldsEdited: fieldsEdited
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "course",
      primary_object_id: payload.course.id,
      primary_object_specifier: "calendar",
      primary_object_id_and_ancestor_ids: [models.planbook.id, payload.course.id],
      adverbial_verb: "editing",
      adverbial_object_type: "annotation",
      adverbial_object_id: payload.annotation.id,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: [models.planbook.id, payload.course.id]
    };
    return {
      title: "Edited Note for Turned-off Class",
      titleForAnalytics: "Lesson - Update Annotation",
      description: description,
      context: newContext,
      activity: (0, _defaultCourseDateActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});