define("cc-frontend/lib/actions/course/COURSE_CHANGE_TITLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels, _base, _lodashEs, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = changeCourseTitleAction;
  _exports.patches = patches;
  _exports.narrative = narrative;
  _exports.undoPatches = void 0;

  function changeCourseTitleAction(payload) {
    return {
      name: "COURSE_CHANGE_TITLE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("course", payload.courseId).set("attributes.title", payload.title).patch;

    return [patch];
  }

  const undoPatches = null;
  _exports.undoPatches = undoPatches;

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
    let newTitle = payload.title;
    let oldTitle = payload.originalTitle;
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "course",
      primary_object_id: payload.courseId,
      primary_object_specifier: "title",
      primary_object_id_and_ancestor_ids: null,
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: null
    };
    return {
      title: "Renamed course",
      titleForAnalytics: "Course -  Change Title",
      description: `Renamed your "${oldTitle}" class "${newTitle}"`,
      context: (0, _defaultCourseContext.default)(payload, models),
      activity: (0, _defaultCourseActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});