define("cc-frontend/lib/actions/app-state/PLANBOOK_LINE_UP_CLASSES", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _base, _lodashEs, _createNarrative, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = planbookLineUpClassesAction;

  function planbookLineUpClassesAction(payload) {
    return {
      name: "PLANBOOK_LINE_UP_CLASSES",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    let titleForAnalytics = payload.classesLinedUp ? "Planbook - Line Up Classes Off" : "Planbook - Line Up Classes On";
    let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
    let context = (0, _defaultPlanbookContext.default)(payload, models);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      primary_object_type: "planbook",
      primary_possessive: "owns",
      primary_object_id_and_ancestor_ids: [payload.planbookId],
      primary_verb: "customize",
      primary_object_specifier: "line_up_classes",
      adverbial_verb: "toggling",
      adverbial_object_type: "course",
      adverbial_object_specifier: payload.classesLinedUp ? "off" : "on"
    };
    return (0, _createNarrative.default)({
      titleForAnalytics: titleForAnalytics,
      context: context,
      newAnalyticsEvents: [newEvent]
    });
  }
});