define("cc-frontend/components/icons/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <g clip-path="url(#a)">
      <path
        d="M9.639 20.972H6.805a.472.472 0 0 0-.472.472 1.889 1.889 0 1 0 3.778 0 .472.472 0 0 0-.472-.472Zm4.721-7.415v-.614c0-.606-.066-1.211-.198-1.803a6.611 6.611 0 0 1-2.522-4.062 5.667 5.667 0 0 0-2.002-1V4.915a1.417 1.417 0 0 0-2.833 0v1.161c-2.758.746-4.722 3.57-4.722 6.867v.613a13.014 13.014 0 0 1-1.37 5.79.482.482 0 0 0 .217.633.51.51 0 0 0 .208.047h14.167a.472.472 0 0 0 .425-.68 13.013 13.013 0 0 1-1.37-5.79ZM18.138.666a5.194 5.194 0 1 0 0 10.389 5.194 5.194 0 0 0 0-10.389Zm-.709 2.361a.708.708 0 1 1 1.417 0V5.86a.708.708 0 1 1-1.416 0V3.027Zm.709 6.611a1.067 1.067 0 1 1-.2-2.125 1.067 1.067 0 0 1 .2 2.125Z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <rect width="24" height="24"></rect>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "PthFQP2S",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M9.639 20.972H6.805a.472.472 0 0 0-.472.472 1.889 1.889 0 1 0 3.778 0 .472.472 0 0 0-.472-.472Zm4.721-7.415v-.614c0-.606-.066-1.211-.198-1.803a6.611 6.611 0 0 1-2.522-4.062 5.667 5.667 0 0 0-2.002-1V4.915a1.417 1.417 0 0 0-2.833 0v1.161c-2.758.746-4.722 3.57-4.722 6.867v.613a13.014 13.014 0 0 1-1.37 5.79.482.482 0 0 0 .217.633.51.51 0 0 0 .208.047h14.167a.472.472 0 0 0 .425-.68 13.013 13.013 0 0 1-1.37-5.79ZM18.138.666a5.194 5.194 0 1 0 0 10.389 5.194 5.194 0 0 0 0-10.389Zm-.709 2.361a.708.708 0 1 1 1.417 0V5.86a.708.708 0 1 1-1.416 0V3.027Zm.709 6.611a1.067 1.067 0 1 1-.2-2.125 1.067 1.067 0 0 1 .2 2.125Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"rect\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/alert.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});