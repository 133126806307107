define("cc-frontend/components/user/onboarding/toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" local-class="user-sign__button" ...attributes>{{yield}}</button>
  */
  {
    "id": "RNl4BKNm",
    "block": "[[[11,\"button\"],[16,0,[29,[[28,[37,0],[\"user-sign__button\"],[[\"from\"],[\"cc-frontend/components/user/onboarding/toggle-button.css\"]]]]]],[24,4,\"button\"],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/user/onboarding/toggle-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});