define("cc-frontend/lib/actions/app/STANDARDS_SEARCH_SELECT_SUBJECT", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = standardsSearchSelectSubjectAction;

  function standardsSearchSelectSubjectAction(payload) {
    return {
      name: "STANDARDS_SEARCH_SELECT_SUBJECT",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: null,
      root_type: "app",
      date_plan_scheduled_for: null,
      primary_verb: "use",
      primary_possessive: "owns",
      primary_object_type: "app",
      primary_object_id: "commoncurriculum.com",
      primary_object_specifier: "standards_search",
      primary_object_id_and_ancestor_ids: ["commoncurriculum.com"],
      adverbial_verb: "selecting",
      adverbial_object_type: "subject",
      adverbial_object_id: payload.subject,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: ["commoncurriculum.com"]
    };
    return (0, _createNarrative.default)({
      titleForAnalytics: "Standards Search - Select Subject",
      description: `Selected ${payload.subject}`,
      newAnalyticsEvents: [newEvent]
    });
  }
});