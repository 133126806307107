define("cc-frontend/components/user/onboarding/toggle-button.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-sign__button": "_user-sign__button_1frzsu"
  };
  _exports.default = _default;
});