define("cc-frontend/components/blank-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "4RVjxxF3",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/blank-template.hbs",
    "isStrictMode": false
  });

  class BlankTemplate extends Ember.Component {}

  _exports.default = BlankTemplate;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BlankTemplate);
});