define("cc-frontend/components/icons/shrink-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M12.39.263a.53.53 0 0 0-.368.378c-.011.05-.021 1.865-.021 4.087v3.996l-1.639-1.635c-.9-.9-1.675-1.654-1.72-1.677-.208-.105-.495-.025-.637.177-.065.092-.073.123-.073.276 0 .141.01.186.058.255.031.047.987 1.017 2.123 2.157 2.195 2.2 2.18 2.187 2.403 2.187.053 0 .14-.021.192-.046.066-.03.761-.711 2.223-2.177 2.08-2.085 2.129-2.135 2.156-2.254a.502.502 0 0 0-.72-.564c-.032.017-.801.772-1.709 1.678l-1.65 1.647V4.692c0-3.883-.003-4.06-.045-4.153a.56.56 0 0 0-.253-.25.67.67 0 0 0-.32-.026ZM2.129 11.521a.539.539 0 0 0-.286.247.512.512 0 0 0 .17.651l.103.073h19.768l.104-.073a.504.504 0 0 0 0-.838l-.104-.073-9.844-.004c-5.414-.002-9.874.005-9.911.017ZM12.3 13.594c-.096.046-.527.466-2.187 2.13-1.136 1.14-2.092 2.11-2.123 2.156-.048.07-.058.114-.058.256 0 .153.008.184.073.276.102.145.24.217.417.217a.551.551 0 0 0 .22-.04c.045-.023.82-.778 1.72-1.677L12 15.277v3.996c.001 2.221.01 4.036.022 4.087.106.455.749.525.94.102.043-.094.045-.26.046-4.154v-4.056l1.65 1.648c.908.906 1.677 1.661 1.71 1.678a.51.51 0 0 0 .647-.16.505.505 0 0 0 .072-.405c-.027-.118-.076-.169-2.155-2.253-1.463-1.466-2.158-2.147-2.224-2.178a.454.454 0 0 0-.408.012Z"
      clip-rule="evenodd"
    ></path>
  </svg>
  */
  {
    "id": "wOjqptGT",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M12.39.263a.53.53 0 0 0-.368.378c-.011.05-.021 1.865-.021 4.087v3.996l-1.639-1.635c-.9-.9-1.675-1.654-1.72-1.677-.208-.105-.495-.025-.637.177-.065.092-.073.123-.073.276 0 .141.01.186.058.255.031.047.987 1.017 2.123 2.157 2.195 2.2 2.18 2.187 2.403 2.187.053 0 .14-.021.192-.046.066-.03.761-.711 2.223-2.177 2.08-2.085 2.129-2.135 2.156-2.254a.502.502 0 0 0-.72-.564c-.032.017-.801.772-1.709 1.678l-1.65 1.647V4.692c0-3.883-.003-4.06-.045-4.153a.56.56 0 0 0-.253-.25.67.67 0 0 0-.32-.026ZM2.129 11.521a.539.539 0 0 0-.286.247.512.512 0 0 0 .17.651l.103.073h19.768l.104-.073a.504.504 0 0 0 0-.838l-.104-.073-9.844-.004c-5.414-.002-9.874.005-9.911.017ZM12.3 13.594c-.096.046-.527.466-2.187 2.13-1.136 1.14-2.092 2.11-2.123 2.156-.048.07-.058.114-.058.256 0 .153.008.184.073.276.102.145.24.217.417.217a.551.551 0 0 0 .22-.04c.045-.023.82-.778 1.72-1.677L12 15.277v3.996c.001 2.221.01 4.036.022 4.087.106.455.749.525.94.102.043-.094.045-.26.046-4.154v-4.056l1.65 1.648c.908.906 1.677 1.661 1.71 1.678a.51.51 0 0 0 .647-.16.505.505 0 0 0 .072-.405c-.027-.118-.076-.169-2.155-2.253-1.463-1.466-2.158-2.147-2.224-2.178a.454.454 0 0 0-.408.012Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/shrink-vertical.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});