define("cc-frontend/components/kb/search/contact-us", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/app", "cc-frontend/lib/actions/app-state/KB_SEND_US_A_MESSAGE", "lodash-es", "tracked-built-ins"], function (_exports, _glimmerComponent, _app, _KB_SEND_US_A_MESSAGE, _lodashEs, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="power-select-default-trigger power-select-default-multiple-trigger kb-search kb-search--contact-us ember-basic-dropdown-trigger"
  >
    <input
      type="search"
      class="js-contact-us-input"
      {{autofocus}}
      {{on "input" (pick "target.value" this.searchAlgolia)}}
      placeholder="Give us a brief summary of your question"
    />
  </div>
  {{#if (gt this.hits.length 0)}}
    <div class="kb-contact-us__results-heading">
      Here are some articles that might help:
    </div>
    <div class="kb-contact-us__result-list">
      {{#each this.hits as |hit|}}
        <LinkTo @route="help.article" @model={{hit.id}} class="kb-contact-us__result">
          {{{hit.attributes.title}}}
        </LinkTo>
      {{/each}}
    </div>
  {{/if}}
  {{#if this.hasEnteredSearchTerm}}
    <div class="kb-contact-us__no-results-wrapper">
      <div class="kb-contact-us__no-results-heading">
        Didn't find what you were looking for? Click the button below to send us a message.
      </div>
      <div class="kb-contact-us__no-results-button" {{on "click" this.sendUsAMessage}}>
        Send us a message!
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "1kKfDFWB",
    "block": "[[[10,0],[14,0,\"power-select-default-trigger power-select-default-multiple-trigger kb-search kb-search--contact-us ember-basic-dropdown-trigger\"],[12],[1,\"\\n  \"],[11,\"input\"],[24,0,\"js-contact-us-input\"],[24,\"placeholder\",\"Give us a brief summary of your question\"],[24,4,\"search\"],[4,[38,0],null,null],[4,[38,1],[\"input\",[28,[37,2],[\"target.value\",[30,0,[\"searchAlgolia\"]]],null]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"hits\",\"length\"]],0],null],[[[1,\"  \"],[10,0],[14,0,\"kb-contact-us__results-heading\"],[12],[1,\"\\n    Here are some articles that might help:\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"kb-contact-us__result-list\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"hits\"]]],null]],null],null,[[[1,\"      \"],[8,[39,7],[[24,0,\"kb-contact-us__result\"]],[[\"@route\",\"@model\"],[\"help.article\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[2,[30,1,[\"attributes\",\"title\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasEnteredSearchTerm\"]],[[[1,\"  \"],[10,0],[14,0,\"kb-contact-us__no-results-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"kb-contact-us__no-results-heading\"],[12],[1,\"\\n      Didn't find what you were looking for? Click the button below to send us a message.\\n    \"],[13],[1,\"\\n    \"],[11,0],[24,0,\"kb-contact-us__no-results-button\"],[4,[38,1],[\"click\",[30,0,[\"sendUsAMessage\"]]],null],[12],[1,\"\\n      Send us a message!\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"hit\"],false,[\"autofocus\",\"on\",\"pick\",\"if\",\"gt\",\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "cc-frontend/components/kb/search/contact-us.hbs",
    "isStrictMode": false
  });

  let KbSearchContactUs = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class KbSearchContactUs extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "algolia", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "selected", _descriptor4, this);

      _initializerDefineProperty(this, "searchTerm", _descriptor5, this);

      _initializerDefineProperty(this, "hits", _descriptor6, this);
    }

    get hasEnteredSearchTerm() {
      return !(0, _lodashEs.isNil)(this.searchTerm) && this.searchTerm !== "";
    }

    searchAlgolia(searchQuery) {
      let term = (0, _lodashEs.truncate)(searchQuery, {
        length: 511
      });
      this.searchTerm = term;

      if (term === "") {
        this.hits = [];
        return;
      }

      return this.algolia.help.search(term).then(function (data) {
        return (0, _lodashEs.map)((0, _lodashEs.take)(data.hits, 5), hit => {
          return {
            id: hit.id,
            attributes: {
              title: hit._highlightResult.attributes.title.value
            }
          };
        });
      }).then(hits => {
        // set(this, 'hasHits', hits && hits.length > 0)
        this.hits = hits;
        return hits;
      }).catch(function (err) {
        (0, _app.handleError)(err);
      });
    }

    selectArticle(matches) {
      let match = (0, _lodashEs.first)(matches);
      if (match) this.router.transitionTo("help.article", match.id);
    }

    sendUsAMessage() {
      this.store.dispatchPreparedAction((0, _KB_SEND_US_A_MESSAGE.default)({
        searchQuery: this.searchTerm,
        locationOfAction: "contact_us_page"
      }));

      if (window && window.Intercom) {
        window.Intercom("showNewMessage", this.searchTerm);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "algolia", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selected", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "searchTerm", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hits", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchAlgolia", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "searchAlgolia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectArticle", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectArticle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendUsAMessage", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sendUsAMessage"), _class.prototype)), _class));
  _exports.default = KbSearchContactUs;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KbSearchContactUs);
});