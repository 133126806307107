define("cc-frontend/models/analytics/planbook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPossessiveForPlanbook = getPossessiveForPlanbook;

  /**
   * The theory here is that we can specify types without having a specific type for each event
   * This might be a terrible idea, but it will take FOREVER to create all the events for each permutation
   * and I think this gets us quite a ways to consistency without having to do that.
   */
  function getPossessiveForPlanbook(findFn, userId, planbookId) {
    let planbook = findFn("planbook", planbookId);
    let user = findFn("user", userId);
    let isOwner = user ? user.id === planbook.relationships.owner.data.id : false;
    return isOwner ? "owns" : "others";
  } // date_plan_scheduled_for: Date | nul
  // course_id: string | null
  // card_stack_id: string | null
  // card_ids_edited: string[]
  // words_added: number
  // attachments_added: number
  // links_added: number
  // standards_added: number
  // comments_added: number
  // cards_added: number

});