define("cc-frontend/models/analytics/lesson", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPossessiveForLesson = getPossessiveForLesson;

  function getPossessiveForLesson(findFn, userId, ownerIds) {
    let user = findFn("user", userId);
    let isOwner = ownerIds ? (0, _lodashEs.includes)(ownerIds, userId) ? "owns" : "others" : "unknown";
    return isOwner;
  }
});