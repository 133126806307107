define("cc-frontend/lib/actions/lesson/LESSON_FIRST_CARDS_EDIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "moment", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _moment, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_FIRST_CARDS_EDIT = void 0;
  const LESSON_FIRST_CARDS_EDIT = {
    name: "LESSON_FIRST_CARDS_EDIT",

    patches(payload) {
      return [_patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.cards", (0, _lodashEs.cloneDeep)(payload.cards)).set("relationships.course.data", {
        id: payload.course.id,
        type: "course"
      }).set("relationships.planbook.data", {
        id: payload.planbook.id,
        type: "planbook"
      }).set("meta.cardsUpdatingFromTemplate", false).patch // Internal flag we use when the container-lesson sets the templates
      ];
    },

    undoPatches(payload) {
      return [_patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.cards", null).set("meta.cardsUpdatingFromTemplate", true).patch // Internal flag we use when the container-lesson sets the templates
      ];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "cards",
        adverbial_verb: null,
        adverbial_object_type: null,
        adverbial_object_specifier: null,
        adverbial_object_id: null,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: null
      };
      return {
        title: "Edit the Cards on a lesson",
        titleForAnalytics: "Lesson - Edited Cards",
        description: `Edited a lesson on ${formattedDateString} to ${(0, _lodashEs.get)(models, "course.attributes.title")}`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models, "created"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_FIRST_CARDS_EDIT = LESSON_FIRST_CARDS_EDIT;
  var _default = LESSON_FIRST_CARDS_EDIT;
  _exports.default = _default;
});