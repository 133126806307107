define("cc-frontend/components/dialogs/public-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swal2-icon swal2-success animate" style="display: block;">
    <span class="line tip animate-success-tip"></span>
    <span class="line long animate-success-long"></span>
    <div class="placeholder"></div>
    <div class="fix"></div>
  </div>
  <h1 class="create-annotation__title">
    Here's your link!
  </h1>
  <h2 class="create-annotation__description tw-select-text">
    {{!-- <a href="{{linkUrl}}" target="_blank">{{linkUrl}}</a> --}}
    <a href="{{@options.url}}" target="_blank" class="tw-break-words tw-select-text">
      {{@options.url}}
    </a>
  </h2>
  
  <div class="create-annotation__submit-buttons">
    <CopyButton
      class="create-annotation__submit-button focus on insert"
      @clipboardText={{@options.url}}
      @success={{this.showCopyToast}}
      @container="#dialog-focus-trap"
    >
      Copy Url
    </CopyButton>
  </div>
  */
  {
    "id": "XKXwkpJk",
    "block": "[[[10,0],[14,0,\"swal2-icon swal2-success animate\"],[14,5,\"display: block;\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"line tip animate-success-tip\"],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"line long animate-success-long\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"placeholder\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"fix\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Here's your link!\\n\"],[13],[1,\"\\n\"],[10,\"h2\"],[14,0,\"create-annotation__description tw-select-text\"],[12],[1,\"\\n\"],[1,\"  \"],[10,3],[15,6,[29,[[30,1,[\"url\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"tw-break-words tw-select-text\"],[12],[1,\"\\n    \"],[1,[30,1,[\"url\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"create-annotation__submit-buttons\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"create-annotation__submit-button focus on insert\"]],[[\"@clipboardText\",\"@success\",\"@container\"],[[30,1,[\"url\"]],[30,0,[\"showCopyToast\"]],\"#dialog-focus-trap\"]],[[\"default\"],[[[[1,\"\\n    Copy Url\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@options\"],false,[\"copy-button\"]]",
    "moduleName": "cc-frontend/components/dialogs/public-link.hbs",
    "isStrictMode": false
  });

  let PublicLink = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class PublicLink extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notifier", _descriptor, this);
    }

    showCopyToast() {
      this.notifier.success("", {
        title: `Your URL has been Copied!`,
        message: "Press Command(⌘) + v to paste on Mac, Control + v on Windows.",
        duration: 6000
      });
      this.accept();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifier", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showCopyToast", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showCopyToast"), _class.prototype)), _class));
  _exports.default = PublicLink;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PublicLink);
});