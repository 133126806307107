define("cc-frontend/components/planner/card-stacks/lesson/lesson-review-notice.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "review": "_review_1l72gp",
    "review-icon": "_review-icon_1l72gp",
    "review-text": "_review-text_1l72gp",
    "review-header": "_review-header_1l72gp",
    "review-subheader": "_review-subheader_1l72gp"
  };
  _exports.default = _default;
});