define("cc-frontend/lib/actions/class-website/default-class-website-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, findFn, userId) {
    return {
      classWebsite: findFn("class-website", payload.websiteId),
      planbook: findFn("planbook", payload.planbookId),
      course: payload.courseId ? findFn("course", payload.courseId) : null,
      user: findFn("user", userId)
    };
  }
});