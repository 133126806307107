define("cc-frontend/components/icons/checkmark-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-opacity=".2"
      fill-rule="evenodd"
      d="M11.268.768a11.193 11.193 0 0 0-5.467 1.864 11.25 11.25 0 0 0-4.974 8.17c-.082.743-.07 1.899.027 2.662a11.09 11.09 0 0 0 2.578 5.821c.123.145.403.441.622.659 2.457 2.444 5.904 3.64 9.302 3.228 1.717-.208 3.376-.83 4.88-1.83a11.248 11.248 0 0 0 4.936-8.145 14.55 14.55 0 0 0 .038-1.965 10.806 10.806 0 0 0-.54-2.796 11.15 11.15 0 0 0-3.962-5.45 11.358 11.358 0 0 0-5.64-2.184 15.339 15.339 0 0 0-1.8-.034Zm.18.998-.42.036c-2.559.217-5.167 1.592-6.85 3.61C2.86 6.99 2.088 8.78 1.832 10.848c-.054.437-.062 1.771-.012 2.22.107.975.318 1.852.644 2.676.953 2.402 2.707 4.292 5.057 5.446 1.115.547 2.192.86 3.447 1 .465.052 1.55.06 2.004.013a10.317 10.317 0 0 0 7.773-4.907 10.084 10.084 0 0 0 1.42-4.132c.053-.428.072-1.467.037-1.932a10.17 10.17 0 0 0-.676-3.002c-.954-2.388-2.707-4.271-5.047-5.42a9.82 9.82 0 0 0-4.344-1.044 14.141 14.141 0 0 0-.687 0Z"
    ></path>
  </svg>
  */
  {
    "id": "BonglXAM",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-opacity\",\".2\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M11.268.768a11.193 11.193 0 0 0-5.467 1.864 11.25 11.25 0 0 0-4.974 8.17c-.082.743-.07 1.899.027 2.662a11.09 11.09 0 0 0 2.578 5.821c.123.145.403.441.622.659 2.457 2.444 5.904 3.64 9.302 3.228 1.717-.208 3.376-.83 4.88-1.83a11.248 11.248 0 0 0 4.936-8.145 14.55 14.55 0 0 0 .038-1.965 10.806 10.806 0 0 0-.54-2.796 11.15 11.15 0 0 0-3.962-5.45 11.358 11.358 0 0 0-5.64-2.184 15.339 15.339 0 0 0-1.8-.034Zm.18.998-.42.036c-2.559.217-5.167 1.592-6.85 3.61C2.86 6.99 2.088 8.78 1.832 10.848c-.054.437-.062 1.771-.012 2.22.107.975.318 1.852.644 2.676.953 2.402 2.707 4.292 5.057 5.446 1.115.547 2.192.86 3.447 1 .465.052 1.55.06 2.004.013a10.317 10.317 0 0 0 7.773-4.907 10.084 10.084 0 0 0 1.42-4.132c.053-.428.072-1.467.037-1.932a10.17 10.17 0 0 0-.676-3.002c-.954-2.388-2.707-4.271-5.047-5.42a9.82 9.82 0 0 0-4.344-1.044 14.141 14.141 0 0 0-.687 0Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/checkmark-off.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});