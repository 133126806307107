define("cc-frontend/components/planner/card-stacks/cards/parts/top-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="js-card-top-part" local-class="top-part" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "ftHSZ/l+",
    "block": "[[[11,0],[16,0,[29,[\"js-card-top-part \",[28,[37,0],[\"top-part\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/cards/parts/top-part.css\"]]]]]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/top-part.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});