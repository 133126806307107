define("cc-frontend/services/store", ["exports", "@sentry/browser", "cc-frontend/app", "cc-frontend/lib/object-id-gen", "ember-concurrency", "ember-concurrency-ts", "js-cookie", "lodash-es", "cc-frontend/lib/actions/all"], function (_exports, Sentry, _app, _objectIdGen, _emberConcurrency, _emberConcurrencyTs, _jsCookie, _lodashEs, _all) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /** How often we loop through out store checking for stale documents */
  const CHECK_STALENESS_EVERY = 1000 * 60 * 10; // 10 minutes

  /** Have a lower bound of how many times we can call the fn */

  const CHECK_STALENESS_NO_MORE_THAN = 1000 * 60 * 2; // 2 minutes.

  /** At what point we ask the server to see if it has a new revision. */

  const DOCUMENT_STALE_AFTER = 1000 * 60 * 30; // 30 minutes
  // export default Service.extend(Evented, {

  let Store = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), (_class = class Store extends Ember.Service.extend(Ember.Evented) {
    /**
     * Runs immediately upon the store service being instantiated, checks the window for redux dev tools,
     * and then sets the service property REDUX_DEV_TOOLS to the redux extension
     *
     * @return undefined
     */
    constructor(...args) {
      super(...args); // @ts-ignore:

      _defineProperty(this, "sequenceNumber", 0);

      _defineProperty(this, "undoSequenceNumber", 0);

      _defineProperty(this, "lastActionOccuredAt", new Date());

      _defineProperty(this, "REDUX_DEV_TOOLS", null);

      _initializerDefineProperty(this, "finder", _descriptor, this);

      _initializerDefineProperty(this, "fastboot", _descriptor2, this);

      _initializerDefineProperty(this, "memoryEngine", _descriptor3, this);

      _initializerDefineProperty(this, "persister", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "socket", _descriptor6, this);

      _initializerDefineProperty(this, "undo", _descriptor7, this);

      if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        Ember.set(this, "REDUX_DEV_TOOLS", // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION__.connect({
          actionsBlacklist: []
        }));
      } // Create an anonymous user for analytics events, which use userId = "anonymous"
      // when the session is not logged in and error if they don't find a User with that id.


      this.insertDocument({
        id: "anonymous",
        type: "user",
        attributes: {
          firstName: null,
          lastName: null,
          email: null
        }
      });
      this.checkStalenessLoop();
    }

    checkStalenessLoop() {
      if (this.fastboot && this.fastboot.isFastBoot) return;
      setTimeout(() => {
        // We don't need to check staleness if the browser doesn't have focus. Instead,
        // we'll do that immediately if the browser gains focus via the function in
        let hasFocus = this.socket.isBlurred !== true && document.visibilityState !== "hidden";
        if (hasFocus) (0, _emberConcurrencyTs.taskFor)(this.checkStaleness).perform();
        this.checkStalenessLoop();
      }, CHECK_STALENESS_EVERY);
    }
    /**
     * This goes through and makes sure we have the most recent versions of all our documentns
     * It will run at most MAX_CHECK_TIMEOUT which I'm setting to 5 minutes initially.
     * In socket-subscriber, I'm triggering it when the tab receives focus.
     *
     * The goal is that even if our socket is new and our data is old (no idea why this happens),
     * we'll be sure to have the most recent up to date documents at
     */


    *checkStaleness(opts = {
      ignoreTimeSinceFetch: false
    }) {
      let startCheck = performance.now();
      let docsIteratedOver = 0;
      let docsThatWereChecked = 0;
      let docsThatWereStale = 0; // Initially, I don't want to check card-stack-summary as there can be thousand+ (100s per class * n classes)
      // Instead, I'd like to just check the big ones -- course, planbook card-stack, group, user, etc
      // As we see how this works, we can take out this limitation

      let typesToCheck = ["card-stack", "class-website", "course", "fiscal-group", "group", "planbook", "rotation-calendar", "user"];
      let now = new Date();
      (0, _lodashEs.forEach)(typesToCheck, type => {
        (0, _lodashEs.forEach)(this.memoryEngine.rawStore[type], (doc, id) => {
          var _doc$meta, _dateFns$parse, _doc$meta2;

          docsIteratedOver++;
          let timeSinceFetch = doc !== null && doc !== void 0 && (_doc$meta = doc.meta) !== null && _doc$meta !== void 0 && _doc$meta.requestedAt ? now.valueOf() - ((_dateFns$parse = dateFns.parse(doc === null || doc === void 0 ? void 0 : (_doc$meta2 = doc.meta) === null || _doc$meta2 === void 0 ? void 0 : _doc$meta2.requestedAt)) === null || _dateFns$parse === void 0 ? void 0 : _dateFns$parse.valueOf()) : null;

          if (!(0, _lodashEs.isNil)(timeSinceFetch) && !isNaN(timeSinceFetch) && (opts.ignoreTimeSinceFetch === true || timeSinceFetch > DOCUMENT_STALE_AFTER)) {
            docsThatWereChecked++;
            this.socket.checkStaleness(type, id).then(({
              _revision
            }) => {
              var _doc$attributes;

              if (_revision === null) return; // Refetch the document if we hae an old doc
              // Update the last update from server as we can confirm we're current

              let ourRevision = doc === null || doc === void 0 ? void 0 : (_doc$attributes = doc.attributes) === null || _doc$attributes === void 0 ? void 0 : _doc$attributes._revision;

              if (_revision > ourRevision) {
                console.log(`We have a stale document: ${type}:${id}. We have ${ourRevision}. We need ${_revision}.`);
                docsThatWereStale++;
                this.find(type, id, true);
              } else if (_revision === ourRevision) {
                // update the requestedAt as it's now as if we just got it from the server -- we know the _revision
                // is the same
                Ember.set(doc, "meta.requestedAt", now.toISOString());
              }
            });
          }
        });
      });
      let endCheck = performance.now();
      let timeTaken = endCheck - startCheck;
      console.log(`Staleness Check Complete.`);
      Ember.run.later(() => {
        console.log(`Iteration took: ${timeTaken}ms. Documents Scanned: ${docsIteratedOver}. Documents Checked: ${docsThatWereChecked}. Stale Documents: ${docsThatWereStale}`);
      }, 3000);
      yield (0, _emberConcurrency.timeout)(CHECK_STALENESS_NO_MORE_THAN);
    }
    /**
     * Safeguards against sending an unknown/undefined action. Checks if actionName exists in codebase.  If it is a known action, the corresponding
     * action object and the payload are sent through as params to dispatchAction(). If not, an error is thrown in the console.
     *
     * @param  actionName [string]
     * @param  payload    [object] - contents vary based on action
     * @return actionDoc [object] - See ActionDoc interface below for keys/values
     */


    dispatch(actionName, payload) {
      let action = _all.default[actionName];
      if (action === undefined) console.error(`${actionName} does not exist`, actionName);
      action.name = actionName;
      return this.dispatchAction(action, payload);
    }
    /**
     * This function will be called from components/helpers/services as the first step to changing/updating/interacting with the backend.
     * Assembles object, offering the option of null values for patches/undoPatches, and narrative that is passed
     * onto dispatchPreparedAction (see below).
     *
     * @param  action  [object] with keys name, payload/params, patches, undoPatches, and narrative. Exisitng actions can be found in app/lib/actions
     * @param  payload [object] - contents vary based on action - generally includes an id of the object/document to change in db
     * @return actionDoc [object] - See ActionDoc interface below for keys/values
     */


    dispatchAction(action, payload) {
      return this.dispatchPreparedAction({
        name: action.name,
        payload: payload,
        patches: action.patches ? action.patches(payload) : null,
        undoPatches: action.undoPatches ? action.undoPatches(payload) : null,
        narrative: action.narrative ? (0, _lodashEs.partial)(action.narrative, payload) : null
      });
    }
    /**
     * Prepares action object with all necessary information to be sent to the backend.
     * This is the launchpad for sending actions from the frontend to the backend. Sends
     * Sentry information (breadcrumb) to track user actions and make debugging easier in the event of an error.
     * Puts relevant info as params into generateActionDoc() to begin documenting/logging the action.
     * With the returned actionDoc from the generate function, patches are applied (see function below for more info),
     * sends actionDoc to the backend through a socket with the persister service, if there are no matching patches,
     * triggers a named event for an action and passes the action name, payload, and action doc to functions listening for this action.
     * Sends the action payload to the memoryEngine for storage in local memory
     *
     * @param  preparedAction object with important information from the actionObject
     * @return  actionDoc [object] - see interface below for keys/values
     */


    dispatchPreparedAction(preparedAction) {
      Sentry.addBreadcrumb({
        message: preparedAction.name,
        category: "beforeAction"
      });
      Sentry.setContext("Prepared Action", {
        payload: preparedAction.payload,
        patches: "here"
      });
      this.sequenceNumber++;

      let actionId = _objectIdGen.default.create();

      let userId = this.session.userId || "anonymous";
      let sessionId = this.session.id || "anonymous";
      let patches = timestampPatches(preparedAction.patches, actionId);
      let narrative = preparedAction.narrative ? preparedAction.narrative(this.findInMemory.bind(this), userId) : null;
      let actionDoc = generateActionDoc(actionId, preparedAction.name, patches, narrative, userId, sessionId, this.session.fiscalGroupId, this.sequenceNumber, // @ts-ignore
      Math.floor((new Date() - this.lastActionOccuredAt) / 1000) // seconds since last action
      );
      this.undo.addWithPatches(actionId, preparedAction.undoPatches, narrative);
      this.applyPatches(actionDoc);
      this.persister.persist(actionDoc);
      this.trigger("action", preparedAction.name, preparedAction.payload, actionDoc);
      Sentry.setContext("Prepared Action", null); // @ts-ignore:

      if (Ember.get(this, "REDUX_DEV_TOOLS") && window.__ENABLE_REDUX_DEVTOOLS__ === true) {
        // @ts-ignore
        Ember.get(this, "REDUX_DEV_TOOLS").send({
          type: name,
          payload: preparedAction.payload
        }, // @ts-ignore
        Ember.get(this, "memoryEngine.__store__"));
      }

      return actionDoc;
    }
    /**
     * Checks for patches and begins a runLoop, loops through the patches and if the patch has no id, throws an error.
     * Checks if the patch is in the store of the memoryEngine and if it is, sets the revisionAtCreation key on the patch
     * to the value of the _revision key in the returned document.  Ends runLoop.
     *
     * @param actionDoc [object] - see interface below for keys/values
     * @return undefined
     */


    applyPatches(actionDoc) {
      if (actionDoc.attributes.patches && actionDoc.attributes.patches.length > 0) {
        // this function is commented out in memoryEngine - do we still need this line?
        Ember.get(this, "memoryEngine").checkpoint();
        Ember.run.begin();
        actionDoc.attributes.patches.forEach(patch => {
          if (Ember.isNone(patch.document.id)) {
            console.log("Problematic Patch", patch);
            throw new Error("Invalid Patch -- no id");
          }

          if (this.applyPatchToStore(patch)) {
            // MUTATE patch. Probably a better way to do this.
            patch.revisionAtCreation = Ember.get(this.findInMemory(patch.document.modelType, patch.document.id), // @ts-ignore
            "attributes._revision");
          }
        });
        Ember.run.end();
      }
    }
    /**
     * Checks the memoryEngine to see if the desired model is already there, returns if it is,
     * if not, use finder service to ask backend to send requested model here.
     *
     * @param  modelName    [string]
     * @param  id           [string]
     * @param  forceRefresh [boolean - optional]
     * @return              [Promise Object] - as it waits for the finder to access the requested model
     */


    find(modelName, id, forceRefresh) {
      let model = Ember.get(this, "memoryEngine").find(modelName, id);
      Ember.run.later(this, () => Ember.get(this, "socket").subscribe(modelName, id), 1000); // if (model) return new Ember.RSVP.Promise((resolve, _reject) => resolve(model))

      if (model && forceRefresh !== true) return Ember.RSVP.resolve(model);
      return Ember.get(this, "finder").find(modelName, id);
    }
    /**
     * Checks to see if desired model is in memory
     *
     * @param  modelName [description]
     * @param  id        [description]
     * @return           [object/undefined] requested model, if available
     */


    findInMemory(modelName, id) {
      return Ember.get(this, "memoryEngine").find(modelName, id);
    }
    /**
     * Resets the in-memory store using the memoryEngine service which resets its store, history, and undo/redo list to empty arrays
     *
     * @return undefined
     */


    clearMemory() {
      return Ember.get(this, "memoryEngine").reset();
    }
    /**
     * Checks to see if the patch is in the store of the memoryEngine. If the patch there and it says a patch was created returns false
     * if not, make a patch in the memoryEngine and returns true.  Dictates internal revision count of patch (see applyPatches).
     * Triggers a named event "patch" so any other listening functions can use the path parameter for its own use.
     *
     * @param  patch [description]
     * @return       [boolean]
     */


    applyPatchToStore(patch) {
      let model = Ember.get(this, "memoryEngine").find(patch.document.modelType, patch.document.id); // This means it's a patch we created for a document we're not looking at,
      // so we can ignore it intead of applying it to something that doesn't exist

      if (Ember.isNone(model) && patch.isCreatePatch !== true) return false;
      Ember.get(this, "memoryEngine").patch(patch);
      this.trigger("patch", patch);
      return true;
    }

    applyForeignPatchToStore(patch) {
      // Check if the patch upgrades the document to a version larger than ours
      // Check that it's not our own patch applied to the document we have
      // We would want to apply our own patch if we're getting off the subway
      // and our patches are pushed up to the server. But first, a colleagues
      // changes are pushed down. Then, we want to check that
      // So, I think if the version we have was updated by our session
      // AND the patch we're applying was also updated by our session, we can ignore
      // it. If the document was last updated by a different session and we have
      // a patch from our session, we want to apply it...unless that's the one we just wrote
      //
      // We want to ignore our patches all the time unless
      // it follows a patch we didn't have when we wrote it. That means something was injected
      //
      // So, a patch has a version it was written against. We go, "oh, our patch.
      // Which version was it written against?" And then we're like, "Oh, it was written
      // against version 26. Well, which version do we have? We have version 26. So, we can ignore this
      // because it's a version we've applied already"
      // A situation might arise where we're like, "our patch and written against version 26.
      // We have version 36. Wow. That means that there were 10 other changes that applied. WE need to apply
      // this again since it's being applied against a different base."
      //
      let needsMisingPatch = false;

      if (needsMisingPatch) {
        // this is not a method on the service
        // why is this a possible code path if it will never get here?
        Ember.get(this, "persister").requestPatch();
      } else {
        Ember.get(this, "memoryEngine").patch(patch);
        this.trigger("foreign-patch", patch);
      }
    }
    /**
     * Updates exisitng document in memoryEngine store or makes a new one
     * @param  doc [object] - document object
     * @return     [object] - updated document object if it already exists, otherwise, just the document
     */


    insertDocument(doc) {
      // I had to do this because things were failing in tests
      if (doc.meta === undefined) {
        Ember.set(doc, "meta", {});
      }

      Ember.set(doc, "meta.lastUpdateFromServer", new Date());
      Ember.get(this, "memoryEngine").insert(doc);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "memoryEngine", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "persister", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "undo", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkStaleness", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "checkStaleness"), _class.prototype)), _class));
  _exports.default = Store;

  function timestampPatches(patches, actionId) {
    return (0, _lodashEs.map)(patches, patch => {
      patch.id = _objectIdGen.default.create();
      patch.actionId = actionId; // @ts-ignore:

      patch.timeAtCreation = dateFns.format(new Date());
      patch.revisionAtCreation = null;
      return patch;
    });
  }

  function generateActionDoc(id, actionName, patches, _narrative, userId, sessionId, fiscalGroupId, sequenceNumber, secondsSinceLastClientAction) {
    let sessionUrl = (0, _app.generateFullStorySessionUrl)(true);
    let narrative = _narrative || {
      context: {}
    };
    narrative.context = (0, _lodashEs.assign)({}, narrative.context, {
      currentUrl: window.location.href,
      fbp: _jsCookie.default.get("_fbp"),
      fbc: _jsCookie.default.get("_fbc"),
      groupId: fiscalGroupId
    });
    narrative.url = narrative.url || document.location.href; // This is a hacky way of doing it, for sure.
    // If it doesn't have patches, it's just an analytics style action.
    // if it has the word "INTERMEDIATE", that's one of those actions as someone is typing

    let isUserFacing = patches && patches.length > 0 && !(0, _lodashEs.includes)(actionName, "INTERMEDIATE");
    return {
      id: id,
      type: "action",
      attributes: {
        name: actionName,
        patches: patches,
        narrative: narrative,
        attemptCount: 0,
        sync: {
          status: "waiting",
          failureReason: null,
          hasFailed: false,
          attemptCount: 0,
          failureCount: 0,
          retryAt: null,
          isUserFacing: isUserFacing
        },
        timing: {
          // @ts-ignore:
          clientCreatedAt: dateFns.format(new Date()),
          clientSentAt: null,
          serverReceivedAt: null,
          secondsSinceLastClientAction: secondsSinceLastClientAction,
          fullStorySessionUrl: sessionUrl
        },
        order: {
          amongAllClientActions: sequenceNumber,
          amongPublishedClientActions: null,
          followsPublishedActionId: null
        }
      },
      relationships: {
        user: {
          data: {
            id: userId,
            type: "user"
          }
        },
        session: {
          data: {
            id: sessionId,
            type: "session"
          }
        }
      }
    };
  }
});