define("cc-frontend/components/user/onboarding/existing-planbooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-flex tw-flex-col tw-w-full tw-px-10 tw-pb-12">
    <h1 div local-class="user-sign__title">
      Great! You’ve already been invited to a planbook. You can open it now or create your own.
    </h1>
  
    <div class="tw-px-4 tw-pt-8 tw-pb-4 tw-bg-gray-100 tw-rounded-b-lg tw-mt-8">
      {{#each @planbookSummaries key="id" as |planbookSummary|}}
        <span
          class="planbook-pill test-planbook-pill-link tw-cursor-pointer"
          {{on "click" (fn @goToPlanbook planbookSummary.id)}}
        >
          <Planner::Planbook::Pill @planbookId={{planbookSummary.id}} />
        </span>
      {{/each}}
    </div>
  
    <User::Onboarding::NextButton @onClick={{@next}}>Create my own</User::Onboarding::NextButton>
  </div>
  */
  {
    "id": "q8ak5WC+",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-col tw-w-full tw-px-10 tw-pb-12\"],[12],[1,\"\\n  \"],[10,\"h1\"],[15,0,[29,[[28,[37,0],[\"user-sign__title\"],[[\"from\"],[\"cc-frontend/components/user/onboarding/existing-planbooks.css\"]]]]]],[14,\"div\",\"\"],[12],[1,\"\\n    Great! You’ve already been invited to a planbook. You can open it now or create your own.\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"tw-px-4 tw-pt-8 tw-pb-4 tw-bg-gray-100 tw-rounded-b-lg tw-mt-8\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],\"id\",[[[1,\"      \"],[11,1],[24,0,\"planbook-pill test-planbook-pill-link tw-cursor-pointer\"],[4,[38,3],[\"click\",[28,[37,4],[[30,3],[30,2,[\"id\"]]],null]],null],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@planbookId\"],[[30,2,[\"id\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[8,[39,6],null,[[\"@onClick\"],[[30,4]]],[[\"default\"],[[[[1,\"Create my own\"]],[]]]]],[1,\"\\n\"],[13]],[\"@planbookSummaries\",\"planbookSummary\",\"@goToPlanbook\",\"@next\"],false,[\"local-class\",\"each\",\"-track-array\",\"on\",\"fn\",\"planner/planbook/pill\",\"user/onboarding/next-button\"]]",
    "moduleName": "cc-frontend/components/user/onboarding/existing-planbooks.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});