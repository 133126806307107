define("cc-frontend/components/group/dashboard/lessons/lesson.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-detail-view-wrapper": "_lesson-detail-view-wrapper_1nke8p",
    "lesson-detail-date": "_lesson-detail-date_1nke8p",
    "lesson-detail-close-button": "_lesson-detail-close-button_1nke8p"
  };
  _exports.default = _default;
});