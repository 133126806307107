define("cc-frontend/services/routine-editor", ["exports", "ember-concurrency", "lodash", "cc-frontend/lib/reorder-positioned-list"], function (_exports, _emberConcurrency, _lodash, _reorderPositionedList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RoutineEditor = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), (_class = class RoutineEditor extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intermediateUpdate", _descriptor2, this);
    }

    editCardValue(modelsNeededForFirstEdit, cardId, value) {
      this._checkForRoutineFirstEdit(modelsNeededForFirstEdit.planbook, modelsNeededForFirstEdit.routine, modelsNeededForFirstEdit.dateString); // @ts-ignore


      this.intermediateUpdate.perform({
        planbookId: modelsNeededForFirstEdit.planbook.id,
        cardStackId: modelsNeededForFirstEdit.routine.id,
        cardId: cardId,
        value: value
      });
    }

    finishEditingCardValue(modelsNeededForFirstEdit, cardId, value, originalValue) {
      // @ts-ignore
      this.intermediateUpdate.cancelAll();

      this._checkForRoutineFirstEdit(modelsNeededForFirstEdit.planbook, modelsNeededForFirstEdit.routine, modelsNeededForFirstEdit.dateString);

      this.store.dispatch("ROUTINE_UPDATE_CARD_VALUE", {
        cardStackId: modelsNeededForFirstEdit.routine.id,
        planbookId: modelsNeededForFirstEdit.planbook.id,
        cardId: cardId,
        value: value,
        originalValue: originalValue
      });
    }

    removeCard(modelsNeededForFirstEdit, card) {
      this._checkForRoutineFirstEdit(modelsNeededForFirstEdit.planbook, modelsNeededForFirstEdit.routine, modelsNeededForFirstEdit.dateString);

      this.store.dispatch("ROUTINE_REMOVE_SECTION", {
        cardStackId: modelsNeededForFirstEdit.routine.id,
        planbookId: modelsNeededForFirstEdit.planbook.id,
        card: card
      });
    }
    /**
     * Reorder the cards in a routine.
     * TODO this should be combined with the lessonEditor._moveWithinLesson
     * as most of it is doing the same thing. I'd probably extract the middle part to a function
     * that returns positionDiffs gien the oldList, newList, and movedItem
     */


    reorderCards(planbook, routine, dateString, newList, movedItem) {
      // We get a ton of false positives, so find out if the order changes
      // kinda odd, but we use _.min to find out if there's a false as
      // in a list of booleans, false is lower, so
      let existingCards = _lodash.default.sortBy(Ember.get(routine, "attributes.cards"), "attributes.position");

      let thingsHaveNotMoved = _lodash.default.min(_lodash.default.map(newList, (card, index) => {
        return existingCards[index] && existingCards[index].id === card.id;
      }));

      if (thingsHaveNotMoved) return; // Remove old section

      let newSections = _lodash.default.map(newList, card => {
        if (card.id === movedItem.id) {
          let movingSection = _lodash.default.cloneDeep(card);

          movingSection.attributes.position = null;
          return movingSection;
        } else {
          return card;
        }
      });

      let newCardPositions = (0, _reorderPositionedList.default)(newSections);

      let positionDiffs = _lodash.default.reject(newCardPositions, tuple => {
        let section = _lodash.default.find(newSections, {
          id: tuple[0]
        });

        return section.attributes.position === tuple[1];
      });

      this._checkForRoutineFirstEdit(planbook, routine, dateString);

      Ember.get(this, "store").dispatch("ROUTINE_REORDER_SECTIONS", {
        cardStackId: routine.id,
        positionDiffs: positionDiffs,
        sectionBeingMoved: movedItem,
        planbookId: planbook.id
      });
    }

    /**
     * Check if the routine was generated from a template and we need to create it.
     * @param  {Planbook} planbook
     * @param  {CardStack} routine
     * @return undefined
     */
    _checkForRoutineFirstEdit(planbook, routine, dateString) {
      let planbookDate = _lodash.default.chain(planbook.attributes.calendar.dates).find(date => date.attributes.cardStackId === routine.id).value();

      if (Ember.isNone(planbookDate) || Ember.isNone(planbookDate.attributes.cardStackId)) {
        Ember.get(this, "store").dispatch("ROUTINE_FIRST_EDIT", {
          planbook: planbook,
          dateString: dateString,
          cardStackId: routine.id,
          cards: routine.attributes.cards
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intermediateUpdate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* ({
        cardStackId,
        planbookId,
        cardId,
        value
      }) {
        this.store.dispatch("ROUTINE_INTERMEDIATE_UPDATE_CARD_VALUE", {
          cardStackId: cardStackId,
          planbookId: planbookId,
          cardId: cardId,
          value: value
        });
        yield (0, _emberConcurrency.timeout)(750);
      };
    }
  })), _class));
  _exports.default = RoutineEditor;
});