define("cc-frontend/components/planner/card-stacks/cards/parts/attachments.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "list": "_list_14os1r",
    "item": "_item_14os1r",
    "left-side": "_left-side_14os1r",
    "right-side": "_right-side_14os1r",
    "item-title": "_item-title_14os1r",
    "icon": "_icon_14os1r",
    "remove-attachment": "_remove-attachment_14os1r"
  };
  _exports.default = _default;
});