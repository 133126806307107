define("cc-frontend/components/planner/card-stacks/lesson/lesson-title.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-block__move-dots": "_lesson-block__move-dots_1m6x9u",
    "lesson-block__text": "_lesson-block__text_1m6x9u",
    "lesson-block__text__lesson-title-row": "_lesson-block__text__lesson-title-row_1m6x9u",
    "lesson-block__text__lesson-title-row__title": "_lesson-block__text__lesson-title-row__title_1m6x9u"
  };
  _exports.default = _default;
});