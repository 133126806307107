define("cc-frontend/templates/admin/csp/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "POnw2IeK",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-row tw-h-full\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"tw-overflow-scroll\"]],[[\"@jurisdictionId\"],[[30,1,[\"id\"]]]],null],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13]],[\"@model\"],false,[\"admin/csp/jurisdiction\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/admin/csp/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});