define("cc-frontend/lib/actions/lesson/GC_SETTINGS_UPDATE", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareGCSettingsUpdate;

  function prepareGCSettingsUpdate(payload) {
    return {
      name: "GC_SETTINGS_UPDATE",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.googleCardId
    }).set("embedded", `attributes.classSettings`, payload.updatedClassSettings).inc("embedded", `attributes._revision`, 1).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "google_classroom",
      adverbial_verb: "editing",
      adverbial_object_type: "card",
      adverbial_object_specifier: "gc_time",
      adverbial_object_id: payload.googleCardId,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return {
      title: `Changed GC Post ${payload.prop}`,
      titleForAnalytics: "Lesson Card - GC Post - Set Time",
      description: `Updated ${payload.prop} in GC class settings`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});