define("cc-frontend/models/card-stack", ["exports", "cc-frontend/lib/object-id-gen"], function (_exports, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateBlankTemplateAttributes = generateBlankTemplateAttributes;
  _exports.generateBlankLessonAttributes = generateBlankLessonAttributes;
  _exports.CARD_COLORS = void 0;
  const CARD_COLORS = ["yellow", "orange", "red", "magenta", "purple", "navy", "blue", "teal", "green"];
  _exports.CARD_COLORS = CARD_COLORS;

  function generateBlankTemplateAttributes() {
    return {
      title: "My new template",
      subtitle: "",
      usedAs: "lesson-template",
      version: 0,
      parentCardStack: null,
      versionNarrative: {},
      reviews: [],
      _revision: 0,
      _revisedBySessionId: "system",
      cards: [{
        id: _objectIdGen.default.create(),
        type: "card-standards",
        attributes: {
          position: 0,
          title: "Standards",
          standards: [],
          attachments: [],
          comments: [],
          color: "yellow",
          isPublic: false,
          parentCardId: null
        }
      }]
    };
  }

  function generateBlankLessonAttributes() {
    return {
      title: "My new lesson",
      subtitle: "",
      usedAs: "lesson",
      version: 0,
      parentCardStack: null,
      versionNarrative: {},
      _revision: 0,
      reviews: [],
      _revisedBySessionId: "system",
      cards: []
    };
  }
});