define("cc-frontend/components/planner/card-stacks/cards/card.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-sections-sortable": "_lesson-sections-sortable_1m4kwa",
    "lesson-card-wrapper": "_lesson-card-wrapper_1m4kwa",
    "lesson-card": "_lesson-card_1m4kwa",
    "--is-hovering": "_--is-hovering_1m4kwa",
    "--is-mobile": "_--is-mobile_1m4kwa",
    "slide-out": "_slide-out_1m4kwa"
  };
  _exports.default = _default;
});