define("cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_UPDATE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/actions/planbook/date/default-planbook-date-activity", "cc-frontend/lib/actions/planbook/date/default-planbook-date-context", "cc-frontend/lib/actions/planbook/date/default-planbook-date-models", "moment"], function (_exports, _patchUpdater, _lodash, _datesOffCalculator, _defaultPlanbookDateActivity, _defaultPlanbookDateContext, _defaultPlanbookDateModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_DATE_ANNOTATION_UPDATE = void 0;
  const PLANBOOK_DATE_ANNOTATION_UPDATE = {
    name: "PLANBOOK_DATE_ANNOTATION_UPDATE",
    params: {
      annotation: "object",
      planbook: "object"
    },

    patches(payload) {
      let newAnnotations = _lodash.default.map(payload.planbook.attributes.calendar.dateAnnotations, annotation => {
        return annotation.id === payload.annotation.id ? payload.annotation : annotation;
      });

      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(newAnnotations);

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id, "attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("embedded", `attributes`, payload.annotation.attributes).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    undoPatch(payload) {
      let oldAnnotations = JSON.parse(JSON.stringify(payload.planbook.attributes.calendar.dateAnnotations));
      let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(oldAnnotations);

      let oldAnnotation = _lodash.default.find(oldAnnotations, annotation => {
        return annotation.id === payload.annotation.id;
      });

      let patch = _patchUpdater.default.create("planbook", payload.planbook.id, "attributes.calendar.dateAnnotations", {
        id: payload.annotation.id
      }).set("embedded", `attributes`, oldAnnotation.attributes).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookDateModels.default)(payload, findFn, userId, payload.planbook.id);
      let context = (0, _defaultPlanbookDateContext.default)(payload, models);
      let weekdayTitle = Ember.get(models, "rotation.attributes.title");

      let oldAnnotation = _lodash.default.find(payload.planbook.attributes.calendar.dateAnnotations, annotation => {
        return annotation.id === payload.annotation.id;
      });

      let dateStringOld = oldAnnotation.type === "date-annotation-planbook-custom-single" ? oldAnnotation.attributes.dateString : null;
      let startDateOld = oldAnnotation.type === "date-annotation-planbook-custom-range" ? oldAnnotation.attributes.startDate : null;
      let endDateOld = oldAnnotation.type === "date-annotation-planbook-custom-range" ? oldAnnotation.attributes.endDate : null;
      let rotationOld = oldAnnotation.type === "date-annotation-planbook-default" ? findFn("rotation", oldAnnotation.attributes.rotationId) : null;
      let weekdayTitleOld = rotationOld ? Ember.get(rotationOld, "attributes.title") : null;
      let titleOld = oldAnnotation.attributes.title;
      let descriptionOld = oldAnnotation.attributes.description;
      let isOffOld = oldAnnotation.attributes.isOff;

      let newContext = _lodash.default.assign({}, context, {
        annotationId: payload.annotation.id,
        dateStringOld,
        startDateOld,
        endDateOld,
        titleOld,
        isOffOld,
        weekdayTitleOld
      });

      let formattedDateString = (0, _moment.default)(Ember.get(context, "dateString")).format("ddd, MMM. D, YYYY");
      let formattedStartDate = (0, _moment.default)(Ember.get(context, "startDate")).format("ddd, MMM. D, YYYY");
      let formattedEndDate = (0, _moment.default)(Ember.get(context, "endDate")).format("ddd, MMM. D, YYYY");
      let formattedDateStringOld = (0, _moment.default)(dateStringOld).format("ddd, MMM. D, YYYY");
      let formattedStartDateOld = (0, _moment.default)(startDateOld).format("ddd, MMM. D, YYYY");
      let formattedEndDateOld = (0, _moment.default)(endDateOld).format("ddd, MMM. D, YYYY");

      var noteUpdates = function () {
        let hasNewTitle = titleOld !== payload.annotation.attributes.title;
        let hasNewDescription = descriptionOld !== payload.annotation.attributes.description;

        switch (context.type) {
          case "custom.single":
            return hasNewTitle ? hasNewDescription ? `Updated title and description of note on ${formattedDateStringOld}.` : `Updated title of note on ${formattedDateStringOld}.` : hasNewDescription ? `Updated description of note on ${formattedDateStringOld}.` : "";

          case "custom.range":
            return hasNewTitle ? hasNewDescription ? `Updated title and description of note running from ${formattedStartDateOld} to ${formattedEndDateOld}.` : `Updated title of note running from ${formattedStartDateOld} to ${formattedEndDateOld}.` : hasNewDescription ? `Updated description of note running from ${formattedStartDateOld} to ${formattedEndDateOld}.` : "";

          case "default":
            return hasNewTitle ? hasNewDescription ? `Updated title and description of note on ${weekdayTitleOld}.` : `Updated title of note on ${weekdayTitleOld}.` : hasNewDescription ? `Updated description of note on ${weekdayTitleOld}.` : "";
        }
      }();

      var dateUpdates = function () {
        switch (context.type) {
          case "custom.single":
            return dateStringOld !== Ember.get(context, "dateString") ? `Moved note from ${formattedDateStringOld} to ${formattedDateString}` : "";

          case "custom.range":
            return startDateOld !== Ember.get(context, "startDate") || endDateOld !== Ember.get(context, "endDate") ? `Set note to show on every day from ${formattedStartDate} to ${formattedEndDate}.` : "";

          case "default":
            return weekdayTitleOld !== weekdayTitle ? `Set note to appear every ${weekdayTitle}.` : "";
        }
      }();

      var isOffUpdates = function () {
        if (isOffOld === payload.annotation.attributes.isOff) return "";
        let planbookDatesOff = Ember.get(models, "planbook.attributes.calendar.datesOff");

        let turnedOff = _lodash.default.includes(planbookDatesOff, (0, _datesOffCalculator.findPlanbookDatesOff)([payload.annotation]));

        switch (context.type) {
          case "custom.single":
            return turnedOff ? `Turned off all classes on ${formattedDateString}.` : `Turned all classes on ${formattedDateString} back on.`;

          case "custom.range":
            return turnedOff ? `Turned off all classes on every day from ${formattedStartDate} to ${formattedEndDate}.` : `Turned all classes on every day from ${formattedStartDate} to ${formattedEndDate} back on.`;

          case "default":
            return turnedOff ? `Turned off all classes on every ${weekdayTitle}.` : `Turned all classes on every ${weekdayTitle} back on.`;
        }
      }();

      let analyticsModifier = payload.annotation.type === "date-annotation-planbook-custom-single" ? "Single" : payload.annotation.type === "date-annotation-planbook-custom-range" ? "Range" : "Default";
      return {
        title: "Edited A Date",
        titleForAnalytics: `Planbook Date - Update Annotation - ${analyticsModifier}`,
        description: `${noteUpdates} ${dateUpdates} ${isOffUpdates}`,
        context: newContext,
        activity: (0, _defaultPlanbookDateActivity.default)(payload, models)
      };
    }

  };
  _exports.PLANBOOK_DATE_ANNOTATION_UPDATE = PLANBOOK_DATE_ANNOTATION_UPDATE;
  var _default = PLANBOOK_DATE_ANNOTATION_UPDATE;
  _exports.default = _default;
});