define("cc-frontend/lib/actions/class-website/default-class-website-context", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultClassWebsiteContext;

  function defaultClassWebsiteContext(payload, models) {
    // We use get as some of these values might be null/undefined and we want to not break
    let websiteSlug = (0, _lodashEs.get)(models, "classWebsite.attributes.slug");
    return {
      planbookTitle: (0, _lodashEs.get)(models, "planbook.attributes.title"),
      websiteId: (0, _lodashEs.get)(payload, "websiteId"),
      websiteURL: websiteSlug ? `www.commoncurriculum.com/sites/${websiteSlug}` : null
    };
  }
});