define("cc-frontend/controllers/planbookv5", ["exports", "cc-frontend/app", "cc-frontend/helpers/belongs-to", "cc-frontend/lib/actions/planbook/PLANBOOK_UNDELETE", "lodash", "moment"], function (_exports, _app, _belongsTo, _PLANBOOK_UNDELETE, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Planbookv5Controller = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = (0, _belongsTo.default)("model.planbook", "owner"), _dec8 = Ember.computed("router.currentRouteName"), _dec9 = Ember.computed("router.currentRouteName"), _dec10 = Ember.computed("router.currentRouteName"), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class Planbookv5Controller extends Ember.Controller {
    get isInPlannerRoute() {
      return _lodash.default.includes(Ember.get(this, "router.currentRouteName"), "planbookv5.planner");
    }

    get isInLinkShareableRoute() {
      const linkShareableRoutes = ["planbookv5.planner.day", "planbookv5.planner.week", "planbookv5.planner.month", "planbookv5.planner.timeline", "planbookv5.planner.outline", "planbookv5.standards-tracker"];
      return _lodash.default.includes(linkShareableRoutes, Ember.get(this, "router.currentRouteName"));
    }

    get isInSetupRoute() {
      return _lodash.default.includes(Ember.get(this, "router.currentRouteName"), "planbook.setup");
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "dialog", _descriptor4, this);

      _initializerDefineProperty(this, "rpc", _descriptor5, this);

      _initializerDefineProperty(this, "windowscroll", _descriptor6, this);

      _initializerDefineProperty(this, "owner", _descriptor7, this);

      _defineProperty(this, "queryParams", ["hideNav"]);

      if (window) {
        window.CCIMPERSONATE_PLANBOOK = () => {
          Ember.get(this, "session").impersonateUserId(Ember.get(this, "model.planbook.relationships.owner.data.id"), window.location.href);
        };
      }
    }

    deletePlanbook() {
      Ember.get(this, "dialog").blank("dialogs/confirm-delete-planbook", {
        className: "dialog--create-annotation"
      }).then(() => {
        Ember.get(this, "rpc").execute("PLANBOOK_DELETE", Ember.get(this, "model.planbook.id")).then(() => {
          window.location.href = "/users/" + Ember.get(this, "owner.id"); // Ember.getOwner(this).lookup('router:main').transitionTo('user.show.index', get(this, "owner.id"))
        });
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

    signInOrUp(e) {
      console.log("router, this.router", this.get("router"), this.router.currentURL);
      e.preventDefault();
      this.router.transitionTo("user.sign", {
        queryParams: {
          afterSignInRedirectTo: this.router.currentURL
        }
      });
    }

    undeletePlanbook(planbook) {
      Ember.get(this, "store").dispatchAction(_PLANBOOK_UNDELETE.default, {
        planbookId: planbook.id,
        userId: planbook.relationships.owner.data.id,
        timestamp: (0, _moment.default)().format()
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "windowscroll", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isInPlannerRoute", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isInPlannerRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInLinkShareableRoute", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isInLinkShareableRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInSetupRoute", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isInSetupRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deletePlanbook", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "deletePlanbook"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "signInOrUp", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "signInOrUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "undeletePlanbook", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "undeletePlanbook"), _class.prototype)), _class));
  _exports.default = Planbookv5Controller;
});