define("cc-frontend/lib/actions/app/CREATE_CLASS_GO_TO_FINAL_SCREEN", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createClassGoToFinalScreenAction;

  function createClassGoToFinalScreenAction(payload) {
    return {
      name: "CREATE_CLASS_GO_TO_FINAL_SCREEN",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: null,
      root_type: "app",
      date_plan_scheduled_for: null,
      primary_verb: "use",
      primary_possessive: "owns",
      primary_object_type: "app",
      primary_object_id: "commoncurriculum.com",
      primary_object_specifier: "course_create",
      primary_object_id_and_ancestor_ids: ["commoncurriculum.com"],
      adverbial_verb: "navigating",
      adverbial_object_type: "screen",
      adverbial_object_id: "final_screen",
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: ["commoncurriculum.com"]
    };
    return (0, _createNarrative.default)({
      title: "Final Screen",
      description: `Creating a class from the Planner`,
      titleForAnalytics: "Create Class - Go To Final Screen",
      newAnalyticsEvents: [newEvent]
    });
  }
});