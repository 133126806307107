define("cc-frontend/components/icons/message-bubble-highlighted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Messages-Bubble--Streamline-Ultimate"
  ><desc>Messages Bubble Streamline Icon: https://streamlinehq.com</desc><defs></defs><path
      d="M12 1C5.649 1 0.5 5.253 0.5 10.5a8.738 8.738 0 0 0 3.4 6.741L1.5 23l6.372 -3.641A13.608 13.608 0 0 0 12 20c6.351 0 11.5 -4.253 11.5 -9.5S18.351 1 12 1Z"
      fill="#fef992"
      stroke-width="1"
    ></path><path
      d="M12 5a14.371 14.371 0 0 1 11.5 5.265C23.346 5.127 18.256 1 12 1S0.654 5.127 0.5 10.265A14.371 14.371 0 0 1 12 5Z"
      fill="#fef9929c"
      stroke-width="1"
    ></path><path
      d="M12 1C5.649 1 0.5 5.253 0.5 10.5a8.738 8.738 0 0 0 3.4 6.741L1.5 23l6.372 -3.641A13.608 13.608 0 0 0 12 20c6.351 0 11.5 -4.253 11.5 -9.5S18.351 1 12 1Z"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path></svg>
  */
  {
    "id": "UA4CBBHg",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[14,1,\"Messages-Bubble--Streamline-Ultimate\"],[12],[10,\"desc\"],[12],[1,\"Messages Bubble Streamline Icon: https://streamlinehq.com\"],[13],[10,\"defs\"],[12],[13],[10,\"path\"],[14,\"d\",\"M12 1C5.649 1 0.5 5.253 0.5 10.5a8.738 8.738 0 0 0 3.4 6.741L1.5 23l6.372 -3.641A13.608 13.608 0 0 0 12 20c6.351 0 11.5 -4.253 11.5 -9.5S18.351 1 12 1Z\"],[14,\"fill\",\"#fef992\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M12 5a14.371 14.371 0 0 1 11.5 5.265C23.346 5.127 18.256 1 12 1S0.654 5.127 0.5 10.265A14.371 14.371 0 0 1 12 5Z\"],[14,\"fill\",\"#fef9929c\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M12 1C5.649 1 0.5 5.253 0.5 10.5a8.738 8.738 0 0 0 3.4 6.741L1.5 23l6.372 -3.641A13.608 13.608 0 0 0 12 20c6.351 0 11.5 -4.253 11.5 -9.5S18.351 1 12 1Z\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/message-bubble-highlighted.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});