define("cc-frontend/components/icons/trash-can-higlighted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="Bin-1--Streamline-Ultimate"><desc
    >Bin 1 Streamline Icon: https://streamlinehq.com</desc><defs></defs><path
      d="m21 4.5 -1.812 17.209A2 2 0 0 1 17.2 23.5H6.8a2 2 0 0 1 -1.989 -1.791L3 4.5Z"
      fill="#fef9929c"
      stroke-width="1"
    ></path><path
      d="m20.684 7.5 0.316 -3 -18 0 0.316 3 17.368 0z"
      fill="#fef992"
      stroke-width="1"
    ></path><path
      d="m21 4.5 -1.812 17.209A2 2 0 0 1 17.2 23.5H6.8a2 2 0 0 1 -1.989 -1.791L3 4.5Z"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path><path
      d="m0.5 4.5 23 0"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path><path
      d="M7.5 4.5v-3a1 1 0 0 1 1 -1h7a1 1 0 0 1 1 1v3"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path><path
      d="m12 9 0 10.5"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path><path
      d="M16.5 9 16 19.5"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path><path
      d="M7.5 9 8 19.5"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path></svg>
  */
  {
    "id": "dHP2E1h+",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[14,1,\"Bin-1--Streamline-Ultimate\"],[12],[10,\"desc\"],[12],[1,\"Bin 1 Streamline Icon: https://streamlinehq.com\"],[13],[10,\"defs\"],[12],[13],[10,\"path\"],[14,\"d\",\"m21 4.5 -1.812 17.209A2 2 0 0 1 17.2 23.5H6.8a2 2 0 0 1 -1.989 -1.791L3 4.5Z\"],[14,\"fill\",\"#fef9929c\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"m20.684 7.5 0.316 -3 -18 0 0.316 3 17.368 0z\"],[14,\"fill\",\"#fef992\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"m21 4.5 -1.812 17.209A2 2 0 0 1 17.2 23.5H6.8a2 2 0 0 1 -1.989 -1.791L3 4.5Z\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"m0.5 4.5 23 0\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M7.5 4.5v-3a1 1 0 0 1 1 -1h7a1 1 0 0 1 1 1v3\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"m12 9 0 10.5\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M16.5 9 16 19.5\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M7.5 9 8 19.5\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/trash-can-higlighted.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});