define("cc-frontend/utils/extended-moment", ["exports", "moment", "moment-range"], function (_exports, _moment, _momentRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const moment = (0, _momentRange.extendMoment)(_moment.default);
  var _default = moment;
  _exports.default = _default;
});