define("cc-frontend/components/group/members-table/cells/actions-ignore-request", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/app", "tracked-built-ins"], function (_exports, _glimmerComponent, _app, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.ignoringRequest}}
    <DS::ThreeDotSpinner />
  {{else}}
    <div
      class="group-members-table__remove-button test-remove-member-btn"
      {{on "click" this.removeMember}}
    >
      Ignore
    </div>
  {{/if}}
  */
  {
    "id": "lMxZ0rrs",
    "block": "[[[41,[30,0,[\"ignoringRequest\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"group-members-table__remove-button test-remove-member-btn\"],[4,[38,2],[\"click\",[30,0,[\"removeMember\"]]],null],[12],[1,\"\\n    Ignore\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"d-s/three-dot-spinner\",\"on\"]]",
    "moduleName": "cc-frontend/components/group/members-table/cells/actions-ignore-request.hbs",
    "isStrictMode": false
  });

  let GroupMembersTableActionsIgnoreRequestCell = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class GroupMembersTableActionsIgnoreRequestCell extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "rpc", _descriptor2, this);

      _initializerDefineProperty(this, "dialog", _descriptor3, this);

      _initializerDefineProperty(this, "ignoringRequest", _descriptor4, this);
    }

    rpcToIgnoreRequest(groupId, membershipId) {
      this.rpc.group_ignore_membership_request({
        groupId,
        membershipId
      }).then(() => {
        if (this.isDestroyed || this.isDestroying) return;
        Ember.run.later(() => this.ignoringRequest = false, 250);
      });
    }

    removeMember() {
      this.dialog.blank("dialogs/confirm-ignore-membership-request", {
        className: "dialog--create-annotation"
      }).then(() => {
        this.ignoringRequest = true;
        this.rpcToIgnoreRequest(this.args.group.id, this.args.row.id);
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ignoringRequest", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeMember", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeMember"), _class.prototype)), _class));
  _exports.default = GroupMembersTableActionsIgnoreRequestCell;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GroupMembersTableActionsIgnoreRequestCell);
});