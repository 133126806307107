define("cc-frontend/components/planner/card-stacks/cards/parts/comments", ["exports", "@ember-decorators/component", "cc-frontend/app"], function (_exports, _component, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (gt this.comments.length 0)}}
    <div class="comments-list" ...attributes>
      <div class="comments-list__header">
        {{partial "icons/ios-chatbubbles-outline"}}
        Comments
      </div>
      {{#each this.comments as |comment|}}
        <div class="comments-list__item test-comment-item">
          <div class="comments-list__text">
            <span class="comments-list__item-text" data-test-comment-body>
              {{{comment.text}}}
            </span>
            <span class="comments-list__item-comments-author">
              {{comment.commenterName}}
            </span>
          </div>
          {{#if (includes this.permissions "can-edit-comments")}}
            <span
              class="comments-list__item-remove-comment-btn test-delete-comment"
              {{action "removeComment" comment}}
              data-test-delete-comment-button
            >
              {{partial "icons/trash"}}
            </span>
          {{/if}}
        </div>
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "TFN/PTLE",
    "block": "[[[41,[28,[37,1],[[30,0,[\"comments\",\"length\"]],0],null],[[[1,\"  \"],[11,0],[24,0,\"comments-list\"],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"comments-list__header\"],[12],[1,\"\\n      \"],[19,\"icons/ios-chatbubbles-outline\",[]],[1,\"\\n      Comments\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"comments\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"comments-list__item test-comment-item\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"comments-list__text\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"comments-list__item-text\"],[14,\"data-test-comment-body\",\"\"],[12],[1,\"\\n            \"],[2,[30,2,[\"text\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"comments-list__item-comments-author\"],[12],[1,\"\\n            \"],[1,[30,2,[\"commenterName\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"permissions\"]],\"can-edit-comments\"],null],[[[1,\"          \"],[11,1],[24,0,\"comments-list__item-remove-comment-btn test-delete-comment\"],[24,\"data-test-delete-comment-button\",\"\"],[4,[38,6],[[30,0],\"removeComment\",[30,2]],null],[12],[1,\"\\n            \"],[19,\"icons/trash\",[2]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"comment\"],true,[\"if\",\"gt\",\"partial\",\"each\",\"-track-array\",\"includes\",\"action\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards/parts/comments.hbs",
    "isStrictMode": false
  });

  let Comments = (_dec = (0, _component.classNames)("lesson-section__comments"), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = class Comments extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modelsNeededForFirstEdit", void 0);

      _defineProperty(this, "cardStackId", void 0);

      _defineProperty(this, "cardId", void 0);

      _initializerDefineProperty(this, "lessonEditor", _descriptor, this);
    }

    removeComment(comment) {
      window.swal({
        title: "Delete Comment?",
        text: "Are you sure you want to delete this comment?",
        type: "warning",
        showCancelButton: true
      }).then(() => {
        this.get("lessonEditor").removeComment(this.get("modelsNeededForFirstEdit"), Ember.get(this, "cardStackId"), Ember.get(this, "cardId"), comment);
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "lessonEditor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "removeComment", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "removeComment"), _class2.prototype)), _class2)) || _class);
  _exports.default = Comments;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Comments);
});