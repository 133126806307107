define("cc-frontend/lib/actions/course/COURSE_SET_ENTER_TIMES_STATE", ["exports", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models"], function (_exports, _base, _planbook, _lodashEs, _patchUpdater, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareCourseSetEnterTimesStateAction;

  function prepareCourseSetEnterTimesStateAction(payload) {
    return {
      name: "COURSE_SET_ENTER_TIMES_STATE",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("course", payload.courseId).set("attributes.courseDateTimeSetting", payload.courseDateTimeSetting).patch;

    return [patch];
  }

  const stateDescriptions = {
    UNSET: "undecided times",
    NO: "not have times",
    YES_UNSET: "have times",
    YES_SAME: "have the same times every day",
    YES_DIFFERENT: "have different times"
  };

  function narrative(payload, findFn, userId) {
    var _models$course;

    let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
    let courseTitle = (_models$course = models.course) === null || _models$course === void 0 ? void 0 : _models$course.attributes.title;
    let newState = payload.courseDateTimeSetting;
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "course",
      primary_object_id: payload.courseId,
      primary_object_specifier: "enter_times_state",
      primary_object_id_and_ancestor_ids: null,
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: null
    };
    return {
      title: "Change course times state",
      titleForAnalytics: "Course - Set Enter Times State",
      description: `Changed your "${courseTitle}" class to ${stateDescriptions[newState]}`,
      context: (0, _defaultCourseContext.default)(payload, models),
      activity: (0, _defaultCourseActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});