define("cc-frontend/components/user/onboarding/planbook-basics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-flex tw-flex-col tw-w-full tw-px-10 tw-pb-12">
    {{! <h1 div class="user-sign__title">
      Create your first planbook!
    </h1> }}
    <div class="tw-pt-4">
      <User::CreatePlanbookForm @userId={{@userId}} />
    </div>
  </div>
  */
  {
    "id": "QdWFtn4O",
    "block": "[[[10,0],[14,0,\"tw-flex tw-flex-col tw-w-full tw-px-10 tw-pb-12\"],[12],[1,\"\\n\"],[1,\"  \"],[10,0],[14,0,\"tw-pt-4\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@userId\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@userId\"],false,[\"user/create-planbook-form\"]]",
    "moduleName": "cc-frontend/components/user/onboarding/planbook-basics.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});