define("cc-frontend/lib/actions/lesson/LESSON_CARD_MARK_PUBLIC", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_MARK_PUBLIC = void 0;
  const LESSON_CARD_MARK_PUBLIC = {
    name: "LESSON_CARD_MARK_PUBLIC",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("embedded", "attributes.isPublic", payload.value) // This needs to be a document set
      .set("attributes.sectionsArePristine", false).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).set("attributes.isPublic", payload.originalValue) // This needs to be a document set
      .set("attributes.sectionsArePristine", payload.originalValue).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let card = (0, _lodashEs.find)((0, _lodashEs.get)(models, "cardStack.attributes.cards"), {
        id: payload.sectionId
      });
      let cardTitle = (0, _lodashEs.get)(card, "title");
      let showHidden = payload.value === true ? "Showed" : "Hide";
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = Object.assign({}, context, {
        cardTitle: cardTitle,
        showOrHide: showHidden
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "cards",
        adverbial_verb: "editing",
        adverbial_object_type: "card",
        adverbial_object_specifier: "public",
        adverbial_object_id: payload.sectionId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: `${showHidden} Lesson Card on Class Website`,
        titleForAnalytics: "Lesson Card - Toggle Public",
        description: `${showHidden} ${cardTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_MARK_PUBLIC = LESSON_CARD_MARK_PUBLIC;
  var _default = LESSON_CARD_MARK_PUBLIC;
  _exports.default = _default;
});