define("cc-frontend/utils/planbook/update-planbook-to-rotation-calendar", ["exports", "cc-frontend/lib/object-id-gen", "lodash-es", "cc-frontend/utils/filter-dates/planbook", "cc-frontend/utils/filter-dates/rotation-calendar", "cc-frontend/utils/filter-dates/shared"], function (_exports, _objectIdGen, _lodashEs, _planbook, _rotationCalendar, _shared) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updatePlanbookToRotationCalendar = updatePlanbookToRotationCalendar;

  function updatePlanbookToRotationCalendar(sourceRotationCalendar, destinationRotationCalendar, planbook) {
    let effectiveDate = sourceRotationCalendar.attributes.datesLastChangedOn;

    if (effectiveDate === null || effectiveDate === undefined || typeof effectiveDate !== "string") {
      throw new Error("Old Rotation Calendar needs a `datesLastChangedOn` Property");
    }
    /**
     * ---------------------------------------------------------------------------------
     * Pluck all the cardStackIds with their rotationId so we can shift them
     * ---------------------------------------------------------------------------------
     */


    let newCustomDates = generateCustomDates({
      sourceRotationCalendar,
      destinationRotationCalendar,
      effectiveDate,
      customDates: (0, _planbook.filterPlanbookCustomDates)(planbook)
    });
    let newPlanbookDates = (0, _lodashEs.map)(newCustomDates, date => {
      return {
        id: date.dateString,
        type: "planbook-date-custom",
        attributes: {
          cardStackId: date.cardStackId,
          dateString: date.dateString
        }
      };
    }); // Handle unclaimed cardStackIdsArray
    // if (cardStackIdsArray.length > 0) {
    //   // We have to go to the end of the school year and append I think.
    //   forEach(cardStackIdsArray, hash => {})
    // }

    /**
     * --------------------------------------------------------------------------------
     * Generate new card stacks as needed
     * --------------------------------------------------------------------------------
     */

    /**
     * Pluck default dates and add as necessary in the next step
     */

    let defaultDates = (0, _planbook.filterPlanbookDefaultDates)(planbook);
    /**
     * Ids of Rotations we don't have yet in this planbook yet
     */

    let newRotationIds = (0, _rotationCalendar.pluckNewRotationIds)(sourceRotationCalendar, defaultDates);
    /**
     * The new cards stacks we need to generate for routine templates
     */

    let newCardStacks = [];

    if (newRotationIds.length > 0) {
      let semesterId = sourceRotationCalendar.attributes.semesters[0].id;
      (0, _lodashEs.forEach)(newRotationIds, rotationId => {
        // We'll use this in the planbook-date-default and the card stack
        let cardStackId = _objectIdGen.default.create(); // Push in the planbook-date-default to the planbook


        defaultDates.push({
          id: `${semesterId}:${rotationId}`,
          type: "planbook-date-default",
          attributes: {
            cardStackId: cardStackId,
            rotationId: rotationId,
            semesterId: semesterId
          }
        }); // Generate the new card stack

        newCardStacks.push({
          id: cardStackId,
          type: "card-stack",
          attributes: {
            usedAs: "routine-template",
            _revisedBySessionId: null,
            _revision: 0,
            version: 0,
            reviews: [],
            cards: (0, _lodashEs.map)(planbook.relationships.courses.data, (datum, index) => {
              return {
                id: _objectIdGen.default.create(),
                type: "card-course-slot",
                attributes: {
                  courseId: datum.id,
                  position: index * 1000,
                  parentCardId: null
                }
              };
            })
          },
          meta: {
            permissions: planbook.meta.permissions
          },
          relationships: {
            planbook: {
              data: {
                id: planbook.id,
                type: "planbook"
              }
            }
          }
        });
      });
    }
    /**
     * --------------------------------------------------------------------------------
     * Combine them all together
     * --------------------------------------------------------------------------------
     */

    /**
     * Pluck out the custom dates that didn't change
     */


    let customDatesToKeep = (0, _shared.filterForDatesBefore)((0, _planbook.filterPlanbookCustomDates)(planbook), effectiveDate);
    return {
      dates: [...defaultDates, ...customDatesToKeep, ...newPlanbookDates],
      newCardStacks: newCardStacks
    };
  }

  function generateCustomDates({
    sourceRotationCalendar,
    destinationRotationCalendar,
    effectiveDate,
    customDates
  }) {
    /**
     * ---------------------------------------------------------------------------------
     * Pluck all the cardStackIds with their rotationId so we can shift them
     * ---------------------------------------------------------------------------------
     */

    /**
     * The dates in the destination calendar on and after the effective date
     */
    let relevantSourceDates = (0, _rotationCalendar.filterForRelevantDates)(sourceRotationCalendar, effectiveDate);
    let relevantDestinationDates = (0, _rotationCalendar.filterForRelevantDates)(destinationRotationCalendar, effectiveDate);
    /**
     * An array of maps of that have all the cardStackId and what rotation they were originally on
     * We keep it in an array so we can reapply them in order if we run out of rotations. A previous
     * implementation was a map: {a: CardStackId[], b: CardStackId[]}, but if we needed to lay down
     * the card stacks ids on days the rotation calendar didn't cover, we had lost their original order.
     */

    let cardStackIdsArray = (0, _lodashEs.compact)((0, _lodashEs.map)(relevantSourceDates, date => {
      if (date.attributes.rotationId === null) return null;
      let customDate = (0, _shared.findDate)(customDates, date.attributes.dateString);
      return {
        rotationId: date.attributes.rotationId,
        cardStackId: customDate ? customDate.attributes.cardStackId : null
      };
    }));
    /**
     * ------------------------------------------------------------------
     * Create new planbook dates, using the routineIds we extracted above
     * ------------------------------------------------------------------
     */

    /**
     * Planbook dates that we need to add to the planbook as a result of the rotation calendar
     * changing.
     */

    let newCustomDates = (0, _lodashEs.compact)((0, _lodashEs.map)(relevantDestinationDates, rotationDate => {
      if (rotationDate.attributes.rotationId === null) return null; // Find a relevant id. This is a bit confusing, but the first branch is for courses
      // where you want to move a lesson to all the available rotations. E.g. a course that is on A annd B days
      // the second fork is for planbooks where you just want to make sure A days move to A days, etc

      let customDate = (0, _lodashEs.find)(cardStackIdsArray, hash => hash.rotationId === rotationDate.attributes.rotationId);
      (0, _lodashEs.pull)(cardStackIdsArray, customDate); // remove the element we just removed from the array so we don't reapply it

      let cardStackId = (customDate === null || customDate === void 0 ? void 0 : customDate.cardStackId) || null;
      return {
        dateString: rotationDate.attributes.dateString,
        cardStackId: cardStackId
      };
    })); // filter out any remain cardStackIds that are null

    cardStackIdsArray = (0, _lodashEs.reject)(cardStackIdsArray, hash => hash.cardStackId === null);
    return newCustomDates;
  }
});