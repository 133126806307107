define("cc-frontend/components/planner/card-stacks/lesson/lesson-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    data-has-permission-to-move={{includes @permissions "can-edit-plan"}}
    local-class="lesson-block__move-dots"
    class="lesson-move-dots js-lesson-move-pad test-lesson-move-pad
      {{if (includes @permissions "can-edit-plan") "js-can-drag-lesson"}}"
  ></div>
  <div local-class="lesson-block__text">
    {{#if @isNonInstructional}}
      <div local-class="lesson-block__text__lesson-title-row__title" data-test-lesson-topic>
        {{@course.attributes.title}}
      </div>
    {{else}}
      <div local-class="lesson-block__text__lesson-title-row" data-test-lesson-topic>
        <Planner::CardStacks::CardStackTitle
          @placeholder="Lesson Topic"
          @value={{@lessonTitle}}
          @cardStackId={{@cardStackId}}
          @class="{{local-class "lesson-block__text__lesson-title-row__title"}}
               js-test-lesson-title"
          @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
          @isEditable={{includes @permissions "can-edit-plan"}}
        />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "fQA7WMHk",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"lesson-move-dots js-lesson-move-pad test-lesson-move-pad\\n    \",[52,[28,[37,2],[[30,1],\"can-edit-plan\"],null],\"js-can-drag-lesson\"]],null],\" \",[28,[37,3],[\"lesson-block__move-dots\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/lesson-title.css\"]]]]]],[15,\"data-has-permission-to-move\",[28,[37,2],[[30,1],\"can-edit-plan\"],null]],[12],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,3],[\"lesson-block__text\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/lesson-title.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,3],[\"lesson-block__text__lesson-title-row__title\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/lesson-title.css\"]]]]]],[14,\"data-test-lesson-topic\",\"\"],[12],[1,\"\\n      \"],[1,[30,3,[\"attributes\",\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,3],[\"lesson-block__text__lesson-title-row\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/lesson-title.css\"]]]]]],[14,\"data-test-lesson-topic\",\"\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@placeholder\",\"@value\",\"@cardStackId\",\"@class\",\"@modelsNeededForFirstEdit\",\"@isEditable\"],[\"Lesson Topic\",[30,4],[30,5],[29,[[28,[37,3],[\"lesson-block__text__lesson-title-row__title\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/lesson-title.css\"]]],\"\\n             js-test-lesson-title\"]],[30,6],[28,[37,2],[[30,1],\"can-edit-plan\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@permissions\",\"@isNonInstructional\",\"@course\",\"@lessonTitle\",\"@cardStackId\",\"@modelsNeededForFirstEdit\"],false,[\"concat\",\"if\",\"includes\",\"local-class\",\"planner/card-stacks/card-stack-title\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson/lesson-title.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});