define("cc-frontend/components/dialog-manager", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/transitions/fade", "ember-headlessui/utils/keyboard"], function (_exports, _glimmerComponent, _fade, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Portal @target="dialog-portal">
    {{#animated-if this.dialog.isOpen use=this.fade}}
      {{! <div class="tw-absolute tw-pin"> }}
      <div
        class="ember-dialog-dialog
          {{this.dialog.options.className}}
          {{if this.dialog.options.substrate "substrate"}}
          js-opened-from-menu"
        style="z-index: 99999999999999999 !important;"
        tabindex="-1"
        id="dialog-focus-trap"
        {{on "keydown" this.onKeydown}}
        {{headlessui-focus-trap focusTrapOptions=(hash onDeactivate=this.decline)}}
      >
        <button class="btn-exit-modal-string" {{on "click" this.decline}} type="button">
          Close
          <span class="close-modal-times">
            &times;
          </span>
        </button>
        <div class="dialog-content">
          <div class="dialog-body">
            {{! I have no idea what this error means. I think it's because it's not typing component correctly}}
            {{component
              this.dialog.templateName
              options=this.dialog.options
              accept=this.accept
              decline=this.decline
            }}
          </div>
        </div>
      </div>
      {{! </div> }}
    {{/animated-if}}
  </Portal>
  */
  {
    "id": "PCLKlgEQ",
    "block": "[[[8,[39,0],null,[[\"@target\"],[\"dialog-portal\"]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,1],[[30,0,[\"dialog\",\"isOpen\"]]],[[\"use\"],[[30,0,[\"fade\"]]]],[[\"default\"],[[[[1,\"    \"],[11,0],[16,0,[29,[\"ember-dialog-dialog\\n        \",[30,0,[\"dialog\",\"options\",\"className\"]],\"\\n        \",[52,[30,0,[\"dialog\",\"options\",\"substrate\"]],\"substrate\"],\"\\n        js-opened-from-menu\"]]],[24,5,\"z-index: 99999999999999999 !important;\"],[24,\"tabindex\",\"-1\"],[24,1,\"dialog-focus-trap\"],[4,[38,3],[\"keydown\",[30,0,[\"onKeydown\"]]],null],[4,[38,4],null,[[\"focusTrapOptions\"],[[28,[37,5],null,[[\"onDeactivate\"],[[30,0,[\"decline\"]]]]]]]],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn-exit-modal-string\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"decline\"]]],null],[12],[1,\"\\n        Close\\n        \"],[10,1],[14,0,\"close-modal-times\"],[12],[1,\"\\n          ×\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"dialog-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"dialog-body\"],[12],[1,\"\\n\"],[1,\"          \"],[46,[30,0,[\"dialog\",\"templateName\"]],null,[[\"options\",\"accept\",\"decline\"],[[30,0,[\"dialog\",\"options\"]],[30,0,[\"accept\"]],[30,0,[\"decline\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]]]]]],[],false,[\"portal\",\"animated-if\",\"if\",\"on\",\"headlessui-focus-trap\",\"hash\",\"component\"]]",
    "moduleName": "cc-frontend/components/dialog-manager.hbs",
    "isStrictMode": false
  });

  let DialogManager = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DialogManager extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fade", _fade.default);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "dialog", _descriptor2, this);
    }

    accept() {
      Ember.get(this, "dialog").accept(...arguments);
    }

    decline() {
      Ember.get(this, "dialog").decline(...arguments);
    }

    onKeydown(event) {
      switch (event.key) {
        // Ref: https://www.w3.org/TR/wai-aria-practices-1.2/#keyboard-interaction-12
        // `Escape` key is handled by focus-trap
        case _keyboard.Keys.Enter:
          this.dialog.accept(...arguments);
          break;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "accept", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decline", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "decline"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype)), _class));
  _exports.default = DialogManager;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DialogManager);
});