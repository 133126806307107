define("cc-frontend/components/icons/arrow-left-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="currentColor"
    viewBox="0 0 30 30"
  >
    <g clip-path="url(#a)">
      <path
        fill-rule="evenodd"
        d="M14.619.913c-3.468.295-6.61 1.752-9.06 4.202A14.397 14.397 0 0 0 1.312 15.37c0 3.55 1.256 6.89 3.597 9.563a14.442 14.442 0 0 0 10.903 4.939c2.09 0 4.04-.418 5.975-1.281 3.188-1.422 5.865-4.118 7.283-7.333a14.421 14.421 0 0 0 1.242-5.888 14.39 14.39 0 0 0-3.597-9.562A14.482 14.482 0 0 0 17.821 1a16.246 16.246 0 0 0-3.202-.088Zm.166 1.255c-2.652.226-5.097 1.177-7.13 2.774-2.816 2.213-4.564 5.35-5.005 8.977-.076.622-.076 2.278 0 2.9.075.623.159 1.089.297 1.662A13.255 13.255 0 0 0 13.97 28.482a14.54 14.54 0 0 0 3.684 0 13.278 13.278 0 0 0 9.624-6.496 13.506 13.506 0 0 0 1.645-4.774 14.545 14.545 0 0 0 0-3.685 13.272 13.272 0 0 0-5.664-9.101 13.366 13.366 0 0 0-5.604-2.17c-.787-.11-2.124-.151-2.87-.088Z"
        clip-rule="evenodd"
      ></path>
      <path
        d="M15.983 10.507a.63.63 0 0 0-.89-.89l-5.287 5.287a.628.628 0 0 0 0 .89l5.287 5.287a.63.63 0 1 0 .89-.89l-4.212-4.213h9.809a.63.63 0 0 0 0-1.259h-9.81l4.213-4.212Z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path d="M.812.37h30v30h-30z"></path>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "OseakBh3",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"30\"],[14,\"height\",\"30\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 30 30\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M14.619.913c-3.468.295-6.61 1.752-9.06 4.202A14.397 14.397 0 0 0 1.312 15.37c0 3.55 1.256 6.89 3.597 9.563a14.442 14.442 0 0 0 10.903 4.939c2.09 0 4.04-.418 5.975-1.281 3.188-1.422 5.865-4.118 7.283-7.333a14.421 14.421 0 0 0 1.242-5.888 14.39 14.39 0 0 0-3.597-9.562A14.482 14.482 0 0 0 17.821 1a16.246 16.246 0 0 0-3.202-.088Zm.166 1.255c-2.652.226-5.097 1.177-7.13 2.774-2.816 2.213-4.564 5.35-5.005 8.977-.076.622-.076 2.278 0 2.9.075.623.159 1.089.297 1.662A13.255 13.255 0 0 0 13.97 28.482a14.54 14.54 0 0 0 3.684 0 13.278 13.278 0 0 0 9.624-6.496 13.506 13.506 0 0 0 1.645-4.774 14.545 14.545 0 0 0 0-3.685 13.272 13.272 0 0 0-5.664-9.101 13.366 13.366 0 0 0-5.604-2.17c-.787-.11-2.124-.151-2.87-.088Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M15.983 10.507a.63.63 0 0 0-.89-.89l-5.287 5.287a.628.628 0 0 0 0 .89l5.287 5.287a.63.63 0 1 0 .89-.89l-4.212-4.213h9.809a.63.63 0 0 0 0-1.259h-9.81l4.213-4.212Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M.812.37h30v30h-30z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/arrow-left-circle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});