define("cc-frontend/lib/actions/app-state/KB_SAD_FACE_CLICKED", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareKbSadFaceClicked;

  function prepareKbSadFaceClicked(payload) {
    return {
      name: "KB_SAD_FACE_CLICKED",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: "help_site",
      root_type: "app",
      primary_verb: "use",
      primary_object_type: "app",
      primary_object_id: "help_site",
      primary_object_id_and_ancestor_ids: ["help_site", payload.article.id],
      adverbial_verb: "clicking",
      adverbial_object_type: "reaction",
      adverbial_object_id: "sad_face",
      adverbial_object_id_and_ancestor_ids: ["help_site", payload.article.id, "sad_face"]
    };
    return (0, _createNarrative.default)({
      title: "Sad Face Selected",
      description: "User indicated that the article did not answer their question",
      titleForAnalytics: "KB Article - Sad Face Clicked",
      context: {
        articleId: payload.article.id,
        articleTitle: payload.article.attributes.title
      },
      newAnalyticsEvents: [newEvent]
    });
  }
});