define("cc-frontend/lib/actions/course/calendar/TURN_DATE_OFF_AND", ["exports", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/lesson", "lodash-es", "moment", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater"], function (_exports, _base, _lesson, _lodashEs, _moment, _datesOffCalculator, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTurnDateOffAnd = prepareTurnDateOffAnd;
  _exports.narrative = narrative;
  _exports.default = void 0;

  function prepareTurnDateOffAnd(payload) {
    return {
      name: "TURN_DATE_OFF_AND",
      payload: payload,
      patches: payload.bumpOrDeletePatches.concat(patches(payload)),
      undoPatches: payload.bumpOrDeleteUndoPatches.concat(undoPatches(payload)),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  var _default = prepareTurnDateOffAnd;
  _exports.default = _default;

  let patches = function (payload) {
    let newAnnotations = payload.course.attributes.calendar.dateAnnotations.concat([payload.dateAnnotation]);
    let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

    let patch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  };

  let undoPatches = function (payload) {
    let patch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", JSON.parse(JSON.stringify(payload.course.attributes.calendar.datesOff || []))).inc("attributes.calendar.version", 1);

    return [patch.patch];
  };

  function narrative(payload, findFn, userId) {
    let formattedDate = (0, _moment.default)(payload.dateString).format("MMMM D, YYYY");
    let eventTitleForAnalytics = "Lesson - Toggle Off - No Lesson";
    let adverbialObjectSpecifierOptions = {
      BUMP: "off-and-bump",
      DELETE: "off-and-delete",
      NO_LESSON: "off"
    };
    let adverbialObjectSpecifier = adverbialObjectSpecifierOptions[payload.bumpOrDelete];
    let sharedEventProperties = {
      root_id: payload.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: payload.dateAnnotation.attributes.dateString,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
      primary_possessive: (0, _lesson.getPossessiveForLesson)(findFn, userId, [payload.planbook.relationships.owner.data.id]),
      primary_object_type: "lesson",
      adverbial_object_possessive: (0, _lesson.getPossessiveForLesson)(findFn, userId, [payload.planbook.relationships.owner.data.id])
    };
    let newEvent = { ...(0, _base.createBlankEvent)(),
      ...sharedEventProperties,
      primary_verb: "edit",
      adverbial_verb: "toggling",
      adverbial_object_type: "lesson",
      adverbial_object_specifier: adverbialObjectSpecifier,
      adverbial_object_id: payload.cardStackId,
      primary_object_specifier: "lesson_slot",
      adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id]
    };
    let secondEvent = null;

    if (payload.bumpOrDelete === "BUMP" && payload.cardStackId !== null) {
      eventTitleForAnalytics = "Lesson - Toggle Off - Bump Lesson";
      secondEvent = { ...(0, _base.createBlankEvent)(),
        ...sharedEventProperties,
        primary_verb: "move",
        primary_object_specifier: null,
        adverbial_verb: "bumping",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: "forward",
        adverbial_object_id: payload.cardStackId,
        adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id]
      };
    } else if (payload.bumpOrDelete === "DELETE" && payload.cardStackId !== null) {
      eventTitleForAnalytics = "Lesson - Toggle Off - Delete Lesson";
      secondEvent = { ...(0, _base.createBlankEvent)(),
        ...sharedEventProperties,
        primary_verb: "delete",
        primary_object_id: payload.cardStackId,
        primary_object_specifier: null,
        adverbial_verb: "removing",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: "content",
        adverbial_object_id: null,
        adverbial_object_id_and_ancestor_ids: null
      };
    } else {
      null;
    }

    return {
      title: `Turned Class Off`,
      titleForAnalytics: eventTitleForAnalytics,
      description: `Turned ${payload.course.attributes.title} off on ${formattedDate}`,
      activity: {},
      context: {
        dateString: payload.dateString,
        courseId: payload.course.id,
        courseTitle: payload.course.attributes.title
      },
      newAnalyticsEvents: secondEvent ? [newEvent, secondEvent] : [newEvent]
    };
  }
});