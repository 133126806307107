define("cc-frontend/services/algolia", ["exports", "algoliasearch"], function (_exports, _algoliasearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Algolia extends Ember.Service {
    get index() {
      var client = (0, _algoliasearch.default)("O7L4OQENOZ", "10cdfef85b6690cfe4a7bb1f030d8fd9");
      return client.initIndex("common-standards-project");
    }

    get help() {
      var client = (0, _algoliasearch.default)("O7L4OQENOZ", "593c807db82cd9b033bca564730bb871");
      return client.initIndex("common-curriculum-kb");
    }

    get canonicalGroups() {
      var client = (0, _algoliasearch.default)("O7L4OQENOZ", "593c807db82cd9b033bca564730bb871");
      return client.initIndex("canonical-groups");
    }

  }

  _exports.default = Algolia;
});