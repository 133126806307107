define("cc-frontend/utils/course/times", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCourseTimePart = formatCourseTimePart;
  _exports.parseCourseTimePart = parseCourseTimePart;
  _exports.formatCourseTime = formatCourseTime;
  _exports.addCourseTimeParts = addCourseTimeParts;

  function formatCourseTimePart(startOrEnd) {
    const partM = (0, _moment.default)().startOf("day").add(startOrEnd, "minutes");
    return partM.format("h:mma");
  }
  /**
   * @param start When parsing end, you may supply the existing start, to enforce
   * start <= end and reasonable AM/PM guesses.
   * @returns Parsed time part, or null if invalid
   */


  function parseCourseTimePart(startOrEndInputStr, start) {
    const partM = (0, _moment.default)(startOrEndInputStr.trim(), ["LT"]);
    if (!partM.isValid()) return null;
    let timePart = 60 * partM.hours() + partM.minutes(); // Try to guess if the user implicitly wants PM.

    const explicitlyAM = startOrEndInputStr.toLowerCase().includes("a");

    if (!explicitlyAM && timePart < 12 * 60) {
      if (timePart < 6 * 60 || start !== undefined && start > timePart) {
        timePart += 12 * 60;
      }
    } // Enforce start <= timePart.


    if (start !== undefined && start > timePart) timePart = start;
    return timePart;
  }

  function formatCourseTime(time) {
    if (time === null) return "";
    return `${formatCourseTimePart(time.start)} - ${formatCourseTimePart(time.end)}`;
  }

  function addCourseTimeParts(timePart, deltaMinutes) {
    const dayLength = 24 * 60; // Positive modulus (range [0, dayLength))

    return ((timePart + deltaMinutes) % dayLength + dayLength) % dayLength;
  }
});