define("cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash", "moment", "cc-frontend/lib/dates-off-calculator"], function (_exports, _patchUpdater, _base, _planbook, _lodash, _moment, _datesOffCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = preparePlanbookAddSpecialEvent;

  function preparePlanbookAddSpecialEvent(payload) {
    return {
      name: "PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodash.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let newAnnotations = payload.planbook.attributes.calendar.dateAnnotations.concat([payload.dateAnnotation]);
    let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(newAnnotations);

    let patch = _patchUpdater.default.create("planbook", payload.planbook.id).push("attributes.calendar.dateAnnotations", payload.dateAnnotation).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  }

  function undoPatches(payload) {
    let oldAnnotations = JSON.parse(JSON.stringify(payload.planbook.attributes.calendar.dateAnnotations));
    let allDatesOff = (0, _datesOffCalculator.findPlanbookDatesOff)(oldAnnotations);

    let patch = _patchUpdater.default.create("planbook", payload.planbook.id).pull("attributes.calendar.dateAnnotations", {
      id: payload.dateAnnotation.id
    }).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  }

  function narrative(payload, findFn, userId) {
    let descriptionStarter = "Added Special Event";
    let descriptionPhrase = payload.dateAnnotation.type === "date-annotation-planbook-custom-single" ? `on ${(0, _moment.default)(payload.dateAnnotation.attributes.dateString).format("ddd, MMM. D, YYYY")}` : `from ${(0, _moment.default)(payload.dateAnnotation.attributes.startDate).format("ddd, MMM. D, YYYY")} to ${(0, _moment.default)(payload.dateAnnotation.attributes.endDate).format("ddd, MMM. D, YYYY")}`;
    let titleForAnalytics = payload.dateAnnotation.type === "date-annotation-planbook-custom-single" ? "Planbook Date - Create Annotation - Single" : "Planbook Date - Create Annotation - Range";
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbook.id,
      root_type: "planbook",
      primary_object_type: "planbook",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
      primary_object_id_and_ancestor_ids: [payload.planbook.id],
      primary_verb: "edit",
      primary_object_specifier: "calendar",
      adverbial_verb: "adding",
      adverbial_object_type: "annotation",
      adverbial_object_specifier: payload.dateAnnotation.type === "date-annotation-planbook-custom-single" ? "single" : "range"
    };
    return {
      title: `Added Special Event`,
      titleForAnalytics: titleForAnalytics,
      description: `${descriptionStarter} ${descriptionPhrase}`,
      activity: {},
      context: {
        noSchool: false,
        planbookId: payload.planbook.id,
        dateAnnotationId: payload.dateAnnotation.id
      },
      newAnalyticsEvents: [newEvent]
    };
  }
});