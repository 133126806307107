define("cc-frontend/controllers/print/planbook-date", ["exports", "cc-frontend/helpers/belongs-to", "cc-frontend/utils/extended-moment", "lodash", "cc-frontend/helpers/find-document", "cc-frontend/helpers/has-many-collectively"], function (_exports, _belongsTo, _extendedMoment, _lodash, _findDocument, _hasManyCollectively) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanbookDateController = (_dec = Ember.inject.service, _dec2 = (0, _findDocument.default)("model.planbookId", "planbook"), _dec3 = (0, _hasManyCollectively.default)("planbook", "course", "courses"), _dec4 = (0, _belongsTo.default)("planbook", "rotationCalendar"), _dec5 = Ember.computed.equal("pageBreaks", "after-day"), _dec6 = Ember.computed.equal("pageBreaks", "after-class"), _dec7 = Ember.computed("pageBreaks"), _dec8 = Ember.computed("isFilteringClasses", "planbook.content.relationships.courses.data.@each.id", "courses.isPending", "courses.content"), _dec9 = Ember.computed("startDate", "endDate", "rotationCalendar.content.attributes.schoolDays"), _dec10 = Ember.computed("dateStrings.[]"), (_class = class PlanbookDateController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "queryParams", ["startDate", "endDate", "classesLinedUp", "isFilteringClasses", "courseIds", "asColumns", "headerText", "showComments", "showStandardDescriptions", "isFilteringCards", "visibleCards", "pageBreaks"]);

      _defineProperty(this, "classesLinedUp", true);

      _defineProperty(this, "asColumns", false);

      _defineProperty(this, "isFilteringClasses", false);

      _defineProperty(this, "courseIds", []);

      _defineProperty(this, "showComments", false);

      _defineProperty(this, "showStandardDescriptions", true);

      _defineProperty(this, "isFilteringCards", false);

      _defineProperty(this, "visibleCards", []);

      _defineProperty(this, "pageBreaks", "");

      _initializerDefineProperty(this, "planbook", _descriptor2, this);

      _initializerDefineProperty(this, "courses", _descriptor3, this);

      _initializerDefineProperty(this, "rotationCalendar", _descriptor4, this);

      _initializerDefineProperty(this, "pageBreakAfterDay", _descriptor5, this);

      _initializerDefineProperty(this, "pageBreakAfterClass", _descriptor6, this);
    }

    get normalPageBreaks() {
      return Ember.get(this, "pageBreaks") === "none" || Ember.get(this, "pageBreaks") === "";
    }

    get visibleCourses() {
      if (Ember.get(this, "courses.isPending") === true) return [];
      let isFiltering = Ember.get(this, "isFilteringClasses");

      if (isFiltering === true) {
        return _lodash.default.compact(_lodash.default.map(Ember.get(this, "courseIds"), id => _lodash.default.find(Ember.get(this, "courses.content"), course => Ember.get(course, "id") === id)));
      } else {
        return _lodash.default.map(Ember.get(this, "courses.content"), course => course);
      }
    }

    get dateStrings() {
      let validSchoolDays = Ember.get(this, "rotationCalendar.content.attributes.schoolDays");
      let startDate = Ember.get(this, "startDate");
      let endDate = Ember.get(this, "endDate");

      let range = _extendedMoment.default.range(startDate, endDate);

      let acc = [];
      Array.from(range.by("days")).map(moment => {
        if (_lodash.default.includes(validSchoolDays, moment.weekday())) acc.push(moment);
      });

      let newDates = _lodash.default.uniq(_lodash.default.map(acc, m => m.format("YYYY-MM-DD")));

      return newDates;
    }

    get dateRangeString() {
      var start = (0, _extendedMoment.default)(Ember.get(this, "dateStrings.firstObject"));
      var end = (0, _extendedMoment.default)(Ember.get(this, "dateStrings.lastObject"));
      var startMonth = start.format("MMMM");
      var endMonth = end.format("MMMM");

      if (Ember.get(this, "dateStrings.firstObject") === Ember.get(this, "dateStrings.lastObject")) {
        return start.format("MMMM D, YYYY");
      } else if (startMonth === endMonth) {
        return start.format("MMMM D") + " - " + end.format("D, YYYY");
      } else {
        return start.format("MMM. D") + " - " + end.format("MMM. D, YYYY");
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "planbook", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "courses", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rotationCalendar", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pageBreakAfterDay", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pageBreakAfterClass", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "normalPageBreaks", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "normalPageBreaks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibleCourses", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "visibleCourses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateStrings", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "dateStrings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateRangeString", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "dateRangeString"), _class.prototype)), _class));
  _exports.default = PlanbookDateController;
});