define("cc-frontend/lib/actions/lesson/LESSON_CARD_DELETE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "lodash-es", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _lodashEs, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_DELETE = void 0;
  const LESSON_CARD_DELETE = {
    name: "LESSON_CARD_DELETE",

    patches(payload) {
      if ((0, _lodashEs.isNil)(payload.card)) throw Error(`Nil Card trying to be deleted on ${payload.cardStackId}`);

      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).pull("attributes.cards", {
        id: payload.card.id
      }).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).addToSet("attributes.cards", JSON.parse(JSON.stringify(payload.card))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let card = payload.card;
      let cardTitle = (0, _lodashEs.get)(card, "attributes.title");
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = Object.assign({}, context, {
        cardTitle: cardTitle
      });
      let lessonTitle = models.cardStack.attributes.title;
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "cards",
        adverbial_verb: "removing",
        adverbial_object_type: "card",
        adverbial_object_specifier: null,
        adverbial_object_id: payload.card.id,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Deleted a Lesson Card",
        titleForAnalytics: "Lesson Card - Remove",
        description: `Removed "${cardTitle}" card from ${lessonTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "removed"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_DELETE = LESSON_CARD_DELETE;
  var _default = LESSON_CARD_DELETE;
  _exports.default = _default;
});