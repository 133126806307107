define("cc-frontend/components/icons/chat-bubble-question-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      d="M18 1.5C8.466 1.5 0.75 7.8795 0.75 15.75C0.789903 17.7095 1.2688 19.6351 2.15142 21.3851C3.03405 23.135 4.29793 24.6647 5.85 25.8615L2.25 34.5L11.808 29.0385C13.8102 29.676 15.8988 30.0003 18 30C27.5265 30 35.25 23.6205 35.25 15.75C35.25 7.8795 27.5265 1.5 18 1.5Z"
      stroke="#757575"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M13.5 12C13.5004 11.1888 13.7201 10.3928 14.1358 9.69615C14.5515 8.99955 15.1477 8.42827 15.8615 8.04277C16.5753 7.65726 17.38 7.47187 18.1904 7.5062C19.0009 7.54053 19.7871 7.79331 20.4657 8.23779C21.1443 8.68228 21.6901 9.30193 22.0454 10.0312C22.4007 10.7605 22.5523 11.5722 22.4841 12.3805C22.4159 13.1889 22.1304 13.9637 21.6579 14.6232C21.1854 15.2826 20.5435 15.802 19.8 16.1265C19.265 16.36 18.8098 16.7445 18.49 17.2328C18.1703 17.7212 18 18.2923 18 18.876V19.5"
      stroke="#757575"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M18 23.625C17.9005 23.625 17.8052 23.5855 17.7348 23.5152C17.6645 23.4448 17.625 23.3495 17.625 23.25C17.625 23.1505 17.6645 23.0552 17.7348 22.9848C17.8052 22.9145 17.9005 22.875 18 22.875"
      stroke="#757575"
    ></path>
    <path
      d="M18 23.625C18.0995 23.625 18.1948 23.5855 18.2652 23.5152C18.3355 23.4448 18.375 23.3495 18.375 23.25C18.375 23.1505 18.3355 23.0552 18.2652 22.9848C18.1948 22.9145 18.0995 22.875 18 22.875"
      stroke="#757575"
    ></path>
  </svg>
  */
  {
    "id": "NORTUb/O",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"36\"],[14,\"height\",\"36\"],[14,\"viewBox\",\"0 0 36 36\"],[14,\"fill\",\"none\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18 1.5C8.466 1.5 0.75 7.8795 0.75 15.75C0.789903 17.7095 1.2688 19.6351 2.15142 21.3851C3.03405 23.135 4.29793 24.6647 5.85 25.8615L2.25 34.5L11.808 29.0385C13.8102 29.676 15.8988 30.0003 18 30C27.5265 30 35.25 23.6205 35.25 15.75C35.25 7.8795 27.5265 1.5 18 1.5Z\"],[14,\"stroke\",\"#757575\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M13.5 12C13.5004 11.1888 13.7201 10.3928 14.1358 9.69615C14.5515 8.99955 15.1477 8.42827 15.8615 8.04277C16.5753 7.65726 17.38 7.47187 18.1904 7.5062C19.0009 7.54053 19.7871 7.79331 20.4657 8.23779C21.1443 8.68228 21.6901 9.30193 22.0454 10.0312C22.4007 10.7605 22.5523 11.5722 22.4841 12.3805C22.4159 13.1889 22.1304 13.9637 21.6579 14.6232C21.1854 15.2826 20.5435 15.802 19.8 16.1265C19.265 16.36 18.8098 16.7445 18.49 17.2328C18.1703 17.7212 18 18.2923 18 18.876V19.5\"],[14,\"stroke\",\"#757575\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18 23.625C17.9005 23.625 17.8052 23.5855 17.7348 23.5152C17.6645 23.4448 17.625 23.3495 17.625 23.25C17.625 23.1505 17.6645 23.0552 17.7348 22.9848C17.8052 22.9145 17.9005 22.875 18 22.875\"],[14,\"stroke\",\"#757575\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M18 23.625C18.0995 23.625 18.1948 23.5855 18.2652 23.5152C18.3355 23.4448 18.375 23.3495 18.375 23.25C18.375 23.1505 18.3355 23.0552 18.2652 22.9848C18.1948 22.9145 18.0995 22.875 18 22.875\"],[14,\"stroke\",\"#757575\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/chat-bubble-question-mark.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});