define("cc-frontend/components/planner/menus/course-date-annotation-menu/index", ["exports", "@glimmer/component", "cc-frontend/app", "cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_HIDE", "cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_UPDATE", "lodash-es"], function (_exports, _component, _app, _COURSE_DATE_ANNOTATION_HIDE, _COURSE_DATE_ANNOTATION_UPDATE, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu @iconColumns="4" @arrowPlacement="TOP_RIGHT">
    <div class="g-dropdown-menu__title">
      Note Menu
    </div>
    <DS::DropdownMenu::SingleButtonRow
      @iconName="icons/pencil"
      @clickAction={{this.edit}}
      @isAtTopOfMenu={{true}}
    >
      Edit Note
    </DS::DropdownMenu::SingleButtonRow>
    <DS::DropdownMenu::SingleButtonRow @iconName="icons/add-circle" @clickAction={{this.delete}}>
      Remove Note & Turn On
    </DS::DropdownMenu::SingleButtonRow>
    <DS::DropdownMenu::SingleButtonRow @iconName="icons/trash-can" @clickAction={{this.delete}}>
      Remove Note & Hide Class
    </DS::DropdownMenu::SingleButtonRow>
  </DS::DropdownMenu>
  */
  {
    "id": "M2TRmPRn",
    "block": "[[[8,[39,0],null,[[\"@iconColumns\",\"@arrowPlacement\"],[\"4\",\"TOP_RIGHT\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"g-dropdown-menu__title\"],[12],[1,\"\\n    Note Menu\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@iconName\",\"@clickAction\",\"@isAtTopOfMenu\"],[\"icons/pencil\",[30,0,[\"edit\"]],true]],[[\"default\"],[[[[1,\"\\n    Edit Note\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@iconName\",\"@clickAction\"],[\"icons/add-circle\",[30,0,[\"delete\"]]]],[[\"default\"],[[[[1,\"\\n    Remove Note & Turn On\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@iconName\",\"@clickAction\"],[\"icons/trash-can\",[30,0,[\"delete\"]]]],[[\"default\"],[[[[1,\"\\n    Remove Note & Hide Class\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-s/dropdown-menu\",\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/course-date-annotation-menu/index.hbs",
    "isStrictMode": false
  });

  let CourseDateAnnotationMenuComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class CourseDateAnnotationMenuComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);

      _initializerDefineProperty(this, "courseCalendarEditor", _descriptor3, this);
    }

    edit() {
      this.args.closeMenu();
      this.modalManager.openModal("planner/modals/edit-annotation", {
        allowRangeToggle: false,
        dateIs: "SINGLE_DATE",
        datesAreEditable: false,
        description: this.args.annotation.attributes.description,
        endDate: this.args.courseDate.dateString,
        isRange: false,
        minimumStartDate: this.args.courseDate.dateString,
        modalTitle: "Edit Special Event",
        startDate: this.args.courseDate.dateString,
        submitButtonText: "Update",
        title: this.args.annotation.attributes.title,
        submitAction: ({
          title,
          description,
          endDate,
          startDate
        }) => {
          let newAnnotation = (0, _lodashEs.cloneDeep)(this.args.annotation);
          newAnnotation.attributes.title = title;
          newAnnotation.attributes.description = description;
          this.store.dispatchPreparedAction((0, _COURSE_DATE_ANNOTATION_UPDATE.default)({
            annotation: newAnnotation,
            course: this.args.course
          }));
        }
      });
    } // TODO Change this to use the sub-menu like we do for all our other menus


    delete() {
      this.args.closeMenu();
      swal({
        title: "Turn Class Back On?",
        text: "This will also delete the note you left.",
        type: "question",
        showCancelButton: true
      }).then(() => {
        this.courseCalendarEditor.toggleCustomDate(this.args.course, this.args.courseDate.dateString, true, false);
      }).then(() => {
        swal({
          type: "success",
          title: "The class is back on!"
        });
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

    hideClass() {
      this.args.closeMenu();
      swal({
        title: "Hide Class?",
        text: "Are you sure you want to hide the class for this day?",
        type: "warning",
        showCancelButton: true
      }).then(() => {
        this.store.dispatchAction(_COURSE_DATE_ANNOTATION_HIDE.default, {
          annotation: this.args.annotation,
          course: this.args.course
        });
      }).then(() => {
        swal({
          type: "success",
          title: "The class is hidden!",
          text: 'Go to the "Day Menu" to turn the class back on.'
        }).catch(error => {
          if (error instanceof Error) (0, _app.handleError)(error);
        });
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "courseCalendarEditor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideClass", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "hideClass"), _class.prototype)), _class));
  _exports.default = CourseDateAnnotationMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CourseDateAnnotationMenuComponent);
});