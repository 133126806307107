define("cc-frontend/templates/print/course-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x6KPPg1t",
    "block": "[[[10,0],[14,0,\"ccp-page-header\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"ccp-page-header__title\"],[12],[1,\"\\n    \"],[1,[34,0]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ccp-outline__course-title tw-text-center\"],[12],[1,[30,0,[\"course\",\"content\",\"attributes\",\"title\"]]],[1,\"\\n    Outline\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[28,[37,2],[[28,[37,3],[[30,0,[\"course\",\"content\",\"meta\",\"permissions\"]],\"can-view-plan\"],null]],null],[[[1,\"  You don't have permission to print this plan.\\n\"]],[]],[[[41,[33,4,[\"isFulfilled\"]],[[[1,\"  \"],[10,0],[14,0,\"ccp-print-outline\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@course\",\"@planbook\",\"@rotationCalendar\",\"@startDate\",\"@endDate\",\"@isPrinting\"],[[30,0,[\"course\",\"content\"]],[30,0,[\"planbook\",\"content\"]],[30,0,[\"rotationCalendar\",\"content\"]],[30,0,[\"firstDateOfLessons\"]],[30,0,[\"lastDateOfLessons\"]],true]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],null]],[]]]],[],false,[\"headerText\",\"if\",\"not\",\"includes\",\"rotationCalendar\",\"planner/course/outline-and-detail/outline\"]]",
    "moduleName": "cc-frontend/templates/print/course-outline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});