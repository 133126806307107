define("cc-frontend/utils/course/update-course-to-rotation-calendar", ["exports", "lodash-es", "cc-frontend/utils/filter-dates/course", "cc-frontend/utils/filter-dates/rotation-calendar", "cc-frontend/utils/filter-dates/shared"], function (_exports, _lodashEs, _course, _rotationCalendar, _shared) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateCourseToRotationCalendar = updateCourseToRotationCalendar;

  function updateCourseToRotationCalendar(sourceRotationCalendar, destinationRotationCalendar, course) {
    let effectiveDate = sourceRotationCalendar.attributes.datesLastChangedOn;

    if (effectiveDate === null || effectiveDate === undefined || typeof effectiveDate !== "string") {
      // TODO This is a truly terribly named variable that does something very different than what it sounds
      // Originally, it was supposed to see if something was updated to the most recent version of a rotationcalendar
      // At least, that's my memory and how it sounds. In practice, it sounds like a variable we pass around
      // to say from which date to change dates. This NEEDS to be resolved.
      throw new Error("Old Rotation Calendar needs a `datesLastChangedOn` Property");
    }

    let availableRotationIds = (0, _lodashEs.map)(sourceRotationCalendar.attributes.rotations, "id");
    let defaultAnnotations = (0, _lodashEs.filter)(course.attributes.calendar.dateAnnotations, annotation => annotation.type === "date-annotation-course-default");
    let rotationTurnedOffFor = (0, _lodashEs.map)((0, _lodashEs.filter)(defaultAnnotations, annotation => {
      return annotation.attributes.isOff === true;
    }), annotation => annotation.attributes.rotationId);
    /**
     * cardStackIds/DateStrings we need to add as the rotation calendar changed what we had in these dates
     */

    let newCustomDates = generateCustomDates({
      sourceRotationCalendar,
      destinationRotationCalendar,
      effectiveDate,
      customDates: (0, _course.filterCourseDateCustom)(course),
      filterForRotationIds: (0, _lodashEs.difference)(availableRotationIds, rotationTurnedOffFor)
    });
    /**
     * Turn the array above into course-date-customs
     */

    let newCourseDates = (0, _lodashEs.map)((0, _lodashEs.filter)(newCustomDates, date => date.cardStackId !== null || date.unitStart.length > 0 || date.unitEnd.length > 0), date => {
      return {
        id: date.dateString,
        type: "course-date-custom",
        attributes: {
          cardStackId: date.cardStackId,
          dateString: date.dateString,
          time: null,
          isForcedOn: false,
          unitEnd: date.unitEnd,
          unitIds: date.unitIds,
          unitStart: date.unitStart
        }
      };
    });
    /**
     * Pluck default dates and add as necessary in the next step
     */

    let defaultDates = (0, _course.filterCourseDateDefault)(course);
    /**
     * IDs of Rotations we don't have yet in this planbook yet
     */

    let newRotationIds = (0, _rotationCalendar.pluckNewRotationIds)(sourceRotationCalendar, defaultDates);
    /**
     * The new cards stacks we need to generate for routine templates
     */

    if (newRotationIds.length > 0) {
      let semesterId = sourceRotationCalendar.attributes.semesters[0].id;
      (0, _lodashEs.forEach)(newRotationIds, rotationId => {
        defaultDates.push({
          id: `${semesterId}:${rotationId}`,
          type: "course-date-default",
          attributes: {
            cardStackId: null,
            rotationId: rotationId,
            semesterId: semesterId,
            time: null
          }
        });
      });
    }
    /**
     * --------------------------------------------------------------------------------
     * Combine them all together
     * --------------------------------------------------------------------------------
     */

    /**
     * Pluck out the custom dates that didn't change
     */


    let customDatesToKeep = (0, _shared.filterForDatesBefore)((0, _course.filterCourseDateCustom)(course), effectiveDate);
    return {
      dates: [...defaultDates, ...customDatesToKeep, ...newCourseDates]
    };
  }

  function generateCustomDates({
    sourceRotationCalendar,
    destinationRotationCalendar,
    effectiveDate,
    customDates,
    filterForRotationIds
  }) {
    /**
     * ---------------------------------------------------------------------------------
     * Pluck all the cardStackIds with their rotationId so we can shift them
     * ---------------------------------------------------------------------------------
     */

    /**
     * The dates in the destination calendar on and after the effective date
     */
    let relevantRotationCalendarSourceDates = (0, _lodashEs.reject)((0, _rotationCalendar.filterForRelevantDates)(sourceRotationCalendar, effectiveDate, true, filterForRotationIds), date => date.attributes.rotationId === null);
    let relevantDestinationDates = (0, _rotationCalendar.filterForRelevantDates)(destinationRotationCalendar, effectiveDate, true, filterForRotationIds);
    /**
     * An array of maps of that have all the cardStackId and what rotation they were originally on
     * We keep it in an array so we can reapply them in order if we run out of rotations. A previous
     * implementation was a map: {a: CardStackId[], b: CardStackId[]}, but if we needed to lay down
     * the card stacks ids on days the rotation calendar didn't cover, we had lost their original order.
     */

    let relevantCourseSourceDates = (0, _lodashEs.filter)(customDates, date => date.attributes.dateString >= effectiveDate);
    let cardStackIdsArray = (0, _lodashEs.map)(relevantRotationCalendarSourceDates, date => {
      let courseDate = (0, _shared.findDate)(customDates, date.attributes.dateString);
      return {
        rotationId: date.attributes.rotationId,
        originalDateString: courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.dateString,
        cardStackId: courseDate ? courseDate.attributes.cardStackId : null,
        unitStart: courseDate ? courseDate.attributes.unitStart : [],
        unitEnd: courseDate ? courseDate.attributes.unitEnd : [],
        unitIds: courseDate ? courseDate.attributes.unitIds : []
      };
    });
    (0, _lodashEs.forEach)(relevantCourseSourceDates, courseDate => {
      let foundDate = (0, _lodashEs.find)(cardStackIdsArray, date => date.originalDateString === courseDate.attributes.dateString);

      if (foundDate === undefined) {
        let rotationCalendarDate = (0, _shared.findDate)(relevantRotationCalendarSourceDates, courseDate.attributes.dateString);
        cardStackIdsArray.push({
          originalDateString: courseDate.attributes.dateString,
          rotationId: (rotationCalendarDate === null || rotationCalendarDate === void 0 ? void 0 : rotationCalendarDate.attributes.rotationId) || null,
          cardStackId: courseDate ? courseDate.attributes.cardStackId : null,
          unitStart: courseDate ? courseDate.attributes.unitStart : [],
          unitEnd: courseDate ? courseDate.attributes.unitEnd : [],
          unitIds: courseDate ? courseDate.attributes.unitIds : []
        });
      }
    });
    /**
     * ------------------------------------------------------------------
     * Create new course dates, using the cardStackId we extracted above
     * ------------------------------------------------------------------
     */

    /**
     * Course dates that we need to add to the planbook as a result of the rotation calendar
     * changing.
     */

    let newCustomDates = (0, _lodashEs.compact)((0, _lodashEs.map)(relevantDestinationDates, rotationDate => {
      if (rotationDate.attributes.rotationId === null) return null; // Find a relevant id. This is a bit confusing, but the first branch is for courses
      // where you want to move a lesson to all the available rotations. E.g. a course that is on A annd B days
      // the second fork is for planbooks where you just want to make sure A days move to A days, etc

      let customDate = (0, _lodashEs.find)(cardStackIdsArray, hash => (0, _lodashEs.includes)(filterForRotationIds, hash.rotationId));

      if (customDate) {
        (0, _lodashEs.pull)(cardStackIdsArray, customDate); // remove the element we just removed from the array so we don't reapply it
      }

      let ret = {
        dateString: rotationDate.attributes.dateString,
        cardStackId: customDate ? customDate.cardStackId : null,
        unitStart: customDate ? customDate.unitStart : [],
        unitEnd: customDate ? customDate.unitEnd : [],
        unitIds: customDate ? customDate.unitIds : []
      };
      return ret;
    })); // filter out any remain cardStackIds that are null

    cardStackIdsArray = (0, _lodashEs.reject)(cardStackIdsArray, hash => hash.cardStackId === null && hash.unitEnd.length > 0 && hash.unitStart.length > 0); // Check if we have cardStacks that didn't fit in the available rotations dates

    if (cardStackIdsArray.length > 0) {
      let schoolDays = sourceRotationCalendar.attributes.schoolDays;
      let lastDate = dateFns.parse(sourceRotationCalendar.attributes.semesters[0].endDate);
      let dateToApply = dateFns.addDays(lastDate, 1);

      while (cardStackIdsArray.length > 0) {
        dateToApply = dateFns.addDays(lastDate, 1);

        if ((0, _lodashEs.includes)(schoolDays, dateFns.getDay(dateToApply)) !== true) {
          lastDate = dateToApply;
          continue;
        }

        let customDate = cardStackIdsArray.shift();
        if (customDate === undefined) continue;
        newCustomDates.push({
          dateString: dateFns.format(dateToApply, "YYYY-MM-DD"),
          cardStackId: customDate === null || customDate === void 0 ? void 0 : customDate.cardStackId,
          unitStart: customDate === null || customDate === void 0 ? void 0 : customDate.unitStart,
          unitEnd: customDate === null || customDate === void 0 ? void 0 : customDate.unitEnd,
          unitIds: customDate === null || customDate === void 0 ? void 0 : customDate.unitIds
        });
      }
    }

    return newCustomDates;
  }
});