define("cc-frontend/components/user/create-school-form", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/app", "ember-animated/transitions/fade", "tracked-built-ins"], function (_exports, _glimmerComponent, _app, _fade, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="modal">
    <div local-class="modal-inner">
      <div local-class="modal-inner__close" {{on "click" @closeModal}}>
        <Icons::RemoveCircle />
      </div>
  
      <AnimatedContainer>
  
        {{#animated-if (eq this.formPane 1) use=this.fade}}
          <User::CreateSchoolForm::PaneOne
            @purchasingFor={{this.purchasingFor}}
            @goForward={{this.goForward}}
            @goBack={{this.goBack}}
          />
        {{else}}
          {{#animated-if (eq this.formPane 2) use=this.fade}}
            <User::CreateSchoolForm::PaneTwo
              @schoolInfo={{this.schoolInfo}}
              @updateField={{this.updateField}}
              @goForward={{this.goForward}}
              @goBack={{this.goBack}}
            />
          {{else}}
            {{#if (eq this.formPane 3)}}
              <User::CreateSchoolForm::PaneThree
                @schoolInfo={{this.schoolInfo}}
                @updateField={{this.updateField}}
                @callMe={{this.callMe}}
                @toggleOn={{this.toggleOn}}
                @createSchool={{this.createSchool}}
                @goBack={{this.goBack}}
                @choosePaymentMethod={{this.choosePaymentMethod}}
              />
            {{/if}}
          {{/animated-if}}
        {{/animated-if}}
      </AnimatedContainer>
    </div>
  </div>
  */
  {
    "id": "vhK9Kdns",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"modal\"],[[\"from\"],[\"cc-frontend/components/user/create-school-form.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"modal-inner\"],[[\"from\"],[\"cc-frontend/components/user/create-school-form.css\"]]]]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[[28,[37,0],[\"modal-inner__close\"],[[\"from\"],[\"cc-frontend/components/user/create-school-form.css\"]]]]]],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[6,[39,4],[[28,[37,5],[[30,0,[\"formPane\"]],1],null]],[[\"use\"],[[30,0,[\"fade\"]]]],[[\"default\",\"else\"],[[[[1,\"        \"],[8,[39,6],null,[[\"@purchasingFor\",\"@goForward\",\"@goBack\"],[[30,0,[\"purchasingFor\"]],[30,0,[\"goForward\"]],[30,0,[\"goBack\"]]]],null],[1,\"\\n\"]],[]],[[[6,[39,4],[[28,[37,5],[[30,0,[\"formPane\"]],2],null]],[[\"use\"],[[30,0,[\"fade\"]]]],[[\"default\",\"else\"],[[[[1,\"          \"],[8,[39,7],null,[[\"@schoolInfo\",\"@updateField\",\"@goForward\",\"@goBack\"],[[30,0,[\"schoolInfo\"]],[30,0,[\"updateField\"]],[30,0,[\"goForward\"]],[30,0,[\"goBack\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,5],[[30,0,[\"formPane\"]],3],null],[[[1,\"            \"],[8,[39,9],null,[[\"@schoolInfo\",\"@updateField\",\"@callMe\",\"@toggleOn\",\"@createSchool\",\"@goBack\",\"@choosePaymentMethod\"],[[30,0,[\"schoolInfo\"]],[30,0,[\"updateField\"]],[30,0,[\"callMe\"]],[30,0,[\"toggleOn\"]],[30,0,[\"createSchool\"]],[30,0,[\"goBack\"]],[30,0,[\"choosePaymentMethod\"]]]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[]]]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@closeModal\"],false,[\"local-class\",\"on\",\"icons/remove-circle\",\"animated-container\",\"animated-if\",\"eq\",\"user/create-school-form/pane-one\",\"user/create-school-form/pane-two\",\"if\",\"user/create-school-form/pane-three\"]]",
    "moduleName": "cc-frontend/components/user/create-school-form.hbs",
    "isStrictMode": false
  });

  let CreateSchoolForm = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class CreateSchoolForm extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fade", _fade.default);

      _initializerDefineProperty(this, "rpc", _descriptor, this);

      _initializerDefineProperty(this, "formPane", _descriptor2, this);

      _defineProperty(this, "schoolInfo", new _trackedBuiltIns.TrackedObject({
        schoolType: null,
        title: null,
        numberOfSeats: null,
        location: null,
        role: null,
        call: null,
        phoneNumber: null,
        paymentMethod: null
      }));

      _defineProperty(this, "isCreatingSchool", false);
    }

    goForward(paneNumber) {
      this.formPane = paneNumber;
    }

    goBack(paneNumber) {
      this.formPane = paneNumber;
    }

    purchasingFor(value) {
      this.schoolInfo.schoolType = value;
      this.formPane = 2;
    }

    choosePaymentMethod(value) {
      this.schoolInfo.paymentMethod = value;
    }

    updateField(fieldName, val) {
      // I **think** this is called a form is blurred as the component is being torn down
      if (this.isDestroyed || this.isDestroying) return;
      if (val === undefined) return;
      this.schoolInfo[fieldName] = val;
    }

    toggleOn() {
      this.schoolInfo.call = !this.schoolInfo.call;
    }

    callMe(val) {
      this.schoolInfo.call = val;
    }

    async createSchool() {
      this.isCreatingSchool = true;
      if (this.schoolInfo.schoolType === null) return;
      if (this.schoolInfo.title === null) return;

      try {
        let resp = await this.rpc.group_create_school_v2({
          schoolType: this.schoolInfo.schoolType,
          title: this.schoolInfo.title,
          numberOfSeats: this.schoolInfo.numberOfSeats ? parseInt(this.schoolInfo.numberOfSeats) : null,
          location: this.schoolInfo.location,
          role: this.schoolInfo.role,
          call: this.schoolInfo.call || false,
          phoneNumber: this.schoolInfo.phoneNumber // paymentMethod: this.schoolInfo.paymentMethod,

        });
        this.isCreatingSchool = false;

        if (resp.responseOk) {
          Ember.getOwner(this).lookup("router:main").transitionTo("new-group.show.edit.members", resp.result.groupId);
          this.args.closeModal();
        } else {
          console.error("Creating a school was unsuccessful");
        }
      } catch (error) {
        console.error(error);
        (0, _app.handleError)(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "formPane", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "goForward", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "goForward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goBack", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "purchasingFor", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "purchasingFor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "choosePaymentMethod", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "choosePaymentMethod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateField", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleOn", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "callMe", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "callMe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createSchool", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "createSchool"), _class.prototype)), _class));
  _exports.default = CreateSchoolForm;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CreateSchoolForm);
});