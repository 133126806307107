define("cc-frontend/utils/card-stack/filter-cards", ["exports", "remeda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCardGoogleClassroom = isCardGoogleClassroom;
  _exports.isCardTitleValueHtml = isCardTitleValueHtml;
  _exports.isCardValueHtml = isCardValueHtml;
  _exports.isCardStandards = isCardStandards;
  _exports.isCardCourseSlot = isCardCourseSlot;
  _exports.filterGoogleClassroomCards = filterGoogleClassroomCards;
  _exports.findGoogleClassroomCard = findGoogleClassroomCard;

  function isCardGoogleClassroom(card) {
    return card.type === "card-google-classroom";
  }

  function isCardTitleValueHtml(card) {
    return card.type === "card-title-value-html";
  }

  function isCardValueHtml(card) {
    return card.type === "card-value-html";
  }

  function isCardStandards(card) {
    return card.type === "card-standards";
  }

  function isCardCourseSlot(card) {
    return card.type === "card-course-slot";
  }

  function filterGoogleClassroomCards(cards) {
    return R.filter(cards, isCardGoogleClassroom);
  }

  function findGoogleClassroomCard(cards, id) {
    return R.pipe(cards, R.filter(isCardGoogleClassroom), R.find(card => Ember.get(card, "id") === id));
  }
});