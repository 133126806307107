define("cc-frontend/lib/actions/course/calendar/CHANGE_UNIT_COLOR", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHANGE_UNIT_COLOR = void 0;
  const CHANGE_UNIT_COLOR = {
    name: "CHANGE_UNIT_COLOR",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.color", payload.color).patch;

      let summaryPatch = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.color", payload.color).patch;

      return [patch, summaryPatch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.color", payload.previousColor).patch;

      let summaryPatch = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.color", payload.previousColor).patch;

      return [patch, summaryPatch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.courseId);
      let models = {
        course: course,
        planbook: findFn("planbook", (0, _lodashEs.get)(course, "relationships.planbook.data.id")),
        unit: findFn("card-stack", payload.cardStackId) || findFn("card-stack-summary", payload.cardStackId)
      };
      let courseTitle = (0, _lodashEs.get)(models, "course.attributes.title");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "unit",
        primary_object_id: models.unit.id,
        primary_object_specifier: "color",
        primary_object_id_and_ancestor_ids: [models.planbook.id, models.course.id],
        adverbial_verb: null,
        adverbial_object_type: null,
        adverbial_object_id: null,
        adverbial_object_specifier: null,
        adverbial_object_id_and_ancestor_ids: [models.planbook.id, models.course.id]
      };
      return {
        title: "Changed Unit Color",
        titleForAnalytics: "Unit - Changed Color",
        description: `Changed the color of your unit to ${payload.color}`,
        context: {
          courseTitle,
          planbookTitle: (0, _lodashEs.get)(models, "planbook.attributes.title"),
          unitId: payload.cardStackId,
          courseId: (0, _lodashEs.get)(models, "course.id"),
          planbookId: (0, _lodashEs.get)(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${course.id}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.CHANGE_UNIT_COLOR = CHANGE_UNIT_COLOR;
  var _default = CHANGE_UNIT_COLOR;
  _exports.default = _default;
});