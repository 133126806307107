define("cc-frontend/components/planner/course/create-edit/name-and-more/one-time.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-time-toggle-date-input-group": "_course-time-toggle-date-input-group_pphefz",
    "toggle-button": "_toggle-button_pphefz",
    "checkmark-on": "_checkmark-on_pphefz",
    "checkmark-off": "_checkmark-off_pphefz",
    "toggle-date-group": "_toggle-date-group_pphefz",
    "time-date": "_time-date_pphefz",
    "course-one-time-input-wrapper": "_course-one-time-input-wrapper_pphefz",
    "course-one-time-input": "_course-one-time-input_pphefz",
    "course-time-done-button-wrapper": "_course-time-done-button-wrapper_pphefz",
    "course-time-done-button": "_course-time-done-button_pphefz",
    "course-one-time-input-separator": "_course-one-time-input-separator_pphefz",
    "toggle": "_toggle_pphefz"
  };
  _exports.default = _default;
});