define("cc-frontend/components/d-s/simple-content-editable", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SimpleContentEditable = (_dec = (0, _component.tagName)("div"), _dec2 = (0, _component.attribute)(), _dec3 = (0, _component.attribute)(), _dec4 = Ember.inject.service, _dec5 = (0, _component.attribute)(), _dec6 = Ember.computed("this.isEditable"), _dec(_class = (_class2 = class SimpleContentEditable extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "_value", void 0);

      _defineProperty(this, "tagName", "div");

      _defineProperty(this, "isUserTyping", false);

      _defineProperty(this, "plaintext", true);

      _defineProperty(this, "focusInWordCount", void 0);

      _defineProperty(this, "originalValue", void 0);

      _defineProperty(this, "onKeyDown", void 0);

      _initializerDefineProperty(this, "placeholder", _descriptor, this);

      _initializerDefineProperty(this, "tabindex", _descriptor2, this);

      _initializerDefineProperty(this, "fastboot", _descriptor3, this);
    }

    // init
    didReceiveAttrs() {
      if (this.isUserTyping) return;

      if (this.value !== this._value) {
        if (this.fastboot.isFastBoot !== true) {
          // @ts-ignore
          // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
          Ember.run.scheduleOnce("afterRender", () => this.$().text(this._value) || "");
        }

        Ember.set(this, "_value", this.value);
      }
    } // Cps/getters


    get contentEditable() {
      var editable = this.isEditable;
      return editable ? true : false;
    } // Actions


    mouseLeave() {
      this.set("isUserTyping", false);
    }

    focusIn() {
      var text = this.$().text();
      Ember.set(this, "originalValue", text);
      var wordCount = 0; // if there are words

      if (text.length > 0) {
        wordCount = text.split(" ").length;
      }

      this.set("focusInWordCount", wordCount);
      this.set("isUserTyping", true);
      if (this.onFocusIn) this.onFocusIn();
      return true;
    }

    focusOut() {
      this.set("isUserTyping", false);
      if (this.onFocusOut) this.onFocusOut();
      if (!this.isEditable) return;
      var text = this.$().text();
      let focusOutWordCount;
      if (text.length > 0) focusOutWordCount = text.split(" ").length;
      let wordCountChange = focusOutWordCount - this.focusInWordCount;

      if (text !== this.originalValue) {
        this.onFinishedEditing(text, this.originalValue, wordCountChange);
      } // we've gotten value updates, so once we focus out, we set those


      Ember.set(this, "_value", this.value); // Ugh. This prevents a weird double text display where it looks like {{_value}}{{_value}}. Presumably,
      // Ember is getting confused by the text typed in and {{_value}}. This line straightens that out
      //@ts-ignore - asking for 3 args, works with 2
      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions

      Ember.run.scheduleOnce("afterRender", () => {
        if (this.$()) this.$().text(this._value) || "";
      });
      return true;
    }

    keyDown(event) {
      if (!event.metaKey) {
        this.set("isUserTyping", true);
      } // prevent enter key


      if (event.keyCode === 13 || event.which === 13) {
        event.preventDefault();
        return true;
      }

      if (this.onKeyDown) this.onKeyDown(event);
      return true;
    }

    keyUp(event) {
      // this.set('_value', this.$().text())
      if (!this.isEditable) return;

      if (this.onValueUpdate) {
        let newValue = this.$().text();

        if (newValue !== this.originalValue) {
          this.onValueUpdate(newValue);
        }
      }
    }

    drop(event) {
      event.preventDefault();
    }

    click(event) {
      if (this.onClick) this.onClick(event);
    }

    paste(e) {
      var pastedText;
      e.preventDefault();
      if (!this.isEditable) return; //@ts-ignore - ts can't type window?

      if (window.clipboardData && window.clipboardData.getData) {
        // IE
        //@ts-ignore
        pastedText = window.clipboardData.getData("URL");
      } else if (e.originalEvent.clipboardData && e.originalEvent.clipboardData.getData) {
        pastedText = e.originalEvent.clipboardData.getData("text/plain");
      }

      document.execCommand("insertHTML", false, pastedText);
      this.set("_value", this.$().text());
      if (this.onValueUpdate) this.onValueUpdate(this.$().text());
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "placeholder", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Title";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "tabindex", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "fastboot", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "contentEditable", [_dec5, _dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "contentEditable"), _class2.prototype)), _class2)) || _class);
  _exports.default = SimpleContentEditable;
});