define("cc-frontend/lib/actions/lesson/LESSON_CARD_UPDATE", ["exports", "@sentry/browser", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, Sentry, _patchUpdater, _base, _planbook, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareLessonCardUpdate = prepareLessonCardUpdate;

  function prepareLessonCardUpdate(payload) {
    return {
      name: "LESSON_CARD_UPDATE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.sectionId
    }).set("embedded", `attributes.${payload.prop}`, payload.value).inc("embedded", `attributes._revision`, 1).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.sectionId
    }).set("embedded", `attributes.${payload.prop}`, payload.originalValue).inc("embedded", `attributes._revision`, -1).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let card = (0, _lodashEs.find)((0, _lodashEs.get)(models, "cardStack.attributes.cards"), {
      id: payload.sectionId
    });
    let cardTitle = (0, _lodashEs.get)(card, ["attributes", "title"]);
    let title = "";
    let titleForAnalytics = "";
    let description = "";
    let context = (0, _defaultLessonContext.default)(payload, models);
    let sharedEventProperties = {
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "cards",
      adverbial_verb: "editing",
      adverbial_object_type: "card",
      adverbial_object_id: payload.sectionId,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    let newEvent = null;

    if (payload.prop === "title") {
      title = "Renamed Lesson Card";
      titleForAnalytics = "Lesson Card - Edit Title";
      description = `Changed card title from "${payload.originalValue}" to "${payload.value}"`;
      newEvent = { ...(0, _base.createBlankEvent)(),
        ...sharedEventProperties,
        adverbial_object_specifier: "title"
      };
    } else if (payload.prop === "value") {
      title = "Edited Lesson Card";
      titleForAnalytics = "Lesson Card - Edit Value";
      let deltaInWords;

      if (payload.wordCountChange && payload.wordCountChange > 0) {
        deltaInWords = payload.wordCountChange === 1 ? `and added 1 word` : `and added ${payload.wordCountChange} words`;
      } else if (payload.wordCountChange && payload.wordCountChange < 0) {
        deltaInWords = -payload.wordCountChange === 1 ? `and deleted 1 word` : `and deleted ${-payload.wordCountChange} words`;
      } else {
        deltaInWords = "";
      }

      description = `Edited ${cardTitle} ${deltaInWords}.`;
      let newContext = (0, _lodashEs.assign)({}, context, {
        wordCountChange: payload.wordCountChange
      });
      context = newContext;
      newEvent = { ...(0, _base.createBlankEvent)(),
        ...sharedEventProperties,
        adverbial_object_specifier: "value"
      };
    } else if (payload.prop === "color") {
      title = "Changed Lesson Card Color";
      titleForAnalytics = "Lesson Card - Changed Color";
      description = `Changed the color of ${cardTitle}`;
      newEvent = { ...(0, _base.createBlankEvent)(),
        ...sharedEventProperties,
        adverbial_object_specifier: "color"
      };
    }

    if (newEvent === null) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        scope.setExtra("prop", payload.prop);
        Sentry.captureMessage("We updated a property we didn't handle in our analytics");
      });
    }

    return {
      title: title,
      titleForAnalytics: titleForAnalytics,
      description: description,
      context: context,
      activity: (0, _defaultLessonActivity.default)(payload, models),
      newAnalyticsEvents: (0, _lodashEs.compact)([newEvent])
    };
  }
});