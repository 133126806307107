define("cc-frontend/components/planner/card-stacks/lesson/units-in-header.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "units-block-wrapper": "_units-block-wrapper_1r8bgj",
    "units-block": "_units-block_1r8bgj",
    "units-block__unit": "_units-block__unit_1r8bgj",
    "unit-title": "_unit-title_1r8bgj",
    "unit-date-range": "_unit-date-range_1r8bgj",
    "unit-color-block": "_unit-color-block_1r8bgj"
  };
  _exports.default = _default;
});