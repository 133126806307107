define("cc-frontend/components/planner/card-stacks/lesson/date-annotations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="course-title-for-annotations">
    {{@course.attributes.title}}
  </div>
  {{#each @courseDate.visibleAnnotations as |annotation|}}
    <Planner::CardStacks::CourseDateAnnotation
      @annotation={{annotation}}
      @course={{@course}}
      @courseDate={{@courseDate}}
    />
  {{/each}}
  */
  {
    "id": "DKqfXR39",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"course-title-for-annotations\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/date-annotations.css\"]]]]]],[12],[1,\"\\n  \"],[1,[30,1,[\"attributes\",\"title\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2,[\"visibleAnnotations\"]]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@annotation\",\"@course\",\"@courseDate\"],[[30,3],[30,1],[30,2]]],null],[1,\"\\n\"]],[3]],null]],[\"@course\",\"@courseDate\",\"annotation\"],false,[\"local-class\",\"each\",\"-track-array\",\"planner/card-stacks/course-date-annotation\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson/date-annotations.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});