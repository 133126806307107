define("cc-frontend/lib/actions/course/calendar/MOVE_UNIT", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "@mobily/ts-belt", "cc-frontend/utils/filter-dates/course"], function (_exports, _lodashEs, _patchUpdater, _unitUtils, _base, _planbook, _tsBelt, _course) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "MOVE_UNIT",
    params: {
      unitId: "string",
      amount: "number",
      course: "object",
      planbook: "object",
      newStartDate: "string",
      rotationCalendar: "object"
    },

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let unit = (0, _lodashEs.find)(payload.course.attributes.calendar.units, {
        id: payload.unitId
      });
      if (unit === undefined || unit === null) return [];
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        transferrableArray,
        courseDatesWithoutUnit
      } = (0, _unitUtils.extractUnit)(payload.amount, unit, payload.course.attributes.calendar.dates, consolidatedCourseDatesOff, schoolDays);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, payload.newStartDate, payload.amount, unit, courseDatesWithoutUnit, consolidatedCourseDatesOff, schoolDays, true);

      try {
        let ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
        let {
          courseDates,
          units
        } = ret;
        let unitIdsOnTheMove = (0, _lodashEs.get)((0, _lodashEs.find)(units, {
          id: payload.unitId
        }), "descendantIds") || [];
        unitIdsOnTheMove = unitIdsOnTheMove.concat([payload.unitId]);
        let cardStackIdsOnTheMove = (0, _tsBelt.pipe)(courseDates, _tsBelt.A.filter(_course.isCourseDateCustom), _tsBelt.A.filter(cd => (0, _lodashEs.includes)(cd.attributes.unitIds, payload.unitId)), _tsBelt.A.map(cd => cd.attributes.cardStackId));

        let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).patch;

        patch.unitIdsOnTheMove = unitIdsOnTheMove;
        patch.cardStackIdsOnTheMove = cardStackIdsOnTheMove;
        return [patch];
      } catch (e) {
        console.error(e);
        return [];
      }
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let course = findFn("course", payload.course.id);
      let planbook = findFn("planbook", payload.planbook.id);
      let unit = findFn("card-stack", payload.unitId) || findFn("card-stack-summary", payload.unitId) || {};
      let unitTitle = (0, _lodashEs.get)(unit, "attributes.title");
      let amount = Math.abs(payload.amount);
      let direction = payload.amount > 0 ? "forward" : "backwards";
      let dayOrDays = payload.amount === 1 ? "day" : "days";
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "move",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "unit",
        primary_object_id: payload.unitId,
        primary_object_specifier: null,
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
        adverbial_verb: "dragging_dropping",
        adverbial_object_type: "unit",
        adverbial_object_id: payload.unitId,
        adverbial_object_specifier: null,
        adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id]
      };
      return {
        title: "Moved Unit",
        titleForAnalytics: "Unit - Move",
        description: `Moved "${unitTitle}" ${direction} ${amount} ${dayOrDays}`,
        context: {
          unitTitle: (0, _lodashEs.get)(unit, "attributes.title"),
          courseTitle: (0, _lodashEs.get)(course, "attributes.title"),
          planbookTitle: (0, _lodashEs.get)(planbook, "attributes.title"),
          unitId: (0, _lodashEs.get)(unit, "id"),
          courseId: (0, _lodashEs.get)(course, "id"),
          planbookId: (0, _lodashEs.get)(planbook, "id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `unit-${payload.unitId}`,
          to: [`course-edits:course-${course.id}`, `planbook-edits:planbook-${planbook.id}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.default = _default;
});