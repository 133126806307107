define("cc-frontend/components/planner/course/outline-and-detail/unit-row", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="course-outline-unit depth-{{@unit.ancestorIds.length}} {{if @hasFocus "is-focused-on"}}"
  >
    <div class="course-outline-unit__date-range">
      {{date-range @unit.startDate @unit.endDate}}
    </div>
    {{#if @isSelectable}}
      <span
        class="course-outline-unit__icon {{if this.isSelected "is-selected"}}"
        {{on "click" (fn this.unitSelect @unit)}}
      >
        <Icons::IosCheckmarkOutline />
      </span>
    {{else}}
      <span class="course-outline-unit__icon">
        <Icons::IosFolderOutline />
      </span>
    {{/if}}
    <input
      value={{@cardStack.attributes.title}}
      data-card-stack-id={{@cardStack.id}}
      class="js-course-outline-input course-outline-unit__input"
      data-course-outline-input-id={{@unit.id}}
      readonly={{this.isReadOnly}}
      placeholder="Unit Title"
      {{on "focus" this.onFocusIn}}
      {{on "keyDown" this.keyDown}}
      {{on "input" this.onTitleIntermediateEdit}}
      {{on "blur" this.onTitleEdited}}
    />
  </div>
  {{yield}}
  */
  {
    "id": "QXHW7q0q",
    "block": "[[[10,0],[15,0,[29,[\"course-outline-unit depth-\",[30,1,[\"ancestorIds\",\"length\"]],\" \",[52,[30,2],\"is-focused-on\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"course-outline-unit__date-range\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1,[\"startDate\"]],[30,1,[\"endDate\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[11,1],[16,0,[29,[\"course-outline-unit__icon \",[52,[30,0,[\"isSelected\"]],\"is-selected\"]]]],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"unitSelect\"]],[30,1]],null]],null],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"course-outline-unit__icon\"],[12],[1,\"\\n      \"],[8,[39,5],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[11,\"input\"],[16,2,[30,4,[\"attributes\",\"title\"]]],[16,\"data-card-stack-id\",[30,4,[\"id\"]]],[24,0,\"js-course-outline-input course-outline-unit__input\"],[16,\"data-course-outline-input-id\",[30,1,[\"id\"]]],[16,\"readonly\",[30,0,[\"isReadOnly\"]]],[24,\"placeholder\",\"Unit Title\"],[4,[38,2],[\"focus\",[30,0,[\"onFocusIn\"]]],null],[4,[38,2],[\"keyDown\",[30,0,[\"keyDown\"]]],null],[4,[38,2],[\"input\",[30,0,[\"onTitleIntermediateEdit\"]]],null],[4,[38,2],[\"blur\",[30,0,[\"onTitleEdited\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,5,null]],[\"@unit\",\"@hasFocus\",\"@isSelectable\",\"@cardStack\",\"&default\"],false,[\"if\",\"date-range\",\"on\",\"fn\",\"icons/ios-checkmark-outline\",\"icons/ios-folder-outline\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/course/outline-and-detail/unit-row.hbs",
    "isStrictMode": false
  });

  let UnitRow = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed("selectedUnitIds.[]"), _dec5 = Ember.computed("canBeEditable", "cardStack.meta.permissions"), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class UnitRow extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "lessonEditor", _descriptor3, this);

      _defineProperty(this, "modelsNeededForFirstEdit", void 0);

      _defineProperty(this, "arrowUpDown", void 0);

      _defineProperty(this, "canBeEditable", void 0);

      _defineProperty(this, "cardStack", void 0);

      _defineProperty(this, "selectedUnitIds", void 0);

      _defineProperty(this, "unit", void 0);

      _defineProperty(this, "onUnitSelect", void 0);

      _defineProperty(this, "isSelectable", void 0);

      _defineProperty(this, "hasFocus", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "course", void 0);

      _defineProperty(this, "rotationCalendar", void 0);
    }

    get isSelected() {
      let selectedUnitIds = Ember.get(this, "selectedUnitIds");
      return (0, _lodashEs.includes)(selectedUnitIds, this.cardStack.id);
    }

    get isReadOnly() {
      if (this.canBeEditable === false) return true;

      if (this.cardStack) {
        return !(0, _lodashEs.includes)(this.cardStack.meta.permissions, "can-edit-plan");
      } else {
        return false;
      }
    }

    keyDown(event) {
      if (event.keyCode === 38) this.arrowUpDown("up");
      if (event.keyCode === 40) this.arrowUpDown("down");
    }

    onTitleIntermediateEdit(event) {
      let value = event.target.value;
      if (this.session.isAuthenticated !== true) return;
      this.lessonEditor.editTitle(this.modelsNeededForFirstEdit, this.cardStack.id, value, false);
    }

    onTitleEdited(event) {
      let value = event.target.value;
      if (this.session.isAuthenticated !== true) return;
      this.lessonEditor.finishEditingTitle(this.modelsNeededForFirstEdit, this.cardStack.id, value, this.cardStack.attributes.title || null);
    }

    unitSelect(cardStackId) {
      if (this.onUnitSelect) {
        this.onUnitSelect(cardStackId);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lessonEditor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isReadOnly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keyDown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "keyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTitleIntermediateEdit", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onTitleIntermediateEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTitleEdited", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onTitleEdited"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unitSelect", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "unitSelect"), _class.prototype)), _class));
  _exports.default = UnitRow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnitRow);
});