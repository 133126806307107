define("cc-frontend/components/icons/save-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    ...attributes
  >
    <g clip-path="url(#a)">
      <rect width="18" height="18" fill="#fff" rx="9"></rect>
      <path
        fill="#00E1A2"
        d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm5.197 6.15L9.06 13.117a.75.75 0 0 1-1.073.143L4.32 10.328a.75.75 0 0 1-.113-1.058.75.75 0 0 1 1.043-.112l3.06 2.444 4.68-6.352a.75.75 0 0 1 1.244.059.75.75 0 0 1-.037.841Z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <rect width="18" height="18" fill="#fff" rx="9"></rect>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "hpwNDgl6",
    "block": "[[[11,\"svg\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"width\",\"18\"],[24,\"height\",\"18\"],[24,\"fill\",\"none\"],[24,\"viewBox\",\"0 0 18 18\"],[17,1],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"rect\"],[14,\"width\",\"18\"],[14,\"height\",\"18\"],[14,\"fill\",\"#fff\"],[14,\"rx\",\"9\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,\"fill\",\"#00E1A2\"],[14,\"d\",\"M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm5.197 6.15L9.06 13.117a.75.75 0 0 1-1.073.143L4.32 10.328a.75.75 0 0 1-.113-1.058.75.75 0 0 1 1.043-.112l3.06 2.444 4.68-6.352a.75.75 0 0 1 1.244.059.75.75 0 0 1-.037.841Z\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"rect\"],[14,\"width\",\"18\"],[14,\"height\",\"18\"],[14,\"fill\",\"#fff\"],[14,\"rx\",\"9\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "cc-frontend/components/icons/save-check.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});