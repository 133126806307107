define("cc-frontend/lib/actions/course/calendar/COURSE_DATE_SAVE_AS_TEMPLATE", ["exports", "cc-frontend/lib/patch-updater", "lodash", "lodash-es", "cc-frontend/models/analytics/planbook", "cc-frontend/models/analytics/base"], function (_exports, _patchUpdater, _lodash, _lodashEs, _planbook, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareSaveAsLessonTemplate = prepareSaveAsLessonTemplate;
  _exports.default = _exports.COURSE_DATE_SAVE_AS_TEMPLATE = void 0;

  function prepareSaveAsLessonTemplate(payload) {
    return {
      name: COURSE_DATE_SAVE_AS_TEMPLATE.name,
      payload: payload,
      patches: COURSE_DATE_SAVE_AS_TEMPLATE.patches(payload),
      undoPatches: COURSE_DATE_SAVE_AS_TEMPLATE.undoPatches,
      narrative: (0, _lodashEs.partial)(COURSE_DATE_SAVE_AS_TEMPLATE.narrative, payload)
    };
  }

  const COURSE_DATE_SAVE_AS_TEMPLATE = {
    name: "COURSE_DATE_SAVE_AS_TEMPLATE",

    patches(payload) {
      let lessonAttributes = _lodash.default.cloneDeep(payload.cardStackAttributes);

      lessonAttributes.version = 0;
      lessonAttributes._revision = 0;
      lessonAttributes.usedAs = "lesson-template";

      let lessonPatch = _patchUpdater.default.create("card-stack", payload.newId).set("relationships.planbook.data", {
        id: payload.planbookId,
        type: "planbook"
      }).set("meta", {
        permissions: ["can-edit-plan"]
      });

      _lodash.default.forEach(lessonAttributes, (val, key) => {
        lessonPatch.set(`attributes.${key}`, val);
      });

      let planbookPatch = _patchUpdater.default.create("planbook", payload.planbookId).push("relationships.cardStackTemplates.data", {
        id: payload.newId,
        type: "card-stack"
      }).patch;

      return [lessonPatch.patch, planbookPatch];
    },

    undoPatches: null,

    narrative(payload, findFn, userId) {
      let models = {
        planbook: findFn("planbook", payload.planbookId)
      };
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_type: "planbook",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_object_id_and_ancestor_ids: [payload.planbookId],
        primary_verb: "edit",
        primary_object_specifier: "templates",
        adverbial_verb: "saving",
        adverbial_object_type: "template"
      };
      return {
        title: null,
        titleForAnalytics: "Lesson - Save to Library",
        description: null,
        context: {
          planbookTitle: models.planbook.attributes.title,
          planbookId: models.planbook.id
        },
        activity: null,
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.COURSE_DATE_SAVE_AS_TEMPLATE = COURSE_DATE_SAVE_AS_TEMPLATE;
  var _default = COURSE_DATE_SAVE_AS_TEMPLATE;
  _exports.default = _default;
});