define("cc-frontend/components/planner/modals/template-library", ["exports", "@ember-decorators/component", "cc-frontend/lib/assign-new-card-ids", "cc-frontend/helpers/has-many"], function (_exports, _component, _assignNewCardIds, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="modal">
    <div local-class="modal-inner">
      <div local-class="modal-inner__close" {{on "click" @closeModal}}>
        <Icons::RemoveCircle />
      </div>
      <Planner::TemplateChooser::ChooseFromGallery
        @planbook={{this.model.planbook}}
        @rotationCalendar={{this.model.rotationCalendar}}
        @chooseTemplate={{this.pasteTemplate}}
        @hideCreateTemplate={{true}}
      />
    </div>
  </div>
  {{yield}}
  */
  {
    "id": "7IfHU+z+",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"modal\"],[[\"from\"],[\"cc-frontend/components/planner/modals/template-library.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"modal-inner\"],[[\"from\"],[\"cc-frontend/components/planner/modals/template-library.css\"]]]]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[[28,[37,0],[\"modal-inner__close\"],[[\"from\"],[\"cc-frontend/components/planner/modals/template-library.css\"]]]]]],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@planbook\",\"@rotationCalendar\",\"@chooseTemplate\",\"@hideCreateTemplate\"],[[30,0,[\"model\",\"planbook\"]],[30,0,[\"model\",\"rotationCalendar\"]],[30,0,[\"pasteTemplate\"]],true]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null]],[\"@closeModal\",\"&default\"],false,[\"local-class\",\"on\",\"icons/remove-circle\",\"planner/template-chooser/choose-from-gallery\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/modals/template-library.hbs",
    "isStrictMode": false
  });

  let TemplateLibraryComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _hasMany.default)("model.planbook", "card-stack", "cardStackTemplates"), _dec6 = Ember._action, _dec(_class = (_class2 = class TemplateLibraryComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "model", void 0);

      _defineProperty(this, "closeModal", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);

      _initializerDefineProperty(this, "lessonEditor", _descriptor3, this);

      _initializerDefineProperty(this, "lessonTemplates", _descriptor4, this);
    }

    // Actions
    pasteTemplate(template) {
      let newTemplateAttributes = (0, _assignNewCardIds.default)(template.attributes);
      newTemplateAttributes.title = null;
      this.lessonEditor.pasteCardStack(this.model.modelsNeededForFirstEdit, this.model.cardStack.id, newTemplateAttributes, this.model.cardStack.attributes, "template", {
        id: template.id,
        planbookId: this.model.planbook.id,
        courseId: null,
        // I think this is right -- we should really get the ownerIds from the planbook that's referenced in the card stack
        ownerIds: [this.model.planbook.relationships.owner.data.id],
        version: template.attributes.version
      });
      this.closeModal();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "lessonEditor", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "lessonTemplates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "pasteTemplate", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "pasteTemplate"), _class2.prototype)), _class2)) || _class);
  _exports.default = TemplateLibraryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TemplateLibraryComponent);
});