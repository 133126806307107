define("cc-frontend/services/callbacks/action/analytics", ["exports", "@sentry/browser", "cc-frontend/app", "lodash-es"], function (_exports, Sentry, _app, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.track = track;

  function _default(actionStream) {
    actionStream.onValue(([_type, _payload, actionDoc]) => {
      var _actionDoc$attributes;

      if (actionDoc.attributes.narrative === null) return;
      let humanizedContext = (0, _lodashEs.reduce)(actionDoc.attributes.narrative.context, (acc, v, k) => {
        return (0, _lodashEs.assign)(acc, {
          [(0, _lodashEs.map)((0, _lodashEs.words)(k), _lodashEs.capitalize).join(" ")]: v
        });
      }, {});
      let title = actionDoc.attributes.narrative.titleForAnalytics;
      let sessionUrl = (0, _app.generateFullStorySessionUrl)(true);
      let context = (0, _lodashEs.assign)({}, humanizedContext, {
        Description: Ember.get(actionDoc.attributes.narrative, "description"),
        "Action Id": actionDoc.id,
        "Session Id": actionDoc.relationships.session.data.id,
        // "Sequence Number":           actionDoc.relationships.originatingUser.data.id,
        "Seconds Since Last Action": actionDoc.attributes.timing.secondsSinceLastClientAction,
        "FullStory Url": sessionUrl,
        URL: typeof window !== "undefined" ? window.location.href : null
      });
      context = (0, _lodashEs.omitBy)(context, _lodashEs.isNil);
      track(title, context, {
        groupId: (_actionDoc$attributes = actionDoc.attributes.narrative.context) === null || _actionDoc$attributes === void 0 ? void 0 : _actionDoc$attributes.groupId
      }); // We don't need to send all our events to plausible -- it's not doing anything useful
      // if (typeof plausible !== "undefined" && window.plausible && title) {
      //   window.plausible(title, context)
      // }

      Sentry.addBreadcrumb({
        message: actionDoc.attributes.name,
        category: "action",
        data: Object.assign({}, humanizedContext, {
          Title: title,
          Description: Ember.get(actionDoc.attributes.narrative, "description"),
          "Action Id": actionDoc.id,
          "Fullstory Url": sessionUrl
        })
      });
    });
  } // Whitelist of actions to send to Knock.app (via Segment), by their titleForAnalytics.
  // (Knock's web UI gets unhappy if we send too many events.)

  /** WHEN CHANGING: COPY CHANGES TO actions_to_send_to_knock IN THE BACKEND. */


  const actionsToSendToKnock = new Set(["User - Create Account", "User - Apply Promotion", "User - Deleted", "User - Trial Start", "User - Trial End", "User - Change Product", "User - Changed Email", "Planbook - Added Collaborator - Group", "Planbook - Added Collaborator - User", "Planbook - Removed Collaborator", "Planbook - Changed Collaborator Permissions", "Planbook - Changed Public Permissions", "Planbook - Integration - Google Classroom Auth Failed", "Planbook - Integration - Add Google Classroom", "Planbook - Integration - Google Classroom Post Failure", "Invites - Accepted Planbook", "Invites - Accepted Group", "Invites - Accepted to Cc", "Referral - Invite", "Invited - To Group", "Subscription - Payment Failed", "Subscription - Payment SUCCEEDED", "Subscription - Converted to Paid", "Subscription - Updated", "Subscription - Reinstated", "Subscription - Canceled", "Subscription - Created", "RecurringSubscriptionPayment", "CancelSubscription", "CREATE_PLANBOOK", "Planbook - Deleted", "Group - Create", "Group - Request Membership", "Group - Request Ignored", "Group - Change Member Role", "Fiscal Group - Upgraded", "Fiscal Group - Downgraded", "Fiscal Group - Change Teacher Seats", "User - Sign Up", "User - Sign In", "Comment - Add", "Comment - Remove"]);

  function track(title, properties, context) {
    if (title) {
      const sendToKnock = actionsToSendToKnock.has(title);

      _app.analytics.track(title, properties, {
        integrations: Object.assign({
          Amplitude: {
            // Let amplitude group sessions
            // https://segment.com/docs/connections/destinations/catalog/amplitude/
            // We also set this in our next-js frontend so it transfers between
            // code bases and before a user has a session.
            session_id: parseInt(sessionStorage.getItem("CC_SESSION_START_TIME"))
          },
          // Since Knock.app is a Segment webhook, we need to refer to it this way.
          // https://segment.com/docs/connections/destinations/catalog/actions-webhook/
          Webhook: sendToKnock
        }),
        context: context || {}
      }); // Send our foo page visits as analytics.page()


      if (title === "Page - Visit") {
        _app.analytics.page("App", properties["Route Name"], {
          fromPage: properties["From Page"],
          fromPagePlacement: properties["From Page Placement"]
        });
      }
    }

    if (window && typeof window.sessionRewind !== "undefined") {
      // TODO once sessoinRewind adds support for this.
      // window.sessionRewind.track
      try {
        window.sessionRewind.logEvent(title, properties);
      } catch (e) {
        console.error("Error sending event to sessionRewind", e);
      }
    }
  }
});