define("cc-frontend/components/user/onboarding/existing-planbooks.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-sign__title": "_user-sign__title_hls8ud",
    "user-sign__subtitle": "_user-sign__subtitle_hls8ud"
  };
  _exports.default = _default;
});