define("cc-frontend/lib/actions/lesson/LESSON_CARD_RENAME_ATTACHMENT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _base, _planbook, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_RENAME_ATTACHMENT = void 0;
  const LESSON_CARD_RENAME_ATTACHMENT = {
    name: "LESSON_CARD_RENAME_ATTACHMENT",

    patches(payload) {
      let newAttachments = (0, _lodashEs.cloneDeep)(payload.originalAttachments);
      let attachment = (0, _lodashEs.find)(newAttachments, a => a.id == payload.attachmentId);
      if (attachment === undefined) throw Error("Attachment not found");
      attachment.title = payload.newTitle;

      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      });

      patch.set("embedded", "attributes.attachments", newAttachments);
      return [patch.patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      });

      patch.set("embedded", "attributes.attachments", payload.originalAttachments);
      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let card = (0, _lodashEs.find)((0, _lodashEs.get)(models, "cardStack.attributes.cards"), {
        id: payload.sectionId
      });
      let cardTitle = (0, _lodashEs.get)(card, "attributes.title");
      let attachmentTitle = payload.newTitle;
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = (0, _lodashEs.assign)({}, context, {
        cardTitle: cardTitle,
        attachmentTitle
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "attachments",
        adverbial_verb: "renaming",
        adverbial_object_type: "attachment",
        adverbial_object_specifier: null,
        adverbial_object_id: payload.attachmentId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Renamed a File",
        titleForAnalytics: "Attachment - Renamed",
        description: `Renamed an attachment in "${cardTitle}" to ${payload.newTitle}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "renamed"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_RENAME_ATTACHMENT = LESSON_CARD_RENAME_ATTACHMENT;
  var _default = LESSON_CARD_RENAME_ATTACHMENT;
  _exports.default = _default;
});