define("cc-frontend/lib/actions/planbook/PLANBOOK_CHANGE_TITLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/planbook/default-planbook-activity", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models", "cc-frontend/models/analytics/planbook", "cc-frontend/models/analytics/base"], function (_exports, _patchUpdater, _defaultPlanbookActivity, _defaultPlanbookContext, _defaultPlanbookModels, _planbook, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANBOOK_CHANGE_TITLE = void 0;
  const PLANBOOK_CHANGE_TITLE = {
    name: "PLANBOOK_CHANGE_TITLE",
    params: {
      planbookId: "string",
      title: "string",
      originalTitle: "string"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.title", payload.title).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("planbook", payload.planbookId).set("attributes.title", payload.originalTitle).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
      let newTitle = payload.title;
      let originalTitle = payload.originalTitle;
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        primary_object_id_and_ancestor_ids: [payload.planbookId],
        primary_object_type: "planbook",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_verb: "edit",
        primary_object_specifier: "title"
      };
      return {
        title: "Renamed Planbook",
        titleForAnalytics: "Planbook - Rename",
        description: `Changed the title from ${originalTitle} to ${newTitle}`,
        context: (0, _defaultPlanbookContext.default)(payload, models),
        activity: (0, _defaultPlanbookActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.PLANBOOK_CHANGE_TITLE = PLANBOOK_CHANGE_TITLE;
  var _default = PLANBOOK_CHANGE_TITLE;
  _exports.default = _default;
});