define("cc-frontend/lib/actions/lesson/LESSON_CARD_GC_SCHEDULE_CLASS", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareGCScheduleClass;

  function prepareGCScheduleClass(payload) {
    return {
      name: "LESSON_CARD_GC_SCHEDULE_CLASS",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let removeOld = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.card.id
    }).push("embedded", "attributes.enabledGoogleClassIds", payload.classSetting.googleClassId).pull("embedded", "attributes.classSettings", {
      googleClassId: payload.classSetting.googleClassId
    }).patch; // // It's good practice to clone objects passed in so we don't accidentally see
    // changes appearing in the UI that aren't being persisted to the cardStack.


    let addNew = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.card.id
    }).push("embedded", "attributes.enabledGoogleClassIds", payload.classSetting.googleClassId).push("embedded", "attributes.classSettings", (0, _lodashEs.cloneDeep)(payload.classSetting)).inc("embedded", "attributes._revision", 1).patch;

    return [removeOld, addNew];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "google_classroom",
      adverbial_verb: "editing",
      adverbial_object_type: "card",
      adverbial_object_specifier: "gc_schedule",
      adverbial_object_id: payload.card.id,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return {
      title: "Scheduled a Google Classroom Class",
      titleForAnalytics: "Lesson Card - GC Post - Schedule",
      description: `Scheduled "${payload.className}" to post on "${payload.dateString}"`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models, "created"),
      newAnalyticsEvents: [newEvent]
    };
  }
});