define("cc-frontend/templates/admin/csp/show/standard-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bLiLuckL",
    "block": "[[[10,0],[14,0,\"tw-shadow-lg tw-rounded-lg tw-overflow-y-scroll tw-px-8 tw-py-8 tw-w-full\"],[14,5,\"max-width: calc(33vw - 30px); min-width: calc(33vw - 30px);\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\"],[\"admin.csp.show\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],[[24,0,\"tw-w-12 tw-h-12\"]],null,null]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[44,[[28,[37,3],[\"standard-set-admin\",[30,1,[\"id\"]]],null]],[[[41,[30,2],[[[41,[30,2,[\"isSettled\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@standardSet\"],[[30,2,[\"content\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]]]],[]],null]],[2]]],[13]],[\"@model\",\"standardSet\"],false,[\"link-to\",\"icons/ios-close-outline\",\"let\",\"find-doc\",\"if\",\"admin/csp/standard-set\",\"icons/spinning-circle\"]]",
    "moduleName": "cc-frontend/templates/admin/csp/show/standard-set.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});