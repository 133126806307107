define("cc-frontend/components/icons/filter-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="currentColor"
    viewBox="0 0 25 25"
  >
    <g clip-path="url(#a)">
      <path
        fill-rule="evenodd"
        d="M2.915.875A1.549 1.549 0 0 0 1.796 1.99c-.03.118-.036.221-.026.42.014.295.066.478.194.688.043.07 1.81 2.456 3.928 5.3l3.85 5.172.013 5.016.012 5.016.065.192c.16.469.528.832.99.98.138.043.222.052.457.05.333-.002.503-.046.74-.193.284-.173 2.236-1.656 2.357-1.79.131-.143.268-.383.338-.595.043-.125.045-.334.057-4.404l.012-4.272 3.755-5.04c2.065-2.772 3.813-5.12 3.884-5.22.257-.356.349-.611.348-.972a1.458 1.458 0 0 0-.562-1.168 1.356 1.356 0 0 0-.567-.285c-.178-.046-.36-.047-9.388-.045-7.61.002-9.229.008-9.338.035Zm.12 1.028a.456.456 0 0 0-.258.406.46.46 0 0 0 .035.238c.024.049 1.809 2.46 3.967 5.359 2.158 2.899 3.937 5.305 3.952 5.348.021.057.029 1.317.03 5.094.002 5.593-.01 5.184.167 5.345.12.108.24.147.396.129.152-.018.161-.025 1.382-.945.771-.582.956-.732.996-.81.049-.096.049-.12.06-4.44.012-4.041.016-4.35.054-4.42a987.4 987.4 0 0 1 3.933-5.301 1192.09 1192.09 0 0 0 3.938-5.305.453.453 0 0 0 .067-.36.51.51 0 0 0-.273-.35c-.095-.042-.398-.044-9.218-.043h-9.12l-.108.055Z"
        clip-rule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path d="M.275.838h24v24h-24z"></path>
      </clipPath>
    </defs>
  </svg>
  */
  {
    "id": "s6hnIqn+",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"25\"],[14,\"height\",\"25\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 25 25\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,\"clip-path\",\"url(#a)\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M2.915.875A1.549 1.549 0 0 0 1.796 1.99c-.03.118-.036.221-.026.42.014.295.066.478.194.688.043.07 1.81 2.456 3.928 5.3l3.85 5.172.013 5.016.012 5.016.065.192c.16.469.528.832.99.98.138.043.222.052.457.05.333-.002.503-.046.74-.193.284-.173 2.236-1.656 2.357-1.79.131-.143.268-.383.338-.595.043-.125.045-.334.057-4.404l.012-4.272 3.755-5.04c2.065-2.772 3.813-5.12 3.884-5.22.257-.356.349-.611.348-.972a1.458 1.458 0 0 0-.562-1.168 1.356 1.356 0 0 0-.567-.285c-.178-.046-.36-.047-9.388-.045-7.61.002-9.229.008-9.338.035Zm.12 1.028a.456.456 0 0 0-.258.406.46.46 0 0 0 .035.238c.024.049 1.809 2.46 3.967 5.359 2.158 2.899 3.937 5.305 3.952 5.348.021.057.029 1.317.03 5.094.002 5.593-.01 5.184.167 5.345.12.108.24.147.396.129.152-.018.161-.025 1.382-.945.771-.582.956-.732.996-.81.049-.096.049-.12.06-4.44.012-4.041.016-4.35.054-4.42a987.4 987.4 0 0 1 3.933-5.301 1192.09 1192.09 0 0 0 3.938-5.305.453.453 0 0 0 .067-.36.51.51 0 0 0-.273-.35c-.095-.042-.398-.044-9.218-.043h-9.12l-.108.055Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"clipPath\"],[14,1,\"a\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M.275.838h24v24h-24z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/filter-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});