define("cc-frontend/components/school/edit/calendar.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_q812n6",
    "header__close": "_header__close_q812n6",
    "header__text": "_header__text_q812n6",
    "body": "_body_q812n6",
    "annotation-list": "_annotation-list_q812n6",
    "annotation": "_annotation_q812n6",
    "text-side": "_text-side_q812n6",
    "title-date": "_title-date_q812n6",
    "date": "_date_q812n6",
    "title": "_title_q812n6",
    "description": "_description_q812n6",
    "edit-buttons": "_edit-buttons_q812n6",
    "delete-button": "_delete-button_q812n6",
    "edit-button": "_edit-button_q812n6"
  };
  _exports.default = _default;
});