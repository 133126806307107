define("cc-frontend/components/icons/paperclip-highlighted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Attachment-1--Streamline-Ultimate"
  ><desc>Attachment 1 Streamline Icon: https://streamlinehq.com</desc><defs></defs><path
      d="M2 12.001a10 10 0 1 0 20 0 10 10 0 1 0 -20 0"
      fill="#fef9929c"
      stroke-width="1"
    ></path><path
      d="M9.5 17V9.5c0 -1.656 0.843 -3 2.5 -3s2.5 1.344 2.5 3V19c0 2.486 -1.515 4.5 -4 4.5s-4 -2.014 -4 -4.5V6.5c0 -3.314 2.187 -6 5.5 -6s5.5 2.686 5.5 6v9"
      fill="none"
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    ></path></svg>
  */
  {
    "id": "BePcdBwO",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[14,1,\"Attachment-1--Streamline-Ultimate\"],[12],[10,\"desc\"],[12],[1,\"Attachment 1 Streamline Icon: https://streamlinehq.com\"],[13],[10,\"defs\"],[12],[13],[10,\"path\"],[14,\"d\",\"M2 12.001a10 10 0 1 0 20 0 10 10 0 1 0 -20 0\"],[14,\"fill\",\"#fef9929c\"],[14,\"stroke-width\",\"1\"],[12],[13],[10,\"path\"],[14,\"d\",\"M9.5 17V9.5c0 -1.656 0.843 -3 2.5 -3s2.5 1.344 2.5 3V19c0 2.486 -1.515 4.5 -4 4.5s-4 -2.014 -4 -4.5V6.5c0 -3.314 2.187 -6 5.5 -6s5.5 2.686 5.5 6v9\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#000000\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[13],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/paperclip-highlighted.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});