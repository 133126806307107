define("cc-frontend/components/planner/navs/planbook-main-v2.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "home-button": "_home-button_dik4dp",
    "nav-wrapper": "_nav-wrapper_dik4dp",
    "owner-info-and-main-nav-wrapper": "_owner-info-and-main-nav-wrapper_dik4dp",
    "main-nav": "_main-nav_dik4dp",
    "owner-info": "_owner-info_dik4dp",
    "owner-info__right": "_owner-info__right_dik4dp",
    "owner-info__school": "_owner-info__school_dik4dp",
    "owner-info__name": "_owner-info__name_dik4dp",
    "owner-info__planbook-title": "_owner-info__planbook-title_dik4dp",
    "left-nav": "_left-nav_dik4dp",
    "left-nav__item": "_left-nav__item_dik4dp",
    "left-nav-visible-options": "_left-nav-visible-options_dik4dp",
    "responsive-left-wrapper": "_responsive-left-wrapper_dik4dp",
    "responsive-left-nav": "_responsive-left-nav_dik4dp",
    "responsive-left-nav__icon": "_responsive-left-nav__icon_dik4dp",
    "responsive-left-nav__item": "_responsive-left-nav__item_dik4dp",
    "planbook-nav__center-nav__sub__right": "_planbook-nav__center-nav__sub__right_dik4dp",
    "planbook-nav__center-nav__sub__trigger": "_planbook-nav__center-nav__sub__trigger_dik4dp",
    "planbook-nav__center-nav__sub": "_planbook-nav__center-nav__sub_dik4dp",
    "planbook-nav__center-nav__sub__left": "_planbook-nav__center-nav__sub__left_dik4dp",
    "right-nav-button__primary": "_right-nav-button__primary_dik4dp",
    "right-nav-button__primary-disabled": "_right-nav-button__primary-disabled_dik4dp",
    "right-nav-button__primary-icon": "_right-nav-button__primary-icon_dik4dp",
    "right-nav-button__primary-text": "_right-nav-button__primary-text_dik4dp",
    "section-headline": "_section-headline_dik4dp",
    "--is-blank": "_--is-blank_dik4dp",
    "share-menu-wrapper": "_share-menu-wrapper_dik4dp",
    "single-share-link-row": "_single-share-link-row_dik4dp",
    "row": "_row_dik4dp",
    "row-icon": "_row-icon_dik4dp",
    "row-text": "_row-text_dik4dp",
    "row-text-headline": "_row-text-headline_dik4dp",
    "right-nav": "_right-nav_dik4dp",
    "right-nav-button__secondary": "_right-nav-button__secondary_dik4dp",
    "--signed-out": "_--signed-out_dik4dp",
    "right-nav-button__secondary-icon": "_right-nav-button__secondary-icon_dik4dp",
    "--help": "_--help_dik4dp",
    "right-nav-button__secondary-text": "_right-nav-button__secondary-text_dik4dp",
    "responsive-right-nav": "_responsive-right-nav_dik4dp",
    "responsive-right-wrapper": "_responsive-right-wrapper_dik4dp",
    "right-nav-button__responsive": "_right-nav-button__responsive_dik4dp",
    "sign-in": "_sign-in_dik4dp"
  };
  _exports.default = _default;
});