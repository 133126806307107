define("cc-frontend/components/planner/card-stacks/lesson-editable.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-outer-wrapper": "_lesson-outer-wrapper_e5uued",
    "lesson-outer": "_lesson-outer_e5uued",
    "--room-for-collapse-button": "_--room-for-collapse-button_e5uued",
    "lesson": "_lesson_e5uued",
    "lesson-drop-message": "_lesson-drop-message_e5uued",
    "lesson-course-title-block": "_lesson-course-title-block_e5uued",
    "lesson-course-title": "_lesson-course-title_e5uued",
    "lesson-body": "_lesson-body_e5uued",
    "lesson-header": "_lesson-header_e5uued",
    "lesson-block": "_lesson-block_e5uued",
    "lesson-block__left": "_lesson-block__left_e5uued",
    "--show-as-new-lesson": "_--show-as-new-lesson_e5uued",
    "lesson-block__new-indicator": "_lesson-block__new-indicator_e5uued",
    "lesson-block__right": "_lesson-block__right_e5uued"
  };
  _exports.default = _default;
});