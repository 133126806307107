define("cc-frontend/lib/actions/lesson/LESSON_COPY", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareLessonCopy = prepareLessonCopy;
  _exports.default = _exports.LESSON_COPY = void 0;

  function prepareLessonCopy(payload) {
    return {
      name: LESSON_COPY.name,
      payload: payload,
      patches: LESSON_COPY.patches(payload),
      undoPatches: LESSON_COPY.undoPatches,
      narrative: (0, _lodashEs.partial)(LESSON_COPY.narrative, payload)
    };
  }

  const LESSON_COPY = {
    name: "LESSON_COPY",

    patches(payload) {
      // See PLANBOOK_COPY_ALL_LESSONS_FOR_DAY
      // IF use this again, abstract!
      let lessonAttributes = (0, _lodashEs.cloneDeep)(payload.lessonToCopy.attributes);
      lessonAttributes.parentCardStack = {
        id: payload.lessonToCopy.id,
        version: payload.lessonToCopy.attributes.version,
        ownerIds: payload.ownerIds,
        courseId: payload.courseId,
        planbookId: payload.planbookId
      };

      let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.cardStackAttributes", lessonAttributes).patch;

      return [patch];
    },

    undoPatches: null,

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let cardStackTitle = models.cardStack ? models.cardStack.attributes.title : null;
      return {
        title: null,
        titleForAnalytics: "Lesson - Copy",
        description: `Copied "${cardStackTitle}"`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: null
      };
    }

  };
  _exports.LESSON_COPY = LESSON_COPY;
  var _default = LESSON_COPY;
  _exports.default = _default;
});