define("cc-frontend/components/dialogs/link-as-attachment", ["exports", "@glint/environment-ember-loose/glimmer-component", "lodash-es"], function (_exports, _glimmerComponent, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h1 class="create-annotation__title">
    Enter your link information below:
  </h1>
  
  <div class="bold-dialog__form" style="flex-direction:column;">
    <Input
      @value={{@options.url}}
      placeholder="URL"
      class="bold-dialog__form__input test-enter-teams-name"
      {{autofocus}}
    />
    <Input
      @value={{@options.title}}
      placeholder="Title (optional)"
      class="bold-dialog__form__input test-enter-teams-name"
    />
    {{#if (eq this.validity "invalid")}}
      <div class="bold-dialog__form__warning">
        Please enter a valid link.
      </div>
    {{/if}}
  </div>
  <div class="bold-dialog__buttons">
    {{#liquid-if (eq this.validity "valid") class="lf-fade-down"}}
      <div class="bold-dialog__buttons__submit test-create-team-btn" {{on "click" this.doAccept}}>
        Add link
      </div>
    {{/liquid-if}}
    <div
      class="bold-dialog__buttons__cancel test-cancel-create-team-btn"
      {{on "click" (fn @decline @options)}}
    >
      Cancel
    </div>
  </div>
  */
  {
    "id": "0nHW8Z+G",
    "block": "[[[10,\"h1\"],[14,0,\"create-annotation__title\"],[12],[1,\"\\n  Enter your link information below:\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"bold-dialog__form\"],[14,5,\"flex-direction:column;\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"placeholder\",\"URL\"],[24,0,\"bold-dialog__form__input test-enter-teams-name\"],[4,[38,1],null,null]],[[\"@value\"],[[30,1,[\"url\"]]]],null],[1,\"\\n  \"],[8,[39,0],[[24,\"placeholder\",\"Title (optional)\"],[24,0,\"bold-dialog__form__input test-enter-teams-name\"]],[[\"@value\"],[[30,1,[\"title\"]]]],null],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"validity\"]],\"invalid\"],null],[[[1,\"    \"],[10,0],[14,0,\"bold-dialog__form__warning\"],[12],[1,\"\\n      Please enter a valid link.\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"bold-dialog__buttons\"],[12],[1,\"\\n\"],[6,[39,4],[[28,[37,3],[[30,0,[\"validity\"]],\"valid\"],null]],[[\"class\"],[\"lf-fade-down\"]],[[\"default\"],[[[[1,\"    \"],[11,0],[24,0,\"bold-dialog__buttons__submit test-create-team-btn\"],[4,[38,5],[\"click\",[30,0,[\"doAccept\"]]],null],[12],[1,\"\\n      Add link\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[11,0],[24,0,\"bold-dialog__buttons__cancel test-cancel-create-team-btn\"],[4,[38,5],[\"click\",[28,[37,6],[[30,2],[30,1]],null]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@options\",\"@decline\"],false,[\"input\",\"autofocus\",\"if\",\"eq\",\"liquid-if\",\"on\",\"fn\"]]",
    "moduleName": "cc-frontend/components/dialogs/link-as-attachment.hbs",
    "isStrictMode": false
  });

  // List of chars from https://stackoverflow.com/a/1547940 plus % (for %-encoding).
  // Note: This excludes non-ASCII chars, which are arguably legal, especially if they
  // appear in the actual domain name. However, Google Classroom appears to disallow them,
  // so we do too.
  const URL_VALID_REGEX = /^[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]*$/;
  let LinkAsAttachment = (_dec = Ember.computed("args.options.url"), _dec2 = Ember._action, (_class = class LinkAsAttachment extends _glimmerComponent.default {
    get validity() {
      const rawUrl = this.args.options.url;
      if (rawUrl === null) return "empty";
      const url = rawUrl.trim();
      if (url === "") return "empty";
      return URL_VALID_REGEX.test(url) ? "valid" : "invalid";
    }

    doAccept() {
      if (this.validity !== "valid") return;
      const url = this.args.options.url.trim();
      const title = (0, _lodashEs.isEmpty)(this.args.options.title) ? url : this.args.options.title;
      this.args.accept({
        url,
        title
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "validity", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "validity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doAccept", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "doAccept"), _class.prototype)), _class));
  _exports.default = LinkAsAttachment;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LinkAsAttachment);
});