define("cc-frontend/components/icons/question-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="currentColor"
    viewBox="0 0 30 30"
  >
    <path
      fill-rule="evenodd"
      d="M13.815.646A14.358 14.358 0 0 0 5.475 4.2a14.423 14.423 0 0 0-2.483 2.853A14.383 14.383 0 0 0 .95 18.18a14.573 14.573 0 0 0 2.316 5.175 14.457 14.457 0 0 0 7.895 5.53c2.428.685 5.252.685 7.68 0a14.323 14.323 0 0 0 6.341-3.704 14.31 14.31 0 0 0 3.704-6.341c.738-2.625.679-5.604-.163-8.225A14.428 14.428 0 0 0 22.94 2.99 14.213 14.213 0 0 0 14.926.604c-.364.002-.864.02-1.111.042Zm.345 1.232c-1.59.14-2.702.39-4.005.904-4.406 1.735-7.592 5.82-8.188 10.502-.124.972-.138 2.306-.033 3.186.28 2.354 1.161 4.577 2.538 6.405a14.114 14.114 0 0 0 2.653 2.653 13.28 13.28 0 0 0 5.57 2.415c.874.153 1.229.18 2.305.18s1.43-.027 2.305-.18a13.143 13.143 0 0 0 10.2-8.893c.667-2.054.813-4.27.424-6.433a13.647 13.647 0 0 0-1.498-4.112c-2.107-3.659-5.7-6.042-9.886-6.559-.46-.057-2.01-.1-2.385-.068Zm.15 5.336c-2.163.316-3.891 1.93-4.32 4.034-.07.344-.128 1.002-.1 1.15.088.482.67.688 1.03.363.148-.134.202-.286.225-.641.037-.58.141-1 .362-1.467.511-1.08 1.432-1.83 2.615-2.125.287-.072.38-.08.848-.082.572-.001.774.027 1.215.17a3.822 3.822 0 0 1 2.259 1.932c.294.585.408 1.073.407 1.752 0 .496-.04.767-.18 1.195a3.934 3.934 0 0 1-2.297 2.405 6.678 6.678 0 0 1-.502.152c-.19.05-.42.131-.515.182-.341.182-.62.47-.794.82-.168.34-.192.523-.192 1.44 0 .726.006.823.056.93a.628.628 0 0 0 1.146 0c.05-.106.056-.205.057-.9.001-.69.007-.793.056-.9.075-.165.214-.274.418-.33 1.095-.295 1.828-.7 2.51-1.38a5.11 5.11 0 0 0 .076-7.165 5.083 5.083 0 0 0-2.717-1.49c-.422-.084-1.245-.106-1.663-.045Zm.477 14.366a.953.953 0 0 0-.312.14c-.58.418-.51 1.26.135 1.595.192.1.583.1.783.002a.937.937 0 0 0 .496-1.091.918.918 0 0 0-1.102-.646Z"
      clip-rule="evenodd"
    ></path>
  </svg>
  */
  {
    "id": "h7J2IhtV",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"30\"],[14,\"height\",\"30\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 30 30\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M13.815.646A14.358 14.358 0 0 0 5.475 4.2a14.423 14.423 0 0 0-2.483 2.853A14.383 14.383 0 0 0 .95 18.18a14.573 14.573 0 0 0 2.316 5.175 14.457 14.457 0 0 0 7.895 5.53c2.428.685 5.252.685 7.68 0a14.323 14.323 0 0 0 6.341-3.704 14.31 14.31 0 0 0 3.704-6.341c.738-2.625.679-5.604-.163-8.225A14.428 14.428 0 0 0 22.94 2.99 14.213 14.213 0 0 0 14.926.604c-.364.002-.864.02-1.111.042Zm.345 1.232c-1.59.14-2.702.39-4.005.904-4.406 1.735-7.592 5.82-8.188 10.502-.124.972-.138 2.306-.033 3.186.28 2.354 1.161 4.577 2.538 6.405a14.114 14.114 0 0 0 2.653 2.653 13.28 13.28 0 0 0 5.57 2.415c.874.153 1.229.18 2.305.18s1.43-.027 2.305-.18a13.143 13.143 0 0 0 10.2-8.893c.667-2.054.813-4.27.424-6.433a13.647 13.647 0 0 0-1.498-4.112c-2.107-3.659-5.7-6.042-9.886-6.559-.46-.057-2.01-.1-2.385-.068Zm.15 5.336c-2.163.316-3.891 1.93-4.32 4.034-.07.344-.128 1.002-.1 1.15.088.482.67.688 1.03.363.148-.134.202-.286.225-.641.037-.58.141-1 .362-1.467.511-1.08 1.432-1.83 2.615-2.125.287-.072.38-.08.848-.082.572-.001.774.027 1.215.17a3.822 3.822 0 0 1 2.259 1.932c.294.585.408 1.073.407 1.752 0 .496-.04.767-.18 1.195a3.934 3.934 0 0 1-2.297 2.405 6.678 6.678 0 0 1-.502.152c-.19.05-.42.131-.515.182-.341.182-.62.47-.794.82-.168.34-.192.523-.192 1.44 0 .726.006.823.056.93a.628.628 0 0 0 1.146 0c.05-.106.056-.205.057-.9.001-.69.007-.793.056-.9.075-.165.214-.274.418-.33 1.095-.295 1.828-.7 2.51-1.38a5.11 5.11 0 0 0 .076-7.165 5.083 5.083 0 0 0-2.717-1.49c-.422-.084-1.245-.106-1.663-.045Zm.477 14.366a.953.953 0 0 0-.312.14c-.58.418-.51 1.26.135 1.595.192.1.583.1.783.002a.937.937 0 0 0 .496-1.091.918.918 0 0 0-1.102-.646Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/question-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});