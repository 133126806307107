define("cc-frontend/routes/planbookv5/planner", ["exports", "lodash-es", "cc-frontend/lib/is-mobile", "@marcj/marshal"], function (_exports, _lodashEs, _isMobile, _marshal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _dec10, _dec11, _dec12, _dec13, _class3, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let QueryParams = (_dec = _marshal.field.type(String).optional(), _dec2 = _marshal.field.array(String).optional(), _dec3 = _marshal.field.type(Boolean).optional(), _dec4 = _marshal.field.type(Boolean).optional(), _dec5 = _marshal.field.type(Boolean).optional(), _dec6 = _marshal.field.type(String).optional(), _dec7 = _marshal.field.type(String).optional(), _dec8 = _marshal.field.type(String).optional(), _dec9 = _marshal.field.type(Number).optional(), (_class = class QueryParams {
    constructor() {
      _initializerDefineProperty(this, "date", _descriptor, this);

      _initializerDefineProperty(this, "hiddenCourseIds", _descriptor2, this);

      _initializerDefineProperty(this, "classesLinedUp", _descriptor3, this);

      _initializerDefineProperty(this, "daysAsRows", _descriptor4, this);

      _initializerDefineProperty(this, "lessonsCollapsed", _descriptor5, this);

      _initializerDefineProperty(this, "editingClassId", _descriptor6, this);

      _initializerDefineProperty(this, "focusedItemId", _descriptor7, this);

      _initializerDefineProperty(this, "selectedCourseId", _descriptor8, this);

      _initializerDefineProperty(this, "stepName", _descriptor9, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hiddenCourseIds", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "classesLinedUp", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "daysAsRows", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lessonsCollapsed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editingClassId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "focusedItemId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedCourseId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "stepName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  let PlannerRoute = (_dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember._action, (_class3 = class PlannerRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor10, this);

      _initializerDefineProperty(this, "sessionStore", _descriptor11, this);

      _initializerDefineProperty(this, "router", _descriptor12, this);
    }

    beforeModel() {
      if ((0, _isMobile.default)().phone) {
        this.transitionTo("planbookv5.planner.day");
      }
    }

    didTransition() {
      var _this$router$currentR, _this$context$planboo;

      // do not load anything saved if this is coming from a shared link
      if (((_this$router$currentR = this.router.currentRoute) === null || _this$router$currentR === void 0 ? void 0 : _this$router$currentR.queryParams.hideNav) === true) return true; // @ts-ignore Not sure why it doesn't know there's not a context

      let planbookId = (_this$context$planboo = this.context.planbook) === null || _this$context$planboo === void 0 ? void 0 : _this$context$planboo.id;
      let storedLastView = this.sessionStore.fetchObject(`planbook-${planbookId}-lastView`) || {
        route: "planbookv5.planner.week"
      }; // Currently, we're omitting the date so that people aren't taken to a previous week
      // We might make this more sophisticated in the future or remove other settings
      // like filtering classes.

      let queryParams = (0, _lodashEs.omit)(storedLastView.queryParams, ["date", "stepName", "hideNav", "openedModals"]); // We try/catch these b/c parsing JSON always feels a bit dangerous.
      // and we don't want this to fail on us at all.

      try {
        if (queryParams.hiddenCourseIds) {
          queryParams.hiddenCourseIds = JSON.parse(queryParams.hiddenCourseIds);
        }
      } catch (e) {
        console.log("error parsing hiddenCourseIds", e);
      }

      let queryParmasAreValid = (0, _lodashEs.size)((0, _marshal.validate)(QueryParams, queryParams)) === 0;
      Ember.run.scheduleOnce("afterRender", this, () => {
        // if (this.router.currentRouteName === "planbookv5.planner.index") {
        if (this.router.currentRouteName === "planbookv5.planner.index") {
          if (queryParmasAreValid) {
            this.replaceWith(storedLastView.route, {
              queryParams: { ...queryParams
              }
            });
          } else {
            this.replaceWith(storedLastView.route);
          }
        }
      });
      return;
    }

  }, (_descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "sessionStore", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "router", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "didTransition", [_dec13], Object.getOwnPropertyDescriptor(_class3.prototype, "didTransition"), _class3.prototype)), _class3));
  _exports.default = PlannerRoute;
});