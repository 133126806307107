define("cc-frontend/components/planner/menus/day-menu/change-class-order/provider", ["exports", "@ember-decorators/component", "lodash", "cc-frontend/helpers/find-many"], function (_exports, _component, _lodash, _findMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (or this.courses.isPending this.routine.isPending)}}
    <DS::ThreeDotSpinner />
  {{else}}
    {{yield this.courses.content this.course.isPending}}
  {{/if}}
  */
  {
    "id": "zBRsnZMb",
    "block": "[[[41,[28,[37,1],[[30,0,[\"courses\",\"isPending\"]],[30,0,[\"routine\",\"isPending\"]]],null],[[[1,\"  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,[[30,0,[\"courses\",\"content\"]],[30,0,[\"course\",\"isPending\"]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"or\",\"d-s/three-dot-spinner\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/change-class-order/provider.hbs",
    "isStrictMode": false
  });

  let ChangeClassOrderProvider = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.computed("routine.attributes.cards.length"), _dec4 = (0, _findMany.default)("courseIds", "course"), _dec(_class = (_class2 = class ChangeClassOrderProvider extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "routine", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "courses", _descriptor2, this);
    }

    get courseIds() {
      let cards = Ember.get(Ember.get(Ember.get(this, "routine"), "attributes"), "cards");
      return _lodash.default.chain(cards).filter(card => card.type === "card-course-slot").map(card => card.attributes.courseId).value();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "courseIds", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "courseIds"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "courses", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ChangeClassOrderProvider;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChangeClassOrderProvider);
});