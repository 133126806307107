define("cc-frontend/components/planner/menus/planbook-menu/change-background/title", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Planner::Menus::Titles::WithBackArrow @title="Change Title & Background" @goBack={{@goBack}} />
  */
  {
    "id": "6AnD+At6",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@goBack\"],[\"Change Title & Background\",[30,1]]],null]],[\"@goBack\"],false,[\"planner/menus/titles/with-back-arrow\"]]",
    "moduleName": "cc-frontend/components/planner/menus/planbook-menu/change-background/title.hbs",
    "isStrictMode": false
  });

  let PlanbookMenuPrimaryTitleComponent = (_dec = (0, _component.tagName)(""), _dec(_class = class PlanbookMenuPrimaryTitleComponent extends Ember.Component {}) || _class);
  _exports.default = PlanbookMenuPrimaryTitleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlanbookMenuPrimaryTitleComponent);
});