define("cc-frontend/lib/actions/lesson/LESSON_CARD_PASTE", ["exports", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _base, _planbook, _lodashEs, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lessonCardPasteAction;

  function lessonCardPasteAction(payload) {
    return {
      name: "LESSON_CARD_PASTE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.cardToReplaceId
    });

    if (payload.cardToReplace.type === "card-standards" && payload.cardToPaste.type === "card-standards") {
      patch.addToSet("embedded", "attributes.standards", ["$each", payload.cardToPaste.attributes.standards]);
    } else {
      // Note: This action may change the card's type.
      // If so, the card may end up with extra properties for its type, since the
      // old type's properties are not deleted.
      patch.set("embedded", `type`, payload.cardToPaste.type);
      (0, _lodashEs.each)(payload.cardToPaste.attributes, (val, key) => {
        if (key === "position") return;
        patch.set("embedded", `attributes.${key}`, val);
      });
    }

    return [patch.patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.cardToReplaceId
    }).set("embedded", `type`, payload.cardToReplace.type);

    (0, _lodashEs.each)(JSON.parse(JSON.stringify(payload.cardToReplace.attributes)), (val, key) => {
      patch.set("embedded", `attributes.${key}`, val);
    });
    return [patch.patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let cardToPasteTitle = (0, _lodashEs.get)(payload.cardToPaste, "attributes.title");
    let cardToReplaceTitle = (0, _lodashEs.get)(payload.cardToReplace, "attributes.title");
    let titleForAnalytics = payload.cardToPaste.type === "card-standards" ? "Lesson Card - Paste Standards" : "Lesson Card - Paste";
    let context = (0, _defaultLessonContext.default)(payload, models);
    let newContext = (0, _lodashEs.assign)({}, context, {
      cardToPasteTitle,
      cardToPasteId: payload.cardToPaste.id,
      cardToReplaceTitle,
      cardToReplaceId: payload.cardToReplaceId
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "cards",
      adverbial_verb: "pasting",
      adverbial_object_type: payload.cardToPaste.type === "card-standards" ? "standard" : "card",
      adverbial_object_specifier: payload.cardToPaste.type === "card-standards" ? "lesson" : null,
      adverbial_object_id: payload.cardToPaste.id,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return {
      title: "Pasted a Lesson Card",
      titleForAnalytics: titleForAnalytics,
      description: `Pasted "${cardToPasteTitle}" on top of "${cardToReplaceTitle}" in your ${context.courseTitle} lesson on ${context.date}`,
      context: newContext,
      activity: (0, _defaultLessonActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});