define("cc-frontend/lib/actions/course/COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/utils/filter-dates/course", "lodash", "lodash-es", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_SAVE_AS_TEMPLATE", "cc-frontend/lib/actions/course/calendar/COURSE_ROTATION_SET_TEMPLATE_ID", "cc-frontend/lib/actions/course/default-course-activity", "cc-frontend/lib/actions/course/default-course-context", "cc-frontend/lib/actions/course/default-course-models"], function (_exports, _patchUpdater, _base, _planbook, _course, _lodash, _lodashEs, _COURSE_DATE_SAVE_AS_TEMPLATE, _COURSE_ROTATION_SET_TEMPLATE_ID, _defaultCourseActivity, _defaultCourseContext, _defaultCourseModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareCourseAssignLessonTemplateToWeek = prepareCourseAssignLessonTemplateToWeek;
  _exports.default = _exports.COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK = void 0;

  function prepareCourseAssignLessonTemplateToWeek(payload) {
    return {
      name: COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.name,
      payload: payload,
      patches: COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.patches(payload),
      undoPatches: COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.undoPatches(payload),
      narrative: (0, _lodash.partial)(COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK.narrative, payload)
    };
  }

  const COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK = {
    name: "COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK",

    patches(payload) {
      // check if there are date defaults
      let defaultDates = (0, _lodash.filter)(payload.course.attributes.calendar.dates, _course.isCourseDateDefault);
      let patches = [];

      if (defaultDates.length === 0) {
        let semester = payload.rotationCalendar.attributes.semesters[0];
        patches = (0, _lodash.map)(payload.rotationCalendar.attributes.rotations, rotation => {
          return _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dates", {
            id: rotation.id,
            type: "course-date-default",
            attributes: {
              rotationId: rotation.id,
              semesterId: semester.id,
              cardStackId: payload.cardStackId,
              time: null
            }
          }).inc("attributes.calendar.version", 1).patch;
        }); // patches.concat([
        //   Patch.create("course", payload.course.id).inc("attributes.calendar.version", 1).patch,
        // ])
        // console.log("patches", patches)
      } else {
        patches = (0, _lodash.flatten)((0, _lodash.map)((0, _lodash.filter)(payload.course.attributes.calendar.dates, date => date.type === "course-date-default"), courseDate => {
          return _COURSE_ROTATION_SET_TEMPLATE_ID.default.patches({
            courseId: payload.course.id,
            rotationId: courseDate.attributes.rotationId,
            semesterId: courseDate.attributes.semesterId,
            planbookId: payload.planbookId,
            dateId: courseDate.id,
            cardStackId: payload.cardStackId,
            currentTemplateId: courseDate.attributes.cardStackId
          });
        }));
      }

      if (payload.shouldCreateNewTemplate === true && payload.newTemplateAttrs !== undefined && payload.cardStackId) {
        (0, _lodashEs.forEach)(_COURSE_DATE_SAVE_AS_TEMPLATE.COURSE_DATE_SAVE_AS_TEMPLATE.patches({
          newId: payload.cardStackId,
          planbookId: payload.planbookId,
          cardStackAttributes: payload.newTemplateAttrs
        }), patch => patches.unshift(patch));
      }

      return patches;
    },

    undoPatches(payload) {
      return (0, _lodash.flatten)((0, _lodash.map)((0, _lodash.filter)(payload.course.attributes.calendar.dates, date => date.type === "course-date-default"), courseDate => {
        return _COURSE_ROTATION_SET_TEMPLATE_ID.default.undoPatches({
          courseId: payload.course.id,
          rotationId: courseDate.attributes.rotationId,
          semesterId: courseDate.attributes.semesterId,
          planbookId: payload.planbookId,
          dateId: courseDate.id,
          cardStackId: payload.cardStackId,
          currentTemplateId: courseDate.attributes.cardStackId
        });
      }));
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultCourseModels.default)(payload, findFn, userId);
      let cardStackIdAndAncestorIds = (0, _lodashEs.compact)([payload.planbookId, payload.course.id, payload.cardStackId]);
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_object_type: "course",
        primary_object_id: payload.course.id,
        primary_object_specifier: "template_assignment",
        primary_object_id_and_ancestor_ids: cardStackIdAndAncestorIds,
        adverbial_verb: "editing",
        adverbial_object_type: "rotation",
        adverbial_object_id: payload.rotationCalendar.id,
        adverbial_object_specifier: "all",
        adverbial_object_id_and_ancestor_ids: cardStackIdAndAncestorIds
      };
      return {
        title: "Assigned Template",
        titleForAnalytics: "Course - Assigned Template to Week",
        description: `Added a template to your ${payload.course.attributes.title} class`,
        context: (0, _defaultCourseContext.default)(payload, models),
        activity: (0, _defaultCourseActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK = COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK;
  var _default = COURSE_ASSIGN_LESSON_TEMPLATE_TO_WEEK;
  _exports.default = _default;
});