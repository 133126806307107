define("cc-frontend/components/planner/card-stacks/lesson/lesson-menu-button", ["exports", "@glint/environment-ember-loose/glimmer-component", "tracked-built-ins"], function (_exports, _glimmerComponent, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    id={{concat "lesson-menu-" @course.id @dateString}}
    type="button"
    class="test-lesson-menu-trigger"
    local-class="lesson-block__menu-trigger"
    data-is-non-instructional={{@course.attributes.isNonInstructional}}
    data-test-lesson-menu-trigger={{concat "lesson-menu-" @course.id @dateString}}
    {{on "click" this.toggleLessonMenu}}
  >
    {{#if this.canShowLessonMenu}}
      <Icons::RemoveCircle />
    {{else}}
      <Icons::EllipsesInCircle />
    {{/if}}
    {{#if this.canShowLessonMenu}}
      <DS::DropdownMenuContainer
        @offset="-8px -36px"
        @target={{concat "#lesson-menu-" @course.id @dateString}}
        @closeMenu={{this.toggleLessonMenu}}
      >
        <Planner::Menus::LessonMenu
          @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
          @arrowPlacement={{if (match-media "(max-width: 649px)") "NONE" ""}}
          @dateString={{@dateString}}
          @planbook={{@planbook}}
          @rotationCalendar={{@rotationCalendar}}
          @editCourseTitleAndOrder={{@editCourseTitleAndOrder}}
          @closeMenu={{this.closeMenu}}
          @lesson={{@lesson}}
          @courseDate={{@courseDate}}
          @course={{@course}}
        />
      </DS::DropdownMenuContainer>
    {{/if}}
  </button>
  */
  {
    "id": "haiX1R1n",
    "block": "[[[11,\"button\"],[16,0,[29,[\"test-lesson-menu-trigger \",[28,[37,0],[\"lesson-block__menu-trigger\"],[[\"from\"],[\"cc-frontend/components/planner/card-stacks/lesson/lesson-menu-button.css\"]]]]]],[16,1,[28,[37,1],[\"lesson-menu-\",[30,1,[\"id\"]],[30,2]],null]],[16,\"data-is-non-instructional\",[30,1,[\"attributes\",\"isNonInstructional\"]]],[16,\"data-test-lesson-menu-trigger\",[28,[37,1],[\"lesson-menu-\",[30,1,[\"id\"]],[30,2]],null]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"toggleLessonMenu\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"canShowLessonMenu\"]],[[[1,\"    \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]]],[41,[30,0,[\"canShowLessonMenu\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@offset\",\"@target\",\"@closeMenu\"],[\"-8px -36px\",[28,[37,1],[\"#lesson-menu-\",[30,1,[\"id\"]],[30,2]],null],[30,0,[\"toggleLessonMenu\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,7],null,[[\"@modelsNeededForFirstEdit\",\"@arrowPlacement\",\"@dateString\",\"@planbook\",\"@rotationCalendar\",\"@editCourseTitleAndOrder\",\"@closeMenu\",\"@lesson\",\"@courseDate\",\"@course\"],[[30,3],[52,[28,[37,8],[\"(max-width: 649px)\"],null],\"NONE\",\"\"],[30,2],[30,4],[30,5],[30,6],[30,0,[\"closeMenu\"]],[30,7],[30,8],[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@course\",\"@dateString\",\"@modelsNeededForFirstEdit\",\"@planbook\",\"@rotationCalendar\",\"@editCourseTitleAndOrder\",\"@lesson\",\"@courseDate\"],false,[\"local-class\",\"concat\",\"on\",\"if\",\"icons/remove-circle\",\"icons/ellipses-in-circle\",\"d-s/dropdown-menu-container\",\"planner/menus/lesson-menu\",\"match-media\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/lesson/lesson-menu-button.hbs",
    "isStrictMode": false
  });

  let LessonMenuButton = (_dec = Ember._action, _dec2 = Ember._action, (_class = class LessonMenuButton extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "canShowLessonMenu", _descriptor, this);
    }

    toggleLessonMenu() {
      if (this.isDestroyed || this.isDestroying) return;
      this.canShowLessonMenu = !this.canShowLessonMenu;
    }

    closeMenu() {
      if (this.isDestroyed || this.isDestroying) return;
      this.canShowLessonMenu = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "canShowLessonMenu", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleLessonMenu", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLessonMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMenu", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeMenu"), _class.prototype)), _class));
  _exports.default = LessonMenuButton;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LessonMenuButton);
});