define("cc-frontend/components/group/dashboard/lessons/planbook.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "user-row": "_user-row_1u7sc3",
    "user-information": "_user-information_1u7sc3",
    "course-status-icon": "_course-status-icon_1u7sc3",
    "name-planbook-group": "_name-planbook-group_1u7sc3",
    "lesson-count": "_lesson-count_1u7sc3"
  };
  _exports.default = _default;
});