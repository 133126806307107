define("cc-frontend/components/d-s/attach-files-dropdown.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "attach-files": "_attach-files_1vxbh0",
    "attach-files__item": "_attach-files__item_1vxbh0",
    "attach-files__item--upload-from-filestack": "_attach-files__item--upload-from-filestack_1vxbh0",
    "attach-files__item--upload-has-started": "_attach-files__item--upload-has-started_1vxbh0",
    "attach-files__item-description": "_attach-files__item-description_1vxbh0"
  };
  _exports.default = _default;
});