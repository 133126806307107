define("cc-frontend/lib/actions/group/GROUP_CHANGE_TITLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "lodash-es"], function (_exports, _patchUpdater, _base, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupChangeTitleAction;

  function groupChangeTitleAction(payload) {
    return {
      name: "GROUP_CHANGE_TITLE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("group", payload.groupId).set("attributes.title", payload.value).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("group", payload.groupId).set("attributes.title", payload.originalValue).patch;

    return [patch];
  }

  function narrative(payload, _findFn, _userId) {
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.groupId,
      root_type: "group",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: "owns",
      primary_object_type: "group",
      primary_object_id: payload.groupId,
      primary_object_specifier: "title",
      primary_object_id_and_ancestor_ids: [payload.groupId],
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_id: null,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: [payload.groupId]
    };
    return {
      title: "Changed Group Title",
      titleForAnalytics: "Group - Changed Title",
      description: `Changed the title from "${payload.originalValue}" to ${payload.value}`,
      context: {
        groupId: payload.groupId
      },
      activity: null,
      newAnalyticsEvents: [newEvent]
    };
  }
});