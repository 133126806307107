define("cc-frontend/components/school/edit/teams", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/app", "cc-frontend/helpers/find-many-altogether", "cc-frontend/utils/group", "ember-could-get-used-to-this", "lodash-es", "remeda", "tracked-built-ins"], function (_exports, _glimmerComponent, _app, _findManyAltogether, _group, _emberCouldGetUsedToThis, _lodashEs, R, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="slideout__body__heading">
    <h2 class="slideout__body__heading__title tw-text-link-blue">
      Teams
    </h2>
    <button
      class="slideout__body__heading__add tw-ml-4 slideout__body__heading__add--subtler test-add-team-btn"
      type="button"
      {{on "click" this.addTeam}}
    >
      {{#if this.isCreatingTeam}}
        <Icons::SpinningCircle />
      {{else}}
        <Icons::IosPlus />
        Add Team
      {{/if}}
    </button>
  </div>
  <h3 class="school-setup__h3">
    Group your teachers into teams to keep your school organized
  </h3>
  {{! <h2 class="school-setup__h2 tw-mb-4">Group your teachers into teams to keep your school organized</h2> }}
  <div class="school-show__teams school-show__teams--edit-page">
    {{#if this.groups.isPending}}
      <div class="tw-mt-12 tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full">
        <DS::ThreeDotSpinner />
      </div>
    {{else}}
      {{#each this.sortedGroups as |team|}}
        <Group::TeamEdit @group={{team}} @parentGroup={{@group}} />
      {{/each}}
    {{/if}}
  </div>
  */
  {
    "id": "okwdGd3N",
    "block": "[[[10,0],[14,0,\"slideout__body__heading\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"slideout__body__heading__title tw-text-link-blue\"],[12],[1,\"\\n    Teams\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"slideout__body__heading__add tw-ml-4 slideout__body__heading__add--subtler test-add-team-btn\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"addTeam\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isCreatingTeam\"]],[[[1,\"      \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],null,null,null],[1,\"\\n      Add Team\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"h3\"],[14,0,\"school-setup__h3\"],[12],[1,\"\\n  Group your teachers into teams to keep your school organized\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"school-show__teams school-show__teams--edit-page\"],[12],[1,\"\\n\"],[41,[30,0,[\"groups\",\"isPending\"]],[[[1,\"    \"],[10,0],[14,0,\"tw-mt-12 tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,6],[[28,[37,6],[[30,0,[\"sortedGroups\"]]],null]],null],null,[[[1,\"      \"],[8,[39,7],null,[[\"@group\",\"@parentGroup\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null]],[]]],[13]],[\"team\",\"@group\"],false,[\"on\",\"if\",\"icons/spinning-circle\",\"icons/ios-plus\",\"d-s/three-dot-spinner\",\"each\",\"-track-array\",\"group/team-edit\"]]",
    "moduleName": "cc-frontend/components/school/edit/teams.hbs",
    "isStrictMode": false
  });

  let SchoolEditTeams = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class SchoolEditTeams extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dialog", _descriptor, this);

      _initializerDefineProperty(this, "modalManager", _descriptor2, this);

      _initializerDefineProperty(this, "rpc", _descriptor3, this);

      _initializerDefineProperty(this, "groups", _descriptor4, this);

      _initializerDefineProperty(this, "isCreatingTeam", _descriptor5, this);

      _initializerDefineProperty(this, "nameOfNewTeam", _descriptor6, this);
    }

    get teamMemberships() {
      return (0, _lodashEs.filter)(Ember.get(this.args.group.attributes, "memberships"), _group.isGroupMembershipTeam);
    }

    get groupIds() {
      return R.pipe(Ember.get(this.args.group.attributes, "memberships"), R.filter(_group.isGroupMembershipGroup), R.map(membership => membership.attributes.groupId));
    }

    get sortedGroups() {
      return (0, _lodashEs.sortBy)(this.groups.content, group => (0, _lodashEs.toLower)(group.attributes.title));
    }

    addTeam() {
      this.dialog.blank("dialogs/ask-for-team-title", {
        className: "bold-dialog",
        acceptHandler: "teamTitleAcceptHandler",
        nameOfNewTeam: null
      }).then(nameOfNewTeam => {
        this.isCreatingTeam = true;
        this.rpc.group_create_team({
          groupId: this.args.group.id,
          title: nameOfNewTeam
        }).then(response => {
          if (response.responseOk) {
            this.isCreatingTeam = false;
            this.nameOfNewTeam = null;
          }
        });
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "groups", [_emberCouldGetUsedToThis.use], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _findManyAltogether.default(() => ["group", this.groupIds]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isCreatingTeam", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nameOfNewTeam", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addTeam", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addTeam"), _class.prototype)), _class));
  _exports.default = SchoolEditTeams;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SchoolEditTeams);
});