define("cc-frontend/components/group/dashboard/lesson-review.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lesson-review-wrapper": "_lesson-review-wrapper_f95t6k",
    "lesson-table-nav": "_lesson-table-nav_f95t6k",
    "date-header": "_date-header_f95t6k",
    "date-header-arrow-button": "_date-header-arrow-button_f95t6k",
    "date-header-date": "_date-header-date_f95t6k",
    "table": "_table_f95t6k",
    "day-of-week": "_day-of-week_f95t6k",
    "loader": "_loader_f95t6k",
    "team-header": "_team-header_f95t6k",
    "user-row__no-planbook": "_user-row__no-planbook_f95t6k",
    "user-information__no-planbook": "_user-information__no-planbook_f95t6k",
    "sidebar-wrapper": "_sidebar-wrapper_f95t6k",
    "sidebar-empty": "_sidebar-empty_f95t6k"
  };
  _exports.default = _default;
});