define("cc-frontend/lib/actions/class-website/default-class-website-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(payload, models, verb = "edited") {
    var _models$planbook, _models$user, _models$planbook2;

    let idForObject = models === null || models === void 0 ? void 0 : (_models$planbook = models.planbook) === null || _models$planbook === void 0 ? void 0 : _models$planbook.id;
    if (Ember.isNone(idForObject)) return;
    return {
      actor: `user-${models === null || models === void 0 ? void 0 : (_models$user = models.user) === null || _models$user === void 0 ? void 0 : _models$user.id}`,
      verb: verb,
      object: `planbook-${idForObject}`,
      to: [`planbook-edits:planbook-${models === null || models === void 0 ? void 0 : (_models$planbook2 = models.planbook) === null || _models$planbook2 === void 0 ? void 0 : _models$planbook2.id}`]
    };
  }
});