define("cc-frontend/lib/actions/user/USER_UPDATE_ACCOUNT", ["exports", "cc-frontend/lib/patch-updater"], function (_exports, _patchUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = userUpdateAccount;

  function userUpdateAccount(payload) {
    return {
      name: "USER_UPDATE_ACCOUNT",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: null
    };
  }

  const patches = function (payload) {
    let patch = _patchUpdater.default.create("user", payload.userId).set(`attributes.${payload.field}`, payload.value).patch;

    return [patch];
  };
});