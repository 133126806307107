define("cc-frontend/lib/actions/course/calendar/PULL_MULTIPLE_LESSONS_BACKWARDS", ["exports", "lodash-es", "cc-frontend/lib/actions/lesson/PULL_LESSON_BACKWARDS", "@mobily/ts-belt", "cc-frontend/lib/find-course-date", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/lib/actions/lesson/default-lesson-models", "moment"], function (_exports, _lodashEs, _PULL_LESSON_BACKWARDS, _tsBelt, _findCourseDate, _base, _planbook, _defaultLessonModels, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparePullMulitpleLessonsBackwards = preparePullMulitpleLessonsBackwards;
  _exports.patches = patches;
  _exports.undoPatches = undoPatches;
  _exports.narrative = narrative;
  _exports.name = void 0;
  const name = "PULL_MULTIPLE_LESSONS_BACKWARDS";
  _exports.name = name;

  function preparePullMulitpleLessonsBackwards(payload) {
    return {
      name: name,
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    return (0, _lodashEs.flatMap)(payload.courses, course => {
      return _PULL_LESSON_BACKWARDS.PULL_LESSON_BACKWARDS.patches({
        dateString: payload.dateString,
        course: course,
        planbook: payload.planbook,
        rotationCalendar: payload.rotationCalendar
      });
    });
  }

  function undoPatches(payload) {
    return (0, _lodashEs.flatMap)(payload.courses, course => {
      return _PULL_LESSON_BACKWARDS.PULL_LESSON_BACKWARDS.undoPatches({
        dateString: payload.dateString,
        course: course,
        planbook: payload.planbook,
        rotationCalendar: payload.rotationCalendar
      });
    });
  }

  function narrative(payload, findFn, userId) {
    let planbookId = payload.planbook.id;
    let activityToValues = (0, _lodashEs.map)(payload.courses, course => {
      return `course-edits:course-${course.id}`;
    });
    let courseDateArray = (0, _lodashEs.compact)((0, _lodashEs.map)(payload.courses, course => {
      return (0, _lodashEs.find)(course.attributes.calendar.dates, date => date.attributes.dateString === payload.dateString);
    }));
    let cardStackIds = (0, _lodashEs.map)(courseDateArray, date => {
      return date.attributes.cardStackId;
    });
    let cardStackArray = (0, _lodashEs.compact)((0, _lodashEs.map)(courseDateArray, date => {
      if (date.attributes.cardStackId === undefined || date.attributes.cardStackId === null) return;
      return findFn("card-stack", date.attributes.cardStackId) || findFn("card-stack-summary", date.attributes.cardStackId);
    }));
    let cardStackTitles = (0, _lodashEs.map)(cardStackArray, cardStack => {
      return cardStack.attributes.title;
    });
    activityToValues.push(`planbook-edits:planbook-${planbookId}`);
    let courseCount = payload.courses.length;
    let formattedDate = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
    let analyticsEvents = (0, _tsBelt.pipe)(payload.courses, _tsBelt.A.filter(course => {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);
      return (courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId) !== undefined && courseDate.attributes.cardStackId !== null;
    }), _tsBelt.A.map(course => {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(course, payload.dateString);
      let cardStackId = courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId;
      if (cardStackId === undefined || cardStackId === null) return null;
      let models = (0, _defaultLessonModels.default)({
        cardStackId: cardStackId,
        planbookId: payload.planbook.id
      }, findFn, userId);
      return { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: null,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "move",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "bumping",
        adverbial_object_type: "day",
        adverbial_object_specifier: "backward",
        adverbial_object_id: payload.dateString,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
    }), arr => (0, _lodashEs.compact)(arr));
    return {
      title: "Pulled Back Lessons",
      titleForAnalytics: "Day - Pull Back",
      description: `Moved ${courseCount} lessons backwards to ${formattedDate}`,
      context: {
        courseIds: (0, _lodashEs.map)(payload.courses, "id"),
        planbookTitle: payload.planbook.attributes.title,
        planbookId: payload.planbook.id,
        cardStackIds,
        cardStackTitles
      },
      activity: {
        actor: `user-${userId}`,
        verb: "edited",
        object: `planbook-${planbookId}`,
        to: activityToValues
      },
      newAnalyticsEvents: analyticsEvents
    };
  }
});