define("cc-frontend/lib/actions/lesson/LESSON_CLEAR", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _base, _planbook, _lodash, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareLessonClear = prepareLessonClear;
  _exports.default = _exports.LESSON_CLEAR = void 0;

  function prepareLessonClear(payload) {
    return {
      name: LESSON_CLEAR.name,
      payload: payload,
      patches: LESSON_CLEAR.patches(payload),
      undoPatches: LESSON_CLEAR.undoPatches(payload),
      narrative: (0, _lodash.partial)(LESSON_CLEAR.narrative, payload)
    };
  }

  const LESSON_CLEAR = {
    name: "LESSON_CLEAR",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", "").set("attributes.cards", []);

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.title", "");

      return [patch.patch, patchForCardStackSummary.patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.originalLessonTitle).set("attributes.cards", JSON.parse(JSON.stringify(payload.originalCards)));

      let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId);

      patchForCardStackSummary.set("attributes.title", payload.originalLessonTitle);
      return [patch.patch, patchForCardStackSummary.patch];
    },

    narrative(payload, findFn, userId) {
      let lessonTitle = payload.originalLessonTitle;
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        primary_object_id: payload.cardStackId,
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "delete",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "removing",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: "entire_object",
        adverbial_object_id: null,
        adverbial_object_id_and_ancestor_ids: null
      };
      return {
        title: "Deleted a lesson",
        titleForAnalytics: "Lesson - Delete",
        description: `Deleted "${lessonTitle}"`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models, "deleted"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CLEAR = LESSON_CLEAR;
  var _default = LESSON_CLEAR;
  _exports.default = _default;
});