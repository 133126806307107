define("cc-frontend/lib/actions/lesson/LESSON_FIRST_EDIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/models/course", "cc-frontend/utils/filter-dates/course", "lodash-es", "moment", "remeda", "cc-frontend/lib/unit-utils", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _patchUpdater, _base, _planbook, _course, _course2, _lodashEs, _moment, R, _unitUtils, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_FIRST_EDIT = void 0;
  const LESSON_FIRST_EDIT = {
    name: "LESSON_FIRST_EDIT",

    // TODO_CALENDAR
    patches(payload) {
      var _payload$parentCardSt, _payload$parentCardSt2, _payload$parentCardSt3, _payload$parentCardSt4, _payload$parentCardSt5;

      // Cases:
      // - the course date doesn't exist
      // - the course date exists but doesn't have a lesson Id
      // Do we send the course.calendar.dates?
      // Do we check that when
      // We also might not have a course -- so, we only check if it's used as a lesson
      // Then, we should probably just send over teh course.calendar.dates so
      // it's easier for any component whose editing the lesson to do it.
      // cases:
      // - lesson title
      // - lesson sections
      // Also, change the title from LESSON_FIRST_EDIT to something more specific like,
      // CREATE_LESSON or something like that.
      // We only call this when...
      // - it's used as a lesson. All other times, the lesson is already edited
      let oldCourseDate = R.pipe(payload.course.attributes.calendar.dates, R.filter(_course2.isCourseDateCustom), R.find(date => date.attributes.dateString === payload.dateString));
      let courseDatesWithLesson;

      if (oldCourseDate) {
        courseDatesWithLesson = (0, _lodashEs.map)(payload.course.attributes.calendar.dates, date => {
          if (date.type === "course-date-custom" && date.attributes.dateString === payload.dateString) {
            let clonedDate = (0, _lodashEs.cloneDeep)(date);
            clonedDate.attributes.cardStackId = payload.cardStackId;
            return clonedDate;
          } else {
            return date;
          }
        });
      } else {
        courseDatesWithLesson = payload.course.attributes.calendar.dates.concat([{
          id: payload.dateString,
          type: "course-date-custom",
          attributes: (0, _course.createCourseDateCustomAttributes)({
            dateString: payload.dateString,
            cardStackId: payload.cardStackId
          })
        }]);
      }

      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(courseDatesWithLesson, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays);
      let coursePatch;
      let newCourseDate = R.pipe(courseDates, R.filter(_course2.isCourseDateCustom), R.find(date => date.attributes.dateString === payload.dateString));

      if ((0, _lodashEs.isNil)(oldCourseDate) && (0, _lodashEs.isNil)(newCourseDate) || newCourseDate === undefined) {
        throw Error("There isn't a course date to work with");
      }

      if (oldCourseDate) {
        coursePatch = _patchUpdater.default.create("course", payload.course.id, "attributes.calendar.dates", {
          id: newCourseDate.id
        }).set("embedded", "attributes.cardStackId", newCourseDate.attributes.cardStackId).set("embedded", "attributes.unitStart", newCourseDate.attributes.unitStart).set("embedded", "attributes.unitEnd", newCourseDate.attributes.unitEnd).set("embedded", "attributes.unitIds", newCourseDate.attributes.unitIds).inc(`attributes.calendar.version`, 1);
      } else {
        coursePatch = _patchUpdater.default.create("course", payload.course.id).push("attributes.calendar.dates", newCourseDate).inc(`attributes.calendar.version`, 1);
      } // Why in the world? Shouldn't this just be the default?


      let lessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set(`meta.permissions`, ["can-comment-plan", "can-edit-comments", "can-add-comments", "can-edit-plan", "can-manage-plan", "can-use-app", "can-view-comment", "can-view-plan"]).set("attributes.title", "").set("attributes.usedAs", "lesson").set("attributes.parentCardStack.id", (_payload$parentCardSt = payload.parentCardStack) === null || _payload$parentCardSt === void 0 ? void 0 : _payload$parentCardSt.id).set("attributes.parentCardStack.planbookId", (_payload$parentCardSt2 = payload.parentCardStack) === null || _payload$parentCardSt2 === void 0 ? void 0 : _payload$parentCardSt2.planbookId).set("attributes.parentCardStack.version", (_payload$parentCardSt3 = payload.parentCardStack) === null || _payload$parentCardSt3 === void 0 ? void 0 : _payload$parentCardSt3.version).set("attributes.parentCardStack.courseId", (_payload$parentCardSt4 = payload.parentCardStack) === null || _payload$parentCardSt4 === void 0 ? void 0 : _payload$parentCardSt4.courseId).set("attributes.parentCardStack.ownerIds", (_payload$parentCardSt5 = payload.parentCardStack) === null || _payload$parentCardSt5 === void 0 ? void 0 : _payload$parentCardSt5.ownerIds).set("meta.isNewLesson", false).set("relationships.course.data", {
        id: payload.course.id,
        type: "course"
      }).set("relationships.planbook.data", {
        id: payload.planbook.id,
        type: "planbook"
      }).isCreatePatch();

      if (payload.setCards === true) {
        lessonPatch.set("attributes.cards", (0, _lodashEs.cloneDeep)(payload.cards));
        lessonPatch.set("meta.cardsUpdatingFromTemplate", false);
      } else {
        lessonPatch.set("attributes.cards", null);
        lessonPatch.set("meta.cardsUpdatingFromTemplate", true);
      }

      let lessonSummaryPatch = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set(`meta.permissions`, ["can-comment-plan", "can-edit-comment", "can-edit-plan", "can-manage-plan", "can-use-app", "can-view-comment", "can-view-plan"]).set("attributes.title", "").set("meta.isNewLesson", false).set("relationships.course.data", {
        id: payload.course.id,
        type: "course"
      }).set("relationships.planbook.data", {
        id: payload.planbook.id,
        type: "planbook"
      }).isCreatePatch();

      return [lessonPatch.patch, coursePatch.patch, lessonSummaryPatch.patch];
    },

    undoPatches(payload) {
      return [_patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", (0, _lodashEs.cloneDeep)(payload.course.attributes.calendar.dates)).set("attributes.calendar.units", (0, _lodashEs.cloneDeep)(payload.course.attributes.calendar.units)).inc(`attributes.calendar.version`, 1).patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let formattedDateString = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        primary_object_id: payload.cardStackId,
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "create",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: null,
        adverbial_object_type: null,
        adverbial_object_specifier: null,
        adverbial_object_id: null,
        adverbial_object_id_and_ancestor_ids: null
      };
      return {
        title: "Created A lesson",
        titleForAnalytics: "Lesson - Created",
        description: `Added a lesson on ${formattedDateString} to ${(0, _lodashEs.get)(models, "course.attributes.title")}`,
        context: (0, _defaultLessonContext.default)(payload, models),
        activity: (0, _defaultLessonActivity.default)(payload, models, "created"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_FIRST_EDIT = LESSON_FIRST_EDIT;
  var _default = LESSON_FIRST_EDIT;
  _exports.default = _default;
});