define("cc-frontend/lib/actions/course/calendar/IMPORT_TRANSFERRABLE_ARRAY", ["exports", "@mobily/ts-belt", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "cc-frontend/models/analytics/base", "cc-frontend/utils/filter-dates/course", "lodash-es", "moment"], function (_exports, _tsBelt, _patchUpdater, _unitUtils, _base, _course, _lodashEs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = importTransferrableArrayAction;
  _exports.narrative = narrative;

  function importTransferrableArrayAction(payload) {
    let schoolDays = payload.rotationCalendar.attributes.schoolDays;
    let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
    let newCourseDates = (0, _unitUtils.insertRange)(payload.transferrableArray, payload.newStartDate, 1, null, payload.course.attributes.calendar.dates, consolidatedCourseDatesOff, schoolDays, true);
    let newDatesUnits = null;

    try {
      newDatesUnits = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
    } catch (e) {
      console.error(e);
    }

    return {
      name: "IMPORT_TRANSFERRABLE_ARRAY",
      payload: payload,
      patches: newDatesUnits === null ? [] : patches(payload.course.id, newDatesUnits.courseDates, newDatesUnits.units),
      undoPatches: undoPatches(payload),
      narrative: newDatesUnits === null ? null : (0, _lodashEs.partial)(narrative, payload, newDatesUnits.courseDates, newDatesUnits.units)
    };
  }

  function patches(courseId, courseDates, units) {
    let patch = _patchUpdater.default.create("course", courseId).inc(`attributes.calendar.version`, 1).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("course", payload.course.id).inc(`attributes.calendar.version`, 1).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).patch;

    return [patch];
  }

  function narrative(payload, courseDates, units, findFn, userId) {
    let formattedDateString = (0, _moment.default)(payload.newStartDate).format("ddd, MMM. D, YYYY");
    let originalPlanbookIdAndOriginalCourseId = (0, _lodashEs.compact)([payload.originalPlanbookId, payload.originalCourseId]);
    let unitCount = (0, _tsBelt.pipe)(payload.transferrableArray, _tsBelt.A.map(el => el.unitStart), _tsBelt.A.flat, _tsBelt.A.uniq, _tsBelt.A.length); // Overall event
    // course event

    let courseEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbook.id,
      root_type: "planbook",
      primary_verb: "edit",
      primary_possessive: "owns",
      primary_object_type: "course",
      primary_object_id: payload.course.id,
      primary_object_specifier: "import",
      primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
      adverbial_verb: "importing",
      adverbial_object_type: "course",
      adverbial_object_id: payload.originalCourseId,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: originalPlanbookIdAndOriginalCourseId
    };
    let courseDateEvents = courseDates.map(el => {
      let dateScheduledFor = (0, _lodashEs.find)((0, _lodashEs.filter)(courseDates, _course.isCourseDateCustom), date => date.attributes.cardStackId === el.attributes.cardStackId);
      return { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        primary_object_id: payload.course.id,
        date_plan_scheduled_for: (dateScheduledFor === null || dateScheduledFor === void 0 ? void 0 : dateScheduledFor.attributes.dateString) || null,
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
        primary_verb: "create",
        primary_possessive: "owns",
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "importing",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: null,
        adverbial_object_id: el.attributes.cardStackId,
        adverbial_object_id_and_ancestor_ids: originalPlanbookIdAndOriginalCourseId
      };
    });
    let unitEvents = units.map(el => {
      return { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "create",
        primary_possessive: "owns",
        primary_object_type: "unit",
        primary_object_id: el.id,
        primary_object_specifier: "import",
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
        adverbial_verb: "importing",
        adverbial_object_type: "unit",
        adverbial_object_id: el.id,
        adverbial_object_specifier: null,
        adverbial_object_id_and_ancestor_ids: originalPlanbookIdAndOriginalCourseId
      };
    });
    return {
      title: `Imported ${payload.transferrableArray.length} lessons and ${unitCount} units`,
      titleForAnalytics: "Import Lessons Modal - Import Successful",
      description: `Imported onto ${formattedDateString} in "${payload.course.attributes.title}"`,
      context: {
        lessonCount: payload.transferrableArray.length,
        unitCount: unitCount,
        courseTitle: payload.course.attributes.title,
        planbookTitle: payload.planbook.attributes.title,
        courseId: payload.course.id,
        planbookId: payload.planbook.id
      },
      activity: {
        actor: `user-${userId}`,
        verb: "edited",
        object: `course-${payload.course.id}`,
        to: [`course-edits:course-${payload.course.id}`, `planbook-edits:planbook-${payload.planbook.id}`]
      },
      newAnalyticsEvents: [courseEvent, ...courseDateEvents, ...unitEvents]
    };
  }
});