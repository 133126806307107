define("cc-frontend/lib/actions/course/calendar/RESIZE_UNIT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _unitUtils, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "RESIZE_UNIT",
    params: {
      unitId: "string",
      amount: "number",
      course: "object",
      startOrEndOfUnit: "string",
      planbook: "object",
      rotationCalendar: "object"
    },

    /**
      @param {{
        unit: Object,
        amount: Number,
        newDate?: String,
        courseId: String,
        courseDates: Object[],
        startOrEndOfUnit: "START" | "END",
        consolidatedCourseDatesOff: Object[],
        schoolDays: Number[]
      }} payload [description]
      @return {Array} Patches
     */
    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let unit = (0, _lodashEs.find)(payload.course.attributes.calendar.units, {
        id: payload.unitId
      });
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let newCourseDates;

      if (unit && payload.startOrEndOfUnit === "END") {
        newCourseDates = (0, _unitUtils.moveEndOfUnit)(payload.amount, payload.newDate, unit, payload.course.attributes.calendar.dates, payload.course.attributes.calendar.units, consolidatedCourseDatesOff, schoolDays);
      }

      if (unit && payload.startOrEndOfUnit === "START") {
        newCourseDates = (0, _unitUtils.moveStartOfUnit)(payload.amount, payload.newDate, unit, payload.course.attributes.calendar.dates, payload.course.attributes.calendar.units, consolidatedCourseDatesOff, schoolDays);
      }

      let ret;

      try {
        ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      } catch (e) {
        console.error(e);
        return [];
      }

      let {
        courseDates,
        units
      } = ret;
      let unitIdsOnTheMove = (0, _lodashEs.get)((0, _lodashEs.find)(units, {
        id: payload.unitId
      }), "descendantIds") || [];
      unitIdsOnTheMove = unitIdsOnTheMove.concat([payload.unitId]);
      let cardStackIdsOnTheMove = [];

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch;

      patch.unitIdsOnTheMove = unitIdsOnTheMove;
      patch.cardStackIdsOnTheMove = cardStackIdsOnTheMove;
      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      var _models$unit, _models$unit2, _models$course;

      let course = payload.course;
      let models = {
        course: course,
        planbook: findFn("planbook", payload.planbook.id),
        unit: findFn("card-stack-summary", payload.unitId)
      };
      let unitTitle = (_models$unit = models.unit) === null || _models$unit === void 0 ? void 0 : _models$unit.attributes.title;
      let amount = Math.abs(payload.amount);
      let direction = payload.amount > 0 ? "longer" : "shorter";
      let unit = (0, _lodashEs.find)(course.attributes.calendar.units, {
        id: payload.unitId
      });
      let dayOrDays = payload.amount === 1 ? "day" : "days";
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "unit",
        primary_object_id: payload.unitId,
        primary_object_specifier: null,
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
        adverbial_verb: "resizing",
        adverbial_object_type: "unit",
        adverbial_object_id: payload.unitId,
        adverbial_object_specifier: null,
        adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id]
      };
      return {
        title: "Resized Unit",
        titleForAnalytics: "Unit - Resize",
        verbForStream: "edited",
        description: `Resized "${unitTitle}" to make it ${direction} by ${amount} ${dayOrDays}`,
        context: {
          unitTitle: (_models$unit2 = models.unit) === null || _models$unit2 === void 0 ? void 0 : _models$unit2.attributes.title,
          courseTitle: (_models$course = models.course) === null || _models$course === void 0 ? void 0 : _models$course.attributes.title,
          planbookTitle: models.planbook.attributes.title,
          unitId: payload.unitId,
          courseId: payload.course.id,
          planbookId: models.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${models.course.id}`,
          to: [`course-edits:course-${models.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.default = _default;
});