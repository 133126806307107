define("cc-frontend/components/icons/pencil-and-notebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" fill="none" viewBox="0 0 49 48">
    <path
      fill="#FFBC44"
      d="M8.209 5.792H4.376a1.917 1.917 0 0 0-1.917 1.917v32.583a1.917 1.917 0 0 0 1.917 1.917h3.833"
    ></path>
    <path
      fill="#FFDDA1"
      d="M31.209 7.709v32.583a1.917 1.917 0 0 1-1.917 1.917H8.21V5.792h21.083a1.917 1.917 0 0 1 1.917 1.917Z"
    ></path>
    <path
      stroke="#191919"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M8.209 5.792H4.376a1.917 1.917 0 0 0-1.917 1.917v32.583a1.917 1.917 0 0 0 1.917 1.917h3.833"
    ></path>
    <path fill="#FFEF5E" d="M46.542 13.458h-7.667v24.917h7.667V13.458Z"></path>
    <path fill="#FFBC44" d="M46.542 13.458h-7.667l3.833-11.5 3.834 11.5Z"></path>
    <path fill="#FF808C" d="M38.875 38.375h7.667v3.833a3.833 3.833 0 0 1-7.667 0v-3.833Z"></path>
    <path
      stroke="#191919"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M31.209 7.709v32.583a1.917 1.917 0 0 1-1.917 1.917H8.21V5.792h21.083a1.917 1.917 0 0 1 1.917 1.917Z"
    ></path>
    <path fill="#fff" d="M13.959 15.375h11.5v5.75h-11.5v-5.75Z"></path>
    <path
      stroke="#191919"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M46.542 13.458h-7.667v24.917h7.667V13.458Zm0 0h-7.667l3.833-11.5 3.834 11.5Zm-7.667 24.917h7.667v3.833a3.833 3.833 0 0 1-7.667 0v-3.833Zm-24.916-23h11.5v5.75h-11.5v-5.75Z"
    ></path>
  </svg>
  */
  {
    "id": "FYL0bgOL",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"49\"],[14,\"height\",\"48\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 49 48\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FFBC44\"],[14,\"d\",\"M8.209 5.792H4.376a1.917 1.917 0 0 0-1.917 1.917v32.583a1.917 1.917 0 0 0 1.917 1.917h3.833\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FFDDA1\"],[14,\"d\",\"M31.209 7.709v32.583a1.917 1.917 0 0 1-1.917 1.917H8.21V5.792h21.083a1.917 1.917 0 0 1 1.917 1.917Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"#191919\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M8.209 5.792H4.376a1.917 1.917 0 0 0-1.917 1.917v32.583a1.917 1.917 0 0 0 1.917 1.917h3.833\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FFEF5E\"],[14,\"d\",\"M46.542 13.458h-7.667v24.917h7.667V13.458Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FFBC44\"],[14,\"d\",\"M46.542 13.458h-7.667l3.833-11.5 3.834 11.5Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FF808C\"],[14,\"d\",\"M38.875 38.375h7.667v3.833a3.833 3.833 0 0 1-7.667 0v-3.833Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"#191919\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M31.209 7.709v32.583a1.917 1.917 0 0 1-1.917 1.917H8.21V5.792h21.083a1.917 1.917 0 0 1 1.917 1.917Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#fff\"],[14,\"d\",\"M13.959 15.375h11.5v5.75h-11.5v-5.75Z\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"stroke\",\"#191919\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M46.542 13.458h-7.667v24.917h7.667V13.458Zm0 0h-7.667l3.833-11.5 3.834 11.5Zm-7.667 24.917h7.667v3.833a3.833 3.833 0 0 1-7.667 0v-3.833Zm-24.916-23h11.5v5.75h-11.5v-5.75Z\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/pencil-and-notebook.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});