define("cc-frontend/lib/actions/class-website/REMOVE_COURSE_FROM_CLASS_WEBSITE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/class-website/default-class-website-activity", "cc-frontend/lib/actions/class-website/default-class-website-context", "cc-frontend/lib/actions/class-website/default-class-website-models"], function (_exports, _patchUpdater, _base, _planbook, _lodashEs, _defaultClassWebsiteActivity, _defaultClassWebsiteContext, _defaultClassWebsiteModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeCourseFromClassWebsiteAction;

  function removeCourseFromClassWebsiteAction(payload) {
    return {
      name: "REMOVE_COURSE_FROM_CLASS_WEBSITE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("class-website", payload.websiteId).pull("relationships.courses.data", {
      id: payload.courseId,
      type: "course"
    }).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("class-website", payload.websiteId).push("relationships.courses.data", {
      id: payload.courseId,
      type: "course"
    }).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultClassWebsiteModels.default)(payload, findFn, userId);
    let courseTitle = (0, _lodashEs.get)(models, "course.attributes.title");
    let websiteTitle = (0, _lodashEs.get)(models, "classWebsite.attributes.title");
    let websiteSlug = (0, _lodashEs.get)(models, "classWebsite.attributes.slug");
    let context = (0, _lodashEs.assign)({}, (0, _defaultClassWebsiteContext.default)(payload, models), {
      websiteTitle,
      courseTitle,
      websiteSlug
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: null,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "class_website",
      primary_object_id: payload.websiteId,
      primary_object_specifier: null,
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.websiteId],
      adverbial_verb: "removing",
      adverbial_object_type: "course",
      adverbial_object_id: payload.courseId,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.courseId]
    };
    return {
      title: "Removed class from class website",
      titleForAnalytics: "Class Website - Remove Course",
      activity: (0, _defaultClassWebsiteActivity.default)(payload, models, "removed"),
      description: `Removed ${courseTitle} from the "${websiteTitle}" website`,
      context,
      newAnalyticsEvents: [newEvent]
    };
  }
});