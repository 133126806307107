define("cc-frontend/components/planner/planbook/class-website/settings-wrapper", ["exports", "cc-frontend/lib/actions/class-website/CREATE_CLASS_WEBSITE", "cc-frontend/lib/object-id-gen"], function (_exports, _CREATE_CLASS_WEBSITE, _objectIdGen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @classWebsites as |classWebsite|}}
    {{#if classWebsite.isFulfilled}}
      <Planner::Planbook::ClassWebsite::Settings
        @classWebsite={{classWebsite.content}}
        @courses={{@courses}}
        @planbook={{@planbook}}
      />
    {{/if}}
  {{/each}}
  <div class="class-website-settings-modal__footer">
  
    <div
      class="btn btn-default btn-lg class-website-settings-modal__new-website test-create-class-website"
      {{on "click" this.createNewClassWebsite}}
    >
      Create a class website
    </div>
  </div>
  {{yield}}
  */
  {
    "id": "Lbr1kRNY",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[41,[30,2,[\"isFulfilled\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@classWebsite\",\"@courses\",\"@planbook\"],[[30,2,[\"content\"]],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[10,0],[14,0,\"class-website-settings-modal__footer\"],[12],[1,\"\\n\\n  \"],[11,0],[24,0,\"btn btn-default btn-lg class-website-settings-modal__new-website test-create-class-website\"],[4,[38,4],[\"click\",[30,0,[\"createNewClassWebsite\"]]],null],[12],[1,\"\\n    Create a class website\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,5,null]],[\"@classWebsites\",\"classWebsite\",\"@courses\",\"@planbook\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"planner/planbook/class-website/settings\",\"on\",\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/planbook/class-website/settings-wrapper.hbs",
    "isStrictMode": false
  });

  let SettingsWrapper = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SettingsWrapper extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    createNewClassWebsite() {
      Ember.get(this, "store").dispatchPreparedAction((0, _CREATE_CLASS_WEBSITE.default)({
        // @ts-ignore remove once we type the file
        planbookId: Ember.get(this, "planbook.id"),
        websiteId: _objectIdGen.default.create().toLowerCase()
      }));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createNewClassWebsite", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "createNewClassWebsite"), _class.prototype)), _class));
  _exports.default = SettingsWrapper;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SettingsWrapper);
});