define("cc-frontend/components/icons/search-magnifying-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="currentColor"
    viewBox="0 0 19 19"
  >
    <path
      fill-rule="evenodd"
      d="M7.517.6a7.541 7.541 0 0 0-2.975.794A7.825 7.825 0 0 0 .234 7.499C.154 8.163.193 9.2.325 9.885c.61 3.16 3.157 5.668 6.31 6.212 2.153.372 4.388-.163 6.07-1.453.15-.115.283-.21.293-.21.01 0 .922.904 2.025 2.009 1.156 1.157 2.063 2.038 2.14 2.078a.866.866 0 0 0 .58.034.889.889 0 0 0 .392-.37.814.814 0 0 0-.011-.625c-.04-.077-.921-.984-2.078-2.14a153.292 153.292 0 0 1-2.008-2.025c0-.01.094-.142.21-.293.767-1 1.295-2.264 1.488-3.56.1-.672.1-1.612 0-2.288A7.835 7.835 0 0 0 9.298.687a7.016 7.016 0 0 0-.691-.074c-.2-.01-.426-.022-.502-.028A7.404 7.404 0 0 0 7.517.6Zm-.041 1.453c-2.78.214-5.16 2.332-5.715 5.082a6.369 6.369 0 0 0 2.71 6.554 6.343 6.343 0 0 0 6.86.138 6.641 6.641 0 0 0 2.1-2.1 6.355 6.355 0 0 0 .312-6.072A6.376 6.376 0 0 0 8.468 2.05c-.476-.037-.488-.037-.992.002Z"
      clip-rule="evenodd"
    ></path>
  </svg>
  */
  {
    "id": "P1B2UMS4",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"19\"],[14,\"height\",\"19\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 19 19\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M7.517.6a7.541 7.541 0 0 0-2.975.794A7.825 7.825 0 0 0 .234 7.499C.154 8.163.193 9.2.325 9.885c.61 3.16 3.157 5.668 6.31 6.212 2.153.372 4.388-.163 6.07-1.453.15-.115.283-.21.293-.21.01 0 .922.904 2.025 2.009 1.156 1.157 2.063 2.038 2.14 2.078a.866.866 0 0 0 .58.034.889.889 0 0 0 .392-.37.814.814 0 0 0-.011-.625c-.04-.077-.921-.984-2.078-2.14a153.292 153.292 0 0 1-2.008-2.025c0-.01.094-.142.21-.293.767-1 1.295-2.264 1.488-3.56.1-.672.1-1.612 0-2.288A7.835 7.835 0 0 0 9.298.687a7.016 7.016 0 0 0-.691-.074c-.2-.01-.426-.022-.502-.028A7.404 7.404 0 0 0 7.517.6Zm-.041 1.453c-2.78.214-5.16 2.332-5.715 5.082a6.369 6.369 0 0 0 2.71 6.554 6.343 6.343 0 0 0 6.86.138 6.641 6.641 0 0 0 2.1-2.1 6.355 6.355 0 0 0 .312-6.072A6.376 6.376 0 0 0 8.468 2.05c-.476-.037-.488-.037-.992.002Z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "cc-frontend/components/icons/search-magnifying-glass.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});